<template>
  <div class="p-3 border rounded-sm border-gray-50 shadow-lg">
    <div class="text-gray-800 font-semibold py-2 px-3 rounded-t-md">
      {{ titleCard }}
    </div>
    <div class="text-gray-500 flex flex-wrap  gap-4 mt-2">
      <div
        v-for="(monthData, monthName) in processedData"
        :key="monthName"
        class="p-3 border-2 border-blue-50 rounded-md shadow shadow-blue-100"
      >
        <h2 class="text-gray-800 font-bold text-sm">{{ monthName }}</h2>
        <div v-for="(productData, productName) in monthData" :key="productName">
          <div class="bg-blue-50 p-1 mt-2 rounded-md">
            <h3 class="text-gray-800 font-bold text-xs mt-2">
              {{ productName }}
            </h3>
            <div class="py-1">
              <div class="font-bold text-xs">
                Venta total:
                <span
                  class="font-bold"
                  :class="{ 'text-blue-500': productData.venta_total > 0 }"
                >
                  {{ money() }} {{ format(productData.venta_total) }}
                </span>
              </div>
              <div class="font-bold text-xs">
                Ganancias:
                <span
                  class="font-bold"
                  :class="{ 'text-blue-500': productData.ganancias > 0 }"
                >
                  {{ money() }} {{ format(productData.ganancias) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

import { money, format} from "@/publicjs/money";
export default {
  name: "SalesTypeOfProducts",
  props: {
    datas: {
      type: Object,
      required: true,
    },
    titleCard: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const processedData = computed(() => {
      const monthNames = Object.keys(props.datas);
      return monthNames.reduce((acc, monthName) => {
        const monthData = props.datas[monthName];
        const products = Object.keys(monthData);
        const processedProducts = products.reduce((productAcc, productName) => {
          const productData = monthData[productName];
          const parsedProductData = {
            ganancias: parseNumber(productData.ganancias),
            venta_total: parseNumber(productData.venta_total),
          };
          return { ...productAcc, [productName]: parsedProductData };
        }, {});
        return { ...acc, [monthName]: processedProducts };
      }, {});
    });

    const parseNumber = (value) => {
      const numberString = value.replace(/,/g, "");
      return parseFloat(numberString);
    };

    return {
      processedData,
      money, 
      format
    };
  },
};
</script>
