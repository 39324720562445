<template>
  <div class="mt-4">
    <div class="flex flex-wrap md:flex-nowrap items-end gap-4 mb-6">
      <label class="block w-full md:w-auto">
        <span
          class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
        >
          Fec. de inicio
        </span>
        <input
          type="date"
          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
          v-model="dataForm.dateStart"
          @keydown.enter.prevent
          @change="resetValuesAndGetData"
        />
      </label>
      <label class="block w-full md:w-auto">
        <span
          class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
        >
          Fec. término
        </span>
        <input
          type="date"
          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
          v-model="dataForm.dateEnd"
          @keydown.enter.prevent
          @change="resetValuesAndGetData"
        />
      </label>
      <label class="block w-full md:w-auto">
        <span
          class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
        >
          Tasa de impuesto por tarjeta
        </span>
        <select
          class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full focus:border-blue-500 uppercase"
          v-model="dataForm.tax"
          @change="resetValuesAndGetData"
        >
          <option value="0">NINGUNA</option>
          <option value="3">3 %</option>
          <option value="4">4 %</option>
          <option value="5">5 %</option>
          <option value="6">6 %</option>
          <option value="7">7 %</option>
          <option value="8">8 %</option>
          <option value="9">9 %</option>
          <option value="10">10 %</option>
          <option value="11">11 %</option>
          <option value="12">12 %</option>
          <option value="13">13 %</option>
          <option value="14">14 %</option>
          <option value="15">15 %</option>
        </select>
      </label>
      <button
        class="bg-blue-500 text-white py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
        @click="resetValuesAndGetData"
      >
        Generar consulta
      </button>
      <button
        class="bg-green-500 text-white py-3 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-green-500"
        @click="exportPDF(registers, dataForm.dateStart, dataForm.dateEnd)"
      >
        <font-awesome-icon icon="fa-solid fa-print" />
      </button>
      <div class="text-3xl text-slate-600 uppercase">
        <strong class="font-bold text-blue-600">Ganancia:</strong>
        {{ money() }} {{ format(revenue) }}
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="1000">1000 Registros</option>
          <option value="2000">2000 Registros</option>
          <option value="3000">3000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="12" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.fecha_de_emision }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombre_producto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.cantidad }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.utilidad }} %
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.precio_compra) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.precio_de_venta) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.igv_aplicado }} %
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.descuento_aplicado }}%
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    :class="
                      register.retencion > 0
                        ? 'text-white bg-red-500 px-2 py-1 rounded-md'
                        : ''
                    "
                  >
                    {{ register.retencion }} %
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.total_retencion) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap bg-green-50 text-green-500"
                >
                  {{ money() }} {{ format(register.ganancia) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions } from "@/alerts";
import { idLocalStore } from "@/publicjs/localStorage";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import { money, format } from "@/publicjs/money";
import calculateUtility from "@/publicjs/calculateUtility";
import exportPDF from "@/components/Reports/Sales/DailyReport/exportPDF";
import { currentDay } from "@/publicjs/currentDate";
export default {
  name: "DailyReport",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const revenue = ref(0);
    const dataForm = ref({
      dateEnd: "",
      dateStart: "",
      tax: 5,
      store: 0,
    });
    const header = [
      [
        {
          title: "fec. venta",
        },
        {
          title: "producto",
        },
        {
          title: "cant.",
        },
        {
          title: "utilidad",
        },
        {
          title: "p. compra",
        },
        {
          title: "p. vendido",
        },
        {
          title: "itbis. aplicado",
        },
        {
          title: "desc. aplicado",
        },
        {
          title: "retencion x tarj.",
        },
        {
          title: "venta en bruto",
        },
        {
          title: "total desc.",
        },
        {
          title: "ganancia",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(1000);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      revenue.value = 0;
      loading.value = true;
      const response = await axios
        .get(
          `reporte-diario-de-productos-vendidos/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}&store=${dataForm.value.store}`
        )
        .catch((error) => errorActions(error));

      const data = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      // Recorrer la lista y agregar propiedades
      //   data.forEach((dato) => {
      //     const precioCompra = parseFloat(dato.precio_de_compra);

      //     // Calcular retención (solo si la descripción es "TARJETA")
      //     const tax = parseFloat(dataForm.value.tax) / 100;

      //     const retencion = dato.descripcion === "TARJETA" ? tax : 0;

      //     //Calcular la nueva utilidad
      //     const newPriceSale =parseFloat(dato.total) / parseFloat(dato.cantidad)
      //     dato.utilidad = calculateUtility(precioCompra,newPriceSale)

      //     // Calcular ganancia
      //     const totalPrecioCompra = parseFloat(
      //       (parseFloat(dato.cantidad) * precioCompra).toFixed(2)
      //     );
      //     const calcGanancia = parseFloat(dato.total) - totalPrecioCompra;
      //     const totalRetencion  = calcGanancia / (1 + retencion)

      //     const ganancia =
      //       retencion > 0 ? totalRetencion : calcGanancia;

      //     // Agregar propiedades al objeto actual
      //     dato.precio_compra = precioCompra;
      //     dato.retencion = retencion;
      //     dato.ganancia = parseFloat(ganancia.toFixed(2));
      //     dato.total_retencion =parseFloat( calcGanancia - totalRetencion).toFixed(2)
      //     revenue.value += parseFloat(ganancia.toFixed(2));
      //   });
      //   registers.value = data;
      //   loading.value = false;
      // };
      data.forEach((dato) => {
        const totalSale = dato.total;
        const precioCompra = parseFloat(dato.precio_de_compra);

        // Calcular retención (solo si la descripción es "TARJETA")
        const tax = parseFloat(dataForm.value.tax) / 100;

        const retencion = dato.descripcion === "TARJETA" ? tax : 0;

        //Calcular la nueva utilidad
        const newPriceSale = parseFloat(totalSale) / parseFloat(dato.cantidad);
        dato.utilidad = calculateUtility(precioCompra, newPriceSale);

        // Calcular ganancia
        const totalPrecioCompra = parseFloat(
          (parseFloat(dato.cantidad) * precioCompra).toFixed(2)
        );

        //el neuvo precio de la factura
        const retencionAmount = totalSale * retencion;
        const newTotalSale = totalSale - retencionAmount;

        const calcGanancia = parseFloat(newTotalSale) - totalPrecioCompra;
        
        const totalRetencion = totalSale - newTotalSale;

        // const ganancia = retencion > 0 ? calcGanancia - totalRetencion : calcGanancia;

        // Agregar propiedades al objeto actual
        dato.precio_compra = precioCompra;

        dato.retencion = retencion;
        dato.ganancia = parseFloat(calcGanancia.toFixed(2));
        dato.total_retencion = parseFloat(totalRetencion).toFixed(2);
        revenue.value += parseFloat(calcGanancia.toFixed(2));
      });
      registers.value = data;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(() => {
        if (widthNav.value) {
          widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
        }
      });
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);

      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart =currentDay();
      dataForm.value.dateEnd = dates.lastDay;
      dataForm.value.store = await idLocalStore();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      dataForm,
      money,
      format,
      exportPDF,
      revenue,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
