<template>
    <div>
      <h2 class="text-1xl font-bold text-gray-700">
        Configuración de correos
      </h2>
      <MainEmails />
      <div class="py-3 px-3 bg-orange-100 text-orange-600 text-sm rounded-lg mt-3">
        La lista de correos que usted ingresa en este espacio, le servirá para que el sistema le notifique cuando el usuario apertura o cierra la caja.
      </div>
    </div>
  </template>
  
  <script>
  import MainEmails from "@/components/ConfigurationModules/Emails/MainEmails.vue";
import { useRoute } from 'vue-router';
import { onBeforeMount, onMounted } from 'vue';
import { findSubmoduleIdByDescription } from '@/hisAcces';
import Iva from "/public/apis/config.json"
  export default {
    name: "ConfigEmails",
    components: {
        MainEmails,
    },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
    return {
        Iva
    }
  },
  };
  </script>
  