<template>
  <div class="mb-6 flex flex-wrap gap-6">
    <div
      class="bg-orange-50 text-sm text-orange-500 font-semibold px-3 py-3 rounded-md"
    >
      Productos por vencer:
      <span class="rounded-md px-3 py-1 bg-orange-100">{{ productsToExpired }}</span>
    </div>
    <div
      class="bg-red-50 text-sm text-red-500 font-semibold px-3 py-3 rounded-md"
    >
      Productos vencidos:
      <span class="rounded-md px-3 py-1 bg-red-100"> {{ expiredProducts }} </span>
    </div>
    <div
      class="bg-orange-50 text-sm text-orange-500 font-semibold px-3 py-3 rounded-md"
    >
      Productos con stock minimo:
      <span class="rounded-md px-3 py-1 bg-orange-100">{{ productsWhitMinimumStock }}</span>
    </div>
    <div
      class="bg-red-50 text-sm text-red-500 font-semibold px-3 py-3 rounded-md"
    >
      Productos agotados:
      <span class="rounded-md px-3 py-1 bg-red-100">{{outOfStockProducts}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportantReport",
  props:{
    expiredProducts:{
      type:Number,
      required:true
    },
    productsToExpired:{
      type:Number,
      required:true
    },
    productsWhitMinimumStock:{
      type:Number,
      required:true
    },
    outOfStockProducts:{
      type:Number,
      required:true
    }
  }
};
</script>
