<template>
  <div>
    <div
      class="bg-orange-50 px-3 py-3 rounded-md shadow shadow-orange-200 mb-5 mt-6"
    >
      <h2 class="text-orange-700 text-lg font-semibold uppercase">
        ¡¡¡Importante!!! modulos de compras
      </h2>
      <p class="text-orange-500 pt-3">
        <strong class="text-sm block uppercase"> N°1 Seccion compras </strong>
        En la seccion de compras detalle de compras cuando se cambia de precio
        de compra del producto el sistema genera un nuevo precio de venta.

        <br />
        <br />
        <strong class="text-sm block uppercase"
          >N°2 Configuración de lote:</strong
        >
        Cuando se quiere registrar un lote del producto debemos configurar la
        fecha de vencimiento del lote y la descripcion del lote para poder
        indentificar ese lote

        <br />
        <br />
        <strong class="text-sm block uppercase"
          >N°3 Busqueda de productos:</strong
        >
        Por defecto el sistema trae la busqueda de producto por codigo de barra
        y si queremos cambiar el tipo de busqueda solo debemos darle click a
        <code class="bg-orange-100 font-semibold">"por cod. de barra"</code> y
        el sistema cambiar el tipo de busqueda del producto por
        <code class="bg-orange-100 font-semibold"
          >"nombre o codigo de barra"</code
        >
      </p>
      <p class="text-orange-500 pt-3 mt-3">
        <strong class="text-sm block uppercase"
          >N°4 Impresión de comprobantes:</strong
        >
        Al momento de realizar la compra no se genera un comprobante pero, lo
        que podemos hacer es ir la seccion de
        <code class="bg-orange-100 font-semibold">"listado de compras"</code>
        buscar la compra que queremos imprimir o ver su detalle, poner el cursor
        en el boton de más opciones y click en la opcion de
        <code class="bg-orange-100 font-semibold">"imprimir compra"</code>
      </p>
      <p class="text-orange-500 pt-3 mt-3">
        <strong class="text-sm block uppercase">N°5 Estado de compras:</strong>
        Cuando se genera la compra el sistema por defecto guarda el estado de la
        compra con VIGENTE pero aqui te explico que significa todo eso:
        <br />
        <code class="bg-orange-100 font-semibold">"VIGENTE"</code> la cual
        quiere decir que la compra esta activa y los productos que ingresaron
        estan conformes y pasaron a LOTE, y KARDEX. <br />
        <code class="bg-orange-100 font-semibold">"ANULADO"</code> la cual
        quiere decir que la compra a sido anulada y que los productos que
        estaban en KARDEX, LOTE y almacen han sido quitados. <br />
        <code class="bg-orange-100 font-semibold">"DEVOLUCION"</code> la cual
        quiere decir que la venta a sido devuelta al proveedor que se le hizo la
        compra y que los productos que estaban en KARDEX, LOTE y almacen han
        sido quitados
      </p>
    </div>
    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div class="boder rounded-lg shadow-md px-3 py-3 bg-blue-50">
        <h2 class="text-gray-800 text-md font-semibold">
          Configuraciones tipo de pagos
        </h2>
        <p class="text-gray-500 text-sm border-b-2 pb-2 mb-2">
          La tabla de tipo de pago debe de estar con el ID inicial [1]
        </p>
        <div class="ml-3">
          <ul class="text-gray-500 text-sm">
            <li>EFECTIVO</li>
            <li>TARJETA</li>
            <li>TRANSFERENCIA</li>
            <li>CHEQUE</li>
            <li>PAGO COMBINADO</li>
          </ul>
        </div>
      </div>
      <div class="boder rounded-lg shadow-md px-3 py-3 bg-blue-50">
        <h2 class="text-gray-800 text-md font-semibold">
          Configuraciones tipo de venta o compra
        </h2>
        <p class="text-gray-500 text-sm border-b-2 pb-2 mb-2">
          La tabla de tipo de venta o compra debe de estar con el ID inicial con
          [1]
        </p>
        <div class="ml-3">
          <ul class="text-gray-500 text-sm">
            <li>CONTADO</li>
            <li>CREDITO</li>
          </ul>
        </div>
      </div>
      <div class="boder rounded-lg shadow-md px-3 py-3 bg-blue-50">
        <h2 class="text-gray-800 text-md font-semibold">
          Configuraciones número de referencia para la parte de ventas
        </h2>
        <p class="text-gray-500 text-sm border-b-2 pb-2 mb-2">
          El numero de referencia se ingresara de forma manual a la base de
          datos.
          <br />
          El unico requerimiento es que sea de 8 digitos
        </p>
        <div class="ml-3">
          <ul class="text-gray-500 text-sm">
            <li>00000000</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-red-50 px-3 py-3 rounded-md shadow shadow-red-200 mb-5 mt-6 hidden">
      <h2 class="text-red-700 text-lg font-semibold uppercase">
        AUMENTAR EL TIEMPO DE ESPERA DEL SERVIDOR PARA ACTUALIZAR DATOS DE LA
        DGII
      </h2>
      <p class="text-red-500 pt-3">
        <strong class="text-sm block uppercase"> max_execution_time </strong>
        Ubicar esa seccion en php.ini y aumentar a 720
        <code>max_execution_time=720</code> <br /><br />
        <strong class="text-sm block uppercase"> max_input_time </strong>
        Ubicar esa seccion en php.ini y aumentar a 720
        <code>max_input_time=720</code> <br /><br />
        <strong class="text-sm block uppercase"> extension=zip </strong>
        Ubicar esa seccion en php.ini y borrar la coma para manipular archivos
        zip
        <code>extension=zip</code> <br /><br />
      </p>
    </div>
    <div class="bg-gray-50 px-3 py-3 rounded-md shadow mb-5 mt-6">
      <h2 class="text-gray-700 text-lg font-semibold uppercase">
        INGRESO DE PRODUCTOS DESDE LA SECCION DE VENTAS
      </h2>
      <p class="text-gray-500 pt-3">
        Cuando se ingresa un producto de la seccion de ventas, el sistema
        actualizará el stock del producto además el producto sera registrado al
        ultimo ingreso a kardex, si el kardex estaba inactivo o se usó todos los
        productos, el sistema actualizará el el nuevo monto del kardex y
        activará dicha kardex.
        <br />
        <strong>
          El ingreso de producto a almacen por este medio debe de realizarse
          antes de agregar los demas productos por temas de seguridad
        </strong>
        <br />
        <br />
        El monto que pagará el cliente es el que esta marcado de color naranja
      </p>
    </div>
    <div class="bg-gray-50 px-3 py-3 rounded-md shadow mb-5 mt-6">
      <h2 class="text-gray-700 text-lg font-semibold uppercase">
        Aplicar comisión por app de delivery
      </h2>
      <p class="text-gray-500 pt-3">
        Si usted quiere aplicar un precio adicional por el envio del producto a
        domicilio, solo selecciona la empresa de delivery que esta haciendo
        dicho movimiento de producto el sistema aplicara la comision
        configurada.
        <br />
        <strong>
          Lo recomendable y lo correcto es aplicar dicha comisión de envio de
          producto despues de haber cargado todos lo productos.
        </strong>
      </p>
    </div>
    <div class="bg-gray-50 px-3 py-3 rounded-md shadow mb-5 mt-6">
      <h2 class="text-gray-700 text-lg font-semibold uppercase">
        Comprobantes minimos requeridos
      </h2>
      <p class="text-gray-500 pt-3">
        Para que el sistema pueda funcionar es recomendable que exista los siguiente comprobantes.
        <br />
        <strong class="block">
          1: NOTA DE CREDITO <span class="font-semibold">Este comprobante se utiliza al momento de devolver un compra o realizar un nota de credito de la venta si el comprobante se a a usar en la venta debe de configurar el numero en envio de la entidad ficalizadora</span>
        </strong>
        <strong class="block">
          2: NOTA DE CREDITO INTERNO <span class="font-semibold">Este comprobante se utiliza cuando se quiere emitir una nota de credito no valido para la entidad fizcalidora, esto no modifica el estado de la venta a MODIFICADO</span>
        </strong>
        <strong class="block">
          3: ORDEN DE COMPRA <span class="font-semibold">Este comprobante se utiliza realizar la ordenes de compra y luego poder ser utilizado al momento de realizar la compra.</span>
        </strong>
        <strong class="block">
          4: COMPROBANTES PARA LA VENTA: <span class="font-semibold">Para realizar un venta por no menos debe de tener un comprobante ya sea factura, boletas, cotizaciones, pedidos, facturas proforma, etc.</span>
        </strong>
      </p>
    </div>

    <div class="mt-6 pt-3 text-blue-500 text-sm font-semibold">
      © {{ date }} J&A software
    </div>
  </div>
</template>

<script>
export default {
  name: "MainHelpMe",
  setup() {
    const newDate = new Date()
    const date = newDate.getUTCFullYear()
    return {date}
  }
};
</script>
