<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentFormAccess">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw] xl:min-w-[30vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Accesos a módulos específicos del sistema
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Sucursales
            </span>
            <Stores @send-data="getStores"  :selectOption="dataForm.idStore"/>
          </label>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Usuarios
            </span>
            <div
              v-if="refreshUser"
              class="py-2 text-sm rounded-lg border-2 px-3 bg-gray-100 text-gray-400"
            >
              Selecciona una sucursal
            </div>
            <Users v-else @send-data="getUsers" :idstore="dataForm.idStore" />
          </label>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Áreas específicas del sistema
            </span>
            <select
              class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] w-full"
              v-model="dataForm.areas"
              @change="changeOption"
            >
              <option
                :value="area.title"
                v-for="(area, i) in systemEspecificAccess"
                :key="i"
              >
                {{ area.title }}
              </option>
            </select>
          </label>
          <label class="block" v-show="dataForm.accesForSale">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Descuento en el módulo
            </span>
            <input
              type="number"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.discount"
              placeholder="Descuento en el módulo..."
              @keydown.enter.prevent
            />
          </label>
          <label class="block" v-show="dataForm.accesForSale">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Contraseña para aplicar descuento
            </span>
            <input
              type="password"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.password"
              placeholder="Ingresa una contraseña segura..."
              @keydown.enter.prevent
            />
          </label>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando acceso del usuario
              </div>
              <span v-else>Registrar acceso</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../../api";
import Users from "@/components/PublicComponents/Users.vue";
import Stores from "@/components/PublicComponents/Stores.vue";

import systemEspecificAccess from "/public/apis/system-especific-acces.json";
import { idLocalStore } from '@/publicjs/localStorage';

export default {
  name: "RegisterSystemEspecificAcess",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    Users,
    Stores,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      areas: "REALIZAR DESCUENTOS EN VENTA",
      discount: 0,
      password: null,
      accesForSale: true,
    });

    const getUsers = async (data) => {
      dataForm.value.idUser = data;
    };

    const refreshUser = ref(true); // variable para refresecar el componente de usuarios
    const getStores = async (data) => {
      refreshUser.value = true;
      dataForm.value.idStore = data;
      await getUsers();
      refreshUser.value = false;
    };

    const insert = async () => {
      if (dataForm.value.idUser <= 0) {
        errorActions(
          "Selecciona un  <strong>usuario</strong> para registrar su acceso"
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "accesos-especificos",
          {
            idUser: dataForm.value.idUser,
            areas: dataForm.value.areas,
            discount: dataForm.value.discount,
            password: dataForm.value.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El acceso al área   <strong>${dataForm.value.areas}</strong> del usuario `
        );
        dataForm.value.discount = 0
        dataForm.value.password = null
        props.getdata();
        dataForm.value.areas = "REALIZAR DESCUENTOS EN VENTA";
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.msg === "exist") {
        errorActions(
          `El usuario ya tiene un  <strong>acceso configurado para esa área</strong> intenta resgitrando otro acceso`
        );
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el acceso  <strong>${dataForm.value.areas}</strong> del usuario`
        );
      }

      spinner.value = false;
    };

    onBeforeMount(async ()=> {
      refreshUser.value = true;
      dataForm.value.idStore = await idLocalStore()
      refreshUser.value = false;
    })
    onMounted(() => {
      observeElement("#contentFormAccess");      
    });
    const changeOption = () => {
      if (dataForm.value.areas === "REALIZAR DESCUENTOS EN VENTA") {
        dataForm.value.accesForSale = true;
      } else {
        dataForm.value.accesForSale = false;
      }
    }
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getUsers,
      getStores,
      refreshUser,
      systemEspecificAccess,
      changeOption
    };
  },
};
</script>
