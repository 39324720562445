<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de empresas aseguradoras
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Nombres <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.names"
                placeholder="Nombres..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de documentos
              </span>
              <TypeOfDocuments @send-data="getSelectTypeOfDocuments" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de documentos <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberDocument"
                placeholder="N° de documentos..."
                @keydown.enter.prevent
                @keydown.enter="searchForDGII"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Dirección
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.address"
                placeholder="Dirección..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Teléfono / Celular
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.phone"
                placeholder="Teléfono / Celular..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>

            <label class="flex flex-col h-6 mr-5 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado de la empresa
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.state"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                </span>
              </div>
            </label>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../api";
import TypeOfDocuments from "@/components/PublicComponents/TypeOfDocuments.vue";
import { dgii } from '@/repository/DGII/dgii';

export default {
  name: "RegisterInsuranceCompanies",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    TypeOfDocuments,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      idTypeOfDocument: 0,
      names: "",
      address: "",
      numberDocument: "",
      phone: "",
      state: false,
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectTypeOfDocuments = (data) => {
      dataForm.value.idTypeOfDocument = data;
    };

    const insert = async () => {
      if (dataForm.value.idTypeOfDocument <= 0) {
        errorActions("Selecciona un tipo de  <strong>documento</strong>");
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.names.trim().length <= 2) {
        errorActions(
          "Ingresa la razón social de la <strong>empresa aseguradora</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.numberDocument <= 100000) {
        errorActions(
          "Ingresa un número de <strong>documento</strong> válido para la empresa aseguradora"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "aseguradoras",
          {
            idTypeOfDocument: dataForm.value.idTypeOfDocument,
            names: dataForm.value.names,
            address: dataForm.value.address,
            numberDocument: dataForm.value.numberDocument,
            phone: dataForm.value.phone,
            state: dataForm.value.state ? 1 : 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `La empresa  <strong>${dataForm.value.names}</strong>`
        );
        props.getdata();
        dataForm.value.names = "";
        dataForm.value.address = "";
        dataForm.value.numberDocument = 0;
        dataForm.value.phone = "";
        dataForm.value.state = false;
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.msg === "exist") {
        errorActions(
          `Ya existe una  <strong>empresa aseguradora</strong> registrado con el número de documento que estas intentando registrar`
        );
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar la empresa aseguradora <strong>${dataForm.value.names}</strong>`
        );
      }

      spinner.value = false; //dejando activo al boton
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };
    
    const searchForDGII = async () => {
        const data =await  dgii(dataForm.value.numberDocument)
        if(data !== null) {
          dataForm.value.numberDocument = data.numberDocument
          dataForm.value.names = data.bussinesName
        }
    }
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getSelectTypeOfDocuments,
      searchForDGII
    };
  },
};
</script>
