import axios from "@/api";
/**
 * Funcion para insertar el movimiento del producto
 * @param {*} data Los datos del producto ingresado
 * @returns El id del producto ingresado
 */
export const insertproductsAccordingToModuleEntered = async (data) => {
  const { idUser, idStore, idProduct, quantity, module } = data;
  try {
    const response = await axios.post(
      "productos-segun-modulo-ingresado",
      {
        idUser,
        idStore,
        idProduct,
        quantity,
        module,
      },
      {
        method: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    if (data.msg === true) {
      return data.id;
    }
  } catch (error) {
    console.log(error);
  }
};
