import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { money, format } from "@/publicjs/money"; //? Formateo y obtencion de moneda configurada de la empresa
import { errorActions } from "@/alerts"; //? Alertas de error, etc
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { imageInBase64 } from "@/publicjs/logoCompany"; //? Imagen de base64 de la empresa
import { formatDateTime } from "@/publicjs/formatDate"; //? Retorna la fecha y hora actual
import titlesConfig from "/public/apis/config.json"; //? Títulos de configuracion segun pais
import { convertCurrencyToLetters } from "@/publicjs/convertCurrencyToLetters"; //? Convierte moneda a letras
import styles from "@/publicjs/styles";
import axios from "@/api";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//* Función para imprimir el documento
/**
 * @param {*} idOrder | ID de la ultima orden realizada
 */
export const printVoucherOnA4 = async (idOrder) => {
  //* Poblado de datos de la empresa
  const dataStore = JSON.parse(localStorage.getItem("access"));
  const company = await getCompany(dataStore.user.store);

  //* Cargar la imagen o el logo de la empresa
  let base64Image = await imageInBase64(company.companyLogo);

  //? Obtencion de datos de la orden
  const registers = await order(idOrder); //! ID de la ultima orden realizada
  if (registers.length <= 0) {
    errorActions(
      "Lo sentimos hemos tenido unos incoveniente al generar este comprobante"
    );
  }

  try {
    let docDefinition = null;
    docDefinition = {
      // a string or { width: number, height: number }
      pageSize: "A4",

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "portrait", //cambia a [landscape] para imprimir de forma horizontal

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [24, 140, 24, 24],
      //!encabezado del documento con datos de la empresa, sucursal y datos del comprobante
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            columns: [
              {
                width: 100,
                stack: [
                  { image: base64Image, fit: [80, 80] }, // logo
                ],
                alignment: "left",
                margin: [0, 10, 0, 0],
              },
              {
                width: 285,
                stack: [
                  {
                    text: company.companyName,
                    fontSize: styles.fontSize.pdf.xl,
                    alignment: "left",
                    bold: true,
                    color: styles.colors.colorTitle,
                  },
                  {
                    text: "Dirección: " + company.companyAddress,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: "Tel:" + company.companycellphone,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: company.companySlogan,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text:
                      "Sucursal: ".toUpperCase() +
                      registers.sucursal.razon_social.toUpperCase(),
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    bold: true,
                    color: styles.colors.colorTitle,
                  },
                  {
                    text: "DIR: " + registers.sucursal.direccion.toUpperCase(),
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: "TEL: " + registers.sucursal.celular,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                ],
                alignment: "left",
                margin: [2, 10, 24, 0],
              },
              // Columna derecha para los datos de la empresa
              // Tabla para envolver los elementos y aplicar bordes
              {
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        stack: [
                          {
                            text:
                              titlesConfig.titleTypeDocument +
                              " " +
                              company.companyRUC,
                            fontSize: styles.fontSize.pdf.lg,
                            alignment: "center",
                            color: styles.colors.colorTitle,
                            bold: true,
                            margin: [0, 5, 0, 5],
                          },
                          {
                            text: registers.tipo_comprobante.descripcion,
                            fontSize: styles.fontSize.pdf.lg,
                            alignment: "center",
                            color: styles.colors.colorTitle,
                            bold: true,
                            margin: [0, 5, 0, 5],
                          },
                          {
                            text: `${registers.orden.serie} - ${registers.orden.numero_comprobante}`,
                            fontSize: styles.fontSize.pdf.lg,
                            alignment: "center",
                            color: styles.colors.colorTitle,
                            bold: true,
                            margin: [0, 5, 0, 5],
                          },
                        ],
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i, node) {
                    // Agrega un borde horizontal en la parte superior del primer elemento,
                    // y en la parte inferior de todos los elementos
                    if (i === 0 || i === node.table.body.length) {
                      return 2;
                    }
                    // No agrega bordes horizontales para los elementos internos
                    return 0;
                  },
                  hLineColor: function () {
                    // Establece el color del borde horizontal
                    return styles.colors.borderColor;
                  },
                  vLineColor: function () {
                    // CEstablece el color del borde vertical
                    return styles.colors.borderColor;
                  },
                  vLineWidth: function () {
                    // Agrega un borde vertical en el lado derecho de cada elemento
                    return 2;
                  },
                },

                alignment: "right",
                margin: [0, 10, 24, 0],
              },
            ],
            margin: [24, 12, 0, 0], // Margen entre el encabezado y el contenido de la página
          },
          {
            canvas: [
              { type: "rect", x: 24, y: 90, w: pageSize.width - 48, h: 2 },
            ],
            margin: [0, 10], // Margen entre el rectángulo y el contenido de la página
          },
        ];
      },

      //!pie de página del documento
      footer: function (currentPage, pageCount) {
        return {
          text:
            "Emitido por " +
            registers.usuario.nombres +
            "          /               Estas en la página " +
            currentPage.toString() +
            " de " +
            pageCount,
          alignment: "right",
          fontSize: styles.fontSize.pdf.sm,
          color: styles.colors.colorDescription,
          margin: [0, 0, 24, 0],
        };
      },
      content: [
        //? Encabezado del comprobante con el nombre del proveedor
        {
          table: {
            headerRows: 0,
            widths: [82, "*", 98, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Proveedor:",
                  border: [true, true, false, false],
                  fontSize: styles.fontSize.pdf.sm,
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: registers.proveedor.razon_social,
                  border: [false, true, false, false],
                  fontSize: styles.fontSize.pdf.sm,
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
                {
                  text: "Dirección:",
                  border: [false, true, false, false],
                  margin: [0, 5, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: registers.proveedor.direccion,
                  border: [false, true, true, false],
                  margin: [0, 5, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
              ],
              [
                {
                  text: "N° Documento:",
                  border: [true, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: registers.proveedor.ruc,
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
                {
                  text: "Fecha de emision:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: formatDateTime(registers.orden.fecha_de_emision),
                  border: [false, false, true, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
              ],
              [
                {
                  text: "Afecta a:",
                  border: [true, false, false, true],
                  margin: [0, 0, 0, 5],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: registers.comprobante_de_compra.descripcion,
                  border: [false, false, false, true],
                  margin: [0, 0, 0, 5],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
                {
                  text: "N° de compra:",
                  border: [false, false, false, true],
                  margin: [0, 0, 0, 5],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: registers.data_compra.numero_comprobante,
                  border: [false, false, true, true],
                  margin: [0, 0, 0, 5],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
              ],
            ],
          },

          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: [40, "*", 50, 50, 80, 80, 50], //ancho de la columnas
            body: [
              [
                {
                  text: "Cant.",
                  border: [true, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Descripción",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "U.M",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "P.U",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "SubTotal",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: titlesConfig.iva,
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Total",
                  border: [false, true, true, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
              ],
              ...registers.detalle.map((register) => [
                {
                  text: register.cantidad,
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [true, false, false, false],
                  valign: "middle",
                },
                {
                  text:
                    register.nombre_producto.length >= 28
                      ? register.nombre_producto.slice(0, 28) + "xxx"
                      : register.nombre_producto,
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: "NIU",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(register.precio_unitario),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(register.subtotal),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(register.igv),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(register.total),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  bold: true,
                  border: [false, false, true, false],
                  valign: "middle",
                },
              ]), // Datos dinámicos
            ],
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 15, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
        //? Total del comprobante
        {
          table: {
            headerRows: 1,
            widths: [75, "*", 80],
            body: [
              [
                {
                  qr:
                    registers.orden.serie +
                    "-" +
                    registers.orden.numero_comprobante,
                  fit: 65,
                  rowSpan: 4,
                  margin: [5, 10, 0, 0],
                  border: [true, true, false, true],
                  foreground: styles.colors.colorDescription,
                  background: styles.colors.white,
                },
                {
                  text: "SubTotal:",
                  border: [false, true, false, false],
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: money() + " " + format(registers.orden.subtotal),
                  border: [false, true, true, false],
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                },
              ],
              [
                "",
                {
                  text: titlesConfig.iva,
                  border: [false, false, false, false],
                  margin: [0, 2, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: money() + " " + format(registers.orden.igv),
                  border: [false, false, true, false],
                  margin: [0, 2, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                },
              ],
              [
                "",
                {
                  text: "Total:",
                  border: [false, false, false, false],
                  margin: [0, 2, 0, 5],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: money() + " " + format(registers.orden.total),
                  border: [false, false, true, false],
                  margin: [0, 2, 0, 5],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                },
              ],
              [
                "",
                {
                  text: convertCurrencyToLetters(
                    parseFloat(registers.orden.total)
                  ).toUpperCase(),
                  border: [false, false, true, true],
                  margin: [0, 2, -22, 5],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.pdf.xsm,
                  color: styles.colors.colorDescription,
                  bold: true,
                  colSpan: 2,
                },
                "",
              ],
            ],
          },

          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 0, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
      ],
    };
    pdfMake.createPdf(docDefinition).print();
  } catch (error) {
    errorActions("No se pudo generar el comprobante");
  }
};

const order = async (idOrder) => {
  try {
    const response = await axios.get(
      `imprimir-nota-de-credito-compras/?id=${idOrder}`
    );
    if (response.msg === false) {
      return [];
    }
    return await response.data;
  } catch (error) {
    errorActions(error);
  }
};
