<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <div class="flex items-center gap-3">
        <!-- acciones de impresion -->
        <div class="relative group">
          <button
            class="bg-blue-100 flex items-center justify-center w-28 h-9 rounded-md text-blue-600"
          >
            Opciones
            <font-awesome-icon
              icon="fa-solid fa-ellipsis"
              class="rotate-90 ml-2"
            />
          </button>
          <ul
            class="absolute top-full left-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100"
          >
            <button
              class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
              @click="exportPDF(registers, idstore)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a PDF
            </button>
            <button
              class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
              @click="allOrderPurchase(registers)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a excel
            </button>
          </ul>
        </div>
        <!-- fin acciones de impresion -->
      </div>

      <!-- componente para mfiltrar por estado de orden de compra -->
      <label class="block mt-3 md:mt-0 w-full md:w-auto">
        <span class="block text-gray-600 font-semibold mb-1"
          >Filtrar por estados</span
        >
        <select
          v-model="orderState"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
          @change="resetValuesAndGetData"
        >
          <option value="EMITIDO">EMITIDO</option>
          <option value="ANULADO">ANULADO</option>
          <option value="INGRESADO">INGRESADO</option>
        </select>
      </label>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre, codigo de barra o tipo de producto..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                      1: Para poner una tabla responsiva añadir lo siguiente:
                        1.1: class="resize-generate"
                        1.2: ref="widthNav" 
                        1.3: @resize="handleResize"
                      -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="8" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap bg-blue-50 text-blue-500"
                >
                  {{ register.serie }} {{ register.numero_de_comprobante }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.proveedor.razon_social }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap font-bold"
                >
                  {{ money() }} {{ format(register.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.forma_de_pago }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg"
                    :class="{
                      'bg-orange-50 text-orange-600':
                        register.fecha_de_entrega !== null,
                    }"
                  >
                    {{
                      register.fecha_de_entrega === null
                        ? "--/--/----"
                        : formatDate(register.fecha_de_entrega)
                    }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{
                    register.fecha_de_emision === null
                      ? "--/--/----"
                      : formatDateTime(register.fecha_de_emision)
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg text-sm"
                    :class="{
                      'bg-green-50 text-green-700':
                        register.estado === 'INGRESADO',
                      'bg-orange-50 text-orange-600':
                        register.estado === 'EMITIDO',
                      'bg-red-50 text-red-700': register.estado === 'ANULADO',
                    }"
                  >
                    {{ register.estado }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap flex justify-end"
                >
                  <div class="relative group">
                    <button
                      class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                    >
                      <font-awesome-icon icon="fa-solid fa-list-ul" />
                    </button>
                    <ul
                      class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                    >
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="showModal(register.detalle,register.id)"
                        v-if="register.estado === 'EMITIDO'"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-pen"
                          class="mr-2"
                        />
                        Modificar
                      </button>
                      <button
                        class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
                        @click="printOnA4(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir
                      </button>
                      <button
                        class="px-6 py-2 text-red-500 flex items-center hover:bg-red-50 whitespace-nowrap w-full"
                        @click="
                          isDelete(register.id, register.proveedor.razon_social)
                        "
                        v-if="register.estado === 'EMITIDO'"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-cancel"
                          class="mr-2"
                        />
                        Anular
                      </button>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
  <UpdateOrder
    :showModal="showModal"
    :getdata="resetValuesAndGetData"
    :id="idRegister"
    :details="dataOrder"
    v-if="isShowModalUpdate"
  />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observer } from "@/observer";
import Swal from "sweetalert2";
import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";
import { formatDateTime, formatDate } from "@/publicjs/formatDate";
//pdfmake exportar productos a pdf
import { exportPDF } from "@/components/PurchaseOrder/Main/Reports/PDFExportAllPurchaseOrder";

//Reporte en excel
import { allOrderPurchase } from "@/components/PurchaseOrder/Main/Reports/ExcelExportAllPurchaseOrde";

//Imprimir orden
import { printOnA4 } from "@/components/PurchaseOrder/Main/PrintVoucher/PrintOnA4";
import { uploadData } from "@/alerts";
import UpdateOrder from "./UpdateOrder.vue";

export default {
  name: "MainListPurchaseOrder",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    UpdateOrder,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "N° Orden",
        },
        {
          title: "proveedor",
        },
        {
          title: "total",
        },
        {
          title: "forma de pago",
        },
        {
          title: "plazo de entrega",
        },
        {
          title: "fecha de emision",
        },
        {
          title: "estado",
        },
        {
          title: "acciones",
        },
      ],
    ];

    const dataOrder = ref([]);
    const idRegister = ref(0);
    const isShowModalUpdate = ref(false);
    const showModal = (data = [], id = 0) => {
      dataOrder.value = data;
      idRegister.value = id;
      isShowModalUpdate.value = !isShowModalUpdate.value;
    };

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const orderState = ref("EMITIDO");
    const getData = async () => {
      const idStore = await idLocalStore();
      loading.value = true;
      const response = await axios
        .get(
          `orden-de-compras/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idStore}&state=${orderState.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //Acciones para eliminar
    const deleteData = async (id, msg) => {
      const response = await axios
        .put(
          "orden-de-compras/" + id,
          {
            state: "ANULADO",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = response.data;
      if (data.msg === "Request failed with status code 500") {
        errorActions(data.msg);
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      } else if (data.msg === false) {
        errorActions("Lo sentimos no pudimos anular la orden compra " + msg);
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
    };
    //SweetAlert para confirmas la eliminacion del registro
    const isDelete = (id, msg) => {
      Swal.fire({
        title: "¿Estas seguro que deseas anular la orden de compra?",
        html: `Si eliminas la orden de compra del proveedor <strong>${msg} </strong>, no podras recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡Eliminar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        uploadData(
          "Anulando orde de compra",
          "Espere mientras anulamos la orden de compra, solo tomará unos segundos"
        );
        if (result.isConfirmed) {
          await deleteData(id, msg);
          Swal.fire({
            icon: "success",
            title: "Orden de compra anulada",
            text: `${msg}, a sido eliminado de la base de datos`,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });
          //codigo para evitar el error de [resize observer]
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );

          await getData();
        }
      });
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      orderState,
      exportPDF,
      formatDateTime,
      formatDate,
      printOnA4,
      isDelete,
      allOrderPurchase,
      showModal,
      idRegister,
      dataOrder,
      isShowModalUpdate
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
