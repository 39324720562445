<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    id="main-content-credit-note-shopping"
  >
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[40vw]"
        id="subcontent-credit-note-shopping"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Devolucion de compra / nota de credito
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div class="w-full">
          <div>
            <h2 class="text-gray-700 font-bold text-sm">
              Detalles de a compra
            </h2>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <LoadingTables v-if="loading" :columns="5" />
                  <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                    <tr
                      v-for="(shopping, i) in arrayDataOrder.detalle"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ shopping.nombre_producto }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ shopping.cantidad }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(shopping.precio_de_compra) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(shopping.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-center"
                      >
                        <button
                          class="bg-blue-50 text-blue-500 text-sm font-bold px-2 py-1 rounded-md"
                          @click="
                            addProducts(
                              shopping.id,
                              shopping.idcompra,
                              shopping.nombre_producto,
                              shopping.cantidad,
                              shopping.precio_de_compra,
                              shopping.igv,
                              shopping.subtotal,
                              shopping.total,
                              shopping.igv_aplicado,
                              shopping.cantidad,
                              shopping.idproducto
                            )
                          "
                        >
                          <font-awesome-icon icon="fa-solid fa-plus" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mt-10 border-t-2 border-blue-300 pt-5">
            <h2 class="text-blue-500 font-bold text-md mb-3">
              Detalles de productos de devolver
            </h2>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <LoadingTables v-if="loading" :columns="5" />
                  <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                    <tr
                      v-for="(shopping, i) in arrayReturnProducts"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ shopping.productName }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="shopping.quantity"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="
                            handleQuantityChange(i, $event, shopping.igvAplied)
                          "
                          @keyup.enter="
                            handleQuantityChange(i, $event, shopping.igvAplied)
                          "
                        />
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(shopping.purchasePrice) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(shopping.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-center"
                      >
                        <button
                          class="bg-red-50 text-red-500 text-sm font-bold px-2 py-1 rounded-md"
                          @click="removeProduct(i)"
                        >
                          <font-awesome-icon icon="fa-solid fa-cancel" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ShoppingCreditNoteTotalDetails
              :total="totalOfTheOrder"
              :subTotal="subTotalOfTheOrder"
              :iva="ivaOfTheOrder"
            />
            <div
              class="flex justify-between mt-3 items-end flex-wrap bg-blue-50 px-3 py-3 rounded-md border-blue-50 border-2"
            >
              <label class="block w-full md:w-auto">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Motivo de devolución
                </span>
                <input
                  type="email"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none mr-3"
                  v-model="dataForm.motive"
                  placeholder="INGRESA EL MOTIVO DE LA DEVOLUCION..."
                  @keydown.enter.prevent
                  maxlength="95"
                />
              </label>
              <label class="block w-full md:w-auto mb-3 md:mb-0">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  N° de compra
                </span>
                <input
                  type="email"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none mr-3"
                  v-model="dataForm.numberVoucher"
                  placeholder="FC01-78653456"
                  @keydown.enter.prevent
                  maxlength="13"
                />
              </label>
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full md:w-auto justify-center"
                @click="insert"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Registrando Información
                </div>
                <span v-else>Registrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import axios from "../../api";
import { format, money } from "@/publicjs/money";
import ShoppingCreditNoteTotalDetails from "./ShoppingCreditNoteTotalDetails.vue";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";
import { voucherCreditNote } from "@/publicjs/vouchers";

//Impresion de comprobantes
import { printVoucherOnA4 } from "@/components/Shopping/PrintVoucherCreditNote/PrintOnA4";
import { printVoucherOnLetter } from "@/components/Shopping/PrintVoucherCreditNote/PrintOnLetter";
import { printVoucherOnMediaLetter } from "@/components/Shopping/PrintVoucherCreditNote/PrintOnMediaLetter";
import { printVoucherTicket } from "@/components/Shopping/PrintVoucherCreditNote/PrintOnTicket";

export default {
  name: "CreditNoteShopping",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    LoadingTables,
    TableHead,
    ShoppingCreditNoteTotalDetails,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información
    const loading = ref(true);

    const totalOfTheOrder = ref(0);
    const subTotalOfTheOrder = ref(0);
    const ivaOfTheOrder = ref(0);

    const dataForm = ref({
      idUser: 0,
      idStore: 0,
      idVoucher: 0,
      numberVoucher: "",
      motive: "",
    });
    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "Precio",
        },
        {
          title: "Total",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectTypeOfDocuments = (data) => {
      dataForm.value.idTypeOfDocument = data;
    };

    //funcion para traer los detalles de la compra
    const arrayDataOrder = ref([]);
    const arrayReturnProducts = ref([]);
    const getShoppingDetails = async () => {
      const response = await axios
        .get(`imprimir-compras/?id=${props.id}`)
        .catch((error) => errorActions(error));
      const data = response.data;
      if (data.length <= 0) {
        errorActions("No hemos podido encontrar los detalles de la compra");
      } else {
        arrayDataOrder.value = data;
        loading.value = false;
      }
    };

    //Añadir los datos de la compra a lista de datos de productos a devolver
    const addProducts = async (...args) => {
      const [
        idDetail,
        idShopping,
        productName,
        quantity,
        purchasePrice,
        igv,
        subTotal,
        total,
        igvAplied,
        beforeQuantity,
        idProduct,
      ] = args;
      // Verificar si el producto ya ha sido agregado
      const isProductAdded = arrayReturnProducts.value.some(
        (product) =>
          product.idDetail === idDetail && product.productName === productName
      );

      if (isProductAdded) {
        errorActions(
          "El producto que esta intentando añadir ya a añadido con anterioridad a la lista de productos a devolver"
        );
        return;
      }

      // Agregar el producto al array
      arrayReturnProducts.value.push({
        idDetail,
        idShopping,
        productName,
        quantity,
        purchasePrice,
        igv,
        subTotal,
        total,
        igvAplied,
        beforeQuantity,
        idProduct,
        additional: 0,
      });
      calculateTheTotal(arrayReturnProducts.value);
    };

    //Quitar productos de la lista de productos a devolver
    const removeProduct = (index) => {
      // Verificar si el índice está dentro de los límites del array
      if (index >= 0 && index < arrayReturnProducts.value.length) {
        arrayReturnProducts.value.splice(index, 1);
        calculateTheTotal(arrayReturnProducts.value);
      } else {
        errorActions("Índice inválido. No se pudo remover el producto.");
      }
    };

    //funcion para cambiar la cantidad de la orden
    const handleQuantityChange = (index, event, ivaAplied) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;
      arrayReturnProducts.value[index].quantity = newQuantity;
      updateDetailData(index, ivaAplied);
    };

    //funciona para actualizar la
    const updateDetailData = (index, ivaAplied) => {
      const order = arrayReturnProducts.value[index];
      let totalOrder = 0;

      if (order.quantity > order.beforeQuantity) {
        errorActions(
          "Lo sentimos, Usted no puede devolver un producto mayor a la que ingreso a almacén"
        );
        return;
      }

      totalOrder = order.quantity * order.purchasePrice;

      //parseamos el iva del producto
      const configureIva = parseFloat(ivaAplied) / 100;

      //volvemos a calcular el total de orden del producto seleccionado
      order.subTotal =
        Math.round((totalOrder / (configureIva + 1)) * 100) / 100;
      order.iva = Math.round(order.subTotal * configureIva * 100) / 100;
      order.total = totalOrder;

      //llamamos la funcion para calcular el total de orden y le pasamos como parametro el detalle de la orden
      calculateTheTotal(arrayReturnProducts.value);
    };
    //funcion para calcular el total de la orden, incluyendo el iva, subtotal y total
    const calculateTheTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      totalOfTheOrder.value = 0;
      subTotalOfTheOrder.value = 0;
      ivaOfTheOrder.value = 0;

      //recorremos todo el detalle de la orden
      order.forEach((element) => {
        totalOfTheOrder.value += parseFloat(element.total);
        subTotalOfTheOrder.value += parseFloat(element.subTotal);
        ivaOfTheOrder.value += parseFloat(element.igv);
      });
    };

    onMounted(async () => {
      observeElement("#main-content-credit-note-shopping");
      observeElement("#subcontent-credit-note-shopping");
      await getShoppingDetails();

      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
    });

    const insert = async () => {
      spinner.value = true;
      const dataVoucher = await voucherCreditNote();
      dataForm.value.idVoucher = dataVoucher.id;
      const printFormat = dataVoucher.printFormat;

      if (dataForm.value.idVoucher <= 0) {
        errorActions(
          "No hemos podido seleccionar  <strong> un comprobante</strong>"
        );
        spinner.value = false;
        return;
      }
      if (arrayReturnProducts.value.length <= 0) {
        errorActions(
          "No podemos realizar la  <strong>DEVOLUCION</strong> de productos debido a que no a agregado ningun producto a  la lista de devolución"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.motive.length <= 3) {
        errorActions(
          "Debe especificar el motivo por la cual se esta haciendo la <strong>DEVOLUCION</strong> de la compra"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.numberVoucher.length <= 3) {
        errorActions(
          "Debe especificar el  <strong>N° DE LA COMPRA</strong>, este numero es el numero de comprobante que te ha emitido el proveedor"
        );
        spinner.value = false;
        return;
      }
      const response = await axios
        .post(
          "nota-de-credito-compras",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idShopping: props.id,
            idVoucher: dataForm.value.idVoucher,
            numberVoucher: dataForm.value.numberVoucher,
            serie: "NC",
            subTotal: subTotalOfTheOrder.value,
            igv: ivaOfTheOrder.value,
            total: totalOfTheOrder.value,
            motive: dataForm.value.motive,
            arrayDetail: arrayReturnProducts.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        await updateProductsInStock(data.id);

        //? Impresion de comprobante segun su formato
        if (printFormat === "formato_a4") {
          await printVoucherOnA4(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (printFormat === "formato_8x11") {
          await printVoucherOnLetter(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (printFormat === "formato_media_carta") {
          await printVoucherOnMediaLetter(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (printFormat === "formato_ticket") {
          await printVoucherTicket(data.id); // Como parametro recive el ID  de la ultima orden
        }

        confirmationOfRecord(
          `La emision de la <strong>Nota de credito</strong> `
        );

        props.getdata();
        isActive();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar la <strong>Nota de crédito</strong>`
        );
      }

      spinner.value = false; //dejando activo al boton
    };
    const updateProductsInStock = async (id) => {
      await axios
        .put(
          "nota-de-credito-compras/" + id,
          {
            arrayDataDetail: arrayReturnProducts.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(
            "Hemos tenido inconvenientes al momento de actualizar el stock en el almacen" +
              error
          );
        });
    };
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getSelectTypeOfDocuments,
      header,
      loading,
      arrayDataOrder,
      format,
      money,
      arrayReturnProducts,
      addProducts,
      removeProduct,
      handleQuantityChange,
      totalOfTheOrder,
      subTotalOfTheOrder,
      ivaOfTheOrder,
    };
  },
};
</script>
