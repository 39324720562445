import { saveAs } from "file-saver";
import { getCurrentDate } from "@/publicjs/currentDate";
import ExcelJS from "exceljs";
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { closeAlert, errorActions, uploadData } from "@/alerts";
import stylesExcel from "@/publicjs/stylesExcel";
export const exportToAxcel = async (data, idStore) => {
  try {
    await uploadData(
      "Generando reporte".toUpperCase(),
      "Espera un momento mientras preparamos tu reporte, <strong>solo tomará unos segundos....</strong>"
    );
    //* Traer los datos de la sucursal
    const dataCompany = await getCompany(idStore);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      "Reporte de productos".toUpperCase()
    );

    const styles = {
      title: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.xl,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      subtitle: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      header: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      total: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.green },
        },
        alignment: { horizontal: "right", vertical: "middle" },
      },
    };

    // Agregar título
    let rowNumber = 1; // Número de fila que deseas ajustar la altura
    let row = worksheet.getRow(rowNumber);

    worksheet.mergeCells("A1:N1");
    const titleReport = worksheet.getCell("A1");
    titleReport.value = "Reporte de productos".toUpperCase();
    titleReport.style = styles.title;
    row.height = 35;

    rowNumber = 2;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A2:N2");
    const store = worksheet.getCell("A2");
    store.value = "SUCURSAL : " + dataCompany.storeName.toUpperCase();
    store.style = styles.subtitle;
    row.height = 30;

    rowNumber = 3;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A3:N3");
    const dateReport = worksheet.getCell("A3");
    dateReport.value = "FEC:" + getCurrentDate();
    dateReport.style = styles.subtitle;
    row.height = 30;

    // Agregar encabezados
    const headers = [
      "COD. DE BARRA",
      "DESCRIPCION",
      "ITBIS",
      "UTILIDAD",
      "P. DE COMPRA",
      "P. DE VENTA",
      "STOCK",
      "STOCK MINIMO",
      "¿CON STOCK?",
      "VENCIMIENTO",
      "L/I",
      "FACTURADO",
      "UBICACIÓN",
      "ESTADO",
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = styles.header;
    });

    rowNumber = 4; // Número de fila que deseas ajustar la altura
    row = worksheet.getRow(rowNumber);
    row.height = 35;

    // Agregar datos
    data.forEach((rowData) => {
      const rows = worksheet.addRow([
        rowData.codigo_de_barra,
        rowData.nombre_producto.toUpperCase(),
        rowData.igv,
        rowData.utilidad,
        Number(rowData.precio_de_compra), //? Convertir el valor a número
        Number(rowData.precio_de_venta), //? Convertir el valor a número
        rowData.stock,
        rowData.stock_minimo,
        rowData.venta_con_stock === 1 ? "SI" : "NO",
        rowData.vencimiento,
        rowData.libre_de_impuesto,
        rowData.facturado === 1 ? "SI" : "NO",
        rowData.ubicacion,
        rowData.estado === 1 ? "ACTIVO" : "INACTIVO",
      ]);
      //Estilos para las columnas
      rows.getCell(5).style.font = {
        bold: true,
        color: { argb: stylesExcel.color.darkAlt },
        size: stylesExcel.fontSize.md,
      };
      rows.getCell(5).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      (rows.getCell(6).style.font = {
        bold: true,
        color: { argb: stylesExcel.color.green },
        size: stylesExcel.fontSize.md,
      }),
        (rows.getCell(6).alignment = {
          horizontal: "center",
          vertical: "middle",
        });
    });

    //* Ajustar ancho de columnas
    worksheet.columns.forEach((column, index) => {
      //Ancho para la columna [0]
      if (index === 0) {
        column.width = 18;
      } else if (
        index === 2 ||
        index === 3 ||
        index === 4 ||
        index === 5 ||
        index === 6 ||
        index === 7 ||
        index === 8 ||
        index === 9 ||
        index === 10 ||
        index === 11 ||
        index === 12 ||
        index === 13
      ) {
        //Ancho para la columna [5]
        column.width = 25;
      } else {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      }
    });

    //* Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), getCurrentDate() + ".xlsx");
    await closeAlert(); //Cerramos el modal de aviso
  } catch (error) {
    errorActions(
      "Lo sentimos no pudimos generar el reporte, intentalo mas tarde"
    );
  }
};
