<template>
  <div class="mt-4" id="contentData">
    <div
      class="flex flex-wrap gap-4 md:flex-nowrap items-end mb-3 [&>select]:m-0 [&>select]:uppercase [&>select]:w-auto [&>div]:w-auto [&>div]:m-0 [&>select]:cursor-pointer"
    >
      <select
        v-model="limitRegister"
        class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none min-w-[140px] font-semibold w-full md:w-auto"
        @change="resetValuesAndGetData"
      >
        <option value="all">Todos los registros</option>
        <option value="100">100 Registros</option>
        <option value="500">500 Registros</option>
        <option value="1000">1000 Registros</option>
        <option value="5000">5000 Registros</option>
        <option value="10000">10000 Registros</option>
        <option value="20000">20000 Registros</option>
      </select>
      <select
        v-model="priceType"
        @change="resetValuesAndGetData"
        class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none min-w-[140px] font-semibold w-full md:w-auto"
      >
        <option value="1">Ver precio 1</option>
        <option value="2">Ver precio 2</option>
        <option value="3">Ver precio 3</option>
      </select>

      <TypeOfProducts :isDisabled="false" @send-data="getDataTypeOfProducts" />
      <!-- acciones de impresion -->
      <div class="relative group">
        <button
          class="bg-blue-100 flex items-center justify-center w-28 h-10 rounded-md text-blue-600"
        >
          Opciones
          <font-awesome-icon
            icon="fa-solid fa-ellipsis"
            class="rotate-90 ml-2"
          />
        </button>
        <ul
          class="absolute top-full left-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
        >
          <button
            class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
            @click="exportToPDF(registers, dataForm.title)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a PDF
          </button>
          <button
            class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
            @click="exportToExcel(registers, dataForm.title)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a excel
          </button>
        </ul>
      </div>
      <button
        class="py-3 px-3 bg-blue-500 text-white text-xs rounded-md active:scale-95 w-full md:w-auto whitespace-nowrap"
        @click="resetValuesAndGetData"
      >
        Generar reporte
      </button>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="3" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.name }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ format(register.stock) }}
                </td>
                <td
                  class="border-slate-200 text-sm text-right px-6 py-2 whitespace-nowrap uppercase"
                >
                 {{ money() }} {{ format(register.price) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";

import TypeOfProducts from "@/components/PublicComponents/TypeOfProducts.vue";
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
import { exportToPDF } from "./Reports/PDFExportProducts";
import { exportToExcel } from "./Reports/ExcelExportAllProducts";

export default {
  name: "ProductPriceList",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    TypeOfProducts,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Productos",
        },
        {
          title: "stock",
        },
        {
          title: "Precio",
        },
      ],
    ];
    const dataForm = ref({
      store: 0,
      typeProduct: "all",
      title:'LISTA DE PRECIOS '
    });

    //variables de paginacion
    const currentPage = ref(1);
    const priceType = ref(1);
    const limitRegister = ref(1000);
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);

    const getPrices = async (registers, type) => {
      return registers.map((producto) => {
        const primerPrecio = producto.prices[0]; // Tomamos el primer precio
        if (!primerPrecio) {
            return {
                name: producto.nombre_producto,
                stock: producto.stock,
                price: 0 // Puedes ajustar esto a otro valor predeterminado si lo prefieres
            };
        }

        let precio = 0;

        // Determinamos el precio según el tipoPrecio
        if (parseInt(type) === 1) {
          precio = parseFloat(primerPrecio.precio);
        } else if (parseInt(type) === 2) {
          precio = parseFloat(primerPrecio.precio_dos)>0?parseFloat(primerPrecio.precio_dos):parseFloat(primerPrecio.precio);
        } else if (parseInt(type) === 3) {
            precio = parseFloat(primerPrecio.precio_tres)>0?parseFloat(primerPrecio.precio_tres):parseFloat(primerPrecio.precio);
        }

        return {
          name: producto.nombre_producto,
          stock: producto.stock,
          price: precio,
        };
      });
    };

    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `lista-de-precios-productos?page=${currentPage.value}&per_page=${limitRegister.value}&type=${dataForm.value.typeProduct}&store=${dataForm.value.store}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }

      const products = await response.data.data;
      registers.value = await getPrices(products, priceType.value);
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      observeElement("#contentData");
      handleResize();
      window.addEventListener("resize", handleResize);
      dataForm.value.store = await idLocalStore();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };
    const getDataTypeOfProducts = async (data) => {
      dataForm.value.typeProduct = data === "null" ? "all" : data;
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      getDataTypeOfProducts,
      dataForm,
      calculateExpirationDate,
      exportToPDF,
      exportToExcel,
      priceType,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
