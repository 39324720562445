<template>
  <div
    class="py-3 bg-blue-50 px-3 rounded-md border border-blue-100 shadow-sm shadow-blue-100"
    id="contentSearchCustomer"
  >
    <div>
      <div class="w-full md:w-auto mt-3 md:mt-0">
        <span class="text-sm font-semibold text-blue-500 pb-2 block">
          Clientes
        </span>
        <div class="relative md:min-w-[260px] lg:min-w-[320px]">
          <div class="flex items-center relative text-gray-600">
            <div class="absolute top-0 left-3 w-6 h-full flex items-center">
              <font-awesome-icon icon="fa-solid fa-search" class="w-4 h-4" />
            </div>
            <input
              type="search"
              v-model="dataForm.searchInput"
              class="border-2 pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50 focus:border-blue-500"
              placeholder="Buscar por nombre o n° de documento"
              @keydown.enter="getData"
              @keydown.enter.prevent
              autocomplete="off"
              id="inputCustomer"
              title="ALT + C"
            />
          </div>
          <div
            v-if="showCustomer"
            class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
          >
            <div v-if="loadingSearch">
              <div class="px-3">
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="!dataLength"
                class="px-3 py-1 bg-gray-50 text-gray-400"
                @click="showCustomer = false"
              >
                <strong>{{ dataForm.searchInput }}</strong> no existe en nuestra
                base de datos
              </div>
              <div
                v-else
                v-for="(result, i) in dataResult"
                :key="i"
                class="odd:bg-white even:bg-slate-50"
                @blur="showCustomer = false"
              >
                <div
                  class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                  @click="
                    selectCustomer({
                      id: result.id,
                      discount: parseFloat(result.descuento_limite_asignado),
                      numberDocument: result.numero_documento.toString(),
                      type: result.tipo_de_cliente,
                      creditLimit: parseFloat(result.limite_de_credito),
                      fullName: result.nombres,
                      sell_with: result.sell_with,
                      fixed_voucher: result.fixed_voucher,
                      paydays: result.paydays,
                    })
                  "
                >
                  {{ result.nombres }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InfoCustomer
        :creditLimit="dataForm.creditLimit"
        :maximumDiscount="dataForm.maximumDiscount"
        :numberDocument="dataForm.numberDocument"
        :typeCustomer="dataForm.typeCustomer"
        v-if="stateInfo"
      />
    </div>
  </div>
</template>

<script>
import { confirmationOfRecord, errorActions, uploadData } from "@/alerts";
import axios from "../../../api";
import { onMounted, ref } from "vue";
import InfoCustomer from "./InfoCustomer.vue";
import { dgii } from "@/repository/DGII/dgii";
import { observeElement } from "@/observer";
import calculatePaymentDate from "@/publicjs/calculatePaymentDate";
export default {
  name: "SearchCustomer",
  components: {
    InfoCustomer,
  },
  props: {
    stateInfo: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["send-data"],
  setup(_, { emit }) {
    const dataForm = ref({
      idCustomer: 0,
      maximumDiscount: 0,
      creditLimit: 0,
      typeCustomer: 0,
      numberDocument: 0,
      sell_with: 1,
      fixed_voucher: 0,
      searchInput: "",
      paydays: "",
    });
    onMounted(() => {});

    //Funcion para cargar ventas temporales
    const showCustomer = ref(false);
    const loadingSearch = ref(true);
    const dataResult = ref([]);
    const dataLength = ref(false);
    const getData = async () => {
      if (dataForm.value.searchInput.length <= 0) {
        errorActions(
          "Por favor ingresa el<strong> nombre de cliente o su numero de documento</strong>"
        );
        return;
      }

      try {
        loadingSearch.value = true;
        showCustomer.value = true;
        dataLength.value = false;
        const response = await axios
          .get(
            `clientes/?page=1&per_page=30&search=${dataForm.value.searchInput}`
          )
          .catch((error) => errorActions(error));
        dataResult.value = await response.data.data;
        if (dataResult.value.length > 0) {
          dataLength.value = true;
        } else if (dataLength.value === false) {
          await insert();
        }
        loadingSearch.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar el ciente debido a que hemos tenido unos inconvenientes"
        );
      }
    };
    const selectCustomer = async (data) => {
      dataForm.value.idCustomer = data.id;
      dataForm.value.maximumDiscount = data.discount;
      dataForm.value.numberDocument = data.numberDocument;
      dataForm.value.creditLimit = data.creditLimit;
      dataForm.value.typeCustomer = data.type;
      dataForm.value.searchInput = data.fullName;
      dataForm.value.sell_with = data.sell_with;
      dataForm.value.fixed_voucher = data.fixed_voucher;
      if (parseInt(data.paydays) > 0) {
        dataForm.value.paydays = calculatePaymentDate(data.paydays);
      } else {
        dataForm.value.paydays = null;
      }
      showCustomer.value = false;
      emit("send-data", dataForm.value);
    };

    /**
     * Funcion para registrar un nuevo cliente en caso no exista en la base de datos
     */
    const insert = async () => {
      if (isNaN(dataForm.value.searchInput)) {
        errorActions(
          "El cliente aun no existe en nuestra base de datos, <strong>Para registrar un nuevo cliente por favor ingresa el N° de documento correcto</strong> <br> Vuelve a intenarlo de nuevo."
        );
        showCustomer.value = false;
        return;
      }

      /************************************************************************************
       * Consultar datos a la DGII
       ************************************************************************************/
      const dataDgii = await dgii(dataForm.value.searchInput.trim());
      if (dataDgii === null) {
        errorActions(
          "El cliente no existe en la base de datos tampo en la entidad del gobierno, <strong>Si el cliente ya es contribuyente del estado pero aun no esta en la lista de contribuyentes puedes intentar sincronizar los datos</strong> "
        );
        showCustomer.value = false;
        return;
      }
      await uploadData(
        "REGISTRANDO CLIENTE",
        "Espera un momento mientras actualizamos la base de datos con el nuevo cliente."
      );
      //Obtenemos el tipo de documento segun la longitud del numero de documento

      const getTypeOfDocument = await axios.get("tipo_de_documentos");
      const allTypeOfDocument = await getTypeOfDocument.data.data;

      let typeDocument = null;

      //Filtrar tipo de documento como DNI o RNC
      if (
        dataDgii.numberDocument.length > 7 &&
        dataDgii.numberDocument.length <= 9
      ) {
        typeDocument = allTypeOfDocument.filter(
          (item) => item.descripcion === "RNC" || item.descripcion === "DNI"
        );

        //Filtrar tipo de documento como DNI o RNC
      } else if (
        dataDgii.numberDocument.length > 9 &&
        dataDgii.numberDocument.length <= 11
      ) {
        typeDocument = allTypeOfDocument.filter(
          (item) => item.descripcion === "RUC" || item.descripcion === "CEDULA"
        );
      }

      const response = await axios
        .post(
          "clientes",
          {
            idTypeOfDocument: typeDocument[0].id,
            names: dataDgii.bussinesName,
            numberDocument: dataDgii.numberDocument,
            address: "S/D",
            phone: "999999999",
            discountLimit: 0,
            creditLimit: 0,
            customerType: "CASUAL",
            email: "",
            sell_with: 0,
            fixed_voucher: 0,
            paydays: 0,
          },
          {
            method: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => errorActions(error));

      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El cliente <strong>${dataDgii.bussinesName}</strong> `
        );
        await showCustomerData(data.id);
      } else if (data.msg === "exist") {
        errorActions(
          `El cliente <strong>${dataDgii.bussinesName}</strong> ya existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos registrar al cliente <strong>${dataDgii.bussinesName}</strong>...`
        );
      }
      showCustomer.value = false;
    };

    const showCustomerData = async (id) => {
      const response = await axios
        .get("clientes/" + id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      const dataObject = {
        id: data.id,
        discount: parseFloat(data.descuento_limite_asignado),
        numberDocument: data.numero_documento,
        creditLimit: parseFloat(data.limite_de_credito),
        type: data.tipo_de_cliente,
        fullName: data.nombres,
        sell_with: data.sell_with,
        fixed_voucher: data.fixed_voucher,
        paydays:
          parseInt(data.paydays) > 0
            ? calculatePaymentDate(data.paydays)
            : null,
      };
      await selectCustomer(dataObject);
    };

    onMounted(() => {
      observeElement("#contentSearchCustomer");
    });

    return {
      showCustomer,
      loadingSearch,
      dataResult,
      dataLength,
      getData,
      dataForm,
      selectCustomer,
    };
  },
};
</script>
