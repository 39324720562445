<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700">
      Lista de precio de productos
    </h2>
    <ProductPriceList />
  </div>
</template>

<script>
import ProductPriceList from "@/components/Reports/Products/ProductPriceList/ProductPriceList.vue";
import { useRoute } from "vue-router";
import { onMounted } from "vue";
export default {
  name: "ProductPriceListView",
  components: {
    ProductPriceList,
  },
  setup() {
    const route = useRoute();

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
