<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="containerConvertVoucher">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[20vw] md:max-w-[27vw] max-w-[90vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Convertir comprobante
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div
          class="p-2 bg-blue-50 rounded-md text-blue-500 mb-3 text-md font-semibold"
        >
          FP: {{ numberSale }}
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <!-- lista de comprobante -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Comprobante de venta
            </span>
            <VouchersForConvert @send-data="getSelectVouchersOfSale" />
          </label>
          <!-- lista de tipo de ventas -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de venta
            </span>
            <TypeOfSale
              :selectOption="dataForm.idTypeOfSale"
              @send-data="getSelectTypeOfSale"
            />
          </label>

          <div class="flex lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-3 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full justify-center"
              @click="convertVoucher"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Convirtiendo comprobante
              </div>
              <span v-else>Convertir comprobante</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../../api";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";
import TypeOfSale from "@/components/PublicComponents/TypeOfSale.vue";
import VouchersForConvert from "@/components/PublicComponents/VouchersForConvert.vue";
import { updateNumberVoucher, voucherSales } from "@/publicjs/vouchers";
// Print voucher
import { printVoucherOnA4 } from "../PrintVoucher/PrintOnA4";
import { printVoucherOnLetter } from "../PrintVoucher/PrintOnLetter";
import { printVoucherOnMediaLetter } from "../PrintVoucher/PrintOnMediaLetter";
import { printVoucherTicket } from "../PrintVoucher/PrintOnTicket";
export default {
  name: "ConvertVoucher",
  components: {
    TypeOfSale,
    VouchersForConvert,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    numberSale: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idVoucher: 0,
      nameVoucher: "",
      idTypeOfSale: 0,
      numberVoucher: 0,
      serieVoucher: "",
      typePrintVoucher: "",
    });

    /**********************************************************************************************************
     * Funcion para convertir una venta con factura proforma a un comprobante fiscal válido
     **********************************************************************************************************/
    const convertVoucher = async () => {
      spinner.value = true;
      /*********************************************************************************
       * Llamamos los datos del comprobante seleccionado
       *********************************************************************************/
      const { id, numberCurrent, serie, printFormat } = await voucherSales(
        dataForm.value.idVoucher
      );
      dataForm.value.typePrintVoucher = printFormat;
      dataForm.value.idVoucher = id;
      dataForm.value.numberVoucher = numberCurrent;
      dataForm.value.serieVoucher = serie;

      const response = await axios
        .put(
          "ventas/convertir/fc/" + props.id,
          {
            idVoucher: dataForm.value.idVoucher,
            idTypeOfSale: dataForm.value.idTypeOfSale,
            numberVoucher: dataForm.value.numberVoucher,
            serieVoucher: dataForm.value.serieVoucher,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(() => {
          errorActions(
            `Lo sentimos algo salio mal, <strong>no pudimos convertir el comprobante FC ${props.numberSale} a ${dataForm.value.nameVoucher} </strong> <br> vuelve a intentarlo`
          );
          spinner.value = false;
        });
      const data = await response.data;
      if (data.msg === true) {
        //Actualizamos el numero de comprobante
        await updateNumberVoucher(
          dataForm.value.idVoucher,
          dataForm.value.numberVoucher
        );

        if (dataForm.value.typePrintVoucher === "formato_a4") {
          printVoucherOnA4(data.id);
        } else if (dataForm.value.typePrintVoucher === "formato_8x11") {
          printVoucherOnLetter(data.id);
        } else if (dataForm.value.typePrintVoucher === "formato_media_carta") {
          printVoucherOnMediaLetter(data.id);
        } else if (dataForm.value.typePrintVoucher === "formato_ticket") {
          printVoucherTicket(data.id);
        }
        confirmationOfRecord(
          `La venta con  <strong>FC ${dataForm.value.numberVoucher}</strong> a sido convertido a ${dataForm.value.nameVoucher} con éxito`
        );
        isActive();
        props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos <strong>convertir el comprobante</strong> de la venta ${dataForm.value.numberVoucher}`
        );
      }

      spinner.value = false;
    };

    onMounted(async () => {
      observeElement("#containerConvertVoucher");
      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
    });

    const getSelectVouchersOfSale = (data) => {
      dataForm.value.idVoucher = data.id;
      dataForm.value.nameVoucher = data.name;
    };
    const getSelectTypeOfSale = (data) => {
      dataForm.value.idTypeOfSale = data;
    };

    return {
      isActive,
      dataForm,
      convertVoucher,
      spinner,
      getSelectVouchersOfSale,
      getSelectTypeOfSale,
    };
  },
};
</script>
