import axios from "@/api";
//! Retorna el logotipo de la empresa
export const imageInBase64 = async (imageUrl) => {
  try {
    const response = await axios.post("image", { url: imageUrl });
    const base64Image = response.data.image;
    return base64Image;
  } catch (error) {
    throw new Error("Failed to convert image to base64");
  }
};
