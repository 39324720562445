<template>
  <thead class="uppercase bg-gray-50 text-gray-700 text-left">
    <tr v-for="header,i in headers" :key="i">
      <th class="px-6 py-3 text-sm font-medium whitespace-nowrap" v-for="header,e in headers[0]" :key="e" :class="{'text-right':e === headers[0].length - 1 && i === headers.length - 1}">
        {{header.title}}
      </th>
    </tr>
  </thead>
</template>
<script>
export default {
  name: "TableHead",
  props: {
    headers: {
      type: Array,
      required: true
    }
  },
};
</script>
