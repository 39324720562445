<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentData">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 max-w-[90vw] min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            PAGO MASIVO DE C.C
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-4" @submit.prevent v-else>
          <div
            class="px-3 py-2 rounded-md border border-green-100 bg-green-50 text-sm text-green-500 uppercase"
          >
            <strong class="font-semibold block">Total a pagar: </strong>
            <strong class="text-xl"
              >{{ money() }} {{ format(dataForm.totalSale) }}</strong
            >
          </div>
          <!-- lista de tipos de pagos -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de pago
            </span>
            <TypeOfPaymentNotMulptiplyPayment
              :selectOption="dataForm.idTypeOfPayment"
              @send-data="getSelectTypeOfPayment"
            />
          </label>
          <div class="grid md:grid-cols-2 gap-6" v-show="stateInputTypePayment">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Entidad bancaria <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.entityBank"
                placeholder="Entidad bancaria..."
                @keydown.enter.prevent
                maxlength="60"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de operación <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberOperation"
                placeholder="N° de operación..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
          </div>

          <!-- Componente de formatos de impresion de documento -->
          <div class="flex mt-3">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando pago
              </div>
              <span v-else>Registrar pago</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../../api";
import { format, money } from "@/publicjs/money";

import TypeOfPaymentNotMulptiplyPayment from "@/components/PublicComponents/TypeOfPaymentNotMulptiplyPayment.vue";

//componente de loading
import LoadingForms from "@/components/Loadings/LoadingFormsOneColumn.vue";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
} from "@/publicjs/localStorage";

export default {
  name: "RecordBulkPayments",
  components: {
    LoadingForms,
    TypeOfPaymentNotMulptiplyPayment,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    payments: {
      type: Array,
      required: true,
    },
    clearListPayment: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      totalSale: 0,
      entityBank: "",
      numberOperation: "",
      idTypeOfPayment: 0,
      idStore: 0,
      idUser: 0,
      idTurn: 0,
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      observeElement("#contentData");
      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
      dataForm.value.idTurn = await idLocalTurn();
      props.payments.forEach((element) => {
        dataForm.value.totalSale += parseFloat(element.amount);
      });

      isLoading.value = true;
    });

    const insert = async () => {
      if (props.payments.length <= 0) {
        errorActions(
          "Parece que no has seleccionado los pagos de la cuenta por cobrar <strong>del cliente</strong>"
        );
        return;
      }

      spinner.value = true;

      const response = await axios
        .post(
          "pagos-cuentas-por-cobrar/pagos/masivo",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idTurn: dataForm.value.idTurn,
            idTypeOfPayment: dataForm.value.idTypeOfPayment,
            entityBank: dataForm.value.entityBank,
            numberOperation: dataForm.value.numberOperation,
            payments: props.payments,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(
          "Los pagos de la cuenta por cobrar del cliente " +
            dataForm.value.customer
        );
        await props.activeModal(false);
        await props.clearListPayment();
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El pago de la cuenta por cobrar del cliente <trong>${dataForm.value.customer} </strong> que estas intentando registrar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos registrar el pago de la cuenta por cobrar del cliente <trong>${dataForm.value.customer} </strong>`
        );
      }

      spinner.value = false;
    };

    // Estado para mostrar o no los inputs de entidad bancaria y numero de operacion
    const stateInputTypePayment = ref(false);
    //recepcion de datos desde el componente publico
    const getSelectTypeOfPayment = (data) => {
      dataForm.value.idTypeOfPayment = data;
      //Activamos los inputs para ingresar los datos como entidad bancaria y numero de operación
      if (data !== 1) {
        stateInputTypePayment.value = true;
      } else {
        stateInputTypePayment.value = false;
      }
    };

    return {
      isActive,
      dataForm,
      insert,
      isLoading,
      spinner,
      format,
      money,
      stateInputTypePayment,
      getSelectTypeOfPayment,
    };
  },
};
</script>
