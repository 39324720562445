<template>
  <div class="mt-4" id="contentData">
    <div
      class="bg-blue-50 border-2 border-blue-100 shadow-sm shadow-blue-100 p-4 rounded-md mb-3 flex flex-wrap items-end justify-between gap-4"
    >
      <SearchProducts @send-data="getDataProducts" />
      <button
        class="py-3 px-3 bg-blue-500 text-white text-xs rounded-md active:scale-95 w-full md:w-auto"
        @click="getData"
      >
        Generar reporte
      </button>
    </div>
    <div class="mt-3" v-if="!loading">
        <ChartComponent :datas="dataForm.registers" :titleChart="'COMPARACION DE PRECIO ENTRE PROVEEDORES'"/>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";

import SearchProducts from "@/components/Reports/ComparePriceBetweenProviders/SearchProducts.vue";
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
import ChartComponent from './ChartComponent.vue'

export default {
  name: "MainComparePriceBetweenProviders",
  components: {
    SearchProducts,
    ChartComponent
  },
  setup() {
    const loading = ref(true);
    const statTypeProduct = ref(false);
    const dataForm = ref({
      idStore: 0,
      idProduct: 0,
      registers: {},
    });

    const getData = async () => {
      if(dataForm.value.idProduct === 0) {
        errorActions('Selecciona un producto para comparar precios')
        return;
      }
      loading.value = true;
      const response = await axios
        .get(`comparar-precio-entre-proveedores/${dataForm.value.idStore}/?idproduct=${dataForm.value.idProduct}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      dataForm.value.registers = data;
      loading.value = false;
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(() => {
        if (widthNav.value) {
          widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
        }
      });
    };

    onMounted(async () => {
      observeElement("#contentData");
      handleResize();
      window.addEventListener("resize", handleResize);
      dataForm.value.idStore = await idLocalStore();
    });

    const showTypeProduct = async () => {
      statTypeProduct.value = !statTypeProduct.value;
    };
    const getDataProducts = async (data) => {
      dataForm.value.idProduct = data.id;
    };

    return {
      loading,
      getData,
      widthNav,
      handleResize,
      money,
      format,
      statTypeProduct,
      showTypeProduct,
      dataForm,
      calculateExpirationDate,
      getDataProducts,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
