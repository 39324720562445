<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <div class="flex items-end gap-4">
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
            @change="resetValuesAndGetData"
          />
        </label>
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
            @change="resetValuesAndGetData"
          />
        </label>
      </div>
      <!-- acciones de impresion -->
      <div class="relative group">
        <button
          class="bg-blue-100 flex items-center justify-center w-28 h-9 rounded-md text-blue-600"
        >
          Opciones
          <font-awesome-icon
            icon="fa-solid fa-ellipsis"
            class="rotate-90 ml-2"
          />
        </button>
        <ul
          class="absolute top-full right-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100"
        >
          <button
            class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
            @click="exportPDF(registers)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a PDF
          </button>
          <button
            class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
            @click="exportToExcel(registers)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a excel
          </button>
        </ul>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por fecha de emision, N° de NC, nombre o N° de documento del cliente "
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                    1: Para poner una tabla responsiva añadir lo siguiente:
                      1.1: class="resize-generate"
                      1.2: ref="widthNav" 
                      1.3: @resize="handleResize"
                    -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full" id="contentDataTables">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="9" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap bg-blue-50 text-blue-500"
                >
                  {{ register.cliente.nombres }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{
                    register.serie +
                    "-" +
                    register.numero_comprobante
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{ register.comprobante_de_venta.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{
                    register.venta.serie_comprobante +
                    "-" +
                    register.venta.numero_comprobante
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{ register.motivo }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap font-bold"
                >
                  {{ money() }} {{ format(register.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{ formatDateTime(register.fecha_de_emision) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 text-sm rounded-lg"
                    :class="{
                      'bg-green-50 text-green-600':
                        parseFloat(register.total) > 0,
                      'bg-red-50 text-red-600': parseFloat(register.total) <= 0,
                    }"
                  >
                    {{ parseFloat(register.total) > 0 ? "Vigente" : "Usado" }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  <div class="relative group">
                    <button
                      class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                    >
                      <font-awesome-icon icon="fa-solid fa-list-ul" />
                    </button>
                    <ul
                      class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                    >
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnA4(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en A4
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnLetter(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 8 1/2 x 11
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnMediaLetter(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en M. carta
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherTicket(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 80MM
                      </button>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";
import { formatDateTime } from "@/publicjs/formatDate";

//Marcar el lote como vendido
import { markAsSoldInLot } from "@/repository/Lots/MarkAsSoldInLots";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";

//reportes en pdf y excel
import { exportPDF } from "./Reports/PDFExportAllCreditNote";
import { exportToExcel } from "./Reports/ExcelExportAllCreditNote";

import { printVoucherOnA4 } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnA4";
import { printVoucherOnLetter } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnLetter";
import { printVoucherOnMediaLetter } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnMediaLetter";
import { printVoucherTicket } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnTicket";
import { currentDay } from "@/publicjs/currentDate";

export default {
  name: "MainCreditNoteSale",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "cliente",
        },
        {
          title: "nota credito N°",
        },
        {
          title: "afecta a",
        },
        {
          title: "N° de venta",
        },
        {
          title: "motivo",
        },
        {
          title: "total",
        },
        {
          title: "fec. emision",
        },
        {
          title: "estado",
        },
        {
          title: "acciones",
        },
      ],
    ];

    //Inputs data
    const dataForm = ref({
      dateStart: null,
      dateEnd: null,
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const idStore = ref(0);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `nota-de-credito-ventas/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idStore.value}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      observeElement("#contentDataTables");
      idStore.value = await idLocalStore();
      await changeCurrentDate();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //Llamada de funcion para anular la venta esta funcion biene desde el repositorio
    const sendMarkAsSoldInLot = async (id, msg) => {
      await markAsSoldInLot(id, msg, resetValuesAndGetData);
    };

    //actualizar fechas
    const changeCurrentDate = async () => {
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = currentDay()
      dataForm.value.dateEnd = dates.lastDay;
      await resetValuesAndGetData();
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      formatDateTime,
      sendMarkAsSoldInLot,
      dataForm,
      exportPDF,
      exportToExcel,
      printVoucherOnA4,
      printVoucherOnLetter,
      printVoucherOnMediaLetter,
      printVoucherTicket,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
.w-full.resize-x.resize-generate, .w-full.resize-x.resize-generate .overflow-x-auto {
    min-height: 380px;
}
</style>
