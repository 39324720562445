<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-3">
      <div class="flex items-end flex-wrap gap-4">
        <label class="block w-full md:w-auto mb-2">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block w-full md:w-auto mb-2">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
        <label class="block">
          <span
            class="block text-sm mb-2 font-medium text-gray-800 dark:text-white"
          >
            Turnos
          </span>
          <Turns @send-data="getTurns" />
        </label>
        <div class="mb-2">
          <SearchUsers @send-data="getUser" />
        </div>
      </div>
      <div class="flex items-end flex-wrap gap-4">
        <button
          class="text-xs text-white bg-blue-500 rounded-md px-2 py-2 border-2 border-blue-500 mb-2 hover:bg-blue-600 active:scale-95"
          @click="getData"
        >
          Consultar
        </button>
        <button
          class="text-xs text-orange-500 bg-orange-50 rounded-md px-2 py-2 border-2 border-orange-500 mb-2 hover:bg-orange-500 active:scale-95 hover:text-white"
          @click="exportToPDF(registers,dataForm)"
          v-show="!loading && registers.length >0"
        >
          <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
          PDF
        </button>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="30">30 Registros</option>
          <option value="60">60 Registros</option>
          <option value="120">120 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por fecha de salida..."
              v-model="dataForm.search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="7" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombres }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ formatDateTime(register.fecha_ingreso) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{
                    register.fecha_salida !== null
                      ? formatDateTime(register.fecha_salida)
                      : "Aun no sale"
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ format(register.minutos_extra) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ format(register.minutos_tarde) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-2 py-1 rounded-md"
                    :class="{
                      'bg-red-50 text-red-500': register.tardanza === 1,
                    }"
                  >
                    {{ register.tardanza === 1 ? "SI" : "NO" }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";

import { format } from "@/publicjs/money";
import { formatDateTime } from "@/publicjs/formatDate";
import { idLocalStore } from "@/publicjs/localStorage";
import SearchUsers from "@/components/Users/UserAssists/SearchUsers.vue";
import Turns from "@/components/PublicComponents/Turns.vue";

import { exportToPDF } from './Reports/PDFExportAllQuotes'

export default {
  name: "MainListUserAssists",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    SearchUsers,
    Turns,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Nombres",
        },
        {
          title: "turno",
        },
        {
          title: "fec. y hora de ingreso",
        },
        {
          title: "fec. y hora de salida",
        },
        {
          title: "min. extra",
        },
        {
          title: "min. tarde",
        },
        {
          title: "tardanza",
        },
      ],
    ];

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      userName: "",
      idTurn: 0,
      dateStart: null,
      dateEnd: null,
      search: "",
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(30);
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      if (dataForm.value.idUser <= 0) {
        errorActions("Selecciona un usuario para poder ver su asistencia");
        return;
      }
      if (dataForm.value.idTurn === null) {
        errorActions(
          "Selecciona un turno del usuario para poder ver su asistencia"
        );
        return;
      }
      loading.value = true;
      const response = await axios
        .get(
          `marcar-asistencias/?page=${currentPage.value}&per_page=${limitRegister.value}&iduser=${dataForm.value.idUser}&idturn=${dataForm.value.idTurn}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}&search=${dataForm.value.search}&idstore=${dataForm.value.idStore}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data.data;
      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = await data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(() => {
        if (widthNav.value) {
          widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
        }
      });
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      dataForm.value.idStore = await idLocalStore();
      loading.value = false;
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    const getUser = (data) => {
      dataForm.value.idUser = data.id;
      dataForm.value.userName = data.userName;
    };

    //recepcion de datos desde el componente publico, todos los tipos de usuarios
    const getTurns = (data) => {
      dataForm.value.idTurn = data;
      console.log(dataForm.value);
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      format,
      formatDateTime,
      dataForm,
      getUser,
      getTurns,
      exportToPDF
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
