const startDimension = 2.83465;

/**
 * 
 * @param {*} width | Ancho del documento
 * @param {*} height | Atura del documento
 * @returns | Retorna el ancho y el alto del papel
 */
export const paperDimension = (width,height) => {
  const paperWidth = width * startDimension;
  const paperHeight = height * startDimension;
  return {
    paperWidth,
    paperHeight,
  };
};
