import axios from 'axios'

// Crear una instancia de axios
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

// Agregar un interceptor de solicitudes
api.interceptors.request.use(config => {
  // Obtener el token de autenticación del almacenamiento local
  const token = JSON.parse(localStorage.getItem('access'))
  // Si hay un token, agregarlo a los encabezados de la solicitud
  if (token) {
    config.headers.Authorization = `Bearer ${token.token}`
  }
  return config
})

export default api
