<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="containerFormAddProduct">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw] md:max-w-[60vw] lg:max-w-[50vw] max-w-[90vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Ingreso de productos
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div
          class="px-3 py-3 rounded-md bg-orange-50 text-orange-600 text-sm mb-3"
        >
          Estimado usuario este no es la forma correcta de ingresar un producto,
          <strong
            >si hay un descuadre en kardex, lote, almacen y otros módulos del
            sistema usted sera el unico responsable</strong
          >
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 gap-4">
            <div class="w-full col-span-2">
              <div class="relative">
                <div class="flex items-center relative text-gray-600">
                  <div
                    class="absolute top-0 left-3 w-6 h-full flex items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-barcode"
                      class="w-4 h-4"
                    />
                  </div>
                  <input
                    type="search"
                    v-model="searchProduct"
                    class="border pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50"
                    placeholder="Buscar producto por nombre o codigo de barra"
                    @keydown.enter="searchProducts"
                    @keydown.enter.prevent
                    id="searchInputProduct"
                    autocomplete="off"
                  />
                </div>
                <div
                  v-if="showResultsProducts"
                  class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
                >
                  <div v-if="loadingSearchProducts">
                    <div class="px-3">
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="!resultLengthProducts"
                      class="px-3 py-1 bg-gray-50 text-gray-400"
                      @click="showResultsProducts = false"
                    >
                      <strong>{{ searchProduct }}</strong> no existe
                    </div>
                    <div
                      v-else
                      v-for="(result, i) in resultSearchProducts"
                      :key="i"
                      class="odd:bg-white even:bg-slate-50"
                    >
                      <div
                        class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                        @click="
                          selectProduct(result.id, result.nombre_producto)
                        "
                        @blur="showResultsProducts = false"
                      >
                        {{ result.nombre_producto }}
                        <div>
                          <span
                            class="px-3 py-1 rounded-md text-orange-500 bg-orange-50 text-xs font-semibold"
                          >
                            En stock: {{ result.stock }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Cantidad <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.quantity"
                placeholder="Cantidad..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio acordado <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.agreedPrice"
                placeholder="Precio acordado..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Ingresando producto a almacén
              </div>
              <span v-else>Ingresa producto</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../../api";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";
import { money, format } from "@/publicjs/money";
import { insertproductsAccordingToModuleEntered } from "@/repository/Store/ProductsAccordingToModuleEntered/productsAccordingToModuleEntered";
export default {
  name: "AddProduct",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
  },
  emits: ["send-data"],
  setup(props, { emit }) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);
    const showResultsProducts = ref(false);
    const loadingSearchProducts = ref(true);
    const resultSearchProducts = ref([]);
    const resultLengthProducts = ref(false);
    const idStore = ref(0);
    const searchProduct = ref("");

    const dataForm = ref({
      idProduct: 0,
      quantity: 0,
      agreedPrice: 0,
    });

    /**********************************************************************************************************
     * Funcion para actualizar los datos del productos como stock
     **********************************************************************************************************/
    const insert = async () => {
      if (dataForm.value.idProduct <= 0) {
        errorActions(
          "Por favor   <strong>selecciona un producto y vuelve a intentarlo</strong>"
        );
        return;
      }
      if (dataForm.value.quantity <= 0) {
        errorActions(
          "Ingresa  <strong>la cantidad que deseas ingresar a almacen</strong>"
        );
        return;
      }
      if (dataForm.value.agreedPrice < 0) {
        errorActions(
          "Ingresa  <strong>el precio que acordó con el cliente</strong>"
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .put(
          "actualizar-stock-de-producto",
          {
            idProduct: dataForm.value.idProduct,
            idStore: idStore.value,
            quantity: dataForm.value.quantity,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(() => {
          errorActions(
            "Lo sentimos algo salio mal, <strong>revisa que el producto este ingresado a kadex y este bien configurado</strong> <br> y vuelve a intenarlo"
          );
          spinner.value = false;
        });
      const data = await response.data;
      if (data.msg === true) {
        emit("send-data", dataForm.value.agreedPrice);
        insertproductsAccordingToModuleEntered({
          idStore: await idLocalStore(),
          idUser: await idLocalUser(),
          idProduct: dataForm.value.idProduct,
          quantity: dataForm.value.quantity,
          module: "VENTAS",
        });
        confirmationOfRecord(
          `La actualización de stock del producto <strong>${searchProduct.value}</strong>`
        );
        isActive();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos ingresar el producto <strong>${searchProduct.value}</strong>`
        );
      }

      spinner.value = false;
    };

    /**********************************************************************************************************
     * Funcionalidad para buscar productos para luego ser actualizado segun la cantidad que ingresa el usuario
     **********************************************************************************************************/
    const filteredRegisters = ref([]);
    const searchProducts = async () => {
      if (searchProduct.value.length <= 0) {
        errorActions(
          "Por favor ingresa <strong>el nombre de producto o su codigo de barra</strong>"
        );
        return;
      }

      try {
        loadingSearchProducts.value = true;
        showResultsProducts.value = true;
        resultLengthProducts.value = false;
        const response = await axios
          .get(
            `productos/?page=1&per_page=50&search=${searchProduct.value}&idstore=${idStore.value}`
          )
          .catch((error) => errorActions(error));
        filteredRegisters.value = await response.data.data;
        resultSearchProducts.value = filteredRegisters.value.filter(
          (register) => register.estado === 1
        );
        if (resultSearchProducts.value.length > 0) {
          resultLengthProducts.value = true;
        }
        loadingSearchProducts.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar el producto debido a que hemos tenido unos inconvenientes"
        );
      }
    };

    /**********************************************************************************************************
     * Funcion para seleccionar el producto
     **********************************************************************************************************/

    const selectProduct = (id, productName) => {
      dataForm.value.idProduct = id;
      searchProduct.value = productName;
      showResultsProducts.value = false;
    };

    onMounted(async () => {
      observeElement("#containerFormAddProduct");
      idStore.value = await idLocalStore();
    });

    return {
      isActive,
      dataForm,
      insert,
      spinner,
      showResultsProducts,
      loadingSearchProducts,
      resultSearchProducts,
      resultLengthProducts,
      searchProduct,
      searchProducts,
      money,
      format,
      selectProduct,
    };
  },
};
</script>
