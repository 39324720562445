<template>
  <div class="w-full">
    <MainDashboard />
  </div>
</template>

<script>
import MainDashboard from "./Dashboard/MainDashboard.vue";
export default {
  name: "HomePage",
  components: {
    MainDashboard,
  },
};
</script>
