<template>
  <div id="conten-dashboard">
    <LoadingDashboardCards v-if="loading" />
    <div v-else>
      <div v-if="isAccessDashboard">
        <h2 class="text-gray-600 font-bold text-sm uppercase mb-3">
          Tablero: estado de productos
        </h2>
        <ImportantReport
          :expiredProducts="reportsProduct.expiredProducts"
          :productsToExpired="reportsProduct.productsToExpired"
          :productsWhitMinimumStock="reportsProduct.productsWhitMinimumStock"
          :outOfStockProducts="reportsProduct.outOfStockProducts"
        />
        <h2 class="text-gray-600 font-bold text-sm uppercase mb-3">
          Tablero: reporte del día de hoy
        </h2>
        <div class="flex flex-wrap gap-6">
          <TotalSales
            :total="reportCurrent.sales"
            :date="reportCurrent.date"
            :money="money()"
          />
          <TotalShopping
            :total="reportCurrent.shopping"
            :date="reportCurrent.date"
            :money="money()"
          />
          <TotalDisbursements
            :total="reportCurrent.expences"
            :date="reportCurrent.date"
            :money="money()"
          />
          <TotalAccountsPayable
            :total="reportCurrent.AccountsPayable"
            :date="reportCurrent.date"
            :money="money()"
          />
          <TotalAccountsReceivable
            :total="reportCurrent.accountReceivable"
            :date="reportCurrent.date"
            :money="money()"
          />
        </div>
        <h2 class="text-gray-600 font-bold text-sm uppercase mb-3 mt-6">
          Tablero: reporte del mes actual
        </h2>
        <div class="flex flex-wrap gap-6">
          <TotalSalesForTheCurrentMonth
            :total="reportMonth.sales"
            :money="money()"
          />
          <TotalShoppingForTheCurrentMonth
            :total="reportMonth.shopping"
            :money="money()"
          />
          <TotalDisbursementsForTheCurrentMonth
            :total="reportMonth.expences"
            :money="money()"
          />
          <TotalAccountsPayableForTheCurrentMonth
            :total="reportMonth.AccountsPayable"
            :money="money()"
          />
          <TotalAccountsReceivableForTheCurrentMonth
            :total="reportMonth.accountReceivable"
            :money="money()"
          />
        </div>
        <h2 class="text-gray-600 font-bold text-sm uppercase mb-3 mt-6">
          Tablero GENERAL
        </h2>
        <div class="flex flex-wrap gap-6">
          <TotalCustomer :total="reportGeneral.customer" />
          <TotalSupplier :total="reportGeneral.supplier" />
        </div>
        <h2 class="text-gray-600 font-bold text-sm uppercase mb-3 mt-10">
          Tablero: reportes gráficos de ventas de hoy y el mes actual
        </h2>
        <div class="grid md:grid-cols-2 gap-6">
          <TotalSalesReportGrapics :datas="reportChartSales.current" />
          <TotalSalesForMonthReportGrapics :datas="reportChartSales.month" />
        </div>
        <h2 class="text-gray-600 font-bold text-sm uppercase mb-3 mt-10">
          Tablero: reporte gráfico de ventas del año actual
        </h2>

        <TotalSalesForYearReportGrapics :datas="reportOfTheSalesForMonth" />
        <h2 class="text-gray-600 font-bold text-sm uppercase mb-3 mt-10">
          Tablero: reporte gráfico de compras del año actual
        </h2>
        <TotalShoppingForYearReportGrapics
          :datas="reportOfTheShoppingForMonth"
        />
      </div>
      <div v-else class="min-h-[80vh] relative">
        <div
          class="absolute top-1/2 translate-y-[-50%] w-[100%] h-full flex items-center justify-center flex-col gap-10"
        >
          <h2 class="text-2xl text-slate-500 uppercase">
            Hola
            <strong>
              {{ userName }}
            </strong>
          </h2>
          <span class="text-2xl md:text-[80px] font-bold text-slate-300">
            BIENVENIDO
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TotalAccountsPayable from "@/components/Dashboard/Cards/TodayReports/TotalAccountsPayable.vue";
import TotalAccountsReceivable from "@/components/Dashboard/Cards/TodayReports/TotalAccountsReceivable.vue";
import TotalCustomer from "@/components/Dashboard/Cards/GeneralReport/TotalCustomer.vue";
import TotalDisbursements from "@/components/Dashboard/Cards/TodayReports/TotalDisbursements.vue";
import TotalSales from "@/components/Dashboard/Cards/TodayReports/TotalSales.vue";
import TotalShopping from "@/components/Dashboard/Cards/TodayReports/TotalShopping.vue";
import TotalSupplier from "@/components/Dashboard/Cards/GeneralReport/TotalSupplier.vue";

import TotalAccountsPayableForTheCurrentMonth from "@/components/Dashboard/Cards/CurrentMonthReports/TotalAccountsPayableForTheCurrentMonth.vue";
import TotalAccountsReceivableForTheCurrentMonth from "@/components/Dashboard/Cards/CurrentMonthReports/TotalAccountsReceivableForTheCurrentMonth.vue";
import TotalDisbursementsForTheCurrentMonth from "@/components/Dashboard/Cards/CurrentMonthReports/TotalDisbursementsForTheCurrentMonth.vue";
import TotalSalesForTheCurrentMonth from "@/components/Dashboard/Cards/CurrentMonthReports/TotalSalesForTheCurrentMonth.vue";
import TotalShoppingForTheCurrentMonth from "@/components/Dashboard/Cards/CurrentMonthReports/TotalShoppingForTheCurrentMonth.vue";

//Graficos de barra
import TotalSalesReportGrapics from "@/components/Dashboard/GrapicsReport/TotalSalesReportGrapics.vue";
import TotalSalesForMonthReportGrapics from "@/components/Dashboard/GrapicsReport/TotalSalesForMonthReportGrapics.vue";
import TotalSalesForYearReportGrapics from "@/components/Dashboard/GrapicsReport/TotalSalesForYearReportGrapics.vue";
import TotalShoppingForYearReportGrapics from "@/components/Dashboard/GrapicsReport/TotalShoppingForYearReportGrapics.vue";

//Componte para mostrar datos importantes como productos por vencer, etc
import ImportantReport from "@/components/Dashboard/Important/ImportantReport.vue";
import { onMounted, ref } from "vue";
import {
  idLocalStore,
  idLocalUser,
  localUserName,
} from "@/publicjs/localStorage";
import axios from "../../api";
import { errorActions } from "@/alerts";
import { money, format } from "@/publicjs/money";

import LoadingDashboardCards from "@/components/Loadings/LoadingDashboardCards.vue";
import { observeElement } from "@/observer";
import { validateAccessSpecific } from "@/repository/Sales/ValidateAccessEspecific/ValidateAccess";

export default {
  name: "MainDashboard",
  components: {
    TotalAccountsPayable,
    TotalAccountsReceivable,
    TotalCustomer,
    TotalDisbursements,
    TotalSales,
    TotalShopping,
    TotalSupplier,
    TotalAccountsPayableForTheCurrentMonth,
    TotalAccountsReceivableForTheCurrentMonth,
    TotalDisbursementsForTheCurrentMonth,
    TotalSalesForTheCurrentMonth,
    TotalShoppingForTheCurrentMonth,
    TotalSalesReportGrapics,
    TotalSalesForMonthReportGrapics,
    TotalSalesForYearReportGrapics,
    TotalShoppingForYearReportGrapics,
    ImportantReport,
    LoadingDashboardCards,
  },
  setup() {
    const reportsProduct = ref({
      expiredProducts: 0,
      productsToExpired: 0,
      productsWhitMinimumStock: 0,
      outOfStockProducts: 0,
    });

    const reportCurrent = ref({
      sales: 0,
      shopping: 0,
      accountReceivable: 0,
      AccountsPayable: 0,
      expences: 0,
      date: "",
    });
    const reportMonth = ref({
      sales: 0,
      shopping: 0,
      accountReceivable: 0,
      AccountsPayable: 0,
      expences: 0,
      start: "",
      end: "",
    });
    const reportGeneral = ref({
      customer: 0,
      supplier: 0,
    });
    const reportChartSales = ref({
      current: null,
      month: null,
    });
    const reportOfTheSalesForMonth = ref({});
    const reportOfTheShoppingForMonth = ref({});
    const idStore = ref(0);
    const isAccessDashboard = ref(false);
    const userName = ref("");

    const loading = ref(true);

    const getSalesAndShoppingAndProductsCurrent = async () => {
      const response = await axios
        .get(`ventas-compras-cp-cc-del-dia/${idStore.value}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      //Productos
      reportsProduct.value.expiredProducts = parseInt(data.productos_vencidos);
      reportsProduct.value.productsToExpired = parseInt(
        data.productos_por_vencer
      );
      reportsProduct.value.productsWhitMinimumStock = parseInt(
        data.productos_con_stock_minimo
      );
      reportsProduct.value.outOfStockProducts = parseInt(
        data.productos_agotados
      );

      //ventas

      reportCurrent.value.sales = format(data.ventas);
      reportCurrent.value.shopping = format(data.compras);
      reportCurrent.value.accountReceivable = format(data.cuentas_por_cobrar);
      reportCurrent.value.AccountsPayable = format(data.cuentas_por_pagar);
      reportCurrent.value.expences = format(data.desembolsos);
      reportCurrent.value.date = data.fecha;
    };

    const getSalesAndShoppingAndProductsMonth = async () => {
      const response = await axios
        .get(`ventas-compras-cp-cc-del-mes/${idStore.value}`)
        .catch((error) => errorActions(error));
      const data = await response.data;

      //ventas

      reportMonth.value.sales = format(data.ventas);
      reportMonth.value.shopping = format(data.compras);
      reportMonth.value.accountReceivable = format(data.cuentas_por_cobrar);
      reportMonth.value.AccountsPayable = format(data.cuentas_por_pagar);
      reportMonth.value.expences = format(data.desembolsos);
      reportMonth.value.start = data.fecha_de_inicio;
      reportMonth.value.end = data.fecha_de_termino;
    };
    const getReportGeneral = async () => {
      const response = await axios
        .get(`reporte-general-dashboard`)
        .catch((error) => errorActions(error));
      const data = await response.data;

      reportGeneral.value.customer = format(data.clientes);
      reportGeneral.value.supplier = format(data.proveedores);
    };

    const getSalesAndShoppingOfTheMonth = async () => {
      const response = await axios
        .get(`ventas-del-dia-chart/${idStore.value}`)
        .catch((error) => errorActions(error));
      const data = await response.data;

      reportChartSales.value.month = data.ventas_del_mes;
      reportChartSales.value.current = data.ventas_del_dia;
    };
    const getSalesAndShoppingOfTheYear = async () => {
      const response = await axios
        .get(`ventas-y-compras-por-mes-chart/${idStore.value}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      reportOfTheSalesForMonth.value = data.ventas;
      reportOfTheShoppingForMonth.value = data.compras;
    };

    onMounted(async () => {
      observeElement("#conten-dashboard");
      idStore.value = await idLocalStore();
      userName.value = await localUserName();

      const ACCESS = ["DASHBOARD"];

      const validate = await validateAccessSpecific(
        ACCESS,
        await idLocalUser()
      );

      if (validate.includes("DASHBOARD")) {
        isAccessDashboard.value = true;
      }
      if (isAccessDashboard.value) {
        await getSalesAndShoppingAndProductsCurrent();
        await getSalesAndShoppingAndProductsMonth();
        await getReportGeneral();
        await getSalesAndShoppingOfTheMonth();
        await getSalesAndShoppingOfTheYear();
      }
      loading.value = false;
    });

    return {
      reportsProduct,
      loading,
      reportCurrent,
      money,
      reportMonth,
      reportGeneral,
      reportChartSales,
      reportOfTheSalesForMonth,
      reportOfTheShoppingForMonth,
      isAccessDashboard,
      userName,
    };
  },
};
</script>
