<template>
  <div>
    <h2 class="font-bold text-gray-700">Listado de notas de crédito</h2>
    <MainCreditNoteShopping />
  </div>
</template>

<script>
import MainCreditNoteShopping from "@/components/Shopping/CreditNote/MainCreditNoteShopping.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "CreditNoteShopping",
  components: {
    MainCreditNoteShopping,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
