<template>
  <div>
    <div class="grid md:grid-cols-2  gap-6">
      <div>
        <div class="bg-gray-200 animate-pulse h-3 mb-2 w-20 rounded-lg"></div>
        <div class="bg-gray-200 animate-pulse h-9 rounded-lg"></div>
      </div>
      <div>
        <div class="bg-gray-200 animate-pulse h-3 mb-2 w-20 rounded-lg"></div>
        <div class="bg-gray-200 animate-pulse h-9 rounded-lg"></div>
      </div>
      <div>
        <div class="bg-gray-200 animate-pulse h-3 mb-2 w-20 rounded-lg"></div>
        <div class="bg-gray-200 animate-pulse h-9 rounded-lg"></div>
      </div>
      <div>
        <div class="bg-gray-200 animate-pulse h-3 mb-2 w-20 rounded-lg"></div>
        <div class="bg-gray-200 animate-pulse h-9 rounded-lg"></div>
      </div>
    </div>
    <div class="flex mt-6">
      <div class="bg-gray-200 animate-pulse w-28 h-9 rounded-lg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingFormsTwoColumn",
};
</script>
