<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentForm">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de desembolsos o egresos
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto de desembolso <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.amount"
                placeholder="Monto de desemnolso..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de desembolso <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.disbursementNumber"
                placeholder="FC01-00021540..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Beneficiario
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.beneficiary"
                placeholder="EMPRESA DE HIDROCARBUROS PERU"
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Departamento
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.departament"
                placeholder="RR-HH"
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Concepto <span class="text-red-300">*</span>
              </span>
              <textarea
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 resize-none w-full border-2 focus:border-blue-500"
                v-model="dataForm.concept"
                placeholder="COMPRA DE UNA MINI COMPRENSORA"
                maxlength="150"
                cols="30"
                rows="2"
              ></textarea>
            </label>
          </div>

          <div class="flex justify-end mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar desembolso</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../api";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
} from "@/publicjs/localStorage";

export default {
  name: "RegisterDisbursements",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      idTurn: 0,
      amount: 0,
      disbursementNumber: "",
      beneficiary: "",
      departament: "",
      concept: "",
      attachedFile: "",
    });

    onMounted(async () => {
      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
      dataForm.value.idTurn = await idLocalTurn();
      observeElement('#contentForm')
    });

    const insert = async () => {
      if (dataForm.value.idStore <= 0) {
        errorActions("No se pudo ubicar tu sucursal  <strong>Cierra tu sesión y vuelve a intentarlo</strong>");
        return;
      }
      if (dataForm.value.idTurn <= 0) {
        errorActions("No se pudo ubicar el turno actual  <strong>Cierra tu sesión y vuelve a intentarlo</strong>");
        return;
      }
      if (dataForm.value.amount <= 0) {
        errorActions("El monto de desembolso no debe de ser menor o igual a <strong>0</strong>");
        return;
      }
      if (dataForm.value.disbursementNumber.trim().length <= 2) {
        errorActions("Ingresa el número de desembolso <strong>El número es el número de comprobante u otro identificador</strong>");
        return;
      }
      if (dataForm.value.concept.trim().length <= 2) {
        errorActions("Ingresa el concepto o motivo <strong>por el cual se esta realizando el desembolso</strong>");
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "desembolsos",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idTurn: dataForm.value.idTurn,
            amount: dataForm.value.amount,
            disbursementNumber: dataForm.value.disbursementNumber,
            beneficiary: dataForm.value.beneficiary,
            departament: dataForm.value.departament,
            concept: dataForm.value.concept,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El desembolso por monto de  <strong>${dataForm.value.amount}</strong>`
        );
        props.getdata();
        dataForm.value.concept = "";
        dataForm.value.disbursementNumber = "";
        dataForm.value.departament = "";
        dataForm.value.amount = 0;
        dataForm.value.beneficiary = "";
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el desembolso por el monto de <strong>${dataForm.value.amount}</strong>`
        );
      }

      spinner.value = false;
    };
    return {
      isActive,
      dataForm,
      insert,
      spinner,
    };
  },
};
</script>
