//FUNCION PARA EVITAR EL ERROR DE [OBSERVER RESICE]
export const observer = new ResizeObserver((entries) => {
  for (let entry of entries) {
    // const { width, height } = entry.contentRect;
    // console.log("Element size changed:", { width, height });
    entry.contentRect;
  }
});

/**
 *
 * @param {*} elementId | Es el elemento a observar
 * @returns
 */
export const observeElement = (elementId) => {
  const element = document.querySelector(elementId);
  if (!element) {
    console.warn(`Element for observer not found.`);
    return;
  }

  const observer = new ResizeObserver((entries) => {
    for (let entry of entries) {
      // const { width, height } = entry.contentRect;
      // console.log("Element size changed:", { width, height });
      entry.contentRect;
    }
  });

  observer.observe(element);
};
