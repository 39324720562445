<template>
    <div class="grid gap-4">
        <div>
            <h2 class="text-1xl font-bold text-gray-700">Módulos principales de Sistema</h2>
            <ListModules/>
            <!-- <UpdateModules/> -->
        </div>
    </div>
</template>
<script>
import ListModules from '@/components/SystemModules/ListModules.vue';
import { useRoute } from 'vue-router';
import { onBeforeMount, onMounted } from 'vue';
import { findSubmoduleIdByDescription } from '@/hisAcces';
export default {
    name:'SystemModules',
    components: {
        ListModules,
        
    },
  setup() {
    const route = useRoute();

    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });
    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
}
</script>