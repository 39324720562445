import { confirmationOfRecord, errorActions } from "@/alerts";
import axios from "@/api";
/**
 * Funcion para insertar una venta
 * @param {*} data Los datos principales de la venta
 * @returns El estado del registro
 */
export default async (data) => {
  try {
    const response = await axios.post(
      "traslado-interno",
      data,
      {
        method: {
          "Content-Type": "application/json",
        },
      }
    );
    const respondeData = await response.data;
    if (respondeData.message === 'created') {
      confirmationOfRecord(
        `<strong>Por favor informa al sucursal destino del traslado.</strong><br><br> Debito a que tu traslado `
      );
      return {
        id: respondeData.id,
        status: true,
      };
    } else {
      errorActions(
        `Lo sentimos no pudimos registrar el traslado a la otra sucursal <strong>verifica si tuviste algunos problemas al momento de ingresar los productos</strong> `
      );
      return {
        id: 0,
        status: false,
      };
    }
  } catch (error) {
    errorActions(error);
    return {
      id: 0,
      status: false,
    };
  }
};
