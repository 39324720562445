<template>
  <div id="contendAssists">
    <div
      class="flex justify-center items-center flex-wrap md:flex-nowrap bg-sky-950 w-full h-[calc(100vh-6.72rem)] rounded-md shadow-lg shadow-sky-950"
    >
      <div>
        <h2 class="text-white text-2xl font-bold text-center mb-6">
          Hola <span class="text-orange-300">{{ dataForm.userName }}</span
          >,
          <br />
          bienvenido al módulo de asistencias
        </h2>
        <div class="flex justify-center">
          <div
            class="w-28 h-28 border-4 border-sky-600 rounded-full overflow-hidden"
          >
            <div class="w-28 h-28 flex justify-center items-center">
              <img :src="APIURL + dataForm.avatar" alt="" />
            </div>
          </div>
        </div>
        <div class="mt-6">
          <form>
            <label class="block text-center">
              <span
                class="block mb-2 text-sm font-medium text-white dark:text-white uppercase"
              >
                Ingresa tu código de barra
              </span>
              <input
                type="text"
                class="py-2 px-2 bg-sky-800 outline-none rounded-lg text-lg font-bold text-white w-full border-4 border-sky-600 focus:border-blue-400 appearance-none"
                v-model="dataForm.barCode"
                placeholder="..."
                @keydown.enter.prevent
                @keydown.enter="insert"
                maxlength="30"
              />
            </label>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "@/api";
import {
  closeAlert,
  confirmationOfRecord,
  errorActions,
  uploadData,
} from "@/alerts";

import { money, format } from "@/publicjs/money";
import { observeElement } from "@/observer";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
  localAvatarUser,
  localDelayUser,
  localExitWorkTolerance,
  localMinutesLate,
  localUserName,
} from "@/publicjs/localStorage";

export default {
  name: "MainUserAssists",
  setup() {
    const loading = ref(true);
    const dataForm = ref({
      id: 0,
      barCode: "",
      idUser: 0,
      idStore: 0,
      idTurn: 0,
      userName: "",
      avatar: "",
      currentDate: "",
      exitWorkTolerance: "",
    });
    const stateMarker = ref(false);

    //URL PARA LOS RECURSO DE LA API COMO IMAGNES, ARCHIVOS, ETC

    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;
    const getData = async () => {
      const response = await axios
        .get(
          `marcar-asistencias/${dataForm.value.idUser}/?idturn=${dataForm.value.idTurn}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === "aun_no_marca") {
        stateMarker.value = false;
      } else if (data.msg === "ya_marco") {
        stateMarker.value = true;
      } else {
        dataForm.value.id = data.id;
      }
    };

    const insert = async () => {
      if (stateMarker.value === true) {
        errorActions("Usted ya marco su ingreso y salida...");
        return;
      }
      if (dataForm.value.id === 0) {
        await uploadData(
          "MARCANDO INGRESO",
          "Espere un momento mientra registramos tu ingreso"
        );
        const response = await axios
          .post(`marcar-asistencias`, {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idTurn: dataForm.value.idTurn,
            barCode: dataForm.value.barCode,
          })
          .catch((error) => errorActions(error));
        const data = await response.data;
        if (data.msg === true) {
          await closeAlert();
          confirmationOfRecord("Tu ingreso ");
          dataForm.value.barCode = "";
        } else if (data.msg === "codigo_de_barra_incorrecto") {
          await closeAlert();
          errorActions("Ingresa un codigo de barra correcto");
        } else {
          await closeAlert();
          errorActions("Lo sentimos no pudimos marcar tu ingreso");
        }
      } else if (dataForm.value.id > 0 && dataForm.value.id != 0) {
        await uploadData(
          "MARCANDO SALIDA",
          "Espere un momento mientra registramos tu salida"
        );
        const response = await axios
          .put(`marcar-asistencias/${dataForm.value.id}`, {
            idUser: dataForm.value.idUser,
            idTurn: dataForm.value.idTurn,
            barCode: dataForm.value.barCode,
          })
          .catch((error) => errorActions(error));
        const data = await response.data;
        if (data.msg === true) {
          await closeAlert();
          confirmationOfRecord("Tu salida ");
          dataForm.value.barCode = "";
        } else if (data.msg === "codigo_de_barra_incorrecto") {
          await closeAlert();
          errorActions("Ingresa un codigo de barra correcto");
        } else {
          await closeAlert();
          errorActions("Lo sentimos no pudimos marcar tu salida");
        }
      }
      await getData();
    };

    onMounted(async () => {
      observeElement("#contendAssists");
      dataForm.value.idUser = await idLocalUser();
      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idTurn = await idLocalTurn();
      dataForm.value.userName = await localUserName();
      dataForm.value.avatar = await localAvatarUser();
      dataForm.value.delay = await localDelayUser();
      dataForm.value.minutesLate = await localMinutesLate();
      dataForm.value.exitWorkTolerance = await localExitWorkTolerance();
      await getData();
    });

    return {
      loading,
      getData,
      money,
      format,
      dataForm,
      APIURL,
      insert,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
