import { errorActions,uploadData } from "@/alerts";
import axios from "../../api";
import Swal from "sweetalert2";
import { observeElement } from "@/observer";
/**
 *
 * @param {*} id | ID de la orden
 * @param {*} msg | Mensaje para la eliminarcion
 */
const deleteData = async (id) => {
  const response = await axios
    .delete("eliminar-pago-cuenta-por-pagar/" + id, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      errorActions(error);
    });
  const data = response.data;
  if (data.msg === "Request failed with status code 500") {
    errorActions(data.msg);
  } else if (data.msg === false) {
    errorActions(
      `Lo sentimos no pudimos eliminar el pago.`
    );
  }
};
/**
 *
 * @param {*} id | ID del registro al cual queremos eliminar o anular
 * @param {*} msg | Descripcion del registro que queremos eliminar puede ser el nombre, etc.
 * @param {*} action | Funcion para volver a cargar los datos, pero esto funciona siempre en cuando n tenga parametros
 */
export default async (id, action) => {
  Swal.fire({
    title: "¿Estas seguro que deseas eliminar el pago?",
    html: `Si eliminas el pago de la cuenta por pagar, no podras recuperarlo más adelante!`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#315df5",
    cancelButtonColor: "#dc2626",
    confirmButtonText: "Si, Eliminar!",
    cancelButtonText: "Cancelar",
    width: "300px",
  }).then(async (result) => {
    observeElement(".swal2-container");
    if (result.isConfirmed) {
        await uploadData(
            "Elminado pago".toUpperCase(),
            "Espera un momento mientras elminamos el pago"
          );
      await deleteData(id);
      Swal.fire({
        icon: "success",
        title: "Pago Eliminado",
        html: `El pago ha sido eliminado de la base de datos`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
      });
      observeElement(".swal2-container");
      await action();
    }
  });
};
