<template>
  <div class="flex items-center col-span-9" id="contentSearchProductitem">
    <div class="w-[85%] mt-3 md:mt-0">
      <div class="relative w-full">
        <div class="flex items-center relative text-gray-600">
          <div class="absolute top-0 left-3 w-6 h-full flex items-center">
            <font-awesome-icon icon="fa-solid fa-barcode" class="w-4 h-4" />
          </div>
          <input
            type="search"
            v-model="searchInput"
            class="border-2 pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50 focus:border-blue-500"
            placeholder="Buscar por nombre, cod. barra, descripción o tipo de producto"
            @keydown.enter="getData"
            @keydown.enter.prevent
            id="searchInputProduct"
            autocomplete="off"
            title="ALT + P"
          />
        </div>
        <div
          v-if="showProducts"
          class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-72 overflow-y-auto"
          id="resultContainerProducts"
        >
          <div v-if="loadingSearch">
            <div class="px-3">
              <div
                class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
              ></div>
              <div
                class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
              ></div>
            </div>
          </div>
          <div v-else>
            <div
              v-if="!dataLength"
              class="px-3 py-1 bg-gray-50 text-gray-400"
              @click="showProducts = false"
            >
              <strong>{{ searchInput }}</strong> no existe en nuestra base de
              datos
            </div>
            <div
              v-else
              v-for="(result, i) in dataResult"
              :key="i"
              class="odd:bg-white even:bg-slate-50 text-gray-600"
              @blur="showProducts = false"
              tabindex="0"
              @keydown.enter="addProducts(result), (NEWADD = false)"
              :class="[
                'odd:bg-white even:bg-slate-50 text-gray-600',
                { 'bg-[#082f49!important] text-white': selectedIndex === i },
              ]"
              @mouseover="selectedIndex = i"
            >
              <div
                class="py-2 px-3 pb-1 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase flex items-center"
                @click="
                  addProducts({
                    idProduct: result.id,
                    taxFree: result.libre_de_impuesto,
                    salePrice: result.precio_de_venta,
                    shoppingPrice: result.precio_de_compra,
                    quantity: 1,
                    discount: 0,
                    maximumDiscount: result.descuento,
                    total: 0,
                    subTotal: 0,
                    igv: 0,
                    utility: result.utilidad,
                    unitMeasure: 'UNIDADES',
                    insuranceDiscount: 0,
                    igvApplied: result.igv,
                    poster: result.poster,
                    units: result.unidades,
                    productName: result.nombre_producto,
                    expired: result.vencimiento,
                    quantityContained: 1,
                    stock: result.stock,
                    precio_descuento: result.precio_descuento,
                    vencimiento_descuento: result.vencimiento_descuento,
                    warranty_expiration: result.vencimiento_garantia,
                    warranty_type: result.tipo_vencimiento,
                    productSerie: null,
                    tipo: result.tipo,
                    showBTNPriceThree: false,
                    showBTNPriceTwo: false,
                    formBarcode: false,
                  })
                "
              >
                <div class="w-8 h-8 mr-3">
                  <div class="w-8 h-8 rounded-lg overflow-hidden">
                    <img
                      src="@/assets/images/defaults/image-not-found.png"
                      :alt="result.nombre_producto"
                      v-if="result.poster === null"
                    />
                    <img
                      :src="APIURL + result.poster"
                      :alt="result.nombre_producto"
                      v-else
                    />
                  </div>
                </div>
                <div>
                  {{ result.nombre_producto }}
                  <div>
                    <span
                      v-if="isShopping"
                      class="px-3 py-1 rounded-md text-orange-500 bg-orange-50 text-xs font-semibold"
                    >
                      {{ money() }} {{ format(result.precio_de_compra) }}
                    </span>
                    <span
                      v-else
                      class="px-3 py-1 rounded-md text-green-500 bg-green-50 text-xs font-semibold"
                    >
                      {{ money() }} {{ format(result.precio_de_venta) }}
                    </span>
                    <span
                      class="px-3 py-1 rounded-md text-orange-500 bg-orange-50 text-xs font-semibold"
                      v-if="result.vencimiento !== null"
                    >
                      {{
                        calculateExpirationDate(result.vencimiento) ===
                        "VENCIDO"
                          ? "VENCIDO"
                          : formatDate(result.vencimiento)
                      }}
                    </span>
                    <span
                      class="px-3 py-1 rounded-md text-blue-500 bg-white text-xs font-semibold inline-block ml-2"
                      v-if="parseFloat(result.stock) > 0"
                    >
                      <span> Stock. {{ result.stock }} </span>
                    </span>
                    <span
                      class="px-3 py-1 rounded-md text-red-500 bg-red-50 text-xs font-semibold inline-block ml-2"
                      v-else
                    >
                      Sin stock
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="bg-blue-100 px-3 py-2 h-full text-blue-500 rounded-md font-semibold text-sm ml-3 uppercase whitespace-nowrap"
      @click="changeTypeSearch"
      title="ALT+N"
    >
      <span v-if="stateTypeSearch">Cod. Barra</span>
      <span v-else>Nombre y C.B</span>
    </button>
  </div>
</template>

<script>
import { errorActions } from "@/alerts";
import axios from "@/api";
import {
  nextTick,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { idLocalStore } from "@/publicjs/localStorage";
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
import { money, format } from "@/publicjs/money";
import { formatDate } from "@/publicjs/formatDate";
import { observeElement } from "@/observer";
import { percentage } from "@/publicjs/convertToPercentage";
export default {
  name: "SearchProduct",
  emits: ["send-data"],
  props: {
    typeVoucher: {
      type: String,
      required: true,
    },
    sellWithCustomer: {
      type: Number,
      required: false,
      default: 1,
    },
    isShopping: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;
    const dataForm = ref({
      idStore: 0,
      idProduct: 0,
      productName: null,
      taxFree: null,
      salePrice: 0,
      shoppingPrice: 0,
      quantity: 0,
      discount: 0,
      maximumDiscount: 0,
      total: 0,
      subTotal: 0,
      igv: 0,
      tipo: 0,
      utility: 0,
      unitMeasure: null,
      insuranceDiscount: 0,
      igvApplied: 0,
      poster: null,
      units: null,
      expired: null,
      quantityContained: 0,
      precio_descuento: 0,
      stock: 0,
      vencimiento_descuento: null,
      productSerie: null,
      warranty_expiration: null,
      warranty_type: null,
      showBTNPriceTwo: false,
      showBTNPriceThree: false,
      priceSelectedForTheCustomer: 1,
      formBarcode: false,
    });
    const searchInput = ref("");
    const stateTypeSearch = ref(false);
    const showProducts = ref(false);
    const loadingSearch = ref(true);
    const dataResult = ref([]);
    const dataLength = ref(false);
    const NEWADD = ref(false);

    const selectedIndex = ref(-1);
    //const resultContainer = ref(null); // Referencia al contenedor de resultados

    const changeTypeSearch = async () => {
      stateTypeSearch.value = !stateTypeSearch.value;
      await focusSearchProduct();
      await nextTick();
      selectedIndex.value = -1;
      NEWADD.value = false;
      showProducts.value = false;
    };

    const scrollToSelected = () => {
      const container = document.getElementById("resultContainerProducts");
      const selected = container.children[0].children[selectedIndex.value];
      if (selected) {
        const selectedRect = selected.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        if (selectedRect.bottom > containerRect.bottom) {
          container.scrollTop += selectedRect.bottom - containerRect.bottom;
        } else if (selectedRect.top < containerRect.top) {
          container.scrollTop -= containerRect.top - selectedRect.top;
        }
      }
    };

    /*******************************************************************************************
     * Funcion para cambiar de estado de tipo de busqueda con combinacion de teclas ALT+N
     *******************************************************************************************/
    const handleKeyDown = async (event) => {
      if (event.altKey && event.keyCode === 78) {
        await changeTypeSearch();
        await nextTick();
        selectedIndex.value = -1;
        NEWADD.value = false;
        showProducts.value = false;
      }
      if (event.keyCode === 27) {
        await nextTick();
        selectedIndex.value = -1;
        NEWADD.value = false;
        showProducts.value = false;
        await focusSearchProduct();
      }

      if (event.key === "ArrowDown") {
        event.preventDefault();
        if (selectedIndex.value < dataResult.value.length - 1) {
          selectedIndex.value++;
          scrollToSelected();
        }
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        if (selectedIndex.value > 0) {
          selectedIndex.value--;
          scrollToSelected();
        }
      } else if (event.key === "Enter" || event.keyCode === 13) {
        event.preventDefault();
        const selectedResult = dataResult.value[selectedIndex.value];
        if (selectedResult && NEWADD.value && showProducts.value) {
          addProducts({
            idProduct: selectedResult.id,
            taxFree: selectedResult.libre_de_impuesto,
            salePrice: selectedResult.precio_de_venta,
            shoppingPrice: selectedResult.precio_de_compra,
            quantity: 1,
            discount: 0,
            maximumDiscount: selectedResult.descuento,
            total: 0,
            subTotal: 0,
            igv: 0,
            utility: selectedResult.utilidad,
            unitMeasure: "UNIDADES",
            insuranceDiscount: 0,
            igvApplied: selectedResult.igv,
            poster: selectedResult.poster,
            units: selectedResult.unidades,
            productName: selectedResult.nombre_producto,
            expired: selectedResult.vencimiento,
            quantityContained: 1,
            stock: selectedResult.stock,
            precio_descuento: selectedResult.precio_descuento,
            vencimiento_descuento: selectedResult.vencimiento_descuento,
            warranty_expiration: selectedResult.vencimiento_garantia,
            warranty_type: selectedResult.tipo_vencimiento,
            productSerie: null,
            tipo: selectedResult.tipo,
            showBTNPriceThree: false,
            showBTNPriceTwo: false,
            formBarcode: selectedResult.formBarcode,
          });
        }
      }
    };

    onBeforeMount(async () => {
      dataForm.value.idStore = await idLocalStore();
    });
    onMounted(async () => {
      window.addEventListener("keydown", handleKeyDown);
      observeElement("#contentSearchProductitem");
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });

    const getData = async () => {
      if (searchInput.value.length <= 0) {
        errorActions(
          "Por favor ingresa <strong>el nombre de producto o su codigo de barra</strong>"
        );
        return;
      }

      await nextTick();
      selectedIndex.value = -1;
      NEWADD.value = false;

      loadingSearch.value = true;
      showProducts.value = true;
      dataLength.value = false;

      if (stateTypeSearch.value) {
        await getDataForBarcCode();
      } else {
        await getDataAll();
      }
    };

    /*******************************************************************************************
     * Funcion para buscar los productos por:
     * N°01: Nombre
     * N°02: Descripcion
     * N°03: Codigo de barra
     * N°04: Tipo de producto
     *******************************************************************************************/
    const getDataAll = async () => {
      try {
        const response = await axios
          .get(
            `productos-para-venta/?search=${searchInput.value.trim()}&idstore=${
              dataForm.value.idStore
            }`
          )
          .catch((error) => errorActions(error));

        dataResult.value = await response.data;

        if (dataResult.value.length > 0) {
          dataLength.value = true;
          selectedIndex.value = 0;
          NEWADD.value = true;
          document.getElementById("searchInputProduct").blur(); // Esto quita el foco del input
        }

        loadingSearch.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar el producto debido a que hemos tenido unos inconvenientes"
        );
      }
    };

    /*******************************************************************************************
     * Funcion para buscar los productos por codigo de barra, esta funcion solo devuelve
     * un producto en particual
     *******************************************************************************************/
    const getDataForBarcCode = async () => {
      try {
        const response = await axios.get(
          `productos-para-venta/codigo-de-barra/?search=${searchInput.value}&idstore=${dataForm.value.idStore}`
        );
        const data = await response.data;
        // console.log(data);

        //VALIDAR EL PRECIO DEL PRODUCTO CON LA CONFIGURACION DE PRECIO DE VENTA DEL CLIENTE
        let priceSelectedForTheCustomer = 1;
        const currentUM = data.unidades[0];
        const UM = data.unidades.filter(
          (item) => item.unidad_de_medida === currentUM.unidad_de_medida
        );
        let PRICE = 0;
        if (UM && UM.length > 0) {
          if (parseInt(props.sellWithCustomer) <= 1) {
            priceSelectedForTheCustomer = 1;
            PRICE = UM[0].precio;
          } else if (parseInt(props.sellWithCustomer) === 2) {
            if (parseFloat(UM[0].precio_dos) > 0) {
              PRICE = UM[0].precio_dos;
              priceSelectedForTheCustomer = 2;
            } else {
              PRICE = UM[0].precio;
              priceSelectedForTheCustomer = 1;
            }
          } else if (parseInt(props.sellWithCustomer) === 3) {
            if (parseFloat(UM[0].precio_tres) > 0) {
              PRICE = UM[0].precio_tres;
              priceSelectedForTheCustomer = 3;
            } else {
              PRICE = UM[0].precio;
              priceSelectedForTheCustomer = 1;
            }
          }
          if (UM[0].precio_dos > 0) {
            dataForm.value.showBTNPriceTwo = true;
          }
          if (UM[0].precio_tres > 0) {
            dataForm.value.showBTNPriceThree = true;
          }
        } else {
          priceSelectedForTheCustomer = 1;
        }

        await addProducts({
          idProduct: data.id,
          taxFree: data.libre_de_impuesto,
          salePrice: PRICE > 0 ? PRICE : data.precio_de_venta,
          shoppingPrice: data.precio_de_compra,
          quantity: 1,
          discount: 0,
          maximumDiscount: data.descuento,
          total: 0,
          subTotal: 0,
          igv: 0,
          tipo: data.tipo,
          utility: data.utilidad,
          unitMeasure: currentUM.unidad_de_medida,
          insuranceDiscount: 0,
          igvApplied: data.igv,
          poster: data.poster,
          units: data.unidades,
          productName: data.nombre_producto,
          expired: data.vencimiento,
          quantityContained: 1,
          precio_descuento: data.precio_descuento,
          vencimiento_descuento: data.vencimiento_descuento,
          warranty_expiration: data.vencimiento_garantia,
          warranty_type: data.tipo_vencimiento,
          productSerie: null,
          priceSelectedForTheCustomer,
          stock: data.stock,
          showBTNPriceThree: dataForm.value.showBTNPriceThree,
          showBTNPriceTwo: dataForm.value.showBTNPriceTwo,
          formBarcode: true,
        });
        await focusSearchProduct();
      } catch (error) {
        // console.log(error);
        if (error.response.data.msg === null) {
          errorActions(
            "Upsss. El producto que estas buscando <strong>NO EXISTE EN NUESTRA BASE DE DATOS</strong> "
          );
        } else {
          errorActions(error.response.data.msg);
        }
        await nextTick();
        selectedIndex.value = -1; // Restablece el índice seleccionado
        NEWADD.value = false;
        showProducts.value = false;
        await focusSearchProduct();
      }
    };

    /*******************************************************************************************
     * Esta funcion limpia el input buscar productos y devuelve el focus
     *******************************************************************************************/
    const focusSearchProduct = async () => {
      const searchInputProduct = document.getElementById("searchInputProduct");
      searchInputProduct.focus();
      searchInput.value = "";
    };

    /*******************************************************************************************
     * Esta funcion limpia el input buscar productos y devuelve el focus
     *******************************************************************************************/
    const addProducts = async (data) => {
      if (!props.isShopping) {
        if (
          props.typeVoucher !== "COTIZACION" &&
          props.typeVoucher !== "COTIZACIÓN" &&
          props.typeVoucher !== "COTIZACIONES"
        ) {
          if (data.tipo !== 0) {
            if (data.stock <= 0) {
              errorActions(
                "Lo sentimos <strong>el producto que estas intentado vender no tiene el stock suficiente</strong>".toUpperCase()
              );
              await nextTick();
              selectedIndex.value = -1; // Restablece el índice seleccionado
              NEWADD.value = false;
              showProducts.value = false;
              return;
            }
          }
        }
      }

      if (data.expired !== null) {
        if (calculateExpirationDate(data.expired) === "VENCIDO") {
          errorActions(
            "Lo sentimos <strong>el producto que estas intentado vender esta vencido</strong>".toUpperCase()
          );
          showProducts.value = false;
          await focusSearchProduct();
          return;
        }
      }

      let priceSelectedForTheCustomer = 0;
      //VALIDAR EL PRECIO DEL PRODUCTO CON LA CONFIGURACION DE PRECIO DE VENTA DEL CLIENTE
      const currentUM = data.units[0];
      const UM = data.units.filter(
        (item) => item.unidad_de_medida === currentUM.unidad_de_medida
      );
      let PRICE = 0;
      if (UM && UM.length > 0) {
        if (parseInt(props.sellWithCustomer) <= 1) {
          priceSelectedForTheCustomer = 1;
          PRICE = UM[0].precio;
        } else if (parseInt(props.sellWithCustomer) === 2) {
          if (parseFloat(UM[0].precio_dos) > 0) {
            PRICE = UM[0].precio_dos;
            priceSelectedForTheCustomer = 2;
          } else {
            PRICE = UM[0].precio;
            priceSelectedForTheCustomer = 1;
          }
        } else if (parseInt(props.sellWithCustomer) === 3) {
          if (parseFloat(UM[0].precio_tres) > 0) {
            PRICE = UM[0].precio_tres;
            priceSelectedForTheCustomer = 3;
          } else {
            PRICE = UM[0].precio;
            priceSelectedForTheCustomer = 1;
          }
        }
        if (UM[0].precio_dos > 0) {
          dataForm.value.showBTNPriceTwo = true;
        }
        if (UM[0].precio_tres > 0) {
          dataForm.value.showBTNPriceThree = true;
        }
      } else {
        priceSelectedForTheCustomer = 1;
        PRICE = data.salePrice;
      }

      //Si el comprobante es regimen escial descontamos el IGV a los productos que se van a añadir
      let discountForIGV = 0;
      let newPrice = 0;
      if (
        props.typeVoucher === "REGIMENES ESPECIAL" ||
        props.typeVoucher === "REGIMENES ESPECIALES" ||
        props.typeVoucher === "FACTURA" ||
        props.typeVoucher === "REGÍMENES ESPECIALES" ||
        props.typeVoucher === "REGIMEN ESPECIAL"
      ) {
        data.igvApplied = 0;
        const igvApplied = percentage(data.igvApplied);
        discountForIGV = igvApplied * PRICE;
        newPrice = parseFloat(
          parseFloat(PRICE) - parseFloat(discountForIGV)
        ).toFixed(2);
      } else {
        newPrice = PRICE;
      }

      dataForm.value.idProduct = data.idProduct;
      dataForm.value.taxFree = data.taxFree;
      dataForm.value.salePrice = newPrice;
      dataForm.value.shoppingPrice = data.shoppingPrice;
      dataForm.value.quantity = data.quantity;
      dataForm.value.discount = data.discount;
      dataForm.value.maximumDiscount = data.maximumDiscount;
      dataForm.value.total = data.total;
      dataForm.value.subTotal = data.subTotal;
      dataForm.value.igv = data.igv;
      dataForm.value.tipo = data.tipo;
      dataForm.value.utility = data.utility;
      dataForm.value.unitMeasure = currentUM.unidad_de_medida;
      // dataForm.value.unitMeasure = data.unitMeasure;
      dataForm.value.insuranceDiscount = data.insuranceDiscount;
      dataForm.value.igvApplied = data.igvApplied;
      dataForm.value.poster = data.poster;
      dataForm.value.units = data.units;
      dataForm.value.productName = data.productName;
      dataForm.value.expired = data.expired;
      dataForm.value.quantityContained = data.quantityContained;
      dataForm.value.precio_descuento = data.precio_descuento;
      dataForm.value.vencimiento_descuento = data.vencimiento_descuento;
      dataForm.value.warranty_expiration = data.warranty_expiration;
      dataForm.value.warranty_type = data.warranty_type;
      dataForm.value.stock = data.stock;
      dataForm.value.productSerie = null;
      dataForm.value.priceSelectedForTheCustomer = priceSelectedForTheCustomer;
      dataForm.value.formBarcode = data.formBarcode;

      searchInput.value = "";
      emit("send-data", dataForm.value);
      await nextTick();
      selectedIndex.value = -1;
      NEWADD.value = false;
      showProducts.value = false;

      await focusSearchProduct();
    };

    watch([showProducts, NEWADD], ([newShowProducts, newNEWADD]) => {
      if (newShowProducts && newNEWADD) {
        window.addEventListener("keydown", handleKeyDown);
      } else {
        window.removeEventListener("keydown", handleKeyDown);
      }
    });

    return {
      showProducts,
      loadingSearch,
      dataResult,
      dataLength,
      getData,
      dataForm,
      stateTypeSearch,
      changeTypeSearch,
      money,
      format,
      formatDate,
      calculateExpirationDate,
      addProducts,
      searchInput,
      APIURL,
      selectedIndex,
      scrollToSelected,
      NEWADD,
    };
  },
};
</script>
