import { percentage } from "@/publicjs/convertToPercentage";

/**
 *
 * @param {*} amount | Es el monto de la cual queremos sacar utilidades
 * @param {*} utility | Porcetante de utilidad en numero entero
 * @returns | Nuevo precio on la utilidad
 */
export const generateUtility = (amount, utility) => {
  let newPrice = 0;
  const newUtility = percentage(utility);
  const amountTotal = parseFloat(amount)
  const price = amountTotal * newUtility;
  newPrice = Math.ceil((amountTotal + price) * 10) / 10; // si no funciona dividir entre 100 y mult. * 100

  return newPrice.toFixed(2);
};
