import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { money, format } from "@/publicjs/money";
import bwipjs from "bwip-js";
import { closeAlert, errorActions, uploadData } from "@/alerts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

/**
 * 
 * @param {*} barCode Numero o codifgo de barra del producto
 * @param {*} productName Nombre del producto
 * @param {*} productPrice Precio del producto
 * @param {*} shopping Numero de la compra para hacer un seguimiento
 */
export const exportPDFBarcode = async (barCode, productName, productPrice,shopping,company) => {
  await uploadData(
    "Generando reporte".toUpperCase(),
    "Espera un momento mientras preparamos tu reporte, <strong>solo tomará unos segundos....</strong>"
  );
  const canvas = document.createElement("canvas");
  bwipjs.toCanvas(canvas, {
    bcid: "code128", // Tipo de código de barras, puedes cambiarlo según tus necesidades
    text: barCode, // Texto que se va a codificar en el código de barras
    scale: 3, // Escala del código de barras
    height: barCode.length <= 7?5:7, // Altura del código de barras || default [10]
    includetext: true, // Incluir el texto junto al código de barras
  });

  // Obtener la representación en base64 del código de barras generado
  const base64Image = canvas.toDataURL("image/png");

  try {
    let docDefinition = {
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "landscape",
      pageSize: { width: 40, height: 14 }, // Tamaño personalizado del PDF (en este caso, 200x100 unidades)
      pageMargins: [0, 1, 0, 0], // Márgenes de la página (en este caso, sin márgenes)

      content: [
        {
          text: company.length >= 20? company.slice(0, 33).toUpperCase().toUpperCase():company.toUpperCase(),
          fontSize: 1.5,
          bold: true,
          alignment: "center",
          color: "#000000",
          margin:[0,0,0,0.5]
        },
        { image: base64Image, width: barCode.length <= 8?15:28, alignment: "center" },        
        {
          text: productName.length >= 20? productName.slice(0, 25).toUpperCase()+'...'.toUpperCase():productName.toUpperCase(),
          fontSize: 1.5,
          bold: true,
          alignment: "center",
          color: "#000000",
          margin:[0,1,0,0]
        },
        {
          text: money() + format(productPrice)+(shopping!==''?' | '+shopping:''),
          fontSize: 1.5,
          bold: true,
          alignment: "center",
          color: "#000000",
          margin:[0,0,0,0]
        },
      ],
    };
    pdfMake.createPdf(docDefinition).download(productName);
    await closeAlert()
  } catch (error) {
    errorActions("No se pudo generar el reporte");
  }
};
