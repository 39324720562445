import { errorActions } from "@/alerts";
import axios from "@/api";
/*********************************************************************************
 * Funcion para validar el stock del producto
 *********************************************************************************/
export default async (id, stock) => {
  try {
    const response = await axios.get(
      `productos-para-venta/${id}?stock=${stock}`,
      {
        methods: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    if (data === true) return true;
    return false;
  } catch (error) {
    errorActions(error);
    return false;
  }
};
