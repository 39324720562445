<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    id="main-content-credit-note-sale"
  >
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] md:max-w-[80vw] lg:max-w-[80vw]"
        id="subcontent-credit-note-sale"
      >
        <div class="flex justify-between items-center mb-6">
          <h2
            class="text-xl font-medium text-gray-800 dark:text-white uppercase"
          >
            Modificando venta
            <strong class="text-blue-500">{{ infoSale.numberVoucher }}</strong>
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div class="w-full">
          <div
            class="px-3 py-3 bg-red-50 text-red-600 text-sm font-semibold"
            v-show="!aceptOk"
          >
            <strong>Hola {{ dataForm.userName }}!</strong>. <br />
            Al modificar esta <strong>venta</strong> usted esta alterando el
            funcionamiento normal de los módulos de
            <strong>
              (Kardex, Lote, Almacen, ventas, cuentas por cobrar,caja,etc.),
            </strong>
            si hay un descuadre por la modificación de esta venta,
            <strong>usted es el único responsable de tal alteración</strong>.
            <br />
            <br />
            Tenga en cuenta que usted solo debe de modificar las ventas con el
            tipo de pago:
            <strong class="block">
              1: EFECTIVO<br />
              2: TARJETA <br />
              3: TRANSFERENCIA<br />
              4: CHEQUE
            </strong>
            <br />
            Dale click a aceptar si estas seguro de modificar la venta.<br /><br />
            <label
              class="flex items-center cursor-pointer text-gray-500"
              for="acept"
            >
              <input
                type="checkbox"
                class="mr-2"
                id="acept"
                @change="checkAcept"
              />
              Aceptar y continuar
            </label>
          </div>
          <div
            class="py-3 bg-blue-50 px-3 rounded-md grid md:grid-cols-12 gap-4"
            v-show="aceptOk"
          >
            <SearchProduct @send-data="addProducts" :typeVoucher="'NINGUNA'" />
          </div>
          <div v-show="aceptOk">
            <!-- <h2 class="text-gray-700 font-bold text-sm">
              Detalles de la venta
            </h2> -->
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <LoadingTables v-if="loading" :columns="6" />
                  <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                    <tr
                      v-for="(sale, i) in arrayDataOrder"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.nombre_producto }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="sale.cantidad"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="
                            handleQuantityChange(i, $event, sale.igv_aplicado)
                          "
                          @keyup.enter="
                            handleQuantityChange(i, $event, sale.igv_aplicado)
                          "
                          v-if="sale.idventa === null"
                        />
                        <span v-else>{{ sale.cantidad }}</span>
                      </td>
                      <td
                        class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                      >
                        <select
                          class="border-2 bg-gray-50 py-1 text-xs px-3 text-gray-600 cursor-pointer rounded-lg mr-3 font-semibold max-w-[120px] focus:outline-double focus:outline-2 focus:outline-blue-500"
                          @change="
                            handleUnitMeasureChange(
                              i,
                              $event,
                              sale.igv_aplicado
                            )
                          "
                        >
                          <option
                            :value="
                              unit.precio +
                              '|' +
                              unit.cantidad +
                              '|' +
                              unit.unidad_de_medida
                            "
                            v-for="(unit, index) in sale.array_unidades"
                            :key="index"
                            :selected="
                              unit.unidad_de_medida === sale.unidad_de_medida
                            "
                          >
                            {{ unit.unidad_de_medida }}
                          </option>
                        </select>
                      </td>
                      <td
                        class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                      >
                        <select
                          @blur="handlePrices(i, $event, sale)"
                          @input="handlePrices(i, $event, sale)"
                          class="border-2 bg-gray-50 py-1 text-xs px-3 text-gray-600 cursor-pointer rounded-lg mr-3 font-semibold focus:outline-double focus:outline-2 focus:outline-blue-500"
                          v-model="sale.precio_seleccionado_cliente"
                        >
                          <option value="1" selected>PRECIO 1</option>
                          <option value="2" v-if="sale.show_btn_two">
                            PRECIO 2
                          </option>
                          <option value="3" v-if="sale.show_btn_three">
                            PRECIO 3
                          </option>
                        </select>
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.precio_de_venta) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="0"
                          :value="sale.cantidad_a_devolver"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="
                            handleQuantityReturnProductsChange(
                              i,
                              $event,
                              sale.igv_aplicado
                            )
                          "
                          @keyup.enter="
                            handleQuantityReturnProductsChange(
                              i,
                              $event,
                              sale.igv_aplicado
                            )
                          "
                          v-if="sale.idventa !== null"
                        />
                        <span v-else>0.00</span>
                      </td>

                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                      >
                        <button
                          class="bg-red-50 text-red-500 text-sm font-bold px-2 py-1 rounded-md"
                          @click="removeProduct(i, sale.idventa, sale.id)"
                        >
                          <font-awesome-icon icon="fa-solid fa-trash" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <DetailsUpdateSalesVue
              :beforeAmount="infoSale.total"
              :total="dataForm.total"
              :subTotal="dataForm.subTotal"
              :iva="dataForm.igv"
              :totalPayableByCustomer="dataForm.totalPayableByCustomer"
            />
            <div
              class="px-3 py-3 rounded-md border-2 border-blue-100 bg-blue-100 grid gap-4 md:grid-cols-3"
              v-show="isChange"
            >
              <!-- Importe de pago del cliente -->
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-blue-500 dark:text-white"
                >
                  IMPORTE DE PAGO
                </span>
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-gray-50 border-blue-500 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
                  v-model="dataForm.customerAmountPaid"
                  placeholder="Importe del cliente"
                  @keydown.enter.prevent
                  @input="calculteCustomerAmountReturned"
                />
              </label>
              <!-- Monto de vuelto que recivira el cliente -->
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-blue-500 dark:text-white"
                >
                  DEVUELTA
                </span>
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-blue-200 border-blue-200 outline-none rounded-md text-blue-600 w-full border-2 resize-none font-bold"
                  v-model="dataForm.customerAmountReturned"
                  placeholder="Devuelta"
                  @keydown.enter.prevent
                  readonly
                />
              </label>
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-blue-500 dark:text-white"
                >
                  AFECTAR TURNO
                </span>
                <Turns
                  @send-data="handleTurns"
                  class="bg-sky-900 font-semibold text-white border-sky-800"
                />
              </label>
            </div>

            <TypeOfPrinting @send-data="handleFormatPrinting" />

            <div class="flex mt-6 justify-end">
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                @click="insert"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="@/assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Registrando Información
                </div>
                <span v-else>Actualizar venta</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions, successAction } from "@/alerts";
import { observeElement } from "@/observer";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import axios from "@/api";
import { format, money } from "@/publicjs/money";
import {
  idLocalStore,
  idLocalUser,
  localUserName,
} from "@/publicjs/localStorage";
import { percentage } from "@/publicjs/convertToPercentage";
import Swal from "sweetalert2";
import SearchProduct from "./Searchs/SearchProduct.vue"; // Public components
import DetailsUpdateSalesVue from "./DetailsUpdateSales/DetailsUpdateSales.vue";
import TypeOfPrinting from "@/components/PublicComponents/TypeOfPrinting.vue";
// Print voucher
import { printVoucherOnA4 } from "./PrintVoucher/PrintOnA4";
import { printVoucherOnLetter } from "./PrintVoucher/PrintOnLetter";
import { printVoucherOnMediaLetter } from "./PrintVoucher/PrintOnMediaLetter";
import { printVoucherTicket } from "./PrintVoucher/PrintOnTicket";
import Turns from "@/components/PublicComponents/Turns.vue";
export default {
  name: "UpdateSale",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    infoSale: {
      type: Object,
      required: true,
    },
  },
  components: {
    LoadingTables,
    TableHead,
    SearchProduct,
    DetailsUpdateSalesVue,
    TypeOfPrinting,
    Turns,
  },
  setup(props) {
    function isActive() {
      props.activeModal(props.id, props.infoSale);
    }

    const spinner = ref(false);
    const loading = ref(true);
    const isChange = ref(false);
    const usePriceTwo = ref(false);
    const usePriceThree = ref(false);
    const aceptOk = ref(false);

    const dataForm = ref({
      idUser: 0,
      idStore: 0,
      printFormat: "",
      userName: null,
      total: 0,
      subTotal: 0,
      igv: 0,
      customerAmountPaid: 0,
      customerAmountReturned: 0,
      totalPayableByCustomer: 0,
      idTurn: 0,
    });
    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "U.M",
        },
        {
          title: "Precios",
        },
        {
          title: "Importe",
        },
        {
          title: "Total",
        },
        {
          title: "Cant. devolver",
        },
        {
          title: "Acciones",
        },
      ],
    ];
    const handleTurns = (data) => (dataForm.value.idTurn = data);

    //funcion para traer los detalles de la venta
    const arrayDataOrder = ref([]);
    const getSaleDetails = async () => {
      const response = await axios
        .get(`detalle-de-ventas/${props.id}`)
        .catch((error) => errorActions(error));
      const data = response.data;
      if (data.length <= 0) {
        errorActions("No hemos podido encontrar los detalles de la compra");
      } else {
        data.forEach((item) => {
          item.cantidad_a_devolver = 0;
          item.array_unidades = JSON.parse(item.array_unidades)
        });

        arrayDataOrder.value = data;
        loading.value = false;
      }
    };

    /*****************************************************************************
     * Funcion para añadir producto a lista de productos
     * @param {*} detail detalle de la venta
     *****************************************************************************/
    const addProducts = async (data) => {
      let {
        discount,
        idProduct,
        igv,
        igvApplied,
        insuranceDiscount,
        productName,
        quantity,
        salePrice,
        subTotal,
        taxFree,
        total,
        unitMeasure,
        quantityContained,
        shoppingPrice,
        priceSelectedForTheCustomer,
        units,
      } = data;
      //parseamos el iva del producto
      const CONFIGURE_IGV = percentage(igvApplied);

      subTotal = parseFloat(
        (parseFloat(salePrice) / (CONFIGURE_IGV + 1)).toFixed(2)
      );
      igv = parseFloat((parseFloat(subTotal) * CONFIGURE_IGV).toFixed(2));
      total = parseFloat(
        (parseFloat(quantity) * parseFloat(salePrice)).toFixed(2)
      );

      // Buscar el producto en la lista existente
      const existingProductIndex = arrayDataOrder.value.findIndex(
        (order) =>
          order.idproducto === idProduct &&
          order.unidad_de_medida === unitMeasure
      );

      if (existingProductIndex !== -1) {
        // El producto ya existe en la lista, sumar la cantidad y actualizar los precios
        const existingProduct = arrayDataOrder.value[existingProductIndex];
        const newQuantity =
          parseFloat(existingProduct.cantidad) + parseFloat(quantity);

        const thereIsStock = await validateStockProduct(idProduct, newQuantity);

        if (thereIsStock) {
          existingProduct.cantidad = newQuantity;
          existingProduct.subtotal = parseFloat(
            (subTotal * newQuantity).toFixed(2)
          );
          existingProduct.igv = parseFloat(
            (existingProduct.subtotal * CONFIGURE_IGV).toFixed(2)
          );
          existingProduct.total = parseFloat(
            (existingProduct.cantidad * parseFloat(salePrice)).toFixed(2)
          );
        } else {
          errorActions(
            "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
          );
        }
      } else {
        //Si el producto no existe agregamos un nuevo producto a la lista de arreglos de la orden
        const orderData = {
          cantidad: quantity,
          cantidad_contenida_por_unidad: quantityContained,
          descuento_aplicado: discount,
          descuento_por_seguro: insuranceDiscount,
          id: null,
          idproducto: idProduct,
          idventa: null,
          igv_aplicado: igvApplied,
          igv,
          libre_de_impuesto: taxFree,
          nombre_producto: productName,
          precio_de_venta: salePrice,
          subtotal: subTotal,
          total,
          unidad_de_medida: unitMeasure,
          cantidad_a_devolver: 0,
          precio_de_compra: shoppingPrice,
          array_unidades: units,
          precio_seleccionado_cliente: priceSelectedForTheCustomer,
          show_btn_two: data.showBTNPriceTwo,
          show_btn_three: data.showBTNPriceThree,
        };
        arrayDataOrder.value.push(orderData);
      }

      //Llamamos la funcion para calcular la orden y le pasamos como parametro el detalle de la orden
      calculateTheTotal(arrayDataOrder.value);
      isChange.value = true;
      // console.log(arrayDataOrder.value);
      // console.log(dataForm.value);
    };

    /*********************************************************************************
     * Funcion para validar el stock del producto
     *********************************************************************************/
    const validateStockProduct = async (id, stock) => {
      try {
        const response = await axios.get(
          `productos-para-venta/${id}?stock=${stock}`,
          {
            methods: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.data;
        if (data === true) return true;
        return false;
      } catch (error) {
        errorActions(error);
        return false;
      }
    };

    /*****************************************************************************
     * Funcion para quitar productos de la lista de devolucion
     * @param {*} index Indice del registro que sea elminado
     * @param {*} idSale ID de la venta para saber si los productos son productos ya vendidos o productos agregado recientemente
     * @param {*} idDetail ID del detalle de la venta
     *****************************************************************************/
    const removeProduct = async (index, idSale, idDetail) => {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar el detalle?",
        text: `Si eliminas el detalle de venta, ya no podrás recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡eliminar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Verificar si el índice está dentro de los límites del array
          if (index >= 0 && index < arrayDataOrder.value.length) {
            if (idSale !== null) {
              //Elminamos el productos desde la base de datos
              await deleteProduct(idDetail);
            } else {
              await successAction(
                "Eliminado",
                "Detalle de venta elminado con éxito"
              );
            }

            arrayDataOrder.value.splice(index, 1);
            calculateTheTotal(arrayDataOrder.value);
          } else {
            errorActions("Índice inválido. No se pudo remover el producto.");
          }
        }
      });
    };
    /*****************************************************************************
     * Funcion para eliminar el producto a nivel de base de datos
     * @param {*} id Indice del registro que sera eliminado
     *****************************************************************************/
    const deleteProduct = async (id) => {
      const response = await axios
        .delete(`detalle-de-ventas/${id}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === true) {
        await successAction(
          "Elminado",
          "Detalle de venta eliminado con éxito de la base de datos."
        );
      } else {
        errorActions("Lo sentimos no pudimos eliminar el detalle de la venta");
      }
      isChange.value = true;
      calculateTheTotal(arrayDataOrder.value);
    };

    /*****************************************************************************
     * Funcion para cambiar la cantidad de la orden
     * @param {*} index Indice del registro
     * @param {*} event El evento en este caso el valor que vendra en ese evento
     * @param {*} ivaAplied IVA aplicado al producto
     *****************************************************************************/
    const handleQuantityChange = (index, event, ivaAplied) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;

      arrayDataOrder.value[index].cantidad = newQuantity;
      updateDetailData(index, ivaAplied);
    };
    /*****************************************************************************
     * Funcion para actualizar el valor en el objeto y sacar un nuevo calculo total
     * @param {*} index Indice del registro
     * @param {*} ivaAplied IVA aplicado al producto
     *****************************************************************************/
    const updateDetailData = (index, ivaAplied) => {
      const order = arrayDataOrder.value[index];
      let totalOrder = 0;

      const priceForDiscount =
        order.precio_de_venta * percentage(order.descuento_aplicado);
      const newPrice = order.precio_de_venta + priceForDiscount;

      totalOrder = order.cantidad * newPrice;

      //parseamos el iva del producto
      const configureIva = percentage(ivaAplied);

      //volvemos a calcular el total de orden del producto seleccionado
      order.subtotal = parseFloat(totalOrder / (configureIva + 1)).toFixed(2);
      order.igv = parseFloat(order.subtotal * configureIva).toFixed(2);
      order.total = totalOrder.toFixed(2);

      //llamamos la funcion para calcular el total de orden y le pasamos como parametro el detalle de la orden
      calculateTheTotal(arrayDataOrder.value);
    };

    /*****************************************************************************
     * Funcion para especificar la cantidad a devolver
     * @param {*} index Indice del registro
     * @param {*} event El evento en este caso el valor que vendra en ese evento
     * @param {*} ivaApplied El impuesto aplicado
     *****************************************************************************/
    const handleQuantityReturnProductsChange = (index, event, ivaApplied) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 0;

      if (newQuantity > parseFloat(arrayDataOrder.value[index].cantidad)) {
        errorActions(
          "Lo sentimos usted no puede devolver una cantidad mayor a la que compró el cliente"
        );
        event.target.value = 0;
        return;
      }
      if (newQuantity === parseFloat(arrayDataOrder.value[index].cantidad)) {
        errorActions(
          "Si deseas devolver la totalidad del producto vendido te recomiento eliminarlo"
        );
        event.target.value = 0;
        return;
      }

      arrayDataOrder.value[index].cantidad_a_devolver = newQuantity;

      const order = arrayDataOrder.value[index];
      let totalOrder = 0;

      const priceForDiscount =
        order.precio_de_venta * percentage(order.descuento_aplicado);
      const newPrice = order.precio_de_venta + priceForDiscount;

      totalOrder = (order.cantidad - order.cantidad_a_devolver) * newPrice;

      //parseamos el iva del producto
      const configureIva = percentage(ivaApplied);

      //volvemos a calcular el total de orden del producto seleccionado
      order.subtotal = parseFloat(totalOrder / (configureIva + 1)).toFixed(2);
      order.igv = parseFloat(order.subtotal * configureIva).toFixed(2);
      order.total = totalOrder.toFixed(2);
      calculateTheTotal(arrayDataOrder.value);
      isChange.value = true;
      // console.log(dataForm.value);
      // console.log(arrayDataOrder.value);
    };

    /*****************************************************************************
     * Funcion para calcular el total de la orden, incluyendo el iva, subtotal
     * @param {*} order el array de objetos de productos de la tabla
     *****************************************************************************/
    const calculateTheTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      dataForm.value.total = 0;
      dataForm.value.subTotal = 0;
      dataForm.value.igv = 0;
      dataForm.value.totalPayableByCustomer = 0;

      //recorremos todo el detalle de la orden
      order.forEach((element) => {
        dataForm.value.total += parseFloat(element.total);
        dataForm.value.subTotal += parseFloat(element.subtotal);
        dataForm.value.igv += parseFloat(element.igv);
      });
      if (dataForm.value.total > props.infoSale.total) {
        dataForm.value.totalPayableByCustomer =
          dataForm.value.total - props.infoSale.total;
      }
    };

    const checkAcept = () => {
      aceptOk.value = !aceptOk.value;
    };

    onMounted(async () => {
      observeElement("#main-content-credit-note-sale");
      observeElement("#subcontent-credit-note-sale");
      await getSaleDetails();

      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
      dataForm.value.userName = await localUserName();
    });

    /*********************************************************************************
     * Funcion para calcular el vuelto del cliente si el tiepo de pago el efectivo
     *********************************************************************************/
    const calculteCustomerAmountReturned = () => {
      dataForm.value.customerAmountReturned = (
        dataForm.value.customerAmountPaid - dataForm.value.total
      ).toFixed(2);
    };

    //funciona para obtener el formato de impresion
    const handleFormatPrinting = (data) => {
      dataForm.value.printFormat = data;
    };
    /*********************************************************************************
     * Funcion para aplicar el descuento al producto producto
     *********************************************************************************/
    const handleUnitMeasureChange = async (index, event, igvApplied) => {
      const [price, quantityContained, unitMeasure] =
        event.target.value.split("|");
      //Calculamos la cantidad de productos
      const quantity =
        arrayDataOrder.value[index].cantidad * parseFloat(quantityContained);

      //OBTENCION DE LAS UNIDADES DE MEDIDA
      const selectUM = arrayDataOrder.value[index].array_unidades.filter((item) => item.unidad_de_medida === unitMeasure);

      //Validamos que el producto cuente con stock suficiente para ser cambiado de unidad de medida
      const thereIsStock = await validateStockProduct(
        arrayDataOrder.value[index].idproducto,
        quantity
      );

      if (thereIsStock) {
        arrayDataOrder.value[index].precio_de_venta = parseFloat(price);
        arrayDataOrder.value[index].unidad_de_medida = unitMeasure;
        arrayDataOrder.value[index].cantidad_contenida_por_unidad =
          parseFloat(quantityContained);

        if (selectUM[0].precio_dos > 0) {
          arrayDataOrder.value[index].showBTNPriceTwo = true;
        } else {
          arrayDataOrder.value[index].showBTNPriceTwo = false;
        }
        if (selectUM[0].precio_tres > 0) {
          arrayDataOrder.value[index].showBTNPriceThree = true;
        } else {
          arrayDataOrder.value[index].showBTNPriceThree = false;
        }
        updateDetailData(index, igvApplied);
      } else {
        errorActions(
          "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
        );
        event.target.selectedIndex = 0;
        return;
      }
      arrayDataOrder.value[index].precio_seleccionado_cliente = 1;
    };
    /*********************************************************************************
     * Funcion para cambiar el precio del producto por PRECIO 1, PRECIO 2, PRECIO 3
     *********************************************************************************/
    const handlePrices = async (index, event, product) => {
      const price = parseInt(event.target.value);
      const unitMeasure = arrayDataOrder.value[index].unidad_de_medida;
      const selectUM = arrayDataOrder.value[index].array_unidades.filter((item) => item.unidad_de_medida === unitMeasure);
      if (selectUM && selectUM.length > 0) {
        if (price === 1) {
          arrayDataOrder.value[index].precio_de_venta = parseFloat(
            selectUM[0].precio
          );
        } else if (price === 2) {
          if (parseFloat(selectUM[0].precio_dos) > 0) {
            arrayDataOrder.value[index].precio_de_venta = parseFloat(
              selectUM[0].precio_dos
            );
          }
        } else if (price === 3) {
          if (parseFloat(selectUM[0].precio_dos) > 0) {
            arrayDataOrder.value[index].precio_de_venta = parseFloat(
              selectUM[0].precio_tres
            );
          }
        }
        arrayDataOrder.value[index].precio_seleccionado_cliente = price;
        updateDetailData(index, product.igv_aplicado);
      }
    };

    const insert = async () => {
      if (!isChange.value) {
        errorActions(
          "Ud no puede modificar la venta debido a que <strong>no has cambiando ningún detalle de a venta actual</strong>"
        );
        return;
      }
      if (dataForm.value.customerAmountPaid < dataForm.value.total) {
        errorActions(
          "Por favor <strong>Ingresa el nuevo importe de pago del cliente</strong>"
        );
        return;
      }
      if (
        isNaN(dataForm.value.idTurn) ||
        parseInt(dataForm.value.idTurn) <= 0
      ) {
        errorActions(
          "Por favor selecciona el turno actual o un turno al cual se afectará la modificacion de la venta."
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .put(
          "modificar-ventas/" + props.id,
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            total: dataForm.value.total,
            subTotal: dataForm.value.subTotal,
            igv: dataForm.value.igv,

            customerAmountPaid: dataForm.value.customerAmountPaid,
            customerAmountReturned: dataForm.value.customerAmountReturned,

            details: arrayDataOrder.value,

            idTurn: dataForm.value.idTurn,
            totalPayableByCustomer: dataForm.value.totalPayableByCustomer,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        if (dataForm.value.printFormat === "format_a4") {
          await printVoucherOnA4(data.id);
        } else if (dataForm.value.printFormat === "format_m_carta") {
          await printVoucherOnMediaLetter(data.id);
        } else if (dataForm.value.printFormat === "format_8x11") {
          await printVoucherOnLetter(data.id);
        } else if (dataForm.value.printFormat === "format_ticket") {
          await printVoucherTicket(data.id);
        }
        confirmationOfRecord(
          `La venta  <strong> a sido modificado satisfactoriamente</strong>`
        );
        props.getdata();
        isActive();
      } else {
        errorActions(
          `Lo sentimos,  <strong>no pudimos modificar la venta</strong>`
        );
      }
      spinner.value = false;
    };
    
    return {
      isActive,
      dataForm,
      spinner,
      header,
      loading,
      arrayDataOrder,
      format,
      money,
      addProducts,
      removeProduct,
      handleQuantityChange,
      handleQuantityReturnProductsChange,
      checkAcept,
      aceptOk,
      insert,
      calculteCustomerAmountReturned,
      isChange,
      handleFormatPrinting,
      handleTurns,
      handleUnitMeasureChange,
      handlePrices,
      usePriceTwo,
      usePriceThree,
    };
  },
};
</script>
