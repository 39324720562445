<template>
  <div class="mt-4">
    <div>
      <button
        class="bg-blue-500 text-white py-1 px-2 rounded-lg mb-6 flex items-center outline-none shadow-sm hover:shadow-blue-500"
        @click="activeModalRegister"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
        Nuevo registro
      </button>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="1">1 Registros</option>
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>
      <table class="border-collapse w-full border-t-[1px]">
        <TableHead :headers="header" />
        <LoadingTables v-if="loading" :columns="5" />
        <tbody class="text-gray-600 text-center border-t-[1px]" v-else>
          <tr
            v-for="(register, i) in registers"
            :key="i"
            class="hover:bg-blue-50 hover:text-gray-700 font-semibold odd:bg-white even:bg-slate-50"
          >
            <td class="border-slate-200 text-sm px-2 py-2">
              <font-awesome-icon :icon="register.icon" class="text-gray-400" />
            </td>
            <td class="border-slate-200 text-sm px-2 py-2">
              {{ register.description }}
            </td>
            <td class="border-slate-200 text-sm px-2 py-2">
              {{ register.descripcion }}
            </td>
            <td class="border-slate-200 text-sm px-2 py-2">
              <router-link :to="register.url_del_modulo">{{
                register.url_del_modulo
              }}</router-link>
            </td>
            <td class="border-slate-200 text-sm px-2 py-2 text-right">
              <button
                class="bg-blue-600 text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-blue-700"
                @click="activeModalUpdate(register.id)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-pen"
                  class="text-white w-3 h-3"
                />
              </button>
              <button
                class="bg-red-500 ml-3 text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-red-600"
                @click="isDelete(register.descripcion, register.id)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-trash"
                  class="text-white w-3 h-3"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterSubModules
    :activeModal="activeModalRegister"
    :getdata="getData"
    v-if="stateRegister"
  />
  <UpdateSubModules
    :activeModal="activeModalUpdate"
    :getdata="getData"
    :id="id"
    v-if="stateUpdate"
  />
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "../../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterSubModules from "@/components/SystemModules/SystemSubModules/RegisterSubModules.vue";
import UpdateSubModules from "@/components/SystemModules/SystemSubModules/UpdateSubModules.vue";
import Swal from "sweetalert2";
import { errorActions } from "@/alerts";
export default {
  name: "MainSubModules",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterSubModules,
    UpdateSubModules,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Icono",
        },
        {
          title: "Módulos",
        },
        {
          title: "SubMódulos",
        },
        {
          title: "Url",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      try {
        loading.value = true;
        const response = await axios
          .get(
            `submodulos_del_sistema/paginate/all/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}`
          )
          .catch((error) => {
            errorActions(error);
          });
        registers.value = response.data.data;
        resgisterPaginate.value = response.data;
        totalPage.value = resgisterPaginate.value.last_page;
        loading.value = false;
      } catch (error) {
        errorActions(error);
      }
    };
    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };
    onMounted(async () => {
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateRegister = ref(false);
    const stateUpdate = ref(false);
    const activeModalRegister = () => {
      stateRegister.value = !stateRegister.value;
    };

    const id = ref(0);
    const activeModalUpdate = (idData) => {
      stateUpdate.value = !stateUpdate.value;
      id.value = idData;
    };

    //Acciones para eliminar
    const deleteData = async (register, id) => {
      const response = await axios
        .delete(process.env.VUE_APP_API_URL + "submodulos_del_sistema/" + id, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = response.data;
      if (data.msg === "Request failed with status code 500") {
        errorActions(data.msg);
      } else if (data.msg === false) {
        errorActions(
          "Lo sentimos no pudimos eliminar el registro submódulo del sistema"
        );
      }
    };
    //SweetAlert para confirmas la eliminacion del registro
    const isDelete = (register, id) => {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar el registro?",
        text: `Si eliminas a (${register}), ya no podrás recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡eliminar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteData(register, id);
          Swal.fire({
            icon: "success",
            title: "Eliminado",
            text: `${register}, a sido eliminado de la base de datos`,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });

          await getData();
        }
      });
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      activeModalUpdate,
      isDelete,
      id,
      stateUpdate,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
