import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { money, format } from "@/publicjs/money"; //? Formateo y obtencion de moneda configurada de la empresa
import { closeAlert, errorActions, uploadData } from "@/alerts"; //? Alertas de error, etc
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { imageInBase64 } from "@/publicjs/logoCompany"; //? Imagen de base64 de la empresa
import { formatDateTime } from "@/publicjs/formatDate"; //? Retorna la fecha y hora actual
import titlesConfig from "/public/apis/config.json"; //? Títulos de configuracion segun pais
import styles from "@/publicjs/styles";
import axios from "@/api";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import boxConfigurationAccordingToCountry from "/public/apis/boxConfigurationAccordingToCountry.json";

//* Función para imprimir el documento
/**
 * @param {*} idOrder | ID de la ultima orden realizada
 */
export const printOnA4 = async (idOrder) => {
  uploadData(
    "Generando cuadre de caja",
    "Espera un momento mientras consultamos los datos,<strong>Esto puede tardar unos segundos</strong>"
  );
  let boxConfiguration = false;
  if (boxConfigurationAccordingToCountry.country === "PERU") {
    boxConfiguration = true;
  }
  //* Poblado de datos de la empresa
  const dataStore = JSON.parse(localStorage.getItem("access"));
  const company = await getCompany(dataStore.user.store);

  //* Cargar la imagen o el logo de la empresa
  let base64Image = await imageInBase64(company.companyLogo);

  //? Obtencion de datos de la orden
  const registers = await order(idOrder); //! ID de la ultima orden realizada

  let billsRow = [];
  if (boxConfiguration) {
    billsRow.push([
      {
        text: "B. 10",
        border: [true, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 20",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 50",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 100",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 200",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "---",
        border: [false, true, true, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.md,
        color: styles.colors.colorTitle,
        bold: true,
      },
    ]);
    billsRow.push([
      {
        text: registers.billete_10,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [true, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_20,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_50,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_100,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_200,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: "---",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, true, true],
        valign: "middle",
      },
    ]);
  } else {
    billsRow.push([
      {
        text: "B. 50",
        border: [true, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 100",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 200",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 500",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 1000",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "B. 2000",
        border: [false, true, true, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.md,
        color: styles.colors.colorTitle,
        bold: true,
      },
    ]);
    billsRow.push([
      {
        text: registers.billete_50,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [true, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_100,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_200,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_500,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_1000,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.billete_2000,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, true, true],
        valign: "middle",
      },
    ]);
  }

  let coinsRow = [];
  if (boxConfiguration) {
    coinsRow.push([
      {
        text: "M. 1",
        border: [true, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "M. 2",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "M. 5",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "M. 10",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "M. 20",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "50",
        border: [false, true, true, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.md,
        color: styles.colors.colorTitle,
        bold: true,
      },
    ]);
    coinsRow.push([
      {
        text: registers.moneda_1,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [true, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_2,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_5,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_10,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_20,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_50,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, true, true],
        valign: "middle",
      },
    ]);
  } else {
    coinsRow.push([
      {
        text: "M. 1",
        border: [true, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "M. 5",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "M. 10",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "M. 25",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "---",
        border: [false, true, false, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorTitle,
        bold: true,
      },
      {
        text: "---",
        border: [false, true, true, true],
        margin: [0, 2, 0, 2],
        valign: "middle",
        fontSize: styles.fontSize.pdf.md,
        color: styles.colors.colorTitle,
        bold: true,
      },
    ]);
    coinsRow.push([
      {
        text: registers.moneda_1,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [true, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_5,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_10,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: registers.moneda_25,
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: "---",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, false, true],
        valign: "middle",
      },
      {
        text: "---",
        fontSize: styles.fontSize.pdf.sm,
        color: styles.colors.colorDescription,
        border: [false, false, true, true],
        valign: "middle",
      },
    ]);
  }

  try {
    let docDefinition = null;
    docDefinition = {
      // a string or { width: number, height: number }
      pageSize: "A4",

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "portrait", //cambia a [landscape] para imprimir de forma horizontal

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [24, 140, 24, 24],
      //!encabezado del documento con datos de la empresa, sucursal y datos del comprobante
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            columns: [
              {
                width: 100,
                stack: [
                  { image: base64Image, fit: [80, 80] }, // logo
                ],
                alignment: "left",
                margin: [0, 10, 0, 0],
              },
              {
                width: 285,
                stack: [
                  {
                    text: company.companyName,
                    fontSize: styles.fontSize.pdf.xl,
                    alignment: "left",
                    bold: true,
                    color: styles.colors.colorTitle,
                  },
                  {
                    text: "Dirección: " + company.companyAddress,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: "Tel:" + company.companycellphone,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: company.companySlogan,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text:
                      "Sucursal: ".toUpperCase() +
                      registers.razon_social.toUpperCase(),
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    bold: true,
                    color: styles.colors.colorTitle,
                  },
                  {
                    text: "DIR: " + registers.direccion.toUpperCase(),
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: "TEL: " + registers.celular,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                ],
                alignment: "left",
                margin: [2, 10, 24, 0],
              },
              // Columna derecha para los datos de la empresa
              // Tabla para envolver los elementos y aplicar bordes
              {
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        stack: [
                          {
                            text:
                              titlesConfig.titleTypeDocument +
                              company.companyRUC,
                            fontSize: styles.fontSize.pdf.lg,
                            alignment: "center",
                            color: styles.colors.colorTitle,
                            bold: true,
                            margin: [0, 5, 0, 5],
                          },
                          {
                            text:
                              "APERT.: " +
                              formatDateTime(registers.fecha_apertura),
                            fontSize: styles.fontSize.pdf.sm,
                            alignment: "center",
                            color: styles.colors.colorTitle,
                            bold: true,
                            margin: [0, 5, 0, 5],
                          },
                          {
                            text:
                              registers.fecha_de_cierre !== null
                                ? "CIERRE: " +
                                  formatDateTime(registers.fecha_de_cierre)
                                : "---",
                            fontSize: styles.fontSize.pdf.sm,
                            alignment: "center",
                            color: styles.colors.colorTitle,
                            bold: true,
                            margin: [0, 5, 0, 5],
                          },
                        ],
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i, node) {
                    // Agrega un borde horizontal en la parte superior del primer elemento,
                    // y en la parte inferior de todos los elementos
                    if (i === 0 || i === node.table.body.length) {
                      return 2;
                    }
                    // No agrega bordes horizontales para los elementos internos
                    return 0;
                  },
                  hLineColor: function () {
                    // Establece el color del borde horizontal
                    return styles.colors.borderColor;
                  },
                  vLineColor: function () {
                    // CEstablece el color del borde vertical
                    return styles.colors.borderColor;
                  },
                  vLineWidth: function () {
                    // Agrega un borde vertical en el lado derecho de cada elemento
                    return 2;
                  },
                },

                alignment: "right",
                margin: [0, 10, 24, 0],
              },
            ],
            margin: [24, 12, 0, 0], // Margen entre el encabezado y el contenido de la página
          },
          {
            canvas: [
              { type: "rect", x: 24, y: 90, w: pageSize.width - 48, h: 2 },
            ],
            margin: [0, 10], // Margen entre el rectángulo y el contenido de la página
          },
        ];
      },

      //!pie de página del documento
      footer: function (currentPage, pageCount) {
        return {
          text:
            "Emitido por " +
            registers.nombres +
            "          /               Estas en la página " +
            currentPage.toString() +
            " de " +
            pageCount,
          alignment: "right",
          fontSize: styles.fontSize.pdf.sm,
          color: styles.colors.colorDescription,
          margin: [0, 0, 24, 0],
        };
      },
      content: [
        //?Titulo de la informacion del usuario
        {
          text: "información del usuario".toUpperCase(),
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorDescription,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        //? Encabezado del comprobante
        {
          table: {
            headerRows: 0,
            widths: [82, "*", 98, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Responsable:",
                  border: [true, true, false, true],
                  fontSize: styles.fontSize.pdf.sm,
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: registers.nombres,
                  border: [false, true, false, true],
                  fontSize: styles.fontSize.pdf.sm,
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
                {
                  text: "Turno:",
                  border: [false, true, false, true],
                  margin: [0, 5, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: registers.descripcion,
                  border: [false, true, true, true],
                  margin: [0, 5, 0, 0],
                  fontSize: styles.fontSize.pdf.sm,
                  valign: "middle",
                  color: styles.colors.colorDescription,
                },
              ],
            ],
          },

          borderRadius: [5, 5, 5, 5],
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
        //?Titulo de cuadre de caja
        {
          text: "cuadre de caja".toUpperCase(),
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorDescription,
          bold: true,
          margin: [0, 10, 0, 0],
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: [100, "*", 100, 100, 100], //ancho de la columnas
            body: [
              [
                {
                  text: "Monto inicial",
                  border: [true, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto en cheque",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto en transfer...",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto en tarjeta",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto en efectivo",
                  border: [false, true, true, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
              ],
              [
                {
                  text: money() + format(registers.monto_inicial),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [true, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_en_cheque),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_en_transferencia),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_en_tarjeta),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_en_efectivo),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, true, false],
                  valign: "middle",
                },
              ],
              [
                {
                  text: "Otros ingresos",
                  border: [true, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto en desembolso",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto faltante",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto sobrante",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Monto en tránsito",
                  border: [false, true, true, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
              ],
              [
                {
                  text: money() + format(registers.monto_en_credito),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [true, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_de_egreso),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_faltante),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_sobrante),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, false],
                  valign: "middle",
                },
                {
                  text: `
                  EFECTIVO: ${money() + format(registers.dinero_en_transito)} \n
                  OTROS: ${money() + format(registers.dinero_en_transito_otros)}
                  `,
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, true, false],
                  valign: "middle",
                },
              ],
              [
                {
                  text: "Sus descuentos",
                  border: [true, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Cierre total",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Efectivo en caja",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Pagos del mensajero",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "Pagos cuentas por cobrar",
                  border: [false, true, true, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
              ],
              [
                {
                  text: money() + format(registers.descuentos),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [true, false, false, true],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_de_cierre_total),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorWarning,
                  border: [false, false, false, true],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_en_caja),
                  fontSize: styles.fontSize.pdf.md,
                  color: styles.colors.colorSuccess,
                  border: [false, false, false, true],
                  valign: "middle",
                  bold: true,
                },
                {
                  text: money() + format(registers.pago_mensajero),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, true],
                  valign: "middle",
                },
                {
                  text:  money() + format(registers.pagos_cuentas_por_cobrar),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, true, true],
                  valign: "middle",
                },
              ],
              [
                {
                  text: money() + format(registers.descuentos),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [true, false, false, true],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_de_cierre_total),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorWarning,
                  border: [false, false, false, true],
                  valign: "middle",
                },
                {
                  text: money() + format(registers.monto_en_caja),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorSuccess,
                  border: [false, false, false, true],
                  valign: "middle",
                  bold: true,
                },
                {
                  text: money() + format(registers.pago_mensajero),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, true],
                  valign: "middle",
                },
                {
                  text:  money() + format(registers.pagos_cuentas_por_cobrar),
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, true, true],
                  valign: "middle",
                },
              ],
              [
                {
                  text: "CIERRE OFICIAL",
                  border: [true, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: "red",
                  bold: true,
                },
                {
                  text: "-",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "-",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text: "-",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
                {
                  text:"-",
                  border: [false, true, true, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorTitle,
                  bold: true,
                },
              ],
              [
                {
                  text: money() + format((parseFloat(registers.monto_de_cierre_total) -  parseFloat(registers.monto_inicial))),
                  fontSize: styles.fontSize.pdf.sm,
                  color: "red",
                  border: [true, false, false, true],
                  valign: "middle",
                },
                {
                  text: "-",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorWarning,
                  border: [false, false, false, true],
                  valign: "middle",
                },
                {
                  text: "-",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorSuccess,
                  border: [false, false, false, true],
                  valign: "middle",
                  bold: true,
                },
                {
                  text: "-",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, false, true],
                  valign: "middle",
                },
                {
                  text: "-",
                  fontSize: styles.fontSize.pdf.sm,
                  color: styles.colors.colorDescription,
                  border: [false, false, true, true],
                  valign: "middle",
                },
              ],
            ],
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 10, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
        //?Titulo para contabilizar billetes
        {
          text: "BILLETES CONTABILIZADOS".toUpperCase(),
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorDescription,
          bold: true,
          margin: [0, 10, 0, 0],
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: ["*", 70, 70, 70, 70, 70], //ancho de la columnas
            body: billsRow,
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 10, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
        //?Titulo para contabilizar billetes
        {
          text: "MONEDAS CONTABILIZADOS".toUpperCase(),
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorDescription,
          bold: true,
          margin: [0, 10, 0, 0],
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: ["*", 70, 70, 70, 70, 70], //ancho de la columnas
            body: coinsRow,
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 10, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
        //?Titulo de comentario adicional
        {
          text: "comentario adicional".toUpperCase(),
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorDescription,
          bold: true,
          margin: [0, 0, 0, 0],
        },
        {
          text: registers.comentario
            ? registers.comentario
            : "" +
              "\n Dinero que se quedó en caja: " +
              money() +
              "" +
              format(registers.dinero_que_queda) +
              "\n\n Dinero que envió: " +
              money() +
              "" +
              format(registers.dinero_que_deposita) + 
              `${parseFloat(registers.pagos_cuentas_por_cobrar) > 0?'\n\n Cierre final incluido pagos de cuentas por cobrar ' +money() + format(parseFloat(registers.pagos_cuentas_por_cobrar) + parseFloat(registers.monto_de_cierre_total)):''}`,
          fontSize: styles.fontSize.pdf.sm,
          color: styles.colors.colorDescription,
          margin: [0, 0, 0, 0],
        },
        {
          text: "Importante: En el cierre oficial no esta incluido el monto de apertura de la caja",
          fontSize: styles.fontSize.pdf.sm,
          color: "red",
          margin: [0, 10, 0, 0],
        },
      ],
      watermark: {
        text: "CUADRE DE CAJA",
        fontSize: 60,
        color: styles.colors.colorBlue,
        opacity: 0.3,
        angle: -45,
        bold: true,
      },
    };
    pdfMake.createPdf(docDefinition).print();
    closeAlert();
  } catch (error) {
    errorActions("No se pudo generar el reporte");
  }
};

const order = async (idOrder) => {
  try {
    const response = await axios.get(`caja/print/voucher/pdf/${idOrder}`);
    return await response.data;
  } catch (error) {
    errorActions(error);
  }
};
