import Swal from "sweetalert2";
import { observer } from "@/observer";
/**
 * 
 * @param {*} register | Nombre del registro que se va a eliminar
 * @param {*} action | accion a ejecutar
 */
const confirmDelete = ( register, action) => {
    Swal.fire({
        title:'¿Estas seguro que deseas eliminar el registro?',
        text: `Si eliminas a (${register}), ya no podrás recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡eliminar!",
        cancelButtonText: "Cancelar",
        width:"300px",
      }).then((result) => {
        if (result.isConfirmed) {
        action()
          Swal.fire({
            icon: "success",
            title: "Eliminado",
            text: `${register}, a sido eliminado de la base de datos`,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });
        }
      });
}
/**
 * 
 * @param {*} msg | Puede ser el nombre del producto o el registro insertado
 */
const confirmationOfRecord = (msg) => {
    Swal.fire({
        icon: "success",
        title: "Registrado",
        html: `${msg} a sido registrado con éxito a la base de datos.`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
    });
    //codigo para evitar el error de [resize observer]
    observer.observe(
      document.querySelector(".swal2-container"),
      { box: "content-box" },
      10
    );
}
/**
 * 
 * @param {*} msg | Puede ser el nombre del producto o el registro actualizado
 */
const confirmationOfUpdate = (msg) => {
    Swal.fire({
        icon: "success",
        title: "Actualizado",
        html: `<strong>${msg}</strong> a sido actualizado con éxito.`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
    });
    //codigo para evitar el error de [resize observer]
    observer.observe(
      document.querySelector(".swal2-container"),
      { box: "content-box" },
      10
    );
}
/**
 * 
 * @param {*} msg | Puede ser el nombre del producto o el registro eliminado
 */
const errorActions = (msg) => {
    Swal.fire({
        icon: "error",
        title: "Error",
        html: msg,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!"
    });
    //codigo para evitar el error de [resize observer]
    observer.observe(
      document.querySelector(".swal2-container"),
      { box: "content-box" },
      10
    );
}
/**
 * 
 * @param {*} msg | Puede ser el nombre del producto o el registro eliminado
 */
const warningActions = (msg) => {
    Swal.fire({
        icon: "warning",
        title: "Uppsss...",
        html: msg,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!"
    });
    //codigo para evitar el error de [resize observer]
    observer.observe(
      document.querySelector(".swal2-container"),
      { box: "content-box" },
      10
    );
}

const uploadData = async (title, msg) => {
    Swal.fire({
      // icon: "warning",
      title: title,
      html: msg,
      didOpen: () => {
        Swal.showLoading();
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      },
    });
    //codigo para evitar el error de [resize observer]
    observer.observe(
      document.querySelector(".swal2-container"),
      { box: "content-box" },
      10
    );
  };


const closeAlert = async () => {
  Swal.close()
}

/**
 * 
 * @param {*} title | titulo del mensaje de elminación
 * @param {*} msg | mensaje para una accion correcta
 */
const successAction = async (title,msg) => {
  Swal.fire({
      icon: "success",
      title,
      html: msg,
      confirmButtonColor: "#315df5",
      confirmButtonText: "Aceptar!"
  });
  observer.observe(
    document.querySelector(".swal2-container"),
    { box: "content-box" },
    10
  );
}

export  {
    confirmDelete,
    confirmationOfRecord,
    confirmationOfUpdate,
    errorActions,
    warningActions,
    uploadData,
    closeAlert,
    successAction
}