import { saveAs } from "file-saver";
import { getCurrentDate } from "@/publicjs/currentDate";
import ExcelJS from "exceljs";
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { idLocalStore } from "@/publicjs/localStorage"; //? Recuperar el id de la sucursal
import { closeAlert, errorActions, uploadData } from "@/alerts";
import stylesExcel from "@/publicjs/stylesExcel";
/**
 *
 * @param {*} data Array de datos para poblar toda la informacion
 */
export const exportToExcel = async (data) => {
  try {
    await uploadData(
      "Generando reporte".toUpperCase(),
      "Espera un momento mientras preparamos tu reporte,<br> <strong>Esto puede tardar unos segundos...</strong>"
    );

    //* Traer los datos de la sucursal
    const dataCompany = await getCompany(await idLocalStore());

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("CAJAS".toUpperCase());

    const styles = {
      title: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.xl,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      subtitle: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      header: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.sm,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "left", vertical: "middle" },
      },
      total: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.green },
        },
        alignment: { horizontal: "right", vertical: "middle" },
      },
    };

    // Agregar título
    let rowNumber = 1; // Número de fila que deseas ajustar la altura
    let row = worksheet.getRow(rowNumber);

    worksheet.mergeCells("A1:Q1");
    const titleReport = worksheet.getCell("A1");
    titleReport.value = "Reporte de cajas".toUpperCase();
    titleReport.style = styles.title;
    row.height = 35;

    rowNumber = 2;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A2:Q2");
    const store = worksheet.getCell("A2");
    store.value = "SUCURSAL: " + dataCompany.storeName.toUpperCase();
    store.style = styles.subtitle;
    row.height = 30;

    rowNumber = 3;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A3:Q3");
    const dateReport = worksheet.getCell("A3");
    dateReport.value = "FEC: " + getCurrentDate();
    dateReport.style = styles.subtitle;
    row.height = 30;

    // Agregar encabezados
    const headers = [
      "USUARIOS",
      "FEC. APERTURA",
      "MONTO INICIAL",
      "OTROS INGRESOS",
      "MONTO EN CHEQUE",
      "MONTO EN TARJETA",
      "MONTO EN EFECTIVO",
      "MONTO EN TRANSFERENCIA",
      "MONTO DE DESEMBOLSO",
      "MONTO TOTAL DE CIERRE",
      "MONTO FALTANTE",
      "MONTO SOBRANTE",
      "MONTO EN DESCUENTO",
      "DINERO EN TRÁNSITO",
      "FECHA DE CIERRE",
      "ESTADO",
      "MONTO TOTAL EN CAJA",
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = styles.header;
    });

    rowNumber = 4; // Número de fila que deseas ajustar la altura
    row = worksheet.getRow(rowNumber);
    row.height = 35;

    // Agregar datos
    data.forEach((rowData) => {
      const rows = worksheet.addRow([
        rowData.nombres,
        rowData.fecha_apertura,
        Number(rowData.monto_inicial),
        Number(rowData.monto_en_credito),
        Number(rowData.monto_en_cheque),
        Number(rowData.monto_en_tarjeta),
        Number(rowData.monto_en_efectivo),
        Number(rowData.monto_en_transferencia),
        Number(rowData.monto_de_egreso),
        Number(rowData.monto_de_cierre_total),
        Number(rowData.monto_faltante),
        Number(rowData.monto_sobrante),
        Number(rowData.descuentos),
        Number(rowData.dinero_en_transito),
        rowData.fecha_de_cierre,
        rowData.estado === 1 ? "CERRADO" : "ABIERTO",
        Number(rowData.monto_en_caja),
      ]);
      //Estilos para las columnas
      rows.getCell(16).style.font = {
        bold: true,
        color: { argb: stylesExcel.color.warning },
      };
      (rows.getCell(16).style.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: stylesExcel.color.warningTransparent },
      }),
        (rows.getCell(16).alignment = {
          horizontal: "center",
          vertical: "middle",
        }),
        (rows.getCell(17).style.font = {
          bold: true,
          size: stylesExcel.fontSize.md,
        });
    });

    //* Ajustar ancho de columnas
    worksheet.columns.forEach((column, index) => {
      //Ancho para la columnas
      if (index === 0) {
        column.width = 45;
      } else if (
        index === 1 ||
        index === 2 ||
        index === 3 ||
        index === 4 ||
        index === 5 ||
        index === 6 ||
        index === 7 ||
        index === 8 ||
        index === 9 ||
        index === 10 ||
        index === 11 ||
        index === 12 ||
        index === 13 ||
        index === 14 ||
        index === 15 ||
        index === 16
      ) {
        column.width = 30;
      } else {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      }
    });

    //! Agregar una nueva fila debajo de los totales
    const newTotalRow = worksheet.addRow([]);

    /************************************************************************************************ */
    const totalRow = data.length + 4; //total de filas

    const columnPosition = 17; //Posicion de la columna para sumar datos
    /************************************************************************************************ */

    //? Calcular la fórmula de suma como un string
    const sumFormula = `SUM(Q5:Q${totalRow})`;
    newTotalRow.getCell(columnPosition).value = {
      formula: sumFormula,
      result: 0,
    };

    //* Dar formato a la moneda
    newTotalRow.getCell(columnPosition).style = styles.total;
    newTotalRow.getCell(columnPosition).numFmt = "#,##0.00";

    //* Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), getCurrentDate() + ".xlsx");
    await closeAlert(); //Cerramos el modal de aviso
  } catch (error) {
    errorActions(
      "Lo sentimos no pudimos generar el reporte, intentalo mas tarde"
    );
  }
};
