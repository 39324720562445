/**
 * 
 * @returns Retorna a fecha actual en format "DD-MM-YYYY"
 */
export const getCurrentDate = () => {

  const currentDate = new Date();

  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

  return `${currentDay}-${currentMonth}-${currentYear}`;
};

/**
 * 
 * @returns Retorna a fecha actual en format "DD-MM-YYYY"
 */
export const currentDay = () => {

  const currentDate = new Date();

  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

  return `${currentYear}-${currentMonth}-${currentDay}`;
};
