<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[80vw] lg:min-w-[900px] xl:min-w-[1024px]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Pagos
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de pago
            </span>
            <TypeOfPayment
              :selectOption="dataForm.idTypeOfPayment"
              @send-data="getSelectTypeOfPayment"
            />
          </label>

          <div
            class="grid md:grid-cols-2 gap-6"
            v-show="
              dataForm.idTypeOfPayment > 1 && dataForm.idTypeOfPayment <= 4
            "
          >
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Entidad bancaria <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.entityBank"
                placeholder="Entidad bancaria..."
                @keydown.enter.prevent
                maxlength="60"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de operación <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberOperation"
                placeholder="N° de operación..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
          </div>
          <div
            class="grid md:grid-cols-4 gap-6"
            v-show="dataForm.idTypeOfPayment === 5"
          >
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en efectivo
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.cash"
                placeholder="Monto en efectivo..."
                @keydown.enter.prevent
                @input="handleAmount"
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en tarjeta
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.tarjet"
                placeholder="Monto en tarjeta..."
                @keydown.enter.prevent
                @input="handleAmount"
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en transferencia
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.transfer"
                placeholder="Monto en transferencia..."
                @keydown.enter.prevent
                @input="handleAmount"
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en cheque
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.check"
                placeholder="Monto en cheque..."
                @keydown.enter.prevent
                @input="handleAmount"
                min="0"
              />
            </label>
          </div>
          <div class="flex items-center gap-6 flex-wrap">
            <div class="text-sm text-green-500">
              Monto a pagar: {{ money() }} <strong>{{ format(data.amount) }}</strong>
            </div>
            <div class="text-sm text-red-500">
              Estas pagando: {{ money() }} <strong>{{ format(itsPaying) }}</strong>
            </div>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="@/assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando pago
              </div>
              <span v-else>Registrar pago</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { confirmationOfUpdate, errorActions, uploadData } from "@/alerts";
import axios from "@/api";
import { observer } from "@/observer";
import { money, format } from "@/publicjs/money";

//componentes publicos
import TypeOfPayment from "@/components/PublicComponents/TypeOfPayment.vue";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
} from "@/publicjs/localStorage";
export default {
  name: "RegisterUsers",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    TypeOfPayment,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }
    const dataForm = ref({
      idTypeOfPayment: 0,
      entityBank: "",
      numberOperation: "",
      amount: props.data.amount,
      cash: 0,
      tarjet: 0,
      check: 0,
      transfer: 0,
    });
    const spinner = ref(false);
    const itsPaying = ref(0);

    const getSelectTypeOfPayment = (data) => {
      dataForm.value.idTypeOfPayment = parseInt(data);
      if (dataForm.value.idTypeOfPayment === 1) {
        dataForm.value.amount = parseFloat(props.data.amount);
        dataForm.value.cash = parseFloat(props.data.amount);
        dataForm.value.tarjet = 0;
        dataForm.value.transfer = 0;
        dataForm.value.check = 0;
        itsPaying.value = parseFloat(props.data.amount);
      } else if (dataForm.value.idTypeOfPayment === 2) {
        dataForm.value.amount = parseFloat(props.data.amount);
        dataForm.value.tarjet = parseFloat(props.data.amount);
        dataForm.value.cash = 0;
        dataForm.value.transfer = 0;
        dataForm.value.check = 0;
        itsPaying.value = parseFloat(props.data.amount);
      } else if (dataForm.value.idTypeOfPayment === 3) {
        dataForm.value.amount = parseFloat(props.data.amount);
        dataForm.value.transfer = parseFloat(props.data.amount);
        dataForm.value.cash = 0;
        dataForm.value.tarjet = 0;
        dataForm.value.check = 0;
        itsPaying.value = parseFloat(props.data.amount);
      } else if (dataForm.value.idTypeOfPayment === 4) {
        dataForm.value.amount = parseFloat(props.data.amount);
        dataForm.value.check = parseFloat(props.data.amount);
        dataForm.value.cash = 0;
        dataForm.value.tarjet = 0;
        dataForm.value.transfer = 0;
        itsPaying.value = parseFloat(props.data.amount);
      } else {
        dataForm.value.cash = 0;
        dataForm.value.tarjet = 0;
        dataForm.value.transfer = 0;
        dataForm.value.check = 0;
        dataForm.value.amount = 0;      
        itsPaying.value = 0;
      }
    };

    const handleAmount = () => {
      const total =
        parseFloat(dataForm.value.cash) +
        parseFloat(dataForm.value.tarjet) +
        parseFloat(dataForm.value.transfer) +
        parseFloat(dataForm.value.check);
      dataForm.value.amount = total;

      itsPaying.value = total;
    };

    const update = async () => {
      if (parseFloat(dataForm.value.amount) < parseFloat(props.data.amount)) {
        errorActions(
          "El monto a pagar debe de ser igual al monto de deuda del cliente"
        );
        return;
      }

      uploadData(
        "CAMBIANDO ESTADO",
        "Espere un momento mientras cambiamos el estado de pago del mensajero"
      );
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );

      const response = await axios
        .put("repartidor/" + props.data.id, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        uploadData(
          "Registrando pago del mensajero",
          "Espere un momento mientras registramos el pago del mensajero"
        );
        const user = await idLocalUser();
        const store = await idLocalStore();
        const turno = await idLocalTurn();
        await axios
          .post(
            "mensajeros",
            {
              idsucursal: store,
              idusuario: user,
              idventa: props.data.idsale,
              idturno: turno,
              monto: dataForm.value.amount,
              efectivo: dataForm.value.cash,
              tarjeta: dataForm.value.tarjet,
              transferencia: dataForm.value.transfer,
              cheque: dataForm.value.check,
              tipo_de_pago_id: dataForm.value.idTypeOfPayment,
              numero_de_operacion: dataForm.value.numberOperation,
              entidad_bancaria: dataForm.value.entityBank,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch(function (error) {
            errorActions(error);
          });

        confirmationOfUpdate("Estado del repartidor ");

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        await props.getdata();
        props.activeModal();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El registro que estas intentando actualizar, <trong> no existe en nuestra base de datos </strong> `
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos de pago del repartidor`
        );
      }

      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    return {
      isActive,
      getSelectTypeOfPayment,
      update,
      dataForm,
      spinner,
      handleAmount,
      itsPaying,
      money,
      format
    };
  },
};
</script>
