<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="modalContainer">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[30vw] md:max-w-[33vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-md font-medium text-gray-800 dark:text-white">
            Seguimiento de productos
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div
          class="bg-orange-50 border-2 border-orange-100 rounded-md p-3 mb-3 text-orange-600 text-xs"
        >
          Si deseas hacer un seguimiento del producto segun la compra, solo
          ingresa el numero de dicha compra para que posteriormente verifiques
          esos datos.
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              N° de compra
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="numberShopping"
              placeholder="N° de compra"
              @keydown.enter.prevent
              maxlength="13"
            />
          </label>
          <div
            class="flex justify-between mt-[3.5rem!important] lg:mt-[2rem!important]"
          >
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="
                exportPDFBarcode(
                  barCode,
                  productName,
                  salePrice,
                  numberShopping,
                  company
                )
              "
            >
              Generar codigo de barra
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { observeElement } from "@/observer";
import { exportPDFBarcode } from "@/components/Store/Products/Reports/ProductsBarCode";

export default {
  name: "BarCode",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    barCode: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    salePrice: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }
    const numberShopping = ref("");

    //observer
    onMounted(() => {
      observeElement("#modalContainer");
    });

    return {
      isActive,
      numberShopping,
      exportPDFBarcode,
    };
  },
};
</script>
