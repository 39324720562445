<template>
  <div
    class="bg-blue-600 text-white rounded-md shadow-sm grid md:grid-cols-2 overflow-hidden"
  >
    <div class="py-3 pl-4">
      <div class="text-sm font-semibold mb-2">
        Ventas totales:
        <span class="font-bold ml-3">
          {{ money() }} {{ format(calculationsData.totalSale) }}</span
        >
      </div>
      <div class="text-sm font-semibold mb-2">
        N° de ventas:
        <span class="font-bold ml-3">
          {{ format(calculationsData.numberSaler) }}</span
        >
      </div>
      <div class="text-sm font-semibold">
        Ventas promedio:
        <span class="font-bold ml-3">
          {{ money() }} {{ format(calculationsData.averageSales) }}</span
        >
      </div>
    </div>
    <div class="py-3 pl-4 bg-blue-500">
      <div class="text-sm font-semibold mb-2">
        Ganancias:
        <span class="font-bold ml-3">
          {{ money() }} {{ format(calculationsData.profits) }}</span
        >
      </div>
      <div class="text-sm font-semibold mb-2">
        Magen de utilidad:
        <span class="font-bold ml-3"> {{ calculationsData.utility }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { money, format } from "@/publicjs/money";
export default {
  name: "BannerUtility",
  props: {
    totalSale: {
      type: Number,
      required: true,
    },
    numberSaler: {
      type: Number,
      required: true,
    },
    profits: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const calculationsData = ref({
      utility: 0,
      profits: props.profits,
      averageSales: 0,
      totalSale: props.totalSale,
      numberSaler: props.numberSaler,
    });
    const calculate = async () => {
      calculationsData.value.averageSales = parseFloat(
        calculationsData.value.totalSale / calculationsData.value.numberSaler
      ).toFixed(2);

      calculationsData.value.utility = parseInt(
        (calculationsData.value.profits / calculationsData.value.totalSale) *
          100
      );
    };
    onMounted(async () => {
      await calculate();
    });

    return {
      calculationsData,
      money,
      format,
    };
  },
};
</script>
