export default async (pdfDocGenerator) => {
  const createContentPDF = document.createElement("div");
  createContentPDF.id = "iframeContainer";
  createContentPDF.classList.add(
    "fixed",
    "p-4",
    "flex",
    "items-center",
    "justify-center",
    "top-0",
    "left-0",
    "w-full",
    "h-full",
    "bg-black/60",
    "z-50",
    "[&>iframe]:p-10",
    "[&>iframe]:min-w-full",
    "[&>iframe]:md:min-w-[50%]",
    "[&>iframe]:min-h-[calc(100vh-2rem)]"
  );

  const button = document.createElement("button");
  button.classList.add(
    "absolute",
    "top-4",
    "right-4",
    "bg-red-500",
    "px-1",
    "py-1",
    "rounded-md",
    "text-white",
    "hover:bg-red-600"
  );
  button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="none" stroke-width="1.5" viewBox="0 0 24 24" color="currentColor"><path stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M6.758 17.243 12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"></path></svg>`;
  createContentPDF.appendChild(button);

  const container = document.getElementById("container-pdf");

  button.addEventListener("click", () => {
    // Eliminar todos los hijos del elemento
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
  });

  container.appendChild(createContentPDF);
  await pdfDocGenerator.getDataUrl(async (dataUrl) => {
    const targetElement = document.querySelector("#iframeContainer");
    const iframe = document.createElement("iframe");
    iframe.id = "myIframe";
    iframe.src = await dataUrl;
    targetElement.appendChild(iframe);
  });
};
