<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentData">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 max-w-[90vw] min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registrar pago de cuentas por cobrar
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-4" @submit.prevent v-else>
          <div
            class="px-3 py-2 rounded-md border border-blue-100 bg-blue-50 text-md text-blue-500 uppercase"
          >
            {{ dataForm.customer }}
          </div>
          <div class="grid md:grid-cols-2 gap-6">
            <div
              class="px-3 py-2 rounded-md border border-green-100 bg-green-50 text-sm text-green-500 uppercase"
            >
              <strong class="font-semibold block">Total de venta: </strong>
              <strong class="text-xl"
                >{{ money() }} {{ format(dataForm.totalSale) }}</strong
              >
            </div>
            <div
              class="px-3 py-2 rounded-md border border-green-100 bg-green-50 text-sm text-green-500 uppercase"
            >
              <strong class="font-semibold">Adelanto: </strong>
              <strong class="text-xl block"
                >{{ money() }} {{ format(dataForm.advanceAmount) }}</strong
              >
            </div>
            <div
              class="px-3 py-2 rounded-md border border-red-100 bg-red-50 text-sm text-red-500 uppercase"
            >
              <strong class="font-semibold block">Saldo: </strong>
              <strong class="text-xl"
                >{{ money() }} {{ format(dataForm.totalToPay) }}</strong
              >
            </div>
            <div
              class="px-3 py-2 rounded-md border border-orange-100 bg-orange-50 text-sm text-orange-500 uppercase"
            >
              <strong class="font-semibold">Restante: </strong>
              <strong class="text-xl block"
                >{{ money() }} {{ format(dataForm.remainingAmount) }}</strong
              >
            </div>
          </div>
          <!-- lista de tipos de pagos -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de pago
            </span>
            <TypeOfPaymentNotMulptiplyPayment
              :selectOption="dataForm.idTypeOfPayment"
              @send-data="getSelectTypeOfPayment"
            />
          </label>
          <div class="grid md:grid-cols-2 gap-6" v-show="stateInputTypePayment">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Entidad bancaria <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.entityBank"
                placeholder="Entidad bancaria..."
                @keydown.enter.prevent
                maxlength="60"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de operación <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberOperation"
                placeholder="N° de operación..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
          </div>

          <div class="grid md:grid-cols-2 gap-6">
            <label class="flex flex-col h-6 mb-9 md:mb-0 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Tipo de cancelación?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.statePayAll"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.statePayAll === true ? "Pago total" : "Abono" }}
                </span>
              </div>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto de pago <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-md bg-gray-50 outline-none rounded-lg font-semibold text-green-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.amountPayment"
                placeholder="Monto de pago..."
                @keydown.enter.prevent
                min="0"
                @input="calculateRemaining"
              />
            </label>
          </div>

          <!-- Componente de formatos de impresion de documento -->

          <TypeOfPrinting @send-data="handleFormatPrinting" />
          <div class="flex mt-3">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando pago
              </div>
              <span v-else>Registrar pago</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../../api";
import { format, money } from "@/publicjs/money";

import TypeOfPaymentNotMulptiplyPayment from "@/components/PublicComponents/TypeOfPaymentNotMulptiplyPayment.vue";
import TypeOfPrinting from "@/components/PublicComponents/TypeOfPrinting.vue";

//componente de loading
import LoadingForms from "@/components/Loadings/LoadingFormsOneColumn.vue";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
} from "@/publicjs/localStorage";

//Importaciones para imprimir comprobantes
import { printVoucherOnA4 } from "@/components/Sales/AccountsReceivable/PrintVoucher/PrintOnA4";
import { printVoucherOnLetter } from "@/components/Sales/AccountsReceivable/PrintVoucher/PrintOnLetter";
import { printVoucherOnMediaLetter } from "@/components/Sales/AccountsReceivable/PrintVoucher/PrintOnMediaLetter";
import { printVoucherTicket } from "@/components/Sales/AccountsReceivable/PrintVoucher/PrintOnTicket";

export default {
  name: "RegisterPayment",
  components: {
    LoadingForms,
    TypeOfPaymentNotMulptiplyPayment,
    TypeOfPrinting,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const formatPrint = ref("");

    const dataForm = ref({
      totalSale: 0,
      advanceAmount: 0,
      saldo: 0,
      datePayment: "",
      state: false,
      customer: "",
      numberSale: "",
      idAccountReceivable: 0,
      entityBank: "",
      numberOperation: "",
      statePayAll: true,
      amountPayment: 0,
      idTypeOfPayment: 0,
      totalToPay: 0,
      remainingAmount: 0,
      idStore: 0,
      idUser: 0,
      idTurn: 0,
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      observeElement("#contentData");
      const response = await axios
        .get("cuentas-por-cobrar/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data[0];
      dataForm.value.totalSale = data.total_de_venta;
      dataForm.value.advanceAmount = data.cuota_inicial;
      dataForm.value.numberSale = data.numero_comprobante;
      dataForm.value.saldo = data.saldo;
      dataForm.value.totalToPay = data.saldo;
      dataForm.value.remainingAmount = data.saldo;
      dataForm.value.datePayment = data.fecha_de_pago;
      dataForm.value.state = data.estado === 1 ? true : false;
      dataForm.value.customer = data.nombre_cliente_pedido;
      dataForm.value.idAccountReceivable = data.id;

      isLoading.value = true;

      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
      dataForm.value.idTurn = await idLocalTurn();
    });

    const insert = async () => {
      if (
        dataForm.value.amountPayment === null ||
        dataForm.value.amountPayment === ""
      ) {
        errorActions(
          "Ingresa <strong>un monto de pago de la cuenta por cobrar</strong>"
        );
        return;
      }
      if (parseFloat(dataForm.value.amountPayment) <= 0) {
        errorActions(
          "Ingresa un monto mayor  <strong>0</strong> para cobrar la cuenta por cobrar"
        );
        return;
      }
      if (parseFloat(dataForm.value.remainingAmount) < 0) {
        errorActions(
          "El restante  no debe de ser un número <strong>negativo</strong>, por favor ingresa el monto correcto a cobrar."
        );
        return;
      }

      if (dataForm.value.statePayAll) {
        if (
          parseFloat(dataForm.value.amountPayment) <
          parseFloat(dataForm.value.totalToPay)
        ) {
          errorActions(
            "El monto a cobrar no debe de ser  <strong>menor a la deuda</strong> de la cuenta por cobrar"
          );
          return;
        }
      }

      spinner.value = true;

      const response = await axios
        .post(
          "pagos-cuentas-por-cobrar",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idTurn: dataForm.value.idTurn,
            idAccountReceivable: dataForm.value.idAccountReceivable,
            idTypeOfPayment: dataForm.value.idTypeOfPayment,
            amount: dataForm.value.amountPayment,
            entityBank: dataForm.value.entityBank,
            numberOperation: dataForm.value.numberOperation,
            remainingAmount: dataForm.value.remainingAmount,
            totalToPay: dataForm.value.totalToPay,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        if (formatPrint.value === "format_a4") {
          await printVoucherOnA4(data.id);
        } else if (formatPrint.value === "format_m_carta") {
          await printVoucherOnMediaLetter(data.id);
        } else if (formatPrint.value === "format_8x11") {
          await printVoucherOnLetter(data.id);
        } else if (formatPrint.value === "format_ticket") {
          await printVoucherTicket(data.id);
        }

        confirmationOfUpdate(
          "El pago de la cuenta por cobrar del cliente " +
            dataForm.value.customer
        );
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El pago de la cuenta por cobrar del cliente <trong>${dataForm.value.customer} </strong> que estas intentando registrar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar el pago de la cuenta por cobrar del cliente <trong>${dataForm.value.customer} </strong>`
        );
      }

      spinner.value = false;
    };

    // Estado para mostrar o no los inputs de entidad bancaria y numero de operacion
    const stateInputTypePayment = ref(false);
    //recepcion de datos desde el componente publico
    const getSelectTypeOfPayment = (data) => {
      dataForm.value.idTypeOfPayment = data;
      //Activamos los inputs para ingresar los datos como entidad bancaria y numero de operación
      if (data !== 1) {
        stateInputTypePayment.value = true;
      } else {
        stateInputTypePayment.value = false;
      }
    };

    //funcion para calcular es restante del saldo
    const calculateRemaining = async () => {
      const amountPayment =
        dataForm.value.amountPayment === null ||
        dataForm.value.amountPayment === ""
          ? 0
          : dataForm.value.amountPayment;
      const totalPayment =
        parseFloat(dataForm.value.totalToPay) - amountPayment;
      dataForm.value.remainingAmount = parseFloat(totalPayment.toFixed(2));
    };

    //funciona para obtener el formato de impresion
    const handleFormatPrinting = (data) => {
      formatPrint.value = data;
    };

    return {
      isActive,
      dataForm,
      insert,
      isLoading,
      spinner,
      format,
      money,
      stateInputTypePayment,
      getSelectTypeOfPayment,
      calculateRemaining,
      handleFormatPrinting,
    };
  },
};
</script>
