<template>
  <div id="contentReport">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-start mb-3">
      <!-- componente para mfiltrar por estado de orden de compra -->
      <div class="flex flex-wrap items-end gap-4">
        <label class="block md:mt-0 w-full md:w-auto">
          <span class="block text-gray-600 text-sm font-semibold mb-1"
            >Categoría de filtros</span
          >
          <select
            v-model="dataForm.typeFilter"
            class="bg-white border-2 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full focus:border-blue-500"
            @change="showInputdate"
          >
            <option value="days">Semana Actual</option>
            <option value="current_month">Mes Actual</option>
            <option value="current_year">Año Actual</option>
            <option value="custom">Personalizado</option>
          </select>
        </label>
        <label class="block" v-show="dataForm.stateCustomReport">
          <span
            class="block mb-1 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-500 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
            @change="resetValuesAndGetData"
          />
        </label>
        <label class="block" v-show="dataForm.stateCustomReport">
          <span
            class="block mb-1 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-500 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
            @change="resetValuesAndGetData"
          />
        </label>
        <button
          class="bg-blue-500 px-3 py-2 text-sm font-semibold text-white rounded-md border-2 border-blue-500 active:scale-95"
          @click="resetData"
        >
          Consultar
        </button>
      </div>
    </div>
    <div v-if="loading">
      <LoadingDashboardCards/>
    </div>
    <div v-else>
      <ChartComponent
        :datas="dataForm.dataReport.month"
        :titleChart="dataForm.titleChart"
      />
      <BannerUtility
        :numberSaler="dataForm.dataReport.count"
        :totalSale="dataForm.dataReport.totalSale"
        :profits="dataForm.dataReport.totalProfits"
      />
    <div class="grid grid-cols-2 gap-6 mt-4">
      <SalesForMonth :datas="dataForm.dataReport.month" :titleCard="dataForm.titleChart"/>
      <SalesTypeOfProducts :datas="dataForm.dataReport.typeProducts" :titleCard="dataForm.titleChart"/>
    </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "../../../../api";
import { errorActions, warningActions } from "@/alerts";
import { formatDateTime } from "@/publicjs/formatDate";
import BannerUtility from "@/components/Reports/Sales/ReportsProfits/BannerUtility.vue";
import ChartComponent from "@/components/Reports/Sales/ReportsProfits/ChartComponent.vue";
import SalesForMonth from "@/components/Reports/Sales/ReportsProfits/SalesForMonth.vue";
import SalesTypeOfProducts from "@/components/Reports/Sales/ReportsProfits/SalesTypeOfProducts.vue";
import { idLocalStore } from "@/publicjs/localStorage";
import  LoadingDashboardCards from '@/components/Loadings/LoadingDashboardCards.vue'
import { observeElement } from '@/observer';

export default {
  name: "MainReportProfits",
  components: {
    BannerUtility,
    ChartComponent,
    SalesForMonth,
    SalesTypeOfProducts,
    LoadingDashboardCards
  },
  setup() {
    const loading = ref(true);

    const dataForm = ref({
      typeFilter: "days",
      dateStart: null,
      dateEnd: null,
      idStore: 0,
      titleChart: 0,
      stateCustomReport: false,
      dataReport: {
        month: {},
        typeProducts: {},
        count: 0,
        totalSale: 0,
        totalProfits: 0,
      },
    });
    const showInputdate = () => {
      if (dataForm.value.typeFilter === "custom") {
        dataForm.value.stateCustomReport = true;
      } else {
        dataForm.value.stateCustomReport = false;
      }
    };

    const getData = async () => {
      if (dataForm.value.typeFilter === "custom") {
        if (
          dataForm.value.dateStart === null ||
          dataForm.value.dateStart === "" ||
          dataForm.value.dateEnd === null ||
          dataForm.value.dateEnd === ""
        ) {
          errorActions("Ingresa el rango de fechas para generar las consultas");
          return;
        }
      }

      const response = await axios
        .get(
          `reporte-de-ventas-utilidades/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));

      const data = await response.data;
      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }

      if (dataForm.value.typeFilter === "current_year") {
        dataForm.value.dataReport.month = data.ventas_por_meses.meses;
        dataForm.value.dataReport.count =
          data.ventas_por_meses.numero_de_ventas;
        dataForm.value.dataReport.typeProducts =
          data.ventas_por_meses.tipo_de_productos;

        dataForm.value.titleChart = "REPORTE DEL AÑO ACTUAL";
      } else if (dataForm.value.typeFilter === "days") {
        dataForm.value.dataReport.month = data.ventas_por_dia.dias;
        dataForm.value.dataReport.count = data.ventas_por_dia.numero_de_ventas;
        dataForm.value.dataReport.typeProducts =
          data.ventas_por_dia.tipo_de_productos;
        dataForm.value.titleChart = "REPORTE DE LA SEMANA ACTUAL";
      } else if (dataForm.value.typeFilter === "current_month") {
        dataForm.value.dataReport.month = data.ventas_del_mes_actual.dias;
        dataForm.value.dataReport.count =
          data.ventas_del_mes_actual.numero_de_ventas;
        dataForm.value.dataReport.typeProducts =
          data.ventas_del_mes_actual.tipo_de_productos;
        dataForm.value.titleChart = "REPORTE DEL MES ACTUAL";
      } else if (dataForm.value.typeFilter === "custom") {
        dataForm.value.dataReport.month = data.reporte_personalizado.meses;
        dataForm.value.dataReport.count =
          data.reporte_personalizado.numero_de_ventas;
        dataForm.value.dataReport.typeProducts =
          data.reporte_personalizado.tipo_de_productos;
        dataForm.value.titleChart = `REPORTE DESDE  ${dataForm.value.dateStart} HASTA ${dataForm.value.dateEnd}`;
      }
    };

    const calculateTotalSale = async (data) => {
        dataForm.value.dataReport.totalSale = 0
        dataForm.value.dataReport.totalProfits = 0 
      for (const mes in data) {
        const profits = parseFloat(data[mes].ganancias.replace(",", ""));
        const sale = parseFloat(data[mes].venta_total.replace(",", ""));

        dataForm.value.dataReport.totalSale += sale;
        dataForm.value.dataReport.totalProfits += profits;
      }
    };

    const resetData = async () => {
      loading.value = true;

      await getData();
      await calculateTotalSale(dataForm.value.dataReport.month);
      loading.value = false;
    };

    onMounted(async () => {
      observeElement("#contentReport")
      dataForm.value.idStore = await idLocalStore();
      await resetData()
    });

    return {
      loading,
      resetData,
      formatDateTime,
      dataForm,
      showInputdate,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
