<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700 mb-4">
      Configuración de empresa
    </h2>
    <DataCompany/>
  </div>
</template>

<script>
import DataCompany from '@/components/Company/Configurations/DataCompany.vue'
export default {
  name: 'MainCompany',
  components: {
    DataCompany
  }
}
</script>