import {  errorActions } from "@/alerts";
import axios from "@/api";
import { money, format } from "@/publicjs/money";
/**
 * Funcion para enviar correo al momento de cerrar la caja
 * @param {*} data Los datos principales del cuadre de caja
 * @param {*} userName El nombre del usuario
 * @param {*} subject Asunto del correo
 */
export const sendCloseBox = async (data, userName,subject) => {
    const baseURL = window.location.href;
    const pathURL = baseURL.split('/').slice(0, 3).join('/');
  const {
    idStore,
    amountInTheBox,
    totalClosingAmount,
    transferAmount,
    cashAmount,
    targetAmount,
    chequeAmount
  } = data;
  try {
    await axios.post(
      "mensaje-de-cierre-de-caja",
      {
        idStore,
        urlWebSite:pathURL,
        userName,
        subject,
        amountInTheBox: money()+''+format(amountInTheBox),
        totalClosingAmount: money()+''+format(totalClosingAmount),
        transferAmount: money()+''+format(transferAmount),
        cashAmount: money()+''+format(cashAmount),
        targetAmount: money()+''+format(targetAmount),
        chequeAmount: money()+''+format(chequeAmount),
      },
      {
        method: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    errorActions(error);
  }
};
/**
 * Funcion para enviar correo al momento de abrir la caja
 * @param {*} data Los datos principales del cuadre de caja
 * @param {*} userName El nombre del usuario
 * @param {*} subject Asunto del correo
 */
export const sendOpenBox = async (data, userName,subject) => {
  const {
    idStore,
    startingAmount,
  } = data;
  try {
    await axios.post(
      "mensaje-de-apertura-de-caja",
      {
        idStore,
        userName,
        subject,
        startingAmount: money()+''+format(startingAmount),
      },
      {
        method: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    errorActions(error);
  }
};
