<template>
  <div class="p-3 border rounded-sm border-gray-50 shadow-lg">
    <div class="text-gray-800 font-semibold py-2 px-3 rounded-t-md">
      {{ titleCard }}
    </div>
    <div class="text-gray-500 flex flex-wrap gap-4 mt-2">
      <div
        class="p-3 border-2 border-blue-50 rounded-md shadow shadow-blue-100"
        v-for="data in processedData"
        :key="data"
      >
        <h2 class="text-gray-800 font-bold text-sm">{{ data.monthName }}</h2>
        <div class="py-1">
          <div class="font-bold text-xs">
            Venta total:
            <span class="font-bold" :class="{'text-blue-500':data.venta_total > 0}">
             {{ money() }} {{ format(data.venta_total) }}
            </span>
          </div>
          <div class="font-bold text-xs">
            Ganancias:
            <span class="font-bold" :class="{'text-blue-500':data.ganancias > 0}">
             {{ money() }} {{ format(data.ganancias) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { money, format} from "@/publicjs/money";
import {  computed } from "vue";

export default {
  name: "SalesForMonth",
  props: {
    datas: {
      type: Object,
      required: true,
    },
    titleCard: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const processedData = computed(() => {
      const monthNames = Object.keys(props.datas);
      return monthNames.map((month) => ({
        monthName: getMonthName(month),
        venta_total: parseNumber(props.datas[month].venta_total),
        ganancias: parseNumber(props.datas[month].ganancias),

      }));
    });
    const parseNumber = (value) => {
      const numberString = value.replace(/,/g, ''); // Eliminar todas las comas
      return parseFloat(numberString);
    };

    const getMonthName = (data) => {
      const name = data.toUpperCase();
      return name.charAt(0) + name.slice(1);
    };

    return {
      processedData,
      money,format
    };
  },
};
</script>
