<template>
  <div class="mt-4" id="contentData">
    <div
      class="flex justify-between flex-wrap gap-4 md:flex-nowrap items-end mb-3"
    >
      <div class="flex flex-wrap gap-4">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none min-w-[140px] font-semibold w-full md:w-auto"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>

        <!-- acciones de impresion -->
        <div class="relative group">
          <button
            class="bg-blue-100 flex items-center justify-center w-28 h-10 rounded-md text-blue-600"
          >
            Opciones
            <font-awesome-icon
              icon="fa-solid fa-ellipsis"
              class="rotate-90 ml-2"
            />
          </button>
          <ul
            class="absolute top-full left-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
          >
            <button
              class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
              @click="exportToPDF(registers, dataForm.title)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a PDF
            </button>
            <button
              class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
              @click="exportToExcel(registers, dataForm.title)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a excel
            </button>
          </ul>
        </div>
      </div>
      <div class="flex flex-wrap items-center gap-4">
        <button
          class="bg-green-100 text-green-500 text-xs py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:text-white hover:bg-green-500 font-semibold w-full md:w-auto"
          @click="showTypeProduct"
        >
          <font-awesome-icon icon="fa-solid fa-eye" class="mr-2" />
          Tipo de productos
        </button>
        <button
          class="bg-blue-100 text-blue-500 text-xs py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:text-white hover:bg-blue-500 font-semibold w-full md:w-auto"
          @click="showDates"
        >
          <font-awesome-icon icon="fa-solid fa-eye" class="mr-2" />
          Por vencimiento
        </button>
      </div>
    </div>
    <div
      class="bg-blue-50 border-2 border-blue-100 shadow-sm shadow-blue-100 p-4 rounded-md mb-3 flex flex-wrap items-end justify-between gap-4"
      v-if="statTypeProduct || statInputDate"
    >
      <div v-if="statTypeProduct">
        <SearchTypeProducts @send-data="getDataTypeOfProducts" />
      </div>
      <div v-if="statInputDate" class="flex flex-wrap items-center gap-4">
        <label class="block w-full md:w-auto">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block w-full md:w-auto">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
      </div>
      <button
        class="py-3 px-3 bg-blue-500 text-white text-xs rounded-md active:scale-95 w-full md:w-auto"
        @click="resetValuesAndGetData"
      >
        Generar reporte
      </button>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="10" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombre_producto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.facturado }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.libre_de_impuesto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ format(register.stock) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.precio_de_compra) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.precio_de_venta) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ parseInt(register.utilidad) }} %
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.ganancia) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{
                    register.vencimiento !== null
                      ? calculateExpirationDate(register.vencimiento)
                      : "--/--/----"
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                >
                  <span
                    class="px-2 py-1 rounded-md"
                    :class="{
                      'text-red-500': register.estado === 0,
                      'bg-red-50': register.estado === 0,
                    }"
                  >
                    {{ register.estado === 1 ? "Activo" : "Inactivo" }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";

import SearchTypeProducts from "@/components/Reports/Products/SearchTypeProducts.vue";
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
import { exportToPDF } from "./Reports/PDFExportProducts";
import { exportToExcel } from "./Reports/ExcelExportAllProducts";

export default {
  name: "MainReportProducts",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    SearchTypeProducts,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Productos",
        },
        {
          title: "Facturado",
        },
        {
          title: "L/I",
        },
        {
          title: "Stock",
        },
        {
          title: "P. Compra",
        },
        {
          title: "P. Venta",
        },
        {
          title: "Utilidad",
        },
        {
          title: "Ganancia",
        },
        {
          title: "vencimiento",
        },
        {
          title: "estado",
        },
      ],
    ];
    const dataForm = ref({
      idStore: 0,
      typeProduct: 0,
      typeProductDescription: "",
      dateStart: null,
      dateEnd: null,
      title: "RERPOTE GENERAL DEL PRODUCTOS",
    });
    const statTypeProduct = ref(false);
    const statInputDate = ref(false);

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `reporte-de-productos/${dataForm.value.idStore}/?page=${currentPage.value}&per_page=${limitRegister.value}&type_product=${dataForm.value.typeProduct}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      if (statInputDate.value) {
        if (
          dataForm.value.dateStart === null ||
          dataForm.value.dateEnd === "" ||
          dataForm.value.dateEnd === null ||
          dataForm.value.dateStart === ""
        ) {
          errorActions(
            "Selecciona la fecha de inicio y la fecha de termino para realizar dicho registro"
          );
          return;
        }
        dataForm.value.title = `REPORTE POR VENCIMIENTO DESDE ${dataForm.value.dateStart} HASTA  ${dataForm.value.dateEnd}`;
      }
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      observeElement("#contentData");
      handleResize();
      window.addEventListener("resize", handleResize);
      dataForm.value.idStore = await idLocalStore();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };
    const showTypeProduct = async () => {
      statTypeProduct.value = !statTypeProduct.value;
      statInputDate.value = false;
      dataForm.value.typeProduct = 0;
      dataForm.value.dateEnd = "";
      dataForm.value.dateStart = "";
    };
    const showDates = async () => {
      statInputDate.value = !statInputDate.value;
      statTypeProduct.value = false;
      dataForm.value.typeProduct = 0;
      dataForm.value.dateEnd = "";
      dataForm.value.dateStart = "";
    };
    const getDataTypeOfProducts = async (data) => {
      dataForm.value.typeProduct = data.id;
      dataForm.value.typeProductDescription = data.description;
      dataForm.value.title =
        `REPORTE DE PRODUCTO TIPO ${data.description}`.toUpperCase();
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      statTypeProduct,
      showTypeProduct,
      getDataTypeOfProducts,
      showDates,
      statInputDate,
      dataForm,
      calculateExpirationDate,
      exportToPDF,
      exportToExcel,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
