export function findSubmoduleIdByDescription(subModuleDescription) {
  const modules = JSON.parse(localStorage.getItem("modules"));
  for (let i = 0; i < modules.length; i++) {
    const subModules = modules[i].submodulos;
    for (let j = 0; j < subModules.length; j++) {
      if (subModules[j].url === subModuleDescription) {
        return true;
      }
    }
  }
  return false;
}
