import { percentage } from "./convertToPercentage";

const STORE_DATA = JSON.parse(localStorage.getItem("access"));

/**
 *
 * @returns Retorna el id de la sucursal
 */
export const idLocalStore = async () => {
  return await STORE_DATA.user.store;
};

/**
 *
 * @returns Retorna el id del usuario que ha iniciado sesion
 */
export const idLocalUser = async () => {
  return await STORE_DATA.user.key;
};

/**
 *
 * @returns Retorna el id del turno actual
 */
export const idLocalTurn = async () => {
  return await STORE_DATA.user.schedules[0].turn;
};

/**
 *
 * @returns Retorna el tipo de usuario
 */
export const localTypeUser = async () => {
  return await STORE_DATA.user.typeUser;
};

/**
 *
 * @returns Retorna el id del turno actual
 */
export const localMoney = async () => {
  const currency = STORE_DATA.currency;
  const prefix = STORE_DATA.currencyPrefix;
  return {
    currency,
    prefix,
  };
};

/**
 *
 * @returns Retorna el nombre del usuario
 */
export const localUserName = async () => {
  const userName = STORE_DATA.user.name;
  return userName;
};
/**
 *
 * @returns Retorna igv que esta configurado para el sistema pero en este caso el iva entero
 */
export const localIVA = async () => {
  const iva = parseInt(STORE_DATA.iva);
  return iva;
};

/**
 *
 * @returns | Retorna el iva configurado del sistema tanto para hacer la mutiplicacion y división
 */
export const IVA_LOCAL_STORE = () => {
  const iva = percentage(STORE_DATA.iva);

  const TO_DIVIDE = iva + 1;
  const TO_MULTIPLY = iva;

  return [TO_DIVIDE, TO_MULTIPLY];
};

/**
 *
 * @returns Retorna el  avatar del usuario
 */
export const localAvatarUser = async () => {
  const avatar = STORE_DATA.user.avatar;
  return avatar;
};

/**
 *
 * @returns Retorna el total de minutos tarde ingresado
 */
export const localMinutesLate = async () => {
  const avatar = STORE_DATA.user.schedules[0].minutesLate;
  return avatar;
};

/**
 *
 * @returns Retorna el avatar del usuario
 */
export const localDelayUser = async () => {
  const avatar = STORE_DATA.user.schedules[0].isInTime;
  return avatar;
};

/**
 *
 * @returns Retorna la hora de salida del usuario
 */
export const localExitWorkTolerance = async () => {
  const exitWorkTolerance = STORE_DATA.user.schedules[0].exitWorkTolerance;
  return exitWorkTolerance;
};

/**
 *
 * @returns Retorna si la empresa puede enviar correos
 */
export const localCanSendMail = async () => {
  const senMail = STORE_DATA.canSendMail;
  return senMail;
};
