import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/main.css";

//* libreria fontawesone
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// SweetAlert2 y SweetAlert CSS
import VueSweetalert2 from "vue-sweetalert2";

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const token = JSON.parse(localStorage.getItem("access"));

  if (to.path === "/login") {
    // Si ya está en la página de login, no redirigir
    next();
  } else if (requiresAuth && !token) {
    // Si necesita autenticación y no hay token
    next("/login");
  }  else {
    // En todos los demás casos, permitir el acceso
    next();
  }
});

const options = {
  confirmButtonColor: "#000000",
  cancelButtonColor: "#ff7674",
};

/* import specific icons */
import {
  faCartPlus,
  faHouse,
  faCartShopping,
  faDolly,
  faAngleUp,
  faGauge,
  faCircleNotch,
  faBell,
  faChevronRight,
  faTrash,
  faPen,
  faStore,
  faUsers,
  faUserTie,
  faUserLock,
  faMicrochip,
  faPlus,
  faGears,
  faGear,
  faBuilding,
  faPeopleGroup,
  faCircleDollarToSlot,
  faPiggyBank,
  faSackXmark,
  faChartBar,
  faChartSimple,
  faChartPie,
  faTruck,
  faBasketShopping,
  faCheck,
  faBan,
  faKey,
  faRightToBracket,
  faDownload,
  faBarcode,
  faFolderOpen,
  faPrint,
  faEllipsis,
  faListUl,
  faSearch,
  faTicket,
  faDollarSign,
  faEye,
  faComputerMouse,
  faFile,
  faFileInvoice,
  faFileExcel,
  faFilePdf,
  faQuestion,
  faLandmark,
  faServer,
  faCubes,
} from "@fortawesome/free-solid-svg-icons";

import './registerServiceWorker'
/* add icons to the library */
library.add(
  faCartPlus,
  faHouse,
  faDolly,
  faAngleUp,
  faGauge,
  faCircleNotch,
  faBell,
  faChevronRight,
  faTrash,
  faPen,
  faCartShopping,
  faStore,
  faUsers,
  faUserTie,
  faUserLock,
  faMicrochip,
  faPlus,
  faGears,
  faGear,
  faBuilding,
  faPeopleGroup,
  faCircleDollarToSlot,
  faPiggyBank,
  faSackXmark,
  faChartBar,
  faChartSimple,
  faChartPie,
  faTruck,
  faBasketShopping,
  faCheck,
  faBan,
  faKey,
  faRightToBracket,
  faDownload,
  faBarcode,
  faFolderOpen,
  faPrint,
  faEllipsis,
  faListUl,
  faSearch,
  faTicket,
  faDollarSign,
  faEye,
  faComputerMouse,
  faFile,
  faFileInvoice,
  faFileExcel,
  faFilePdf,
  faQuestion,
  faLandmark,
  faServer,
  faCubes
);
const app = createApp(App);
app.use(router);
app.use(VueSweetalert2);
app.use(options);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
