<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    id="main-content-credit-note-sale"
  >
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] md:max-w-[80vw] lg:max-w-[80vw]"
        id="subcontent-credit-note-sale"
      >
        <div class="flex justify-between items-center mb-6">
          <h2
            class="text-xl font-medium text-gray-800 dark:text-white uppercase"
          >
            Modificando pedido
          </h2>
          <button
            @click="showModal"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div class="w-full">
          <div
            class="py-3 bg-blue-50 px-3 rounded-md grid md:grid-cols-12 gap-4"
          >
            <SearchProduct @send-data="addProducts" :typeVoucher="'NINGUNA'" />
          </div>
          <div>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <tbody class="text-gray-500 text-left border-t-[1px]">
                    <tr
                      v-for="(sale, i) in arrayDataOrder"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.productName }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="sale.quantity"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="
                            handleQuantityChange(i, $event, sale.igvApplied)
                          "
                          @keyup.enter="
                            handleQuantityChange(i, $event, sale.igvApplied)
                          "
                        />
                      </td>
                      <td
                        class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                      >
                        <select
                          class="border-2 bg-gray-50 py-1 text-xs px-3 text-gray-600 cursor-pointer rounded-lg mr-3 font-semibold max-w-[120px] focus:outline-double focus:outline-2 focus:outline-blue-500"
                          @change="
                            handleUnitMeasureChange(i, $event, sale.igvApplied)
                          "
                        >
                          <option
                            :value="
                              unit.precio +
                              '|' +
                              unit.cantidad +
                              '|' +
                              unit.unidad_de_medida
                            "
                            v-for="(unit, index) in sale.units"
                            :key="index"
                            :selected="
                              unit.unidad_de_medida === sale.unitMeasure
                            "
                          >
                            {{ unit.unidad_de_medida }}
                          </option>
                        </select>
                      </td>
                      <td
                        class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap uppercase"
                      >
                        <select
                          @blur="handlePrices(i, $event, sale)"
                          @input="handlePrices(i, $event, sale)"
                          class="border-2 focus:outline-double focus:outline-2 focus:outline-blue-500 bg-gray-50 py-1 text-xs px-3 text-gray-600 cursor-pointer rounded-lg mr-3 font-semibold"
                          v-model="sale.priceSelectedForTheCustomer"
                        >
                          <option value="1" selected>PRECIO 1</option>
                          <option value="2" v-if="sale.showBTNPriceTwo">
                            PRECIO 2
                          </option>
                          <option value="3" v-if="sale.showBTNPriceThree">
                            PRECIO 3
                          </option>
                        </select>
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="sale.salePrice"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[120px] rounded-md border-2 border-blue-100 text-center"
                          @blur="handleSalePrice(i, $event, sale.igvApplied)"
                          @keyup.enter="
                            handleSalePrice(i, $event, sale.igvApplied)
                          "
                        />
                        <!-- {{ money() }} {{ format(sale.salePrice) }} -->
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                      >
                        <div class="flex justify-end">
                          <button
                            class="bg-red-100 text-red-500 hover:text-white p-2 w-8 h-8 text-sm active:scale-105 hover:bg-red-500 flex items-center justify-center rounded-md"
                            @click="removeProduct(i, sale.idOrder)"
                            v-if="!sale.isNew"
                          >
                            <font-awesome-icon
                              icon="fa-solid fa-trash"
                              class="w-3 h-3"
                            />
                          </button>
                          <button
                            class="bg-red-100 text-red-500 hover:text-white p-2 w-8 h-8 text-sm active:scale-105 hover:bg-red-500 flex items-center justify-center rounded-md"
                            @click="removeProduct(i, 0)"
                            v-if="sale.isNew"
                          >
                            <font-awesome-icon
                              icon="fa-solid fa-trash"
                              class="w-3 h-3"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="p-4 bg-blue-50 text-blue-500 flex flex-col items-end">
              <div>
                <strong>ITBIS:</strong> {{ money() }} {{ format(dataForm.igv) }}
              </div>
              <div>
                <strong>SUBTOTAL:</strong> {{ money() }}
                {{ format(dataForm.subTotal) }}
              </div>
              <div>
                <strong>TOTAL:</strong> {{ money() }}
                {{ format(dataForm.total) }}
              </div>
            </div>

            <div class="flex mt-6 justify-end">
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                @click="update"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="@/assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Modificando orden
                </div>
                <span v-else>Modificar orden</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions, successAction } from "@/alerts";
import { observeElement, observer } from "@/observer";
import TableHead from "@/components/TablesComponents/TableHead";
import axios from "@/api";
import { format, money } from "@/publicjs/money";
import { percentage } from "@/publicjs/convertToPercentage";
import Swal from "sweetalert2";
import SearchProduct from "@/components/Sales/Searchs/SearchProduct.vue";
export default {
  name: "UpdateSale",
  props: {
    showModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableHead,
    SearchProduct,
  },
  setup(props) {
    const spinner = ref(false);

    const dataForm = ref({
      total: 0,
      subTotal: 0,
      igv: 0,
    });

    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "U.M",
        },
        {
          title: "Precios",
        },
        {
          title: "PU",
        },
        {
          title: "Total",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    const arrayDataOrder = ref([]);

    /*****************************************************************************
     * Funcion para añadir producto a lista de productos
     * @param {*} detail detalle de la venta
     *****************************************************************************/
    const addProducts = async (data) => {
      let {
        discount,
        expired,
        idProduct,
        igv,
        igvApplied,
        insuranceDiscount,
        maximumDiscount,
        poster,
        productName,
        quantity,
        quantityContained,
        salePrice,
        subTotal,
        taxFree,
        total,
        unitMeasure,
        units,
        productSerie,
        warranty_expiration,
        warranty_type,
        shoppingPrice,
        showBTNPriceTwo,
        showBTNPriceThree,
      } = data;
      //parseamos el iva del producto
      const CONFIGURE_IGV = percentage(igvApplied);

      subTotal = parseFloat(
        (parseFloat(salePrice) / (CONFIGURE_IGV + 1)).toFixed(2)
      );
      igv = parseFloat((parseFloat(subTotal) * CONFIGURE_IGV).toFixed(2));
      total = parseFloat(
        (parseFloat(quantity) * parseFloat(salePrice)).toFixed(2)
      );

      // Buscar el producto en la lista existente
      const existingProductIndex = arrayDataOrder.value.findIndex(
        (order) =>
          order.idProduct === idProduct && order.unitMeasure === unitMeasure
      );

      if (existingProductIndex !== -1) {
        // El producto ya existe en la lista, sumar la cantidad y actualizar los precios
        const existingProduct = arrayDataOrder.value[existingProductIndex];
        const newQuantity =
          parseFloat(existingProduct.quantity) + parseFloat(quantity);

        const thereIsStock = await validateStockProduct(idProduct, newQuantity);

        if (thereIsStock) {
          existingProduct.quantity = newQuantity;

          updateDetailData(existingProductIndex,igvApplied)
        } else {
          errorActions(
            "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
          );
        }
      } else {
        //Si el producto no existe agregamos un nuevo producto a la lista de arreglos de la orden
        const orderData = {
          id: 0,
          isNew: true,
          discount,
          expired,
          idProduct,
          igv,
          igvApplied,
          insuranceDiscount,
          maximumDiscount,
          poster,
          productName,
          quantity,
          quantityContained,
          salePrice,
          subTotal,
          taxFree,
          total,
          unitMeasure,
          units,
          productSerie,
          warranty_expiration,
          warranty_type,
          shoppingPrice,
          showBTNPriceTwo,
          showBTNPriceThree,
          priceSelectedForTheCustomer: 1,
        };
        arrayDataOrder.value.push(orderData);
      }
      calculateTheTotal(arrayDataOrder.value);
    };

    /*********************************************************************************
     * Funcion para validar el stock del producto
     *********************************************************************************/
    const validateStockProduct = async (id, stock) => {
      try {
        const response = await axios.get(
          `productos-para-venta/${id}?stock=${stock}`,
          {
            methods: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.data;
        if (data === true) return true;
        return false;
      } catch (error) {
        errorActions(error);
        return false;
      }
    };

    /*****************************************************************************
     * Funcion para quitar productos de la lista de devolucion
     * @param {*} index Indice del registro que sea elminado
     * @param {*} idSale ID de la venta para saber si los productos son productos ya vendidos o productos agregado recientemente
     * @param {*} idDetail ID del detalle de la venta
     *****************************************************************************/
    const removeProduct = async (index, idSale) => {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar el producto?",
        text: `Si eliminas el producto de la venta, ya no podrás recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡eliminar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Verificar si el índice está dentro de los límites del array
          if (index >= 0 && index < arrayDataOrder.value.length) {
            if (idSale !== 0) {
              //Elminamos el productos desde la base de datos
              await deleteProduct(idSale);
            } else {
              await successAction("Eliminado", "Producto quitado con éxito");
            }

            arrayDataOrder.value.splice(index, 1);
            calculateTheTotal(arrayDataOrder.value);
          } else {
            errorActions("Índice inválido. No se pudo remover el producto.");
          }
        }
      });
    };
    /*****************************************************************************
     * Funcion para eliminar el producto a nivel de base de datos
     * @param {*} id Indice del registro que sera eliminado
     *****************************************************************************/
    const deleteProduct = async (id) => {
      const response = await axios
        .delete(`pedido-cliente/${id}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === true) {
        await successAction(
          "Elminado",
          "Detalle de venta eliminado con éxito de la base de datos."
        );
      } else {
        errorActions("Lo sentimos no pudimos eliminar el detalle de la venta");
      }
      calculateTheTotal(arrayDataOrder.value);
    };

    /*****************************************************************************
     * Funcion para cambiar la cantidad de la orden
     * @param {*} index Indice del registro
     * @param {*} event El evento en este caso el valor que vendra en ese evento
     * @param {*} ivaAplied IVA aplicado al producto
     *****************************************************************************/
    const handleQuantityChange = (index, event, ivaAplied) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;

      arrayDataOrder.value[index].quantity = newQuantity;
      updateDetailData(index, ivaAplied);
    };

    /*****************************************************************************
     * Funcion para actualizar el valor en el objeto y sacar un nuevo calculo total
     * @param {*} index Indice del registro
     * @param {*} ivaAplied IVA aplicado al producto
     *****************************************************************************/
    const updateDetailData = (index, ivaAplied) => {
      const order = arrayDataOrder.value[index];
      let totalOrder = parseFloat(order.quantity) * parseFloat(order.salePrice);
      const configureIva = percentage(ivaAplied);
      order.subTotal = parseFloat(totalOrder / (configureIva + 1)).toFixed(2);
      order.igv = parseFloat(order.subTotal * configureIva).toFixed(2);
      order.total = totalOrder.toFixed(2);

      calculateTheTotal(arrayDataOrder.value);
    };

    /*****************************************************************************
     * Funcion para calcular el total de la orden, incluyendo el iva, subtotal
     * @param {*} order el array de objetos de productos de la tabla
     *****************************************************************************/
    const calculateTheTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      dataForm.value.total = 0;
      dataForm.value.subTotal = 0;
      dataForm.value.igv = 0;

      //recorremos todo el detalle de la orden
      order.forEach((element) => {
        dataForm.value.total += parseFloat(element.total);
        dataForm.value.subTotal += parseFloat(element.subTotal);
        dataForm.value.igv += parseFloat(element.igv);
      });
    };

    /*********************************************************************************
     * Funcion para cambiar el precio del producto por PRECIO 1, PRECIO 2, PRECIO 3
     *********************************************************************************/
    const handlePrices = async (index, event, product) => {
      const price = parseInt(event.target.value);
      const order = arrayDataOrder.value[index];
      const UNITS =
        typeof product.units !== "string"
          ? product.units
          : JSON.parse(product.units);

      const unitMeasure = order.unitMeasure;
      const selectUM = UNITS.filter(
        (item) => item.unidad_de_medida === unitMeasure
      );
      if (selectUM && selectUM.length > 0) {
        if (price === 1) {
          order.salePrice = selectUM[0].precio;
        } else if (price === 2) {
          if (parseFloat(selectUM[0].precio_dos) > 0) {
            order.salePrice = selectUM[0].precio_dos;
          }
        } else if (price === 3) {
          if (parseFloat(selectUM[0].precio_dos) > 0) {
            order.salePrice = selectUM[0].precio_tres;
          }
        }
        order.priceSelectedForTheCustomer = price;
        updateDetailData(index, product.igvApplied);
      }
    };

    /*********************************************************************************
     * Funcion para aplicar el descuento al producto producto
     *********************************************************************************/
    const handleUnitMeasureChange = async (index, event, igvApplied) => {
      const currentProduct = arrayDataOrder.value[index];

      const [price, quantityContained, unitMeasure] =
        event.target.value.split("|");
      //Calculamos la cantidad de productos
      const quantity = currentProduct.quantity * parseFloat(quantityContained);

      //OBTENCION DE LAS UNIDADES DE MEDIDA
      const selectUM = currentProduct.units.filter(
        (item) => item.unidad_de_medida === unitMeasure
      );

      //Validamos que el producto cuente con stock suficiente para ser cambiado de unidad de medida
      const thereIsStock = await validateStockProduct(
        currentProduct.idProduct,
        quantity
      );

      if (thereIsStock) {
        currentProduct.salePrice = price;
        currentProduct.unitMeasure = unitMeasure;
        currentProduct.quantityContained = parseFloat(quantityContained);

        if (selectUM[0].precio_dos > 0) {
          currentProduct.showBTNPriceTwo = true;
        } else {
          currentProduct.showBTNPriceTwo = false;
        }
        if (selectUM[0].precio_tres > 0) {
          currentProduct.showBTNPriceThree = true;
        } else {
          currentProduct.showBTNPriceThree = false;
        }
        updateDetailData(index, igvApplied);

        currentProduct.priceSelectedForTheCustomer = 1;
      } else {
        errorActions(
          "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
        );
        event.target.selectedIndex = 0;
        return;
      }
    };

    /*********************************************************************************
     * Funcion para cambiar la cantidad de la orden
     *********************************************************************************/
    const handleSalePrice = async (index, event, igvApplied) => {
      const currentPrice = arrayDataOrder.value[index];

      const newPrice =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : parseFloat(currentPrice.salePrice);

      currentPrice.salePrice = parseFloat(newPrice.toFixed(2));
      updateDetailData(index, igvApplied);
    };

    //Funcion para modificar la orden del cliente
    const update = async () => {
      spinner.value = true;

      const response = await axios
        .put(
          "pedido-cliente/" + props.id,
          {
            total: dataForm.value.total,
            subTotal: dataForm.value.subTotal,
            igv: dataForm.value.igv,
            details: arrayDataOrder.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate();
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        await props.showModal();
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `La orden que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos de la <trong> ORDEN </strong>`
        );
      }

      spinner.value = false;
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    onMounted(async () => {
      observeElement("#main-content-credit-note-sale");
      observeElement("#subcontent-credit-note-sale");
      arrayDataOrder.value = props.details.map((item) => {
        let units;

        try {
          // Convierte la cadena resultante a JSON
          units = JSON.parse(item.units);
        } catch (e) {
          // console.log(e);
        }

        return {
          ...item,
          isNew: false,
          units: units,
        };
      });
      calculateTheTotal(arrayDataOrder.value);
    });

    return {
      dataForm,
      spinner,
      header,
      arrayDataOrder,
      format,
      money,
      addProducts,
      removeProduct,
      handleQuantityChange,
      handlePrices,
      update,
      handleUnitMeasureChange,
      handleSalePrice,
    };
  },
};
</script>
