import { confirmationOfRecord, errorActions } from "@/alerts";
import axios from "../../../api";
/**
 * Funcion para insertar una venta temporal
 * @param {*} data Los datos principales de la venta temporal
 * @param {*} details Los detalles de la venta temporal
 * @returns El id de la venta temporal registrada
 */
export const insertTemporarySale = async (data, details, dataCustomer) => {
  const {
    idUser,
    idStore,
    idTurn,
    idTypeOfSale,
    idTypeOfPayment,
    idCreditNote,
    idVoucher,
    total,
    totalWhitDiscount,
    igv,
    subTotal,
    otherDiscounts,
    totalCreditNote,
    totalCreditNoteDiference,
    discount,
    expireQuotation,
    comentary,
    totalPayableByInsurer,
    totalPayableByCustomer,
    userName,
    customerName,
    numberReference,
    currencyDescription,
    currencyPrefix,
    igvApplied,
    retention,
    entityBank,
    numberOperation,
    cashAmount,
    targetAmount,
    transferAmount,
    chequeAmount,
    customerAmountPaid,
    customerAmountReturned,
    modificationDate,
    sunatAnswer,
  } = data;
  try {
    const response = await axios.post(
      "venta-temporal-cotizacion-pedido",
      {
        idUser,
        idStore,
        idTurn,
        idVoucher,
        idTypeOfSale,
        idTypeOfPayment,
        idCreditNote,
        total,
        totalWhitDiscount,
        igv,
        subTotal,
        otherDiscounts,
        totalCreditNote,
        totalCreditNoteDiference,
        discount,
        expireQuotation,
        comentary,
        totalPayableByInsurer,
        totalPayableByCustomer,
        userName,
        customerName,
        serieVoucher:null,
        numberVoucher:null,
        numberReference,
        currencyDescription,
        currencyPrefix,
        igvApplied,
        retention,
        entityBank,
        numberOperation,
        cashAmount,
        targetAmount,
        transferAmount,
        chequeAmount,
        customerAmountPaid,
        customerAmountReturned,
        modificationDate,
        sunatAnswer,
        state: "VIGENTE",
        type: "VENTA TEMPORAL",
        idCustomer: dataCustomer.id,
        details,
      },
      {
        method: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    if (data.msg === true) {
      confirmationOfRecord(`La venta temporal del cliente <strong>${customerName}</strong>`);
      return data.id;
    } else {
      errorActions(
        `Lo sentimos no pudimos registrar la venta temporal del cliente <strong>${customerName}</strong> verifica si tuviste alguna problemas al momento de ingresar los productos o los datos del cliente`
      );
    }
  } catch (error) {
    errorActions(error);
  }
};
