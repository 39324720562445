import { formatDate } from "./formatDate";
/**
 * Funcion para calcular la fecha de expiracion
 * @param {*} expirationDate | La fecha que queremos hacer un seguimiento para saber cuando expira
 * @returns | Retorna la fecha de expiracion, puede ser de un producot, lote, etc.
 */
export const calculateExpirationDate = (expirationDate) => {
  const dateFormats = ["YYYY-MM-DD", "YYYY/MM/DD", "DD-MM-YYYY", "DD/MM/YYYY"];
  let standardizedDate = null;

  for (const format of dateFormats) {
    const parts = expirationDate.split(/[-/]/);
    const dateFormat = format.split(/[-/]/);

    if (parts.length === dateFormat.length) {
      let year = null;
      let month = null;
      let day = null;

      for (let i = 0; i < parts.length; i++) {
        if (dateFormat[i].toUpperCase() === "YYYY") {
          year = parts[i];
        } else if (dateFormat[i].toUpperCase() === "MM") {
          month = parts[i];
        } else if (dateFormat[i].toUpperCase() === "DD") {
          day = parts[i];
        }
      }

      if (year && month && day) {
        standardizedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        break;
      }
    }
  }

  if (!standardizedDate) {
    console.log(
      'Invalid date format. Please provide a date in one of the following formats: "YYYY-MM-DD", "YYYY/MM/DD", "DD-MM-YYYY", "DD/MM/YYYY"'
    );
    return;
  }

  const currentDate = new Date();

  const expirationDateParts = standardizedDate.split("-");
  const expirationYear = parseInt(expirationDateParts[0]);
  const expirationMonth = parseInt(expirationDateParts[1]) - 1;
  const expirationDay = parseInt(expirationDateParts[2]);
  const expirationDateObj = new Date(
    expirationYear,
    expirationMonth,
    expirationDay
  );

  const timeDifferenceMs = expirationDateObj - currentDate;
  const differenceInDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

  let expirationMessage;
  if (differenceInDays > 15) {
    expirationMessage = formatDate(expirationDate);
  } else if (differenceInDays >= 2 && differenceInDays <= 15) {
    expirationMessage = `Vence en ${differenceInDays} días.`.toUpperCase();
  } else if (differenceInDays === 1) {
    expirationMessage = "Vence mañana".toUpperCase();
  } else if (differenceInDays === 0) {
    expirationMessage = "Vence hoy".toUpperCase();
  } else {
    expirationMessage = "Vencido".toUpperCase();
  }

  return expirationMessage;
};
