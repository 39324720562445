<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[80vw] lg:min-w-[900px] xl:min-w-[1024px] md:max-w-[80vw] lg:max-w-[900px] xl:max-w-[1024px]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de acceso a módulos del sistema
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <AccessForms v-if="loadingModules" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sucursales
              </span>
              <Stores @send-data="getStores" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Usuarios
              </span>
              <div
                v-if="refreshUser"
                class="py-2 text-sm rounded-lg border-2 px-3 bg-gray-100 text-gray-400"
              >
                Selecciona una sucursal
              </div>
              <Users v-else @send-data="getUsers" :idstore="dataForm.idStore" />
            </label>
            <div
              v-if="dataForm.containAccess"
              class="md:col-span-2 lg:col-span-3 bg-red-100 px-3 py-3 rounded-lg text-red-700 text-sm font-semibold"
            >
              El usuario ya cuenta con acceso, si deseas agregarle un acceso
              más, solo dale click al módulo que deseas permitir acceder
            </div>
            <div
              class="md:col-span-2 lg:col-span-3 bg-orange-100 px-3 py-3 rounded-lg text-orange-600 text-sm"
            >
              Para dar acceso a ciertas partes del modulo del sistema a un
              usuario, por favor primero selecciona una sucursal y luego
              selecciona el usuario al cual se le quiere dar el acceso, debes de
              estar seguro de dar ese privilegio al usuario seleccionado.
            </div>
            <div
              class="bg-gray-100 shadow-md border border-blue-200 rounded-lg overflow-hidden"
              v-for="(dataModule, i) in dataModules"
              :key="i"
            >
              <div class="px-4 py-3 text-gray-700 font-semibold text-sm">
                <font-awesome-icon
                  :icon="dataModule.icon"
                  class="w-4 h-4 mr-2 text-gray-400"
                />
                {{ dataModule.description }}
              </div>
              <div class="py-4 px-3 bg-white h-full">
                <label
                  class="flex flex-col mr-5 mb-4 mt-4 cursor-pointer"
                  v-for="(submodule, e) in dataModule.submodules"
                  :key="e"
                >
                  <div
                    class="relative inline-flex items-center h-6 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      @change="
                        addDataModules($event, submodule.idmodule, submodule.id)
                      "
                    />
                    <div
                      class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                    ></div>
                    <span
                      class="ml-3 text-sm font-medium text-gray-500 dark:text-gray-300"
                    >
                      {{ submodule.description }}
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
              v-if="!dataForm.containAccess"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import {
  confirmationOfRecord,
  errorActions,
  uploadData,
  warningActions,
} from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";

import Users from "@/components/PublicComponents/Users.vue";
import Stores from "@/components/PublicComponents/Stores.vue";

import AccessForms from "@/components/Loadings/AccessForms.vue";
import { localTypeUser } from "@/publicjs/localStorage";

export default {
  name: "RegisterAccess",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    Users,
    Stores,
    AccessForms,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      idStore: null,
      idUser: null,
      containAccess: false,
      existeAccess: false,
    });

    //funcion para obtener los modulos completos del sistema
    const dataModules = ref([]);
    const loadingModules = ref(true);
    const getSoftwareModules = async () => {
      loadingModules.value = true;
      const response = await axios
        .get("modulos_del_sistema_all/all")
        .catch((error) => {
          errorActions(error);
        });
      if ((await response.data.length) === 0) {
        warningActions(
          "No pudimos encontrar los modulos y submódulos del sistema"
        );
      }
      const userType = await localTypeUser();
      const DATA = await response.data;

      if (userType === "SUPERADMIN_ROOT") {
        dataModules.value = DATA;
      } else {
        dataModules.value = DATA.filter(
          (item) =>
            item.description !== "Módulos del sistema" &&
            item.description !== "Modulos"
        );
        console.log(dataModules.value);
      }

      loadingModules.value = false;
    };

    //ARRAY PARA AGREGAR EL ACCESO DE MODULOS DE LOS USUARIOS
    const arrayModules = ref([]);
    const addDataModules = async (e, idModule, idSubmodule) => {
      // si el usuario ya tiene acceso insertar el nuevo acceso
      if (dataForm.value.containAccess) {
        uploadData(
          "Agregando permiso",
          "Espere un momento mientras agregamos el permiso del usuario"
        );
        e.target.checked = false;
        await addAccess(dataForm.value.idUser, idModule, idSubmodule);
        if (dataForm.value.existeAccess === true) {
          dataForm.value.existeAccess = false;
        } else {
          e.target.checked = true;
        }
      } else {
        // de lo contrario agregar a un array de objetos
        if (dataForm.value.idUser === null) {
          errorActions("Selecciona un usuario y vuelve a intentarlo");
          e.target.checked = false;
          return;
        }
        // verifica si existe un objeto con el valor que le estamos enviando
        const foundIndex = arrayModules.value.findIndex(
          (module) => module.idSubmodules === idSubmodule
        );
        // si existe el registro con el mismo id lo eliminamos
        if (foundIndex > -1) {
          arrayModules.value.splice(foundIndex, 1);
        } else {
          // si no existe simplemente agregamos el objeto al arreglo
          const newObj = {
            id: arrayModules.value.length + 1,
            idUser: dataForm.value.idUser,
            idModules: idModule,
            idSubmodules: idSubmodule,
          };
          arrayModules.value.push(newObj);
        }
      }
    };

    onMounted(async () => {
      await getSoftwareModules();
    });

    //recepcion de datos desde el componente publico, todas los usuarios
    const getUsers = async (data) => {
      dataForm.value.idUser = data;
      await existAccessUser();
    };
    //recepcion de datos desde el componente publico, todos los sucursales
    const refreshUser = ref(true); // variable para refresecar el componente de usuarios
    const getStores = async (data) => {
      refreshUser.value = true;
      dataForm.value.idStore = data;
      await getUsers();
      refreshUser.value = false;
    };

    // funcion para validar si el usuario ya tiene un acceso registrado con anterioridad
    const existAccessUser = async () => {
      const response = await axios
        .get("accesos/" + dataForm.value.idUser, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === false) {
        dataForm.value.containAccess = false;
      } else if (data.msg === true) {
        dataForm.value.containAccess = true;
      }
    };

    // funcion para agregar mas accesos al usuario despues de haber registrado su acceso por primera vez
    const addAccess = async (idUser, idModule, idSubmodule) => {
      //validar si ya se le dio el acceso con anterioridad
      const responseAccess = await axios
        .get(
          `accesos/access/${idUser}/${idModule}/${idSubmodule}`,
          {
            idUser,
            idModules: idModule,
            idSubmodules: idSubmodule,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const responseData = await responseAccess.data.msg;
      if (responseData === true) {
        dataForm.value.existeAccess = true;
        errorActions("Este usuario ya tiene este acceso");
        return;
      }

      const response = await axios
        .post(
          "accesos",
          {
            idUser,
            idModules: idModule,
            idSubmodules: idSubmodule,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;

      if (data.msg === true) {
        confirmationOfRecord("El acceso del usuario ");

        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        props.getdata();
        arrayModules.value = null;
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          "Lo sentimos, no pudimos registrar el acceso para el usuario"
        );
      }
    };

    //counter para saber si ya finalizo de registrar los modulos y submodulo del usuario

    const insert = async () => {
      if (dataForm.value.idStore === null) {
        errorActions("Selecciona un sucursal");
        return;
      }
      if (dataForm.value.idUser === null) {
        errorActions("Selecciona un usuario");
        return;
      }
      if (
        arrayModules.value.length === 0 ||
        arrayModules.value.length === null
      ) {
        errorActions("No has seleccionado los modulos para el usuario");
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "accesos",
          {
            modules: arrayModules.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
          spinner.value = false; //dejando activo al boton
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord("El acceso del usuario ");

        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        props.getdata();
        spinner.value = false; //dejando activo al boton
        arrayModules.value = null;
        await props.activeModal();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
        spinner.value = false; //dejando activo al boton
      } else if (data.msg === "idExist") {
        errorActions(
          "El usuario ya tiene un acceso asignado por favor, busca al usuario y modifica sus acceso si es necesario"
        );
        spinner.value = false; //dejando activo al boton
      } else {
        errorActions(
          "Lo sentimos, no pudimos registrar el acceso para el usuario"
        );
        spinner.value = false; //dejando activo al boton
      }
    };
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getStores,
      getUsers,
      refreshUser,
      dataModules,
      loadingModules,
      addDataModules,
    };
  },
};
</script>
