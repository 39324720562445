import axios from "@/api";
export default async (products) => {
  if (products.length < 0) {
    return {
      state: false,
      message: "No hay productos que verificar",
      datails: [],
    };
  }
  try {
    const response = await axios.post(
      "verificar-stock",
      {
        products,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data 
    return data
  } catch (error) {
    return {
      state: false,
      message: "Error al momento de verificar el stock de los productos",
      datails: [],
    };
  }
};
