const dayjs = require("dayjs");
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

// Configura la zona horaria que necesitas, por ejemplo 'America/Mexico_City'
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);

// Configura la zona horaria que deseas utilizar
dayjs.tz.setDefault(process.env.VUE_APP_CURRENT_TIME_ZONE); // Reemplaza 'America/Mexico_City' con tu zona horaria

// Función para verificar si la fecha ha vencido
export default (date) => {
    if(date === null || date ==='') {
        return true
    }
  // Parsea la fecha proporcionada
  const expired = dayjs(date, { format: "YYYY-MM-DD HH:mm:ss" });

  // Obtiene la fecha actual
  const currentDate = dayjs();
  // Compara las fechas
  return expired.isBefore(currentDate);
}