<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700">
      Listado de movimiento de productos ingresados
    </h2>
    <MainProductsAccordingToModuleEntered />
  </div>
</template>

<script>
import MainProductsAccordingToModuleEntered from "@/components/Reports/Products/ProductsAccordingToModuleEntered/MainProductsAccordingToModuleEntered.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "ProductsAccordingToModuleEntered",
  components: {
    MainProductsAccordingToModuleEntered,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
