<template>
  <div class="mt-4" id="dataConsolidated">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <div class="flex flex-wrap gap-4 items-end">
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
      </div>
      <div class="flex flex-wrap gap-4 items-end">
        <button
          class="bg-gray-100 text-gray-500 py-2 active:scale-95 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-gray-500"
          @click="exportToPDF(dataForm.data, dataForm)"
          v-show="!loading"
        >
          <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
          Exportar
        </button>
        <button
          class="bg-blue-500 text-white py-2 active:scale-95 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
          @click="getData"
        >
          <font-awesome-icon icon="fa-solid fa-search" class="mr-2" />
          Consutar
        </button>
      </div>
    </div>
    <div
      v-show="loading"
      class="flex justify-center items-center min-h-[calc(100vh-16rem)]"
    >
      <div class="max-w-[20rem] opacity-50">
        <img
          src="@/assets/images/statics/caja-vacia.png"
          class="animate-pulse"
          alt=""
        />
      </div>
    </div>
    <div v-if="!loading">
      <h2 class="text-lg font-bold text-gray-700 mb-6">
        Cuadre total de consolidados
      </h2>
      <div
        class="bg-blue-50 border-2 border-blue-100 p-3 rounded-md mb-3 text-blue-500 font-bold"
      >
        <div class="grid md:grid-cols-2 gap-4">
          <div
            class="bg-blue-100 p-3 rounded-md border-2 border-blue-200 col-span-2 mb-3"
          >
            <p>Ganancia neta en ventas</p>
            <div class="text-2xl">
              {{ money() }} {{ format(dataForm.totalSale) }}
            </div>
          </div>
          <div class="bg-blue-100 p-3 rounded-md border-2 border-blue-200">
            <p>Gastos</p>
            <div class="text-2xl">
              {{ money() }} {{ format(dataForm.totalExpences) }}
            </div>
          </div>
          <div class="bg-blue-100 p-3 rounded-md border-2 border-blue-200">
            <p>Ventas</p>
            <div class="text-2xl">
              {{ money() }} {{ format(dataForm.data.ventas.total) }}
            </div>
          </div>
        </div>
      </div>
      <h2 class="text-lg font-bold text-gray-700 mb-3 mt-6">
        Consolidado por categoría
      </h2>
      <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
        <div class="rounded-md overflow-hidden shadow">
          <div class="bg-slate-100 p-3 text-blue-500 uppercase font-semibold">
            Compras
          </div>
          <div class="p-3 text-2xl font-bold text-gray-500">
            {{ money() }} {{ format(dataForm.data.compras.total) }}
          </div>
        </div>
        <div class="rounded-md overflow-hidden shadow">
          <div class="bg-slate-100 p-3 text-blue-500 uppercase font-bold">
            ventas
          </div>
          <div class="p-3 text-2xl font-bold text-gray-500">
            {{ money() }} {{ format(dataForm.data.ventas.total) }}
          </div>
        </div>
        <div class="rounded-md overflow-hidden shadow">
          <div class="bg-slate-100 p-3 text-blue-500 uppercase font-semibold">
            desembolsos
          </div>
          <div class="p-3 text-2xl font-bold text-gray-500">
            {{ money() }} {{ format(dataForm.data.desembolsos.total) }}
          </div>
        </div>
        <div class="rounded-md overflow-hidden shadow">
          <div class="bg-slate-100 p-3 text-blue-500 uppercase font-semibold">
            pago de servicios
          </div>
          <div class="p-3 text-2xl font-bold text-gray-500">
            {{ money() }} {{ format(dataForm.data.pago_de_servicios.total) }}
          </div>
        </div>
        <div class="rounded-md overflow-hidden shadow">
          <div class="bg-slate-100 p-3 text-blue-500 uppercase font-semibold">
            pago de personal
          </div>
          <div class="p-3 text-2xl font-bold text-gray-500">
            {{ money() }} {{ format(dataForm.data.pago_de_personal.total) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "@/api";
import { closeAlert, errorActions, uploadData, warningActions } from "@/alerts";

import { money, format } from "@/publicjs/money";
import { observeElement } from "@/observer";
import { idLocalStore } from "@/publicjs/localStorage";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import { currentDay } from "@/publicjs/currentDate";

import { exportToPDF } from "./Reports/PDFExport";

export default {
  name: "MainConsolidatedSales",
  setup() {
    const loading = ref(true);
    const dataForm = ref({
      idStore: 0,
      dateStart: null,
      dateEnd: null,
      stateBoxes: false,
      data: {},
      totalExpences: 0,
      totalSale: 0,
    });

    const getDataValidate = async () => {
      await uploadData(
        "GENERANDO REPORTE",
        "Espera un momento mientras preparamos los datos.<br> <strong>Esto tomará solo unos segundo</strong>"
      );
      dataForm.value.stateBoxes = false;
      const response = await axios
        .get(
          `reporte-consolidado/validacion/cajas/?idstore=${dataForm.value.idStore}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.msg === false) {
        dataForm.value.stateBoxes = false;
      } else if (data.msg === true) {
        dataForm.value.stateBoxes = true;
      }
      await closeAlert();
    };
    const getData = async () => {
      await getDataValidate();
      if (!dataForm.value.stateBoxes) {
        warningActions(
          "Lo sentimos no podemos generar el reporte debido a que <strong>tienes cajas abiertas</strong> cierrar todas las cajas y vuelve a intentarlo de nuevo."
        );
        return;
      }
      loading.value = true;
      const response = await axios
        .get(
          `reporte-consolidado/?idstore=${dataForm.value.idStore}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.data = data;
      loading.value = false;
      const totalExpences =
        parseFloat(data.compras.total) +
        parseFloat(data.desembolsos.total) +
        parseFloat(data.pago_de_personal.total) +
        parseFloat(data.pago_de_servicios.total);
      dataForm.value.totalExpences = totalExpences;
      dataForm.value.totalSale = parseFloat(
        data.ventas.total - totalExpences
      ).toFixed(2);
    };

    onMounted(async () => {
      observeElement("#dataConsolidated");
      dataForm.value.idStore = await idLocalStore();
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = currentDay();
      dataForm.value.dateEnd = dates.lastDay;
    });

    return {
      loading,
      getData,
      money,
      format,
      dataForm,
      exportToPDF,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
