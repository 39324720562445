<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Productos con adventencia
          </h2>
          <button
            @click="showModal"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>

        <div class="max-w-full overflow-x-auto">
          <table>
            <TableHead
              :headers="header"
              class="sticky top-0 [&>tr>th]:text-[12px]"
            />
            <tbody
              class="text-gray-500 text-left border-t-[1px]"
              id="dataTable"
            >
              <tr
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50 td-focus"
                v-for="(product, i) in details"
                :key="i"
              >
                <td
                  class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap"
                >
                  {{ product.producto }}
                </td>
                <td
                  class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap"
                >
                  {{ product.cantidad_solicitada }}
                </td>
                <td
                  class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap"
                >
                  {{ product.stock_disponible }}
                </td>
                <td
                  class="border-slate-200 text-xs px-6 py-2 whitespace-nowrap"
                >
                  {{ product.state }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableHead from "@/components/TablesComponents/TableHead";
export default {
  components: {
    TableHead,
  },
  props: {
    showModal: {
      type: Function,
      required: true,
    },
    details: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const header = [
      [
        {
          title: "Producto",
        },
        {
          title: "Estock solicitado",
        },
        {
          title: "Estock actual",
        },
        {
          title: "Mensaje",
        },
      ],
    ];
    return {
      header,
    };
  },
};
</script>
