<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentdata">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de pagos del personal
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 gap-6">
            <div class="col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Usuarios
              </span>
              <SearchUsers @send-data="getUser" />
            </div>
            <div class="col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Buscar prestamos del usuario
              </span>
              <SearchLoans @send-data="getLoans" />
            </div>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sueldo base <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.basePayment"
                placeholder="Sueldo base..."
                @keydown.enter.prevent
                readonly
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Min. acumulados <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.acumulateHours"
                placeholder="Min acumulados..."
                @keydown.enter.prevent
                @input="caculateTotal"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Total por horas extras <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.hourExtra"
                placeholder="Min acumulados..."
                @keydown.enter.prevent
                @input="caculateTotal"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Comisión por ventas <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.commission"
                placeholder="Comision por ventas..."
                @keydown.enter.prevent
                @input="caculateTotal"
              />
            </label>
            <label class="block col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Pago total <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-red-500 w-full border-2 border-red-400 font-bold focus:border-red-500"
                v-model="dataForm.total"
                placeholder="Pago total..."
                @keydown.enter.prevent
              />
            </label>
          </div>
          <div
            class="p-3 rounded-md bg-blue-50 border-2 border-blue-100 text-blue-500 flex flex-wrap gap-4"
            v-show="dataForm.discountFixed > 0"
          >
            <div>
              <span class="text-xs block mb-1"> Prestamo del usuario </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-white outline-none rounded-lg text-blue-500 w-full border-2 border-blue-400 font-bold focus:border-blue-500"
                v-model="dataForm.discount"
                placeholder="Prestamos..."
                @keydown.enter.prevent
                @input="caculateTotal"
              />
            </div>
            <span class="font-bold text-xl">
              <span class="text-xs font-semibold block mb-3">
                Saldo actual
              </span>
              {{ dataForm.discountFixed }}
            </span>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="@/assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "@/api";
import SearchUsers from "@/components/Users/PersonalPayment/SearchUsers.vue";
import SearchLoans from "@/components/Users/PersonalPayment/SearchLoans.vue";
import { idLocalStore, idLocalUser } from '@/publicjs/localStorage';

export default {
  name: "RegisterPersonalPayment",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    SearchUsers,
    SearchLoans,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      idStore: 0,
      idLoans: 0,
      idUser: 0,
      userName: "",
      commission: 0,
      hourExtra: 0,
      acumulateHours: 0,
      paymentForHours: 0,
      total: 0,
      discount: 0,
      discountFixed: 0,
      basePayment: 0,
    });


    const insert = async () => {
      spinner.value = true;
      const response = await axios
        .post(
          "pagos-del-personal",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            basePayment: dataForm.value.basePayment,
            discount: dataForm.value.discount,
            total: dataForm.value.total,
            amountExtra: dataForm.value.hourExtra,
            hourExtra: dataForm.value.acumulateHours,
            commission: dataForm.value.commission,
            idLoans: dataForm.value.idLoans,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El cliente  <strong>${dataForm.value.names}</strong>`
        );
        props.getdata();
        isActive()
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el cliente <strong>${dataForm.value.names}</strong>`
        );
      }

      spinner.value = false;
    };

    const getDataPayment = async () => {
      const response = await axios
        .get(`pagos-del-personal/${dataForm.value.idUser}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === false) {
        errorActions("El usuario no tiene el pago configurado");
        return;
      }
      dataForm.value.basePayment = parseFloat(data.sueldo_base);
      dataForm.value.commission = parseFloat(data.comision_acumulado);
      dataForm.value.acumulateHours = parseFloat(data.hora_extra_acumulada);
      dataForm.value.paymentForHours = parseFloat(data.pago_por_horas_extra);

      const horasTrabajadas = Math.floor(data.hora_extra_acumulada / 60);
      const pagoTotal = horasTrabajadas * data.pago_por_horas_extra;
      dataForm.value.hourExtra = pagoTotal;

      const totalPayment =
        dataForm.value.basePayment + dataForm.value.commission + pagoTotal;
      dataForm.value.total = parseFloat(
        totalPayment + dataForm.value.discount
      ).toFixed(2);
    };

    const getUser = async (data) => {
      dataForm.value.idUser = data.id;
      dataForm.value.userName = data.userName;
      await getDataPayment();
    };
    const getLoans = async (data) => {
      dataForm.value.idLoans = data.id;
      dataForm.value.discount = parseFloat(data.amountLoans);
      dataForm.value.discountFixed = parseFloat(data.amountLoans);
      await caculateTotal();
    };

    const caculateTotal = async () => {
      let horasTrabajadas = Math.floor(
        parseFloat(dataForm.value.acumulateHours) / 60
      );
      let pagoTotal =
        horasTrabajadas * parseFloat(dataForm.value.paymentForHours);
      dataForm.value.hourExtra = pagoTotal;

      const totalPayment =
        dataForm.value.basePayment + dataForm.value.commission + pagoTotal;

      dataForm.value.total = parseFloat(
        totalPayment + dataForm.value.discount
      ).toFixed(2);
    };

    onMounted( async() => {
      observeElement("#contentdata");
      dataForm.value.idStore = await idLocalStore()
      dataForm.value.idUser = await idLocalUser()
    });
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getUser,
      getLoans,
      caculateTotal,
    };
  },
};
</script>
