<template>
  <div
    class="border border-blue-100 mt-3 bg-blue-50 px-3 py-3 rounded-md grid md:grid-cols-2"
  >
    <div>
      <div
        class="px-3 py-3 border border-blue-200 rounded-md text-sm font-semibold"
        v-if="totalPayableByInsurer > 0"
      >
        <div class="text-gray-500">
          Pago fraccionado según la aseguradora:
          <strong class="block" v-if="insurerBussinesName !== null">
            {{ insurerBussinesName }}
          </strong>
        </div>
        <div class="text-blue-500 mt-2 font-bold">
          CLIENTE: {{ money() }}{{ format(totalPayableByCustomer) }}
        </div>
        <div class="text-orange-500 mt-2 font-bold">
          ASEGURADORA: {{ money() }}{{ format(totalPayableByInsurer) }}
        </div>
      </div>
    </div>
    <div>
      <div
        class="flex items-center justify-end text-blue-500 font-semibold mb-2"
        v-if="totalCreditNote > 0"
      >
        <div class="mr-3">NOTA DE CREDITO:</div>
        <div class="text-blue-500 font-bold">
          {{ money() }}{{ format(totalCreditNote) }}
        </div>
      </div>
      <div
        class="flex items-center justify-end text-orange-500 font-semibold mb-2"
        v-if="totalCreditNote > 0"
      >
        <div class="mr-3">DIF. A PAGAR:</div>
        <div class="text-orange-500 font-bold">
          {{ money() }}{{ format(totalCreditNoteDiference) }}
        </div>
      </div>
      <div
        class="flex items-center justify-end text-blue-600 font-semibold mb-2"
        v-if="otherDiscounts > 0"
      >
        <div class="mr-3">OTROS DESCUENTOS:</div>
        <div class="text-blue-500 font-bold">
          {{ money() }}{{ format(otherDiscounts) }}
        </div>
      </div>
      <div
        class="flex items-center justify-end text-orange-500 font-bold mb-2"
        v-if="totalWhitDiscount > 0"
      >
        <div class="mr-3">CLIENTE PAGA:</div>
        <div class="text-orange-500 font-bold">
          {{ money() }}{{ format(totalWhitDiscount) }}
        </div>
      </div>
      <div
        class="flex items-center justify-end text-blue-600 font-semibold mb-2"
      >
        <div class="mr-3">{{ config.iva }}:</div>
        <div class="text-blue-500 font-bold">
          {{ money() }}{{ format(iva) }}
        </div>
      </div>
      <div
        class="flex items-center justify-end text-blue-600 font-semibold mb-2"
      >
        <div class="mr-3">SUBTOTAL:</div>
        <div class="text-blue-500 font-bold">
          {{ money() }}{{ format(subTotal) }}
        </div>
      </div>
      <div class="flex items-center justify-end text-blue-600 font-semibold">
        <div class="mr-3">TOTAL:</div>
        <div class="text-blue-500 font-bold">
          {{ money() }}{{ format(total) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { money, format } from "@/publicjs/money";
import config from "/public/apis/config.json";
export default {
  name: "ComponentForTotalDetails",
  props: {
    iva: {
      type: Number,
      required: true,
    },
    subTotal: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    totalWhitDiscount: {
      type: Number,
      required: true,
    },
    totalCreditNote: {
      type: Number,
      required: true,
    },
    totalCreditNoteDiference: {
      type: Number,
      required: true,
    },
    otherDiscounts: {
      type: Number,
      required: true,
    },
    totalPayableByInsurer: {
      type: Number,
      required: true,
    },
    totalPayableByCustomer: {
      type: Number,
      required: true,
    },
    insurerBussinesName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      money,
      format,
      config,
    };
  },
};
</script>
