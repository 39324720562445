<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700">
      Módulos y SubModulos del Sistema
    </h2>
    <MainSubModules />
  </div>
</template>
<script>
import MainSubModules from "@/components/SystemModules/SystemSubModules/MainSubModules.vue";
import { useRoute } from 'vue-router';
import { onBeforeMount, onMounted } from 'vue';
import { findSubmoduleIdByDescription } from '@/hisAcces';
export default {
  name: "SystemSubModules",
  components: {
    MainSubModules,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
