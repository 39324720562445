<template>
  <div id="contentSearchTypeOfProduct">
    <div>
      <div class="w-full md:w-auto mt-3 md:mt-0">
        <span class="text-sm font-semibold text-blue-500 pb-2 block">
          Tipos de producto
        </span>
        <div class="relative md:min-w-[400px] lg:min-w-[720px]">
          <div class="flex items-center relative text-gray-600">
            <div class="absolute top-0 left-3 w-6 h-full flex items-center">
              <font-awesome-icon icon="fa-solid fa-search" class="w-4 h-4" />
            </div>
            <input
              type="search"
              v-model="dataForm.description"
              class="border-2 pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50 focus:border-blue-500"
              placeholder="Buscar por descripción"
              @keydown.enter="getData"
              @keydown.enter.prevent
              autocomplete="off"
              id="inputCustomer"
            />
          </div>
          <div
            v-if="showTypeOfProducts"
            class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
          >
            <div v-if="loadingSearch">
              <div class="px-3">
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="!dataLength"
                class="px-3 py-1 bg-gray-50 text-gray-400"
                @click="showTypeOfProducts = false"
              >
                <strong>{{ dataForm.description }}</strong> no existe en nuestra
                base de datos
              </div>
              <div
                v-else
                v-for="(result, i) in dataResult"
                :key="i"
                class="odd:bg-white even:bg-slate-50"
                @blur="showTypeOfProducts = false"
              >
                <div
                  class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                  @click="
                    selectTypeOfProduct({
                      id: result.id,
                      description: result.descripcion,
                    })
                  "
                >
                  {{ result.descripcion }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorActions } from "@/alerts";
import axios from "../../../api";
import { onMounted, ref } from "vue";
import { observeElement } from "@/observer";
export default {
  name: "SearchTypeProducts",
  emits: ["send-data"],
  setup(_, { emit }) {
    const dataForm = ref({
      id: 0,
      description: "",
    });
    onMounted(() => {
      observeElement("#contentSearchTypeOfProduct");
    });

    //Funcion para cargar ventas temporales
    const showTypeOfProducts = ref(false);
    const loadingSearch = ref(true);
    const dataResult = ref([]);
    const dataLength = ref(false);
    const getData = async () => {
      try {
        loadingSearch.value = true;
        showTypeOfProducts.value = true;
        dataLength.value = false;
        const response = await axios
          .get(
            `tipo-de-productos/?page=1&per_page=30&search=${dataForm.value.description}`
          )
          .catch((error) => errorActions(error));
        dataResult.value = await response.data.data;
        if (dataResult.value.length > 0) {
          dataLength.value = true;
        }
        loadingSearch.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar el tipo de producto debido a que hemos tenido unos inconvenientes"
        );
      }
    };
    const selectTypeOfProduct = async (data) => {
      dataForm.value.id = data.id;
      dataForm.value.description = data.description;
      showTypeOfProducts.value = false;
      emit("send-data", dataForm.value);
    };

    return {
      showTypeOfProducts,
      loadingSearch,
      dataResult,
      dataLength,
      getData,
      dataForm,
      selectTypeOfProduct,
    };
  },
};
</script>
