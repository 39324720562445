<template>
  <div class="grid gap-4">
    <div>
      <h2 class="text-1xl font-bold text-gray-700">
        Acceso a módulos específicos de sistema
      </h2>
      <MainSystemEspecificAcess />
    </div>
  </div>
</template>
<script>
import MainSystemEspecificAcess from "@/components/SystemModules/SystemEspecificAcess/MainSystemEspecificAcess.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "SystemEspecificAccess",
  components: {
    MainSystemEspecificAcess,
  },
  setup() {
    const route = useRoute();

    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });
    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
