<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Contrataciones / Configuracion de pagos
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div class="relative">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Usuarios
              </span>
              <input
                type="text"
                v-model="dataForm.search"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-blue-200 focus:border-blue-500"
                placeholder="Buscarpor nombre..."
                @keydown.enter="search"
                @keydown.enter.prevent
              />
              <div
                v-if="showResults"
                class="absolute top-[4.2rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg"
              >
                <div v-if="loadingSearch">
                  <div class="px-3">
                    <div
                      class="px-3 p-2 bg-gray-200 animate-pulse rounded-lg mb-2"
                    ></div>
                    <div
                      class="px-3 p-2 bg-gray-200 animate-pulse rounded-lg mb-2"
                    ></div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-if="!resultLength"
                    class="px-3 py-1 bg-gray-50 text-gray-400"
                    @click="showResults = false"
                  >
                    <strong>{{ dataForm.search }}</strong> no existe
                  </div>
                  <div v-else v-for="(result, i) in resultSearch" :key="i">
                    <div
                      class="py-1 px-3 mt-1 mb-1 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500"
                      @click="changeUserID(result.id, result.nombres)"
                      @blur="showResults = false"
                    >
                      {{ result.nombres }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sucursales
              </span>
              <Stores @send-data="getSelectStore" :selectOption="dataForm.idStore" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipos de afilización
              </span>
              <select
                class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none"
                v-model="dataForm.membershipType"
              >
                <option value="AFP CRECER">AFP CRECER</option>
                <option value="AFP POPULAR">AFP POPULAR</option>
                <option value="AFP RESERVAS">AFP RESERVAS</option>
                <option value="AFP SIEMBRA">AFP SIEMBRA</option>
                <option value="AFP ROMANA">AFP ROMANA</option>
                <option value="AFP ATLANTICO">AFP ATLANTICO</option>
                <option value="AFP JMMB">AFP JMMB</option>
                <option value="ONP">ONP</option>
              </select>
            </label>

            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Descuento por afiliación (%)
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.discount"
                placeholder="Descuento por afiliación (%)..."
                @keydown.enter="validatePayment"
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Asignación familiar (%)
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.householdAllowance"
                placeholder="Asignación familiar (%)..."
                @keydown.enter="validatePayment"
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Salario base
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.baseSalary"
                placeholder="Salario base..."
                @keydown.enter="validatePayment"
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Pago mensual
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-green-600 w-full border-2 border-green-500 focus:border-blue-500"
                v-model="dataForm.amounthPayment"
                placeholder="Pago mensual..."
                readonly
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Pago por H. extras
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.overtimePay"
                placeholder="Pago por horas extra..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Minutos extra acumulado
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-orange-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.accumulatedOvertime"
                placeholder="Minutos extra acumulado.."
                disabled
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Comision por ventas
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.commission"
                placeholder="Comision por ventas..."
                disabled
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Codigo de barras
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.barCode"
                placeholder="Código de barras.."
              />
            </label>

            <label class="flex flex-col h-6 mr-5 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado del usuario
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.isMarker"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{
                    dataForm.isMarker === true
                      ? "Si marca asistencia"
                      : "No marca asistencia"
                  }}
                </span>
              </div>
            </label>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../../api";
import {percentage} from '../../../../publicjs/convertToPercentage'

import Stores from "@/components/PublicComponents/Stores.vue";
import { idLocalStore } from '@/publicjs/localStorage';

export default {
  name: "RegisterTurns",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    Stores,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      search: "",
      idStore: 0,
      idUser: 0,
      membershipType: "AFP CRECER",
      discount: 0,
      householdAllowance: 0,
      baseSalary: 0,
      amounthPayment: 0,
      overtimePay: 0,
      accumulatedOvertime: 0,
      commission: 0,
      isMarker: false,
      barCode: "00000000000",
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectStore = (data) => {
      dataForm.value.idStore = data;
    };

    //varibles para los inputs de busqueda
    const searchTerm = ref("");
    const showResults = ref(false);
    const loadingSearch = ref(true);
    const resultSearch = ref([]);
    const resultLength = ref(false);
    // esta funcion sirve para buscar usuario por nombre
    const search = async () => {
      if (dataForm.value.idStore === 0) {
        errorActions(
          "Por favor selecciona una sucursal para poder buscar al usuario"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      loadingSearch.value = true;
      showResults.value = true;
      resultLength.value = false;
      const response = await axios
        .get(
          `usuarios/?page=1&per_page=200&search=${dataForm.value.search}&idstore=${dataForm.value.idStore}`,
          {}
        )
        .catch((error) => errorActions(error));

      if (response.data.data.length > 0) {
        resultLength.value = true;
      }
      resultSearch.value = await response.data.data;
      loadingSearch.value = false;
    };
    //esta funcion sirve para seleccionar al usuario por su id y se almacena en dataForm.idUser
    const changeUserID = async (id, fullName) => {
      dataForm.value.idUser = await id;
      dataForm.value.search = await fullName;
      showResults.value = false;
    };

    //funcion para validar pago
    const validatePayment = () => {
      const discount = parseFloat(dataForm.value.baseSalary) *  percentage(dataForm.value.discount)
      const payment = parseFloat(dataForm.value.baseSalary) - discount
      const householdAllowance = payment * percentage(dataForm.value.householdAllowance)
      const totalPayment = payment + householdAllowance 
      dataForm.value.amounthPayment = totalPayment
    }

    const insert = async () => {
      if (dataForm.value.idUser === 0) {
        errorActions(
          "Selecciona un <strong>personal</strong> y  vuelve a intentarlo"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.idStore === 0) {
        errorActions(
          "Selecciona un <strong>personal</strong> y  vuelve a intentarlo"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.amounthPayment <= 50) {
        errorActions(
          "Por favor ingresa un sueldo minimo segun tu país para el  <strong>personal</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "contrataciones",
          {
            idUser: dataForm.value.idUser,
            membershipType: dataForm.value.membershipType,
            discount: dataForm.value.discount,
            householdAllowance: dataForm.value.householdAllowance,
            baseSalary: dataForm.value.baseSalary,
            amounthPayment: dataForm.value.amounthPayment,
            overtimePay: dataForm.value.overtimePay,
            accumulatedOvertime: dataForm.value.accumulatedOvertime,
            commission: dataForm.value.commission,
            isMarker: dataForm.value.isMarker?1:0,
            barCode: dataForm.value.barCode,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord("El pago del personal "+dataForm.value.search);

        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        props.getdata();
        dataForm.value.idUser = 0;
        dataForm.value.membershipType = "AFP CRECER";
        dataForm.value.discount = 0;
        dataForm.value.householdAllowance = 0;
        dataForm.value.baseSalary = 0;
        dataForm.value.amounthPayment = 0;
        dataForm.value.overtimePay = 0;
        dataForm.value.accumulatedOvertime = 0;
        dataForm.value.commission = 0;
        dataForm.value.isMarker = false;
        dataForm.value.barCode = "";
        spinner.value = false; //dejando activo al boton
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
        spinner.value = false; //dejando activo al boton
      } else if (data.msg === false) {
        errorActions(
          `Lo sentimos, el personal <strong>${dataForm.value.search}</strong> ya tiene configurado su pago`
        );
        spinner.value = false; //dejando activo al boton
      } else {
        errorActions(
          "Lo sentimos, no pudimos registrar la configuración del pago del personal " +
            dataForm.value.search
        );
        spinner.value = false; //dejando activo al boton
      }
    };
    
    onBeforeMount(async ()=> {
      dataForm.value.idStore = await idLocalStore()
    })
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      searchTerm,
      showResults,
      search,
      loadingSearch,
      resultSearch,
      changeUserID,
      getSelectStore,
      resultLength,
      validatePayment
    };
  },
};
</script>
