<template>
  <nav class="min-w-full h-screen bg-sky-950 overflow-hidden sticky top-0">
    <div class="h-screen">
      <LoadingNavCompany v-if="loading" />
      <div class="flex py-2 h-[3.5rem] px-4 bg-sky-950" v-else>
        <div
          class="col-span-1 bg-sky-950 h-11 w-11 flex items-center justify-center rounded-full overflow-hidden flex-shrink-0"
        >
          <div class="h-11 w-11 flex items-center justify-center flex-shrink-0">
            <img
              src="../../../public/img/profiles/default.png"
              v-if="dataCompany.logo === null"
              alt=""
            />
            <img :src="URL_RESOURCE + dataCompany.logo" v-else alt="" />
          </div>
        </div>
        <div
          class="col-span-11 text-white font-bold ml-2 mt-0 mb-0 small-hidden uppercase flex-wrap md:flex-nowrap"
        >
          <span class="truncate overflow-hidden w-[150px] block">{{
            dataCompany.company
          }}</span>
          <span
            class="flex items-center gap-1 text-[13px] bg-sky-900 w-[150px] truncate overflow-hidden text-orange-400 font-semibold px-2 rounded-lg"
          >
            <span
              class="w-2 h-2 rounded-full bg-green-400 block animate-pulse"
            ></span>
            {{ dataCompany.store }}
          </span>
        </div>
      </div>
      <div class="overflow-y-scroll h-[91.5vh] scroll-nav pt-5">
        <ul class="text-blue-100 text-sm" @click="toggleMenu">
          <li>
            <router-link
              to="/"
              class="bg-transparent hover:bg-sky-900 transition-colors flex py-3 px-4 items-center small-center"
            >
              <font-awesome-icon icon="fa-solid fa-gauge" class="w-5 h-5" />
              <span class="ml-2 small-hidden"> Dashboard </span>
            </router-link>
          </li>
          <GetSystemModules />
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import GetSystemModules from "@/components/SystemModules/GetSystemModules.vue";
import LoadingNavCompany from "@/components/Loadings/LoadingNavCompany.vue";
import { onMounted, ref } from "vue";
import axios from "../../api";
import { errorActions } from "@/alerts";
export default {
  name: "NavComponent",
  components: {
    GetSystemModules,
    LoadingNavCompany,
  },
  setup() {
    const loading = ref(true);
    const dataCompany = ref({
      company: "",
      store: "",
      logo: null,
    });
    //dominio de api para traer los recursos de la empresa
    const URL_RESOURCE = process.env.VUE_APP_API_URL_RESOURCE;

    onMounted(async () => {
      loading.value = true;
      if (localStorage.getItem("access")) {
        const dataUser = JSON.parse(localStorage.getItem("access"));
        const responseStore = await axios
          .get("sucursales/" + dataUser.user.store)
          .catch((error) => {
            errorActions(error);
          });
        if (responseStore.data.status === "Expired token") {
          localStorage.removeItem("access");
          localStorage.removeItem("modules");
          localStorage.removeItem("boxState");
          window.location.href = "/login";
        }
        const responseCompany = await axios
          .get("empresa/" + responseStore.data.idempresa)
          .catch((error) => {
            errorActions(error);
          });
        dataCompany.value.store = await responseStore.data.razon_social;
        dataCompany.value.company = await responseCompany.data.razon_social;
        dataCompany.value.logo = await responseCompany.data.logo;
      }

      loading.value = false;
    });

    const toggleMenu = (e) => {
      if (
        e.target.tagName === "SPAN" &&
        e.target.classList.contains("toggle")
      ) {
        e.target.parentNode.children[1].classList.toggle("active");
        e.target.classList.toggle("active");
      }
      if (e.target.tagName === "SPAN" && e.target.classList.contains("ml-2")) {
        e.target.parentNode.parentNode.parentNode.children[1].classList.toggle(
          "active"
        );
        e.target.parentNode.parentNode.classList.toggle("active");
      }
    };
    return {
      toggleMenu,
      loading,
      dataCompany,
      URL_RESOURCE,
    };
  },
};
</script>

<style scoped>
.scroll-nav::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}
.scroll-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 6px var(--purple); */
  background-color: rgba(0, 0, 0, 0.068);
}
.scroll-nav::-webkit-scrollbar-track {
  background-color: transparent;
}
.scroll-nav::-webkit-scrollbar-thumb:hover,
.scroll-nav::-webkit-scrollbar-thumb:active {
  background: #93c5fd;
}
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
