<template>
  <div>
    <ProfileUser />
    <div class="grid lg:grid-cols-12 gap-4">
      <!-- <div class="lg:col-span-8">
        
      </div> -->
    </div>
  </div>
</template>

<script>
import ProfileUser from "@/components/Users/Main/Profile/ProfileUser.vue";
import { useRoute } from "vue-router";
import { onMounted } from "vue";
export default {
  name: "MyProfile",
  components: {
    ProfileUser,
  },
  setup() {
    const route = useRoute();

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
