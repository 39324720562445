<template>
  <div
    class="py-3 bg-blue-50 px-3 rounded-md border border-blue-100 shadow-sm shadow-blue-100"
    id="containerSearchAppDelivery"
  >
    <div>
      <div class="w-full md:w-auto mt-3 md:mt-0">
        <span class="text-sm font-semibold text-blue-500 pb-2 block">
          Apps de delivery
        </span>
        <div class="relative md:min-w-[260px] lg:min-w-[320px]">
          <div class="flex items-center relative text-gray-600">
            <div class="absolute top-0 left-3 w-6 h-full flex items-center">
              <font-awesome-icon icon="fa-solid fa-search" class="w-4 h-4" />
            </div>
            <input
              type="search"
              v-model="dataForm.searchInput"
              class="border-2 pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50 focus:border-blue-500"
              placeholder="Buscar por descripción de la app"
              @keydown.enter="getData"
              @keydown.enter.prevent
              autocomplete="off"
            />
          </div>
          <div
            v-if="showAppDelivery"
            class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
          >
            <div v-if="loadingSearch">
              <div class="px-3">
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="!dataLength"
                class="px-3 py-1 bg-gray-50 text-gray-400"
                @click="showAppDelivery = false"
              >
                <strong>{{ dataForm.searchInput }}</strong> no existe en nuestra
                base de datos
              </div>
              <div
                v-else
                v-for="(result, i) in dataResult"
                :key="i"
                class="odd:bg-white even:bg-slate-50"
                @blur="showAppDelivery = false"
              >
                <div
                  class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                >
                  {{ result.descripcion }}
                  <span
                    class="px-2 py-1 rounded-sm bg-blue-50 text-blue-500 text-xs font-semibold"
                  >
                    comisíon: {{ result.comision }} %
                  </span>
                  <button
                    class="bg-blue-500 text-xs text-white px-2 py-1 ml-3 rounded-md hover:bg-blue-600"
                    @click="
                      selectAppDelivery({
                        idApp: result.id,
                        commission: parseFloat(result.comision),
                        description: result.descripcion,
                      })
                    "
                  >
                    Aplicar
                  </button>
                  <button
                    class="bg-red-500 text-xs text-white px-2 py-1 ml-3 rounded-md hover:bg-red-600"
                    @click="showAppDelivery = false"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorActions } from "@/alerts";
import axios from "../../../api";
import { onMounted, ref } from "vue";
import { observeElement } from "@/observer";
export default {
  name: "SearchCustomer",
  emits: ["send-data"],
  setup(_, { emit }) {
    const dataForm = ref({
      idApp: 0,
      description: null,
      commission: 0,
      searchInput: "",
    });

    onMounted(() => {
      observeElement("#containerSearchAppDelivery");
    });

    //Funcion para cargar ventas temporales
    const showAppDelivery = ref(false);
    const loadingSearch = ref(true);
    const dataResult = ref([]);
    const dataLength = ref(false);
    const getData = async () => {
      if (dataForm.value.searchInput.length <= 0) {
        errorActions(
          "Por favor ingresa el<strong> nombre de la APP de delivery</strong>"
        );
        return;
      }

      try {
        loadingSearch.value = true;
        showAppDelivery.value = true;
        dataLength.value = false;
        const response = await axios
          .get(
            `delivery-app/?page=1&per_page=10&search=${dataForm.value.searchInput}`
          )
          .catch((error) => errorActions(error));

        const allData = await response.data.data;
        const activeData = allData.filter((item) => item.estado === 1);

        dataResult.value = activeData;

        if (dataResult.value.length > 0) dataLength.value = true;

        loadingSearch.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar la APP de delivery debido a que hemos tenido unos inconvenientes"
        );
      }
    };
    const selectAppDelivery = async (data) => {
      dataForm.value.idApp = data.idApp;
      dataForm.value.description = data.description;
      dataForm.value.commission = data.commission;
      dataForm.value.searchInput = data.description;
      showAppDelivery.value = false;
      emit("send-data", dataForm.value);
    };
    return {
      showAppDelivery,
      loadingSearch,
      dataResult,
      dataLength,
      getData,
      dataForm,
      selectAppDelivery,
    };
  },
};
</script>
