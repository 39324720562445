<template>
  <div>
    <h2 class="font-bold text-gray-700">Listado de lotes</h2>
    <MainLots />
    <p class="bg-orange-50 text-orange-500 text-sm px-3 py-3 rounded-md border border-orange-100 mt-3">
        Si el  lote ya venció o el lote de productos ya se vendio puede marcar ese lote como vendido, presionando el boton <code class="bg-orange-100">Marcar vendido</code> ubicado en la fila del producto.
    </p>
  </div>
</template>

<script>
import MainLots from "@/components/Store/BatchesOfProducts/MainLots.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "LotsData",
  components: {
    MainLots,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
