/**
 * @param days El numero de días a sumar
 */
export default (days = 0) => {
    // Get the current date
    const currentDate = new Date();
    
    // Add the specified number of days
    currentDate.setDate(currentDate.getDate() + parseInt(days));
    
    // Format the date to "YYYY-MM-DD"
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
};
