<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar datos del comprobante
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Serie
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.serie"
                placeholder="Serie..."
                @keydown.enter.prevent
                maxlength="4"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de inicio
              </span>
              <input
                type="text"
                maxlength="8"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 -moz-appearance-none -webkit-appearance-none appearance-none"
                v-model="dataForm.numberStart"
                placeholder="N° de inicio..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° actual
              </span>
              <input
                type="text"
                maxlength="8"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-green-500 focus:border-blue-500 -moz-appearance-none -webkit-appearance-none appearance-none"
                v-model="dataForm.numberCurrent"
                placeholder="N° actual..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° final
              </span>
              <input
                type="text"
                maxlength="8"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-orange-300 focus:border-blue-500 -moz-appearance-none -webkit-appearance-none appearance-none"
                v-model="dataForm.numberFinal"
                placeholder="N° final..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fecha de vencimiento
              </span>
              <input
                type="date"
                maxlength="8"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-gray-300 focus:border-blue-500 -moz-appearance-none -webkit-appearance-none appearance-none"
                v-model="dataForm.vencimiento"
                placeholder="Fecha de vencimiento"
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de envio {{ config.auditingInstitution }}
              </span>
              <input
                type="text"
                maxlength="8"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-gray-300 focus:border-blue-500 -moz-appearance-none -webkit-appearance-none appearance-none"
                v-model="dataForm.numberOfSendingSunat"
                placeholder="N° envio"
                @keydown.enter.prevent
              />
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Trabajando?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.working"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.working === true ? "Si" : "No" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Fijado?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.fixed"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.fixed === true ? "Si" : "No" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[1rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado actual
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.state"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                </span>
              </div>
            </label>
          </div>

          <div class="flex mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";
import config from "/public/apis/config.json";

//componente de loading
import LoadingForms from "@/components/Loadings/LoadingFormsLarge.vue";
export default {
  name: "UpdateVouchers",
  components: {
    LoadingForms,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      serie: "",
      numberStart: "",
      numberCurrent: "",
      numberFinal: "",
      vencimiento: "",
      working: false,
      fixed: false,
      state: false,
      numberOfSendingSunat: 0,
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("comprobantes/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;

      dataForm.value.serie = data.serie;
      dataForm.value.numberStart = data.numero_inicio;
      dataForm.value.vencimiento = data.vencimiento;
      dataForm.value.numberCurrent = data.numero_actual;
      dataForm.value.numberFinal = data.numero_final;
      dataForm.value.working = data.trabajando === 1 ? true : false;
      dataForm.value.fixed = data.fijado === 1 ? true : false;
      dataForm.value.state = data.estado === 1 ? true : false;
      dataForm.value.numberOfSendingSunat = data.numero_de_envio_sunat;

      isLoading.value = true;
    });

    const update = async () => {
      if (dataForm.value.serie <= 2) {
        errorActions(
          "Ingresa un serie para el comprobante, te recomendamos que esa serie sea uno válido"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.numberStart.length <= 7) {
        errorActions(
          "Ingresa un <strong>número de inicio</stron> válido, te recomendamos el número que te a proporcianado la ente fiscalizadora"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.numberCurrent.length <= 7) {
        errorActions(
          "Ingresa un <strong>número actual y válido</stron>  segun el ultimo comprobante generado, te recomendamos el número que te a proporcianado la ente fiscalizadora"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.numberFinal.length <= 7) {
        errorActions(
          "Ingresa un <strong>número final</stron> , te recomendamos que uses número final que te a proporcianado la ente fiscalizadora"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      spinner.value = true;

      const response = await axios
        .put(
          "comprobantes/" + props.id,
          {
            serie: dataForm.value.serie,
            numberStart: dataForm.value.numberStart,
            numberFinal: dataForm.value.numberFinal,
            vencimiento: dataForm.value.vencimiento,
            numberCurrent: dataForm.value.numberCurrent,
            working: dataForm.value.working ? 1 : 0,
            fixed: dataForm.value.fixed ? 1 : 0,
            state: dataForm.value.state ? 1 : 0,
            numberOfSendingSunat: dataForm.value.numberOfSendingSunat,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(dataForm.value.serie);
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `Los datos del comprobante con la serie <trong>${dataForm.value.serie} </strong> que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else if (data.msg === "existSerie") {
        errorActions(
          `Ya existe un comprobante usando la serie <strong>${dataForm.value.serie}</strong>`
        );
        spinner.value = false; //dejando activo al boton
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar el comprobante con la serie  <trong>${dataForm.value.serie} </strong>`
        );
      }

      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
      config,
    };
  },
};
</script>
