<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] max-w-[95vw] md:min-w-[40vw] md:max-w-[50vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Verificar estado de Garantía
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div>
          <div class="flex items-center gap-1">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.serie"
              placeholder="SERIE DEL PRODUCTO"
                @keydown.enter="search"
            />
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="search"
            >
              Consultar
            </button>
          </div>
          <div
            v-if="loading"
            class="bg-slate-200 h-40 text-lg rounded-md flex items-center justify-center text-slate-400 animate-pulse mt-10"
          >
            Cargando...
          </div>
          <div v-else>
            <div
              class="mt-4 md:flex md:gap-6"
              v-show="'nombre_producto' in registers"
            >
              <div class="w-32 h-32 rounded-md border shadow-md">
                <img
                  src="@/assets/images/defaults/image-not-found.png"
                  :alt="registers.nombre_producto"
                  v-if="registers.poster === null"
                  class="flex-shrink-0"
                />
                <img
                  :src="APIURL + registers.poster"
                  :alt="registers.nombre_producto"
                  class="flex-shrink-0"
                  v-else
                />
              </div>
              <div>
                <div class="max-w-[400px] text-slate-700 text-sm">
                  <h2 class="font-bold text-lg uppercase">
                    {{ registers.nombre_producto }}
                  </h2>
                  <div class="py-1">
                    <strong>P. vendido:</strong> {{ money() }}
                    {{ format(registers.precio_de_venta) }}
                  </div>
                  <div class="py-1">
                    <strong>Serie:</strong> {{ registers.product_serie }}
                  </div>
                  <div class="py-1">
                    <strong>Expiración:</strong> {{ registers.vencimiento }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="registers.length <= 0"
              class="text-slate-300 font-semibold text-2xl text-center mt-4"
            >
              Sin datos
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorActions, warningActions } from "@/alerts";
import { ref } from "vue";
import { money, format } from "@/publicjs/money";

import { observer } from "@/observer";
import axios from "@/api";
export default {
  name: "CheckWarranty",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }
    const dataForm = ref({
      serie: "",
    });
    const loading = ref(false);
    const registers = ref([]);
    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;
    const search = async () => {
      if (dataForm.value.serie.trim().length <= 3) {
        warningActions(
          "Los sentimos no pudimos realizar tu busqueda debido a que no has introducido la serie del equipo de una forma correcta"
        );
        loading.value = false;
        return [];
      }
      loading.value = true;
      const response = await axios
        .get(`vencimiento-garantia/?serie=${dataForm.value.serie}`)
        .catch((error) => errorActions(error));

      const data = await response.data;
      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        loading.value = false;
        return [];
      }
      registers.value = data;
      loading.value = false;
    };

    return {
      isActive,
      dataForm,
      search,
      registers,
      loading,
      APIURL,
      money,
      format,
    };
  },
};
</script>
