<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700">Configuración de horarios</h2>
    <MainWorkSchedules />
  </div>
</template>

<script>
import MainWorkSchedules from "@/components/Users/WorkSchedules/MainWorkSchedules.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from '@/hisAcces';
export default {
  name: "WorkSchedules",
  components: {
    MainWorkSchedules,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
