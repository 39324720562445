<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div class="bg-white rounded-lg overflow-hidden shadow-xl z-50">
        <h2 class="text-gray-700 font-bold text-md px-5 py-4 mb-0 mt-0">
          Actualizar datos del submódulo
        </h2>
        <form class="w-full px-5 pb-5" @submit.prevent>
          <label for="modules" class="mb-4 block">
            <span class="text-sm text-gray-600 mb-3 block">
              Descripción del módulo
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              v-model="descriptionsData"
              placeholder="Descripción..."
            />
          </label>

          <label for="iconmodule" class="mb-3 block">
            <span class="text-sm text-gray-600 mb-1 block">Ícono</span>
            <select
              class="block px-4 py-2 pr-8 leading-tight text-gray-600 bg-white border rounded appearance-none focus:outline-none focus:bg-white w-full"
              v-model="iconMenuData"
            >
              <option
                v-for="(listIcon, i) in listIcons"
                :value="listIcon.iconName"
                class="bg-white text-gray-600 mb-2 leading-7"
                :key="i"
              >
                {{ listIcon.name }}
              </option>
            </select>
          </label>
          <div class="flex">
            <button
              class="bg-red-500 text-white px-2 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-red-600 active:scale-95 w-full mt-3"
              @click="isActive"
              type="button"
            >
              Cancelar
            </button>
            <button
              class="bg-blue-500 text-white px-2 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 w-full mt-3 ml-3"
              @click="updateData"
            >
              Modificar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import axios from "../../api";
import { confirmationOfUpdate, errorActions } from "../../alerts.js";
// SweetAlert2 CSS
export default {
  name: "UpdateModules",
  props: {
    id: {
      type: Number,
      required: true,
    },
    descriptions: {
      type: String,
      required: true,
    },
    iconMenu: {
      type: String,
      required: true,
    },
    activeModalUpdate: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModalUpdate(true);
    }
    //variables
    const idData = ref(props.id);
    const descriptionsData = ref(props.descriptions);
    const iconMenuData = ref(props.iconMenu);

    const updateData = async () => {
      if (idData.value.length < 5) {
        errorActions(
          "Lo sentimos, no podemos Modificar el Módulo del sistema, escribe una longitud de caracteres mayor a 4"
        );
        return;
      }
      const response = await axios
        .put(
          "modulos_del_sistema/" + idData.value,
          {
            descripcion: descriptionsData.value,
            icono_del_menu: iconMenuData.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = response.data;
      if (data.msg === true) {
        confirmationOfUpdate(descriptionsData.value);
        descriptionsData.value = "";
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          "Lo sentimos no pudimos actualizar el registro del módulo del sistema"
        );
      }
      await props.getdata();
      await props.activeModalUpdate(false);
    };

    const listIcons = ref([]);
    onMounted(async () => {
      const response = await fetch("/apis/icons.json");
      listIcons.value = await response.json();
    });

    return {
      updateData,
      idData,
      descriptionsData,
      isActive,
      iconMenuData,
      listIcons,
    };
  },
};
</script>
