<template>
  <div>
    <MainAccountsReceivable />
  </div>
</template>

<script>
import MainAccountsReceivable from "@/components/Sales/AccountsReceivable/MainAccountsReceivable.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "AccountsPayable",
  components: {
    MainAccountsReceivable,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
