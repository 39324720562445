<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div class="bg-white rounded-lg overflow-hidden shadow-xl z-50">
        <h2 class="text-gray-700 font-bold text-md px-5 py-4 mb-0 mt-0">
          Registro de tipo de documentos
        </h2>
        <form class="w-full px-5 pb-5" @submit.prevent>
          <label class="block mb-4">
            <span class="text-sm text-gray-600 mb-1 block"> Descripción </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              v-model="dataForm.description"
              placeholder="Descripción..."
            />
          </label>
          <label class="block mb-4">
            <span class="text-sm text-gray-600 mb-1 block">
              Tipo de envio
            </span>
            <input
              type="number"
              min="1"
              max="6"
              maxlength="1"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              v-model="dataForm.typeSend"
              placeholder="Tipo de envio..."
            />
          </label>

          <div class="flex">
            <button
              class="bg-red-500 text-white px-2 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-red-600 active:scale-95 w-full mt-3"
              @click="isActive"
              type="button"
            >
              Cancelar
            </button>
            <button
              class="bg-blue-500 text-white px-2 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 w-full mt-3 ml-3"
              @click="insert"
            >
              Registrar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import axios from "../../../api";
export default {
  name: "RegisterTypeOfDocuments",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const dataForm = ref({
      description: "",
      typeSend: "",
    });

    const insert = async () => {
      if (
        dataForm.value.description.length <= 1 ||
        dataForm.value.description === undefined
      ) {
        errorActions(
          "Lo sentimos, no podemos registrar el tipo de documento, Ingresa una descripción válida"
        );
        return;
      }
      if (
        dataForm.value.typeSend.length <= 1 ||
        dataForm.value.typeSend === undefined
      ) {
        errorActions(
          "Lo sentimos, no podemos registrar el tipo de documento, Ingresa un tipo de envio correcto segun tu país"
        );
        return;
      }
      const response = await axios
        .post(
          "tipo_de_documentos",
          {
            description: dataForm.value.description,
            typeSend: dataForm.value.typeSend,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(dataForm.value.description);
        props.getdata();
        dataForm.value.description = "";
        dataForm.value.typeSend = "";
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions("Lo sentimos no pudimos realizar el tipo de documento");
      }
    };
    return {
      isActive,
      dataForm,
      insert,
    };
  },
};
</script>
