<template>
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center py-8 justify-center min-h-screen">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div
          class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
        >
          <div class="flex justify-between items-center mb-6">
            <h2 class="text-xl font-medium text-gray-800 dark:text-white">
              Registrar descuento global
            </h2>
            <button
              @click="isActive"
              class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
            >
              <svg class="w-5 h-5">
                <use href="../../../assets/svg/icon.svg#cancel" />
              </svg>
            </button>
          </div>
          <form class="w-full space-y-6" @submit.prevent>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto (%)
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                max="100"
                min="1"
                v-model="dataForm.amount"
                placeholder="Monto (%)..."
                @keydown.enter.prevent
              />
            </label>
  
            <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                @click="insert"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Registrando Información
                </div>
                <span v-else>Registrar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import { confirmationOfRecord, errorActions } from "@/alerts";
  import { observer } from "@/observer";
  import axios from "../../../api";
  
  export default {
    name: "RegisterDiscountGlobal",
    props: {
      activeModal: {
        type: Function,
        required: true,
      },
      getdata: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      function isActive() {
        props.activeModal(true);
      }
  
      const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información
  
      const dataForm = ref({
        amount: 0,
      });
  
      const insert = async () => {
        if (dataForm.value.amount <= 0) {
          errorActions("Ingresa el monto de <strong>descuento global</strong>");
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          return;
        }
        spinner.value = true;
        const response = await axios
          .post(
            "descuento-global",
            {
              amount: dataForm.value.amount,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch(function (error) {
            errorActions(error);
          });
        const data = await response.data;
        if (data.msg === true) {
          confirmationOfRecord(
            `El descuento global de  <strong>${dataForm.value.amount}% </strong>`
          );
          props.getdata();
          dataForm.value.amount = 0;
          spinner.value = false; //dejando activo al boton
        } else if (data.msg === "Request failed with status code 500") {
          errorActions("Request failed with status code 500");
          spinner.value = false; //dejando activo al boton
        } else if (data.msg === "exist") {
          errorActions("Lo sentimos, no pudimos registrar el <strong>descuento global</strong> debido a que ya has registrado una con anterioridad");
          spinner.value = false; //dejando activo al boton
        } else {
          errorActions(
            `Lo sentimos, no pudimos registrar el <strong>descuento global</strong>`
          );
          spinner.value = false; //dejando activo al boton
        }
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      };
      return {
        isActive,
        dataForm,
        insert,
        spinner,
      };
    },
  };
  </script>
  