<template>
  <div class="grid md:grid-cols-4 gap-6">
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
  </div>
  <div class="hidden md:grid md:grid-cols-4 gap-6 mt-6">
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
  </div>
  <div class="hidden md:grid md:grid-cols-4 gap-6 mt-6">
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
    <div
      class="bg-white text-gray-500 shadow-sm shadow-gray-50 rounded-md px-4 py-4 border border-gray-100 flex justify-between gap-4"
    >
      <div class="w-full">
        <span class="text-sm block mb-3 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-xl block font-extrabold text-gray-600 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
        <span
          class="text-sm block mt-3 text-gray-400 animate-pulse bg-gray-100 rounded-md"
          >&nbsp;</span
        >
      </div>
      <div class="w-10 text-gray-500 flex justify-end">
        <div
          class="w-9 h-9 flex items-center justify-center rounded-full animate-pulse bg-gray-100"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingDashboardCards",
};
</script>
