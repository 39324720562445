<template>
  <form
    class="w-full grid md:grid-cols-3 items-end mt-6 gap-2 flex-wrap"
    @submit.prevent
  >
    <label for="modules" class="w-full">
      <span class="text-sm text-gray-600 mb-1 block">Descripción del módulo</span>
      <input
        type="text"
        class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
        v-model="description"
        :class="{ 'border-red-500': typed }"
        @keyup="description.length < 5 ? (typed = true) : (typed = false)"
        placeholder="Descripción del módulo del sistema"
      />
    </label>
    <label for="iconmodule" class="w-full">
      <span class="text-sm text-gray-600 mb-1 block">Ícono principal</span>
      <select
        class="block px-4 py-2 pr-8 leading-tight text-gray-600 bg-white border rounded appearance-none focus:outline-none focus:bg-white w-full"
        v-model="iconMenu"
      >
        <option
          v-for="(listIcon, i) in listIcons"
          :value="listIcon.iconName"
          class="bg-white text-gray-600 mb-2 leading-7"
          :key="i"
        >
          {{ listIcon.name }}
        </option>
      </select>
    </label>
    <button
      class="bg-blue-500 text-white px-2 py-2 md:ml-3 w-full font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 lg:w-40"
      @click="insert"
    >
      Registrar
    </button>
  </form>
</template>
<script>
import { onMounted, ref } from "vue";
import axios from "../../api";
import { confirmationOfRecord, errorActions } from "../../alerts.js";
// SweetAlert2 CSS
export default {
  name: "RegisterSystemModules",
  props: {
    getdata: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const description = ref("");
    const iconMenu = ref("fa-solid fa-cart-plus");
    const typed = ref(false);

    const insert = async () => {
      if (description.value.length < 5) {
        errorActions(
          "Lo sentimos, no podemos registrar el Módulo del sistema, escribe una longitud de caracteres mayor a 4"
        );
        return;
      }
      const response = await axios
        .post("modulos_del_sistema",
          {
            descripcion: description.value,
            icono_del_menu: iconMenu.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(description.value);
        description.value = "";
        props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          "Lo sentimos no pudimos realizar el registro del módulo del sistema"
        );
      }
    };
    const listIcons = ref([]);
    onMounted(async () => {
      const response = await fetch("/apis/icons.json");
      listIcons.value = await response.json();
    });

    return {
      description,
      insert,
      typed,
      iconMenu,
      listIcons,
    };
  },
};
</script>

<style>
.swal2-title {
  font-size: 1.2rem !important;
}
.swal2-html-container {
  font-size: 0.9rem !important;
}
.swal2-container.swal2-center > .swal2-popup {
  width: 300px !important;
}
</style>
