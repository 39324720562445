<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contendRegister">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de prestamos al personal
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 gap-6">
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Usuarios <span class="text-red-300">*</span>
              </span>
              <SearchUsers @send-data="getUser" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.amountLoans"
                placeholder="Monto de prestamo..."
                @keydown.enter.prevent
                min="1"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Interes <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.interest"
                placeholder="Interes..."
                @keydown.enter.prevent
                @keydown.enter="calculateAmountTotal"
                @blur="calculateAmountTotal"
                min="0"
              />
            </label>
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Total de prestamos
              </span>
              <input
                type="number"
                class="py-2 px-2 font-semibold text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.amountTotal"
                placeholder="Total de prestamos..."
                @keydown.enter.prevent
                readonly
                min="1"
              />
            </label>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="@/assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "@/api";
import { idLocalStore } from "@/publicjs/localStorage";
import SearchUsers from "@/components/Users/StaffLoans/SearchUsers.vue";
import { percentage } from "@/publicjs/convertToPercentage";

export default {
  name: "RegisterStaffLoans",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    SearchUsers,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      userName: "",
      amountLoans: 0,
      interest: 0,
      amountTotal: 0,
      pending: 0,
    });

    const insert = async () => {
      if (dataForm.value.idUser <= 0) {
        errorActions(
          "Selecciona un   <strong>usuario</strong> para poder realizar el prestamo"
        );
        return;
      }
      if (dataForm.value.amountTotal <= 0) {
        errorActions(
          "Ingresa un monto de prestamo <strong> mayor a 0</strong>"
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "prestamos-del-personal",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            amountLoans: dataForm.value.amountLoans,
            interest: dataForm.value.interest,
            amountTotal: dataForm.value.amountTotal,
            pending: dataForm.value.pending,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El prestamo del usuario  <strong>${dataForm.value.userName}</strong>`
        );
        dataForm.value.idUser = 0;
        dataForm.value.amountLoans = 0;
        dataForm.value.amountTotal = 0;
        dataForm.value.pending = 0;
        dataForm.value.interest = 0;
        props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el prestamo del usuario <strong>${dataForm.value.userName}</strong>`
        );
      }

      spinner.value = false;
    };

    onMounted(async () => {
      observeElement("#contendRegister");
      dataForm.value.idStore = await idLocalStore();
    });
    const getUser = (data) => {
      dataForm.value.idUser = data.id;
      dataForm.value.userName = data.userName;
    };
    const calculateAmountTotal = () => {
      if (
        dataForm.value.interest === null ||
        dataForm.value.interest === "" ||
        dataForm.value.interest === 0
      ) {
        dataForm.value.interest = 0;

        dataForm.value.amountTotal = dataForm.value.amountLoans;
        dataForm.value.pending = dataForm.value.amountLoans;
      } else {
        const newTotal =
          dataForm.value.amountLoans * percentage(dataForm.value.interest);
        dataForm.value.amountTotal = parseFloat(
          dataForm.value.amountLoans + newTotal
        ).toFixed(2);
        dataForm.value.pending = parseFloat(
          dataForm.value.amountLoans + newTotal
        ).toFixed(2);
      }
    };

    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getUser,
      calculateAmountTotal,
    };
  },
};
</script>
