<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[80vw] lg:min-w-[900px] xl:min-w-[1024px]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de usuarios
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Nombres y apellidos
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.fullName"
                placeholder="Nombres y apellidos..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Direccion
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.address"
                placeholder="Dirección..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Teléfono / Celular
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.cellPhone"
                placeholder="Teléfono / Celular..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de documentos
              </span>
              <TypeOfDocuments @send-data="getSelecTypeOfDocuments" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de documento
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberDocument"
                placeholder="N° de documento.."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sucursales
              </span>
              <Stores @send-data="getSelectStore" :selectOption="dataForm.idStore" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de usuarios
              </span>
              <TypeOfUsers @send-data="getSelecTypeOfUsers" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Correo electrónico
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.email"
                placeholder="Correo electrónico..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Contraseña
              </span>
              <input
                type="password"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.password"
                placeholder="Descripción..."
              />
            </label>
            <div class="w-full">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Foto de perfil
              </span>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar imagen</span
                    >
                    PNG o JPG
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  accept=".png,.jpg"
                  ref="avatar"
                  @change="selectFile"
                />
              </label>
            </div>

            <label class="flex flex-col h-6 mr-5 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado del usuario
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.state"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                </span>
              </div>
            </label>
            <label class="flex flex-col h-6 mr-5 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Es mensajero?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  @change="isAMessenger = !isAMessenger"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ isAMessenger === true ? "SI" : "NO" }}
                </span>
              </div>
            </label>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";

//componentes publicos
import Stores from "@/components/PublicComponents/Stores.vue";
import TypeOfUsers from "@/components/PublicComponents/TypeOfUsers.vue";
import TypeOfDocuments from "@/components/PublicComponents/TypeOfDocuments.vue";
import { idLocalStore } from '@/publicjs/localStorage';
export default {
  name: "RegisterUsers",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    Stores,
    TypeOfUsers,
    TypeOfDocuments,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      idStore: null,
      idTypeOfUser: null,
      idTypeOfDocument: null,
      fullName: "",
      address: "",
      email: "",
      numberDocument: "",
      cellPhone: "",
      password: "",
      state: true,
    });

    //logo del usuario
    const avatar = ref(null);
    const isAMessenger = ref(false)

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectStore = (data) => {
      dataForm.value.idStore = data;
    };
    //recepcion de datos desde el componente publico, todos los tipos de usuarios
    const getSelecTypeOfUsers = (data) => {
      dataForm.value.idTypeOfUser = data;
    };
    //recepcion de datos desde el componente publico, todos los tipos de documentos
    const getSelecTypeOfDocuments = (data) => {
      dataForm.value.idTypeOfDocument = data;
    };

    // Selecciona el archivo
    const selectFile = async () => {
      await avatar.value.files;
    };

    const insert = async () => {
      if (dataForm.value.fullName.length <= 3) {
        errorActions("Ingresa un nombre de usuario válido");
        return;
      }
      if (dataForm.value.idTypeOfDocument === null) {
        console.log(dataForm.value.idTypeOfDocument);
        errorActions("Selecciona el tipo de documento del usuario");
        return;
      }
      if (
        dataForm.value.numberDocument.length <= 7 ||
        !Number(dataForm.value.numberDocument)
      ) {
        errorActions("Ingresa un numero de documento válido...");
        return;
      }

      if (dataForm.value.idStore === null) {
        errorActions(
          "Selecciona la sucursal a la cual va a pertenecer el usuario"
        );
        return;
      }
      if (dataForm.value.idTypeOfUser === null) {
        errorActions(
          "Selecciona un tipo de usuario o el cargo que tendra este nuevo usuario"
        );
        return;
      }
      if(!isAMessenger.value) {
        if (!validateEmail(dataForm.value.email)) {
        errorActions(
          "Ingresa un correo válido, el correo que ingresas sera con el cual podras acceder al sistema te sugerimos que sea un correo que uses a diario"
        );
        return;
      }
      if (!validatePassword(dataForm.value.password)) {
        errorActions(
          "Ingresa una contraseña más segura. [ Tu contraseña debe de tener Mayúsculas, minúsculas y números ]"
        );
        return;
      }
      }
      
      //dataform de datos

      const formData = new FormData();
      formData.append("idStore", dataForm.value.idStore);
      formData.append("idTypeDocument", dataForm.value.idTypeOfDocument);
      formData.append("idTypeUser", dataForm.value.idTypeOfUser);
      formData.append("fullName", dataForm.value.fullName);
      formData.append("numberDocument", dataForm.value.numberDocument);
      formData.append("address", dataForm.value.address);
      formData.append("email", dataForm.value.email);
      formData.append("password", dataForm.value.password);
      formData.append("cellPhone", dataForm.value.cellPhone);
      formData.append("avatar", avatar.value.files[0]);
      formData.append("state", dataForm.value.state === true ? 1 : 0);
      spinner.value = true;
      const response = await axios
        .post("usuarios", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = await  response.data;
      if (data.msg === true) {
        confirmationOfRecord(dataForm.value.description);

        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        props.getdata();
        dataForm.value.fullName = "";
        dataForm.value.address = "";
        dataForm.value.cellPhone = "";
        dataForm.value.numberDocument = "";
        dataForm.value.email = "";
        dataForm.value.password = "";
        dataForm.value.state = false;
        avatar.value.type = "text";
        avatar.value.type = "file";

        spinner.value = false; //dejando activo al boton
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
        spinner.value = false; //dejando activo al boton
      } else if (data.msg === "errorExtention") {
        errorActions(
          "Por favor ingresa solo archivo de las siguientes extencionaes [ PNG, JPG ]"
        );
        spinner.value = false; //dejando activo al boton
      } else if (data.msg === "there is already an email") {
        errorActions(
          "Ya has registrado este correo con anterioridad, intenta registrando otro"
        );
        spinner.value = false; //dejando activo al boton
      } else {
        errorActions(
          "Lo sentimos no pudimos registrar al usuario " +
            dataForm.value.fullName
        );
        spinner.value = false; //dejando activo al boton
      }
    };

    //funcion para validar una contraseña (Mayusculas, Minisculas y Números )
    const validatePassword = (password) => {
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;

      const hasUppercase = uppercaseRegex.test(password);
      const hasLowercase = lowercaseRegex.test(password);
      const hasNumber = numberRegex.test(password);

      return hasUppercase && hasLowercase && hasNumber;
    };
    //funcion para validar un email ( @, . )
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    onBeforeMount(async ()=> {
      dataForm.value.idStore = await idLocalStore()
    })

    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getSelectStore,
      getSelecTypeOfUsers,
      getSelecTypeOfDocuments,
      selectFile,
      avatar,
      isAMessenger
    };
  },
};
</script>
