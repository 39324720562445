<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[80vw] lg:min-w-[900px] xl:min-w-[1024px]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de horarios del personal
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sucursales
              </span>
              <Stores @send-data="getStores"  :selectOption="dataForm.idStore" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Turnos
              </span>
              <Turns @send-data="getTurns" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Usuarios
              </span>
              <div
                v-if="refreshUser"
                class="py-2 text-sm rounded-lg border-2 px-3 bg-gray-100 text-gray-400"
              >
                Selecciona una sucursal
              </div>
              <Users v-else @send-data="getUsers" :idstore="dataForm.idStore" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Dias de permanencia
              </span>
              <select
                class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none"
                v-model="dataForm.days"
              >
                <option value="LUNES">LUNES</option>
                <option value="MARTES">MARTES</option>
                <option value="MIéRCOLES">MIERCOLES</option>
                <option value="JUEVES">JUEVES</option>
                <option value="VIERNES">VIERNES</option>
                <option value="SáBADO">SABADO</option>
                <option value="DOMINGO">DOMINGO</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Hora de ingreso
              </span>
              <input
                type="time"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-green-200 focus:border-blue-500"
                v-model="dataForm.entryTime"
                placeholder="Nombres y apellidos..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tolerancia de ingreso
              </span>
              <input
                type="time"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-green-200 focus:border-blue-500"
                v-model="dataForm.toleranceEntryTime"
                placeholder="Nombres y apellidos..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Hora de salida
              </span>
              <input
                type="time"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-red-200 focus:border-blue-500"
                v-model="dataForm.departureTime"
                placeholder="Nombres y apellidos..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Salida máxima
              </span>
              <input
                type="time"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-red-200 focus:border-blue-500"
                v-model="dataForm.toleranceDepartureTime"
                placeholder="Nombres y apellidos..."
              />
            </label>
          </div>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";

import Users from "@/components/PublicComponents/Users.vue";
import Turns from "@/components/PublicComponents/Turns.vue";
import Stores from "@/components/PublicComponents/Stores.vue";
import { idLocalStore } from '@/publicjs/localStorage';

export default {
  name: "RegisterWorkSchedules",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    Users,
    Turns,
    Stores,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      idStore: null,
      idUser: null,
      idTurn: null,
      days: "LUNES",
      entryTime: "07:00:00",
      departureTime: "17:00:00",
      toleranceEntryTime: "07:10:00",
      toleranceDepartureTime: "17:10:00",
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getUsers = async (data) => {
      dataForm.value.idUser = data;
    };
    //recepcion de datos desde el componente publico, todos los tipos de usuarios
    const refreshUser = ref(true); // variable para refresecar el componente
    const getStores = async (data) => {
      refreshUser.value = true;
      dataForm.value.idStore = data;
      await getUsers();
      refreshUser.value = false;
    };
    //recepcion de datos desde el componente publico, todos los tipos de usuarios
    const getTurns = (data) => {
      dataForm.value.idTurn = data;
    };
    
    onBeforeMount(async ()=> {
      refreshUser.value = true;
      dataForm.value.idStore = await idLocalStore()
      refreshUser.value = false;
    })

    const insert = async () => {
      if (dataForm.value.idStore === null) {
        errorActions("Selecciona un sucursal");
        return;
      }
      if (dataForm.value.idUser === null) {
        errorActions("Selecciona un usuario");
        return;
      }
      if (dataForm.value.idTurn === null) {
        errorActions("Selecciona un turno");
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "horarios",
          {
            idUser: dataForm.value.idUser,
            idTurn: dataForm.value.idTurn,
            idStore: dataForm.value.idStore,
            days: dataForm.value.days,
            entryTime: dataForm.value.entryTime,
            departureTime: dataForm.value.departureTime,
            toleranceEntryTime: dataForm.value.toleranceEntryTime,
            toleranceDepartureTime: dataForm.value.toleranceDepartureTime,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(dataForm.value.description);

        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        props.getdata();
        dataForm.value.description = "";
        spinner.value = false; //dejando activo al boton
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
        spinner.value = false; //dejando activo al boton
      } else {
        errorActions(
          "Lo sentimos, no pudimos registrar el horario del usuario "
        );
        spinner.value = false; //dejando activo al boton
      }
    };
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getTurns,
      getStores,
      getUsers,
      refreshUser,
    };
  },
};
</script>
