<template>
  <div>
    <Bar :data="data" :options="options" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
} from "chart.js";
import { Bar } from "vue-chartjs";
import { ref } from "vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export default {
  name: "ChartComponent",
  components: {
    Bar,
  },
  props: {
    datas: {
      type: Object,
      required: true,
    },
    titleChart: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const labels = Object.keys(props.datas);
    const months = Object.keys(props.datas[labels[0]]);

    const datasets = months.map((profits) => ({
      label: profits.toUpperCase(),
      data: labels.map((month) => {
        const value = parseFloat(props.datas[month][profits].replace(",", "")); // Remover comas
        return value;
      }),
    }));

    const data = ref({
      labels: labels,
      datasets: datasets,
    });
    //Configuracion de todas la opciones del gráfico de barra, como titulos, leyendas, etc.
    const options = ref({
      responsive: true,

      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        colors: {
          enabled: true,
        },
        title: {
          display: true,
          text: props.titleChart,
        },
      },
    });
    return {
      data,
      options,
    };
  },
};
</script>
<style scoped>
canvas {
  height: 300px !important;
  width: 100% !important;
}
.chart-container {
  width: 100%;
  height: auto;
}
</style>
