<template>
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center py-8 justify-center min-h-screen">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div
          class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
        >
          <div class="flex justify-between items-center mb-6">
            <h2 class="text-xl font-medium text-gray-800 dark:text-white">
              Registro de proveedores
            </h2>
            <button
              @click="isActive"
              class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
            >
              <svg class="w-5 h-5">
                <use href="../../assets/svg/icon.svg#cancel" />
              </svg>
            </button>
          </div>
          <form class="w-full space-y-6" @submit.prevent>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Razón social <span class="text-red-300">*</span>
                </span>
                <input
                  type="text"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.businessName"
                  placeholder="Razón Social..."
                  @keydown.enter.prevent
                  maxlength="100"
                />
              </label>
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Nombre comercial <span class="text-red-300">*</span>
                </span>
                <input
                  type="text"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.tradename"
                  placeholder="Nombre comercial..."
                  @keydown.enter.prevent
                  maxlength="100"
                />
              </label>
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  N° de documento <span class="text-red-300">*</span>
                </span>
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.numberDocument"
                  placeholder="N° de documento..."
                  @keydown.enter.prevent
                  @keydown.enter="searchForDGII"
                />
              </label>
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Representante legal
                </span>
                <input
                  type="text"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.representative"
                  placeholder="Representante legal..."
                  @keydown.enter.prevent
                  maxlength="100"
                />
              </label>
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Dirección
                </span>
                <input
                  type="text"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.address"
                  placeholder="Dirección..."
                  @keydown.enter.prevent
                  maxlength="100"
                />
              </label>
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Teléfono / Celular
                </span>
                <input
                  type="text"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.phone"
                  placeholder="Teléfono / Celular..."
                  @keydown.enter.prevent
                  maxlength="60"
                />
              </label>
              <label class="block">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Fecha límite de pago
                </span>
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.paymentLimitDate"
                  placeholder="Fecha límite de pagos..."
                  @keydown.enter.prevent
                />
              </label>
  
              <label class="flex flex-col h-6 mr-5 cursor-pointer">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Estado del proveedor
                </span>
                <div
                  class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="sr-only peer"
                    v-model="dataForm.state"
                  />
                  <div
                    class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                  ></div>
                  <span
                    class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                  </span>
                </div>
              </label>
            </div>
  
            <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                @click="insert"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Registrando Información
                </div>
                <span v-else>Registrar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import { confirmationOfRecord, errorActions } from "@/alerts";
  import { observer } from "@/observer";
  import axios from "../../api";
import { dgii } from '@/repository/DGII/dgii';
  
  export default {
    name: "RegisterSuppliers",
    props: {
      activeModal: {
        type: Function,
        required: true,
      },
      getdata: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      function isActive() {
        props.activeModal(true);
      }
  
      const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información
  
      const dataForm = ref({
        businessName: '',
        tradename: "",
        numberDocument: "",
        address: "",
        representative: "",
        phone: "",
        paymentLimitDate: 0,
        state: true,
      });
  
      //recepcion de datos desde el componente publico, todas la sucursales
      const getSelectTypeOfDocuments = (data) => {
        dataForm.value.idTypeOfDocument = data;
      };
  
      const insert = async () => {
        if (dataForm.value.businessName.trim().length <= 3) {
          errorActions("Ingresa la <strong>razon social</strong> del proveedor");
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          return;
        }
        if (dataForm.value.tradename.trim().length <= 3) {
          errorActions(
            "Ingresa el <strong>nombre comercial</strong> del proveedor"
          );
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          return;
        }
        if (dataForm.value.numberDocument <= 100000) {
          errorActions(
            "Ingresa un número de <strong>documento</strong> válido del proveedor"
          );
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          return;
        }
        spinner.value = true;
        const response = await axios
          .post(
            "proveedores",
            {
            businessName: dataForm.value.businessName,
            tradename: dataForm.value.tradename,
            numberDocument: dataForm.value.numberDocument,
            address: dataForm.value.address,
            representative: dataForm.value.representative,
            phone: dataForm.value.phone,
            paymentLimitDate: dataForm.value.paymentLimitDate,
            state: dataForm.value.state ? 1 : 0,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch(function (error) {
            errorActions(error);
          });
        const data = await response.data;
        if (data.msg === true) {
          confirmationOfRecord(
            `El proveedor  <strong>${dataForm.value.businessName}</strong>`
          );
          props.getdata();
          dataForm.value.businessName = "";
          dataForm.value.tradename = "";
          dataForm.value.numberDocument = '';
          dataForm.value.address = "";
          dataForm.value.representative = "";
          dataForm.value.phone = "";
          dataForm.value.paymentLimitDate = 0;
          dataForm.value.state = false;
        } else if (data.msg === "Request failed with status code 500") {
          errorActions("Request failed with status code 500");
        } else if (data.msg === "exist") {
          errorActions(
            `Ya existe un <strong>proveedor</strong> registrado con el número de documento que estas intentando registrar`
          );
        } else {
          errorActions(
            `Lo sentimos, no pudimos registrar la empresa aseguradora <strong>${dataForm.value.names}</strong>`
          );
        }
  
        spinner.value = false; //dejando activo al boton
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      };
      const searchForDGII = async () => {
        const data =await  dgii(dataForm.value.numberDocument)
        if(data !== null) {
          dataForm.value.numberDocument = data.numberDocument
          dataForm.value.businessName = data.bussinesName
          dataForm.value.tradename = data.tradeName
        }
    }
      return {
        isActive,
        dataForm,
        insert,
        spinner,
        getSelectTypeOfDocuments,
        searchForDGII
      };
    },
  };
  </script>
  