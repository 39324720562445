<template>
    <div>
      <h2 class="text-1xl font-bold text-gray-700">
        Mantenimiento de productos
      </h2>
      <MainProducts />
    </div>
  </template>
  
  <script>
  import MainProducts from "@/components/Store/Products/Main/MainProducts.vue";
  import { useRoute } from "vue-router";
  import { onBeforeMount, onMounted } from "vue";
  import { findSubmoduleIdByDescription } from "@/hisAcces";
  export default {
    name: "StoreProducts",
    components: {
        MainProducts,
    },
    setup() {
      const route = useRoute();
      onBeforeMount(() => {
        if (!findSubmoduleIdByDescription(route.path)) {
          window.location.href = "/login";
        }
      });
  
      onMounted(() => {
        document.title =
          route.meta.title + process.env.VUE_APP_TITLE ||
          process.env.VUE_APP_TITLE;
      });
    },
  };
  </script>
  