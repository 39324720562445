import { errorActions } from "@/alerts";
import axios from "@/api";
/**
 *
 * @param {*} access Descripción del acceso especifico del sistema
 * @param {*} idUser Id del usuario la cual sera verificado
 */
export const validateAccessSpecific = async (access, idUser) => {
  const response = await axios
    .get(`accesos-especificos/areas/?search=${access}&iduser=${idUser}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      errorActions(error);
    });
  const data = response.data;
  return data;
};
