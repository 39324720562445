import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { money, format } from "@/publicjs/money"; //? Formateo y obtencion de moneda configurada de la empresa
import { errorActions } from "@/alerts"; //? Alertas de error, etc
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { imageInBase64 } from "@/publicjs/logoCompany"; //? Imagen de base64 de la empresa
import { formatDate, formatDateTime } from "@/publicjs/formatDate"; //? Retorna la fecha y hora actual
import titlesConfig from "/public/apis/config.json"; //? Títulos de configuracion segun pais
import { convertCurrencyToLetters } from "@/publicjs/convertCurrencyToLetters"; //? Convierte moneda a letras
import { paperDimension } from "@/publicjs/paperDimension"; //? Convierte moneda a letras
import axios from "@/api";
import styles from "@/publicjs/styles";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//* Función para imprimir el documento
/**
 * @param {*} idOrder | ID de la ultima orden realizada
 */
export const printTicket = async (idOrder) => {
  //* Poblado de datos de la empresa
  const dataStore = JSON.parse(localStorage.getItem("access"));
  const company = await getCompany(dataStore.user.store);

  //* Cargar la imagen o el logo de la empresa
  let base64Image = await imageInBase64(company.companyLogo);

  //? Obtencion de datos de la orden
  const registers = await order(idOrder);
  try {

    //Desestructurando el ancho y al alto del papel
    const regenerateHeight = parseFloat(270 + registers.detalle.length * 12);
    const { paperWidth, paperHeight } = paperDimension(78, regenerateHeight);

    let docDefinition = null;
    docDefinition = {
      // a string or { width: number, height: number }
      pageSize: { width: paperWidth, height: paperHeight },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "portrait", //cambia a [landscape] para imprimir de forma horizontal

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 200, 10, 10],
      //!encabezado del documento con datos de la empresa, sucursal y datos del comprobante
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            width: "*",
            stack: [
              { image: base64Image, fit: [40, 40] }, // logo
            ],
            alignment: "center",
            margin: [10, 10, 0, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: company.companyName,
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
              },
              {
                text: "Dirección: " + company.companyAddress,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "Tel: " + company.companycellphone,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: company.companySlogan,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text:
                  "Sucursal: ".toUpperCase() +
                  registers.sucursal.razon_social.toUpperCase(),
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
                margin: [0, 10, 0, 0],
              },
              {
                text: "DIR: " + registers.sucursal.direccion.toUpperCase(),
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "TEL: " + registers.sucursal.celular,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
            ],
            alignment: "center",
            margin: [10, 10, 10, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: titlesConfig.titleTypeDocument + company.companyRUC,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: registers.tipo_comprobante.descripcion,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: `${registers.orden_de_compra.serie} ${registers.orden_de_compra.numero_de_comprobante}`,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
            ],
            alignment: "center",
            margin: [10, 0, 10, 0],
          },
          {
            canvas: [
              { type: "rect", x: 10, y: 90, w: pageSize.width - 48, h: 2 },
            ],
            margin: [10, 10], // Margen entre el rectángulo y el contenido de la página
          },
        ];
      },

      content: [
        //? Encabezado del comprobante con el nombre del proveedor
        {
          table: {
            headerRows: 0,
            widths: [66, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Proveedor:",
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text:
                    registers.proveedor.razon_social.length >= 24
                      ? registers.proveedor.razon_social.slice(0, 24).toUpperCase() + "***"
                      : registers.proveedor.razon_social.toUpperCase(),
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [-25, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Dirección:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.proveedor.direccion,
                  border: [false, false, false, false],
                  margin: [-30, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "N° Documento:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.proveedor.ruc,
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Fec. de emision:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: formatDateTime(
                    registers.orden_de_compra.fecha_de_emision
                  ),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
            ],
          },
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: [22, "*", 36, 36, 42], //ancho de la columnas
            body: [
              [
                {
                  text: "Cant.",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: "U.M",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: "SubTotal",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: titlesConfig.iva,
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: "Total",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                  alignment: "right",
                },
              ],
              ...registers.detalle.flatMap((register) => {
                return [
                  [
                    {
                      text: register.cantidad,
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: register.unidad_de_medida,
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: money() + format(register.subtotal),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: money() + format(register.igv),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: money() + format(register.total),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      bold: true,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                      alignment: "right",
                    },
                  ],
                  [
                    {
                      text:
                        register.nombre_producto.length >= 30
                          ? register.nombre_producto.slice(0, 30).toUpperCase() +
                          "***" +
                            " | P.U " +
                            money() +
                            format(register.precio_unitario)
                          : register.nombre_producto.toUpperCase() +
                            " | P.U " +
                            money() +
                            format(register.precio_unitario),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      colSpan: 5,
                      margin: [0, 0, 0, 3],
                      valign: "middle",
                    },
                  ],
                ];
              }), // Datos dinámicos
            ],
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 10, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.black
                : styles.colors.black;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.black
                : styles.colors.black;
            },
          },
        },
        //? Total del comprobante
        {
          table: {
            headerRows: 1,
            widths: ["*", 80],
            body: [
              [
                {
                  text: "SubTotal:",
                  border: [false, true, false, false],
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text:
                    money() + " " + format(registers.orden_de_compra.subtotal),
                  border: [false, true, false, false],
                  margin: [0, 5, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: titlesConfig.iva,
                  border: [false, false, false, false],
                  margin: [0, 2, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + " " + format(registers.orden_de_compra.igv),
                  border: [false, false, false, false],
                  margin: [0, 2, 0, 0],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Total:",
                  border: [false, false, false, false],
                  margin: [0, 2, 0, 5],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: money() + " " + format(registers.orden_de_compra.total),
                  border: [false, false, false, false],
                  margin: [0, 2, 0, 5],
                  valign: "middle",
                  alignment: "right",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: convertCurrencyToLetters(
                    parseFloat(registers.orden_de_compra.total)
                  ).toUpperCase(),
                  border: [false, false, false, true],
                  margin: [-5, 2, 0, 5],
                  valign: "middle",
                  alignment: "left",
                  fontSize: styles.fontSize.ticket.sm,
                  color: styles.colors.black,
                  bold: true,
                  colSpan: 2,
                },
                "",
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.black
                : styles.colors.black;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.black
                : styles.colors.black;
            },
          },

          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 0, 0, 0],
        },
        //? QR del comprobante
        {
          qr:
            registers.orden_de_compra.serie +
            "-" +
            registers.orden_de_compra.numero_de_comprobante,
          fit: 65,
          rowSpan: 4,
          margin: [0, 10, 0, 0],
          border: [true, true, false, true],
          foreground: styles.colors.black,
          background: styles.colors.white,
          alignment: "center",
        },
        //?Titulo de la informacion adicional del comprobante
        {
          text: "Información adicional de la orden de compra".toUpperCase(),
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        //? Informacion adicional del comprobante
        {
          table: {
            headerRows: 1,
            widths: [70, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "FORMA DE PAGO:",
                  border: [false, false, false, false],
                  margin: [0, 5, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                  bold: true,
                },
                {
                  text:
                    registers.orden_de_compra.forma_de_pago !== null
                      ? registers.orden_de_compra.forma_de_pago
                      : "Sin especificar",
                  border: [false, false, false, false],
                  margin: [0, 5, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                },
              ],
              [
                {
                  text: "LUGAR ENTREGA:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0,0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                  bold: true,
                },
                {
                  text:
                    registers.orden_de_compra.lugar_de_entrega !== null
                      ? registers.orden_de_compra.lugar_de_entrega
                      : "Sin especificar",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                },
              ],
              [
                {
                  text: "NOTA ADICIONAL:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                  bold: true,
                },
                {
                  text:
                    registers.orden_de_compra.nota !== null
                      ? registers.orden_de_compra.nota
                      : "Sin nota adicional",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                },
              ],
              [
                {
                  text: "FEC. DE ENTREGA:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                  bold: true,
                },
                {
                  text:
                    registers.orden_de_compra.fecha_de_entrega !== null
                      ? "Programado para el día " +
                        formatDate(registers.orden_de_compra.fecha_de_entrega)
                      : "No programada",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 3],
                  valign: "middle",
                  alignment: "left",
                  color: styles.colors.black,
                  borderRadius: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.md,
                },
              ],
            ],
          },

          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 5, 0, 0],
        },

        //?Informacion del emisor
        {
          text:
            "Emitido por: ".toUpperCase() +
            registers.usuario.nombres.slice(0, 5).toUpperCase() +
            "***",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 5],
          alignment: "center",
        },
      ],
    };
    pdfMake.createPdf(docDefinition).print();
  } catch (error) {
    errorActions("No se pudo generar el reporte");
  }
};

const order = async (idOrder) => {
  try {
    const response = await axios.get(
      `imprimir-orden-de-compras/?id=${idOrder}`
    );
    return await response.data;
  } catch (error) {
    errorActions(error);
  }
};
