<template>
  <div >
    <div class="rounded-lg overflow-hidden shadow bg-white">
      <div class="h-1 bg-blue-500"></div>
      <div
        class="flex items-center justify-center flex-col w-full mt-4 px-4 py-4"
      >
        <div
          class="w-20 h-20 border-4 border-blue-100 rounded-full overflow-hidden"
        >
          <img
            :src="API_RESOURCE + dataUser.user.avatar"
            alt=""
            v-if="dataUser.user.avatar !== null"
          />
          <img
            src="../../../../assets/images/defaults/avatar-default.png"
            alt=""
            v-else
          />
        </div>
        <h2 class="capitalize text-xl font-semibold text-gray-600 mt-2">
          {{ dataUser.user.name }}
        </h2>
        <div class="text-sm text-gray-500">
          {{ dataUser.user.email }}
        </div>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden shadow bg-white mt-6">
      <div class="bg-blue-500 py-2 px-3">
        <h3 class="text-white font-bold text-md">Tu horario el día de hoy</h3>
      </div>
      <div class="w-full mt-5">
        <div
          class="text-sm flex justify-between flex-wrap text-gray-500 border-t-2 border-gray-100 py-3 px-3"
        >
          <span class="text-gray-700 font-bold"> Día de acceso </span>
          <span>
            {{ dataUser.user.schedules[0].days.toUpperCase() }}
          </span>
        </div>
        <div
          class="text-sm flex justify-between flex-wrap text-gray-500 border-t-2 border-gray-100 py-3 px-3"
        >
          <span class="text-gray-700 font-bold"> Hora de entrada </span>
          <span>
            <span class="bg-green-100 px-1 rounded-lg text-green-700 mr-2">
                {{ formatTime(dataUser.user.schedules[0].entryWork) }}
            </span>
            <span class="bg-orange-100 px-1 rounded-lg text-orange-700">
                {{ formatTime(dataUser.user.schedules[0].entryWorkTolerance) }}
            </span>
          </span>
        </div>
        <div
          class="text-sm flex justify-between flex-wrap text-gray-500 border-t-2 border-gray-100 py-3 px-3"
        >
          <span class="text-gray-700 font-bold"> Hora de salida </span>
          <span>
            <span class="bg-green-100 px-1 rounded-lg text-green-700 mr-2">
                {{ formatTime(dataUser.user.schedules[0].exitWork) }}
            </span>
            <span class="bg-orange-100 px-1 rounded-lg text-orange-700">
                {{ formatTime(dataUser.user.schedules[0].exitWorkTolerance) }}
            </span>
          </span>
        </div>
        <div
          class="text-sm flex justify-between flex-wrap text-gray-500 border-t-2 border-gray-100 py-3 px-3"
        >
          <span class="text-gray-700 font-bold"> Tardanza </span>
          <span class="text-white px-2 rounded-md font-medium" :class="{'bg-green-600':dataUser.user.schedules[0].minutesLate === 0,'bg-red-600':dataUser.user.schedules[0].minutesLate > 0}">
            {{ dataUser.user.schedules[0].minutesLate }} Min.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

//days js
const dayjs = require("dayjs");
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
//fin days js
export default {
  name: "ProfileUser",
  setup() {
    const API_RESOURCE = process.env.VUE_APP_API_URL_RESOURCE;

    const dataUser = ref({});
    const formatTime = (time) => dayjs(time, "HH:mm:ss").format("hh:mm:ss A");
    if (localStorage.getItem("access")) {
      const data = JSON.parse(localStorage.getItem("access"));
      dataUser.value = data;
    }

    return {
      API_RESOURCE,
      dataUser,
      formatTime
    };
  },
};
</script>
