<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="containerDataAccess">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw] md:max-w-[60vw] lg:max-w-[50vw] max-w-[90vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            VALIDACION DE CREDENCIAL
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Contraseña <span class="text-red-300">*</span>
            </span>
            <input
              type="password"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.password"
              placeholder="Contraseña"
            />
          </label>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="validateAccess"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Validando credencial
              </div>
              <span v-else>Ingresar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../api";
export default {
  name: "ValidateUserAccess",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    area: {
      type: String,
      required: true,
    },
  },
  emits: ["send-data"],
  setup(props, { emit }) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      password: "",
      state: false,
      discount: 0,
    });

    /**********************************************************************************************************
     * Funcion validar el acceso al area o modulo especifico del sistema
     **********************************************************************************************************/
    const validateAccess = async () => {
      if (dataForm.value.password.length <= 0) {
        errorActions(
          "Por favor   <strong>ingresa las credenciales de acceso</strong>"
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "validar-acceso-especifico",
          {
            password: dataForm.value.password,
            area: props.area,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(() => {
          errorActions(
            "Lo sentimos algo salio mal, <strong>vuelve a intentalo mas tarde</strong>"
          );
          spinner.value = false;
        });
      const data = await response.data;
      if (data === false) {errorActions(
          `Lo sentimos, no pudimos ingresar al área del sistema <strong>Revisa si ingresaste una credencial válida.</strong>`
        );
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        dataForm.value.state = true
        dataForm.value.discount = data.limite_de_descuento
        emit("send-data", dataForm.value);
        isActive();        
      }
      dataForm.value.password = ''
      spinner.value = false;
    };

    onMounted(async () => {
      observeElement("#containerDataAccess");
    });

    return {
      isActive,
      dataForm,
      validateAccess,
      spinner,
    };
  },
};
</script>
