<template>
  <div
    id="custom-controls-gallery"
    class="relative w-full right-0"
    data-carousel="slide"
  >
    <!-- Carousel wrapper -->
    <div class="h-20 overflow-hidden rounded-lg">
      <!-- Item 1 -->
      <div
        v-bind:class="{ hidden: activeIndex !== 0, block: activeIndex === 0 }"
        class="duration-700 ease-in-out h-full relative flex justify-center"
        data-carousel-item="active"
      >
        <div
          class="bg-sky-800 rounded-lg bg-opacity-20 max-w-[250px] px-3 flex justify-center w-full h-full relative"
        >
          <img
            src="../../assets/images/statics/ciencias-economicas.png"
            class="absolute block w-12 h-auto left-2 -translate-y-1/2 top-1/2"
            alt=""
          />
          <div class="absolute right-2 -translate-y-1/2 top-1/2">
            <h3 class="text-white text-sm font-bold">Contabilidad</h3>
            <p class="text-blue-200 text-[0.75rem]">
              Reportes 100% optimizados <br />
              para el área contable
            </p>
          </div>
        </div>
      </div>
      <!-- Item 2 -->
      <div
        v-bind:class="{ hidden: activeIndex !== 1, block: activeIndex === 1 }"
        class="duration-700 ease-in-out relative h-full flex justify-center"
        data-carousel-item
      >
        <div
          class="bg-sky-800 rounded-lg bg-opacity-20 max-w-[250px] px-3 flex justify-center w-full h-full relative"
        >
          <img
            src="../../assets/images/statics/carrito-de-compras.png"
            class="absolute block w-12 h-auto left-2 -translate-y-1/2 top-1/2"
            alt=""
          />
          <div class="absolute right-2 -translate-y-1/2 top-1/2">
            <h3 class="text-white text-sm font-bold">Ventas</h3>
            <p class="text-blue-200 text-[0.75rem]">
              Haz tus ventas en un solo lugar <br />
              y de una forma más segura
            </p>
          </div>
        </div>
      </div>
      <!-- Item 2 -->
      <div
        v-bind:class="{ hidden: activeIndex !== 2, block: activeIndex === 2 }"
        class="duration-700 ease-in-out relative h-full flex justify-center"
        data-carousel-item
      >
        <div
          class="bg-sky-800 rounded-lg bg-opacity-20 max-w-[250px] px-3 flex justify-center w-full h-full relative"
        >
          <img
            src="../../assets/images/statics/pilas-de-almacenamiento.png"
            class="absolute block w-12 h-auto left-2 -translate-y-1/2 top-1/2"
            alt=""
          />
          <div class="absolute right-2  -translate-y-1/2 top-1/2">
            <h3 class="text-white text-sm font-bold">Almacen</h3>
            <p class="text-blue-200 text-[0.75rem]">
              Controla el stock de tus productos, <br />
              Kardex, inventario físico, etc.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";

export default {
  name: "LoginSlider",
  setup() {
    const activeIndex = ref(0);

    const updateActiveIndex = () => {
      const items = document.querySelectorAll("[data-carousel-item]");
      if (items) {
        let activeItem = null;
        items.forEach((item) => {
          if (item.dataset.carouselItem === "active") {
            activeItem = item;
          }
        });
        const newIndex = Array.from(items).indexOf(activeItem);
        activeIndex.value = newIndex;
      }
    };

    watch(activeIndex, () => {
      const items = document.querySelectorAll("[data-carousel-item]");
      if (items) {
        items.forEach((item, index) => {
          if (index === activeIndex.value) {
            item.classList.remove("hidden");
            item.classList.add("block");
            item.classList.add("animate-fade-in");
            item.dataset.carouselItem = "active";
          } else {
            item.classList.remove("block");
            item.classList.add("hidden");
            item.dataset.carouselItem = "";
          }
        });
      }
    });

    onMounted(() => {
      setInterval(() => {
        const items = document.querySelectorAll("[data-carousel-item]");
        if (items) {
          let activeItem = null;
          items.forEach((item) => {
            if (item.dataset.carouselItem === "active") {
              activeItem = item;
            }
          });
          const currentIndex = Array.from(items).indexOf(activeItem);
          const nextIndex = (currentIndex + 1) % items.length;
          items[currentIndex].dataset.carouselItem = "";
          items[nextIndex].dataset.carouselItem = "active";
          updateActiveIndex();
        }
      }, 3000);
    });

    return {
      activeIndex,
      updateActiveIndex,
    };
  },
};
</script>

<style scoped>
.animate-fade-in {
  animation-name: fade-in;
  animation-duration: 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
