<template>
    <MainCompany/>
</template>

<script>
import MainCompany from '@/components/Company/Configurations/MainCompany.vue'
import { useRoute } from 'vue-router';
import { onBeforeMount, onMounted } from 'vue';
import { findSubmoduleIdByDescription } from '@/hisAcces';
export default {
    name: 'CompanyConfigurations',
    components: {
        MainCompany
    },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
}
</script>