<template>
  <div
    class="w-screen bg-gradient-to-bl to-blue-800 from-sky-950 md:flex md:flex-wrap md:items-center md:justify-center pl-0 pr-0 pb-0 pt-0"
    style="padding: 0 !important"
  >
    <!-- bg del div -->
    <img
      src="../assets/images/statics/slider.webp"
      alt=""
      class="w-full h-full absolute top-0 left-0 opacity-100 object-cover"
    />
    <!-- end bg div -->
    <div
      class="w-full h-screen md:px-4 md:py-4 flex items-center flex-col justify-center md:h-screen relative"
    >
      <div class="w-full flex flex-col items-center justify-center">
        <div
          class="w-full h-screen md:h-auto md:min-w-[350px] lg:min-w-[350px] md:max-w-[350px] lg:max-w-[350px] bg-sky-950 px-6 py-6 md:rounded-xl"
        >
          <div class="w-full flex justify-center items-center mb-5 z-10">
            <div v-if="loadingCompany">
              <div class="flex items-center justify-center gap-2">
                <div
                  class="w-10 h-10 rounded-lg bg-gray-200 animate-pulse"
                ></div>
                <div
                  class="w-20 h-10 rounded-lg bg-gray-200 animate-pulse"
                ></div>
              </div>
            </div>
            <div
              class="flex items-center justify-center gap-2 rounded-xl"
              v-else
            >
              <img
                :src="URL_RESOURCE + dataForm.logo"
                alt=""
                class="w-10 h-10"
              />
              <div class="text-white text-md font-bold max-w-[120px]">
                {{ dataForm.name }}
              </div>
            </div>
          </div>
          <h2
            class="text-2xl font-semibold text-sky-100 mt-4 mb-6 text-left w-full"
          >
            Inicio de sesión
          </h2>
          <form
            class="space-y-4 w-full"
            @submit.prevent
            @dragenter="false"
            autocomplete="false"
          >
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Turnos
              </span>
              <Turns
                @send-data="getTurns"
                class="bg-sky-900 font-semibold text-white border-sky-800"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Correo electrónico
              </span>
              <input
                type="email"
                class="py-2 px-2 text-sm bg-sky-900 outline-none rounded-lg text-white w-full border-2 border-sky-800 focus:border-blue-500"
                placeholder="Correo electrónico..."
                v-model="dataLogin.email"
                v-on:keyup.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Contraseña
              </span>
              <input
                type="password"
                class="py-2 px-2 text-sm bg-sky-900 outline-none rounded-lg text-white w-full border-2 border-sky-800 focus:border-blue-500"
                placeholder="Contraseña..."
                v-model="dataLogin.password"
                v-on:keyup.enter.prevent
              />
            </label>

            <button
              class="bg-blue-500 w-full outline-none rounded-lg px-4 py-2 text-white active:bg-blue-600"
              @click="login"
              :disabled="dataForm.stateLogin"
            >
              <font-awesome-icon
                icon="fa-solid fa-right-to-bracket"
                class="small-hidden w-4 h-4 mr-2"
              />
              <span v-if="!dataForm.stateLogin">Iniciar sesión</span>
              <span v-else>Ingresando</span>
            </button>
          </form>
          <div class="mt-4 flex flex-col items-center justify-center">
            <p class="text-blue-300 text-sm font-medium mb-2">
              Contactos de soporte
            </p>
            <div class="flex items-center gap-2">
              <a
                href="https://wa.link/sp2c3c" 
                target="_blank"
                class="bg-sky-900 w-8 h-8 rounded-full overflow-hidden active:bg-sky-950 flex items-center justify-center"
              >
              <svg class="w-4 h-4 icons whatsapp" style="fill:#ffffff;">
                <use href="@/assets/svg/icon.svg#whatsapp" />
              </svg>
              </a>
              <a
                href="https://www.youtube.com/@jasoftware6111/featured" 
                target="_blank"
                class="bg-sky-900 w-8 h-8 rounded-full overflow-hidden active:bg-sky-950 flex items-center justify-center"
              >
              <svg class="w-4 h-4 icons youtube" style="fill:#ffffff;">
                <use href="@/assets/svg/icon.svg#youtube" />
              </svg>
              </a>
              <a
                href="https://web.facebook.com/profile.php?id=100083456256377&_rdc=1&_rdr" 
                target="_blank"
                class="bg-sky-900 w-8 h-8 rounded-full overflow-hidden active:bg-sky-950 flex items-center justify-center"
              >
              <svg class="w-4 h-4 icons" style="fill:#ffffff;">
                <use href="@/assets/svg/icon.svg#facebook" />
              </svg>
              </a>
            </div>
          </div>

          <div class="flex items-center justify-center mt-5">
            <Slider />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Turns from "@/components/PublicComponents/Turns.vue";
import Slider from "@/components/Sliders/Login.vue";
import { onMounted, ref } from "vue";
import axios from "@/api";
import { errorActions, warningActions } from "@/alerts";
export default {
  name: "LoginApp",
  components: {
    Turns,
    Slider,
  },

  setup() {
    //datos de los selection option
    const dataForm = ref({
      idTurn: 0,
      name: null,
      logo: null,
      stateLogin: false,
    });

    //datos para iniciar sesión
    const dataLogin = ref({
      email: "",
      password: "",
    });

    //dominio de api para traer los recursos de la empresa
    const URL_RESOURCE = process.env.VUE_APP_API_URL_RESOURCE;

    //obtiene los datos de la empresa
    const loadingCompany = ref(true);
    const getCompany = async () => {
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "empresa")
        .catch((error) => {
          errorActions(error);
        });
      const data = await response.data[0];
      dataForm.value.name = data.razon_social;
      dataForm.value.logo = data.logo;
      loadingCompany.value = false;
    };
    onMounted(() => {
      getCompany();
    });

    const login = async () => {
      try {
        if (dataForm.value.idTurn === 0 || dataForm.value.idTurn === null) {
          errorActions("Selecciona el turno, para acceder al sistema");
          return;
        }

        if (dataLogin.value.password <= 2) {
          warningActions(
            "Por favor ingresa un contraseña para poder iniciar sesión"
          );
          return;
        }

        dataForm.value.stateLogin = true;
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "login",
          {
            correo: dataLogin.value.email,
            password: dataLogin.value.password,
            turn: dataForm.value.idTurn,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.data;
        if (data.user.schedules.length === 0) {
          errorActions(
            "Lo sentimos, Ud. no tiene el acceso configurado para el día de hoy"
          );

          dataLogin.value.email = "";
          dataLogin.value.password = "";
          dataForm.value.stateLogin = false;
        }
        if (data.user.schedules[0].hasAccessToday === false) {
          errorActions(
            "Lo sentimos, Ud. no tiene el acceso configurado para el día de hoy"
          );

          dataLogin.value.email = "";
          dataLogin.value.password = "";
          dataForm.value.stateLogin = false;
        }
        if (data.user.schedules[0].isInTime === false) {
          errorActions(
            "Lo sentimos, Ud. no puede acceder al sistema por que no esta en su hora laboral, revice su horario y vuelve a intentarlo"
          );

          dataLogin.value.email = "";
          dataLogin.value.password = "";
          dataForm.value.stateLogin = false;
        }
        if (
          data.user.schedules[0].isInTime &&
          data.user.schedules[0].hasAccessToday &&
          data.user.schedules.length > 0
        ) {
          localStorage.setItem("access", JSON.stringify(data));
          if (localStorage.getItem("access")) {
            /*************************************************************
             * Validamos si la caja del usuario se encuentra abierta
             *************************************************************/
            const responseBox = await axios
              .get(
                `caja/check-status/?idstore=${data.user.store}&iduser=${data.user.key}&idturn=${data.user.schedules[0].turn}`
              )
              .catch((error) => errorActions(error));
            const dataBox = await responseBox.data;
            if (dataBox.msg === "caja abierta") {
              localStorage.setItem("boxState", true);
              localStorage.setItem("boxState", true);
            } else {
              localStorage.removeItem("boxState");
              localStorage.removeItem("boxState");
            }
            /*************************************************************
             * Redireccionamos al inicio del sistema
             *************************************************************/

            dataLogin.value.email = "";
            dataLogin.value.password = "";
            dataForm.value.stateLogin = false;
            window.location.href = "/";
          }
        }
      } catch (error) {
        if (error.response.status === 401) {
          errorActions(error.response.data.error);
        } else {
          console.error(error);
        }
        dataForm.value.stateLogin = false;
      }
    };

    //recepcion de datos desde el componente publico, todos los tipos de usuarios
    const getTurns = (data) => {
      dataForm.value.idTurn = data;
    };
    return {
      URL_RESOURCE,
      loadingCompany,
      getTurns,
      dataLogin,
      dataForm,
      login,
    };
  },
};
</script>

<style scoped>
.icons:hover {
  fill:rgb(3, 102, 194) !important;
}
.icons.whatsapp:hover  {
  fill:rgb(26, 179, 115) !important;
}
.icons.youtube:hover {
  fill:rgb(255, 121, 121) !important;
}
</style>