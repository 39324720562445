<template>
  <div class="mt-4" id="contentData">
    <div
      class="flex justify-between flex-wrap gap-4 md:flex-nowrap items-end mb-6"
    >
      <div class="flex flex-wrap items-end gap-4">
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Tipo de reporte
          </span>
          <select
            class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full focus:border-blue-500 uppercase"
            v-model="dataForm.typeReport"
            @change="showButtons"
          >
            <option value="607">reporte 607</option>
            <option value="608">reporte 608</option>
            <option value="606">reporte 606</option>
            <option value="update">comprobantes modificados</option>
            <option value="special">regimenes especial</option>
          </select>
        </label>
      </div>
    </div>
    <!-- Para reporte 608 -->
    <div class="grid gap-6" v-show="state608">
      <div class="rounded-md overflow-hidden shadow border">
        <!-- <div class="h-1 w-full bg-blue-500"></div> -->
        <div class="p-8">
          <h2 class="pb-5 text-gray-800 font-bold uppercase">
            Reporte general del <span class="text-blue-500">608</span>
          </h2>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4">
            <button
              class="w-full bg-blue-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-blue-500 hover:bg-blue-600 font-semibold"
              @click="getData608B01B02"
            >
              <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
              generar reporte 608 (b01 - b02)
            </button>
          </div>
        </div>
      </div>
      <div class="rounded-md overflow-hidden shadow border">
        <!-- <div class="h-1 w-full bg-slate-600"></div> -->
        <div class="p-8">
          <h2 class="pb-5 text-gray-800 font-bold uppercase">
            Reporte del 608 por tipo
            <span class="text-blue-500">B01 - B02</span>
          </h2>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4">
            <button
              class="w-full bg-green-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-green-500 hover:bg-green-600 font-semibold"
              @click="getData608B01"
            >
              <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
              generar reporte 608 - b01
            </button>

            <button
              class="w-full bg-blue-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-blue-500 hover:bg-blue-600 font-semibold"
              @click="getData608B02"
            >
              <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
              generar reporte 608 - b02
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="overflow-hidden h-0"
      :class="{ 'h-auto transition-all ': !loading }"
    >
      <div
        class="bg-gray-100 animate-pulse uppercase p-6 font-bold text-gray-400 rounded-md text-center"
      >
        generando reporte
        <span class="block text-xs mt-3"> Esto tomará solo unos segundos </span>
      </div>
    </div>
    <div class="overflow-hidden" :class="{ 'h-0 transition-all': !loading }">
      <!-- Para reporte 607 -->
      <div class="grid gap-6" v-show="state607">
        <div class="rounded-md overflow-hidden shadow border">
          <!-- <div class="h-1 w-full bg-blue-500"></div> -->
          <div class="p-8">
            <h2 class="pb-5 text-gray-800 font-bold uppercase">
              Reporte general del <span class="text-blue-500">607</span>
            </h2>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4">
              <button
                class="w-full bg-blue-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-blue-500 hover:bg-blue-600 font-semibold"
                @click="getData607B01B02"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
                generar reporte 607 (b01 - b02)
              </button>
            </div>
          </div>
        </div>
        <div class="rounded-md overflow-hidden shadow border">
          <!-- <div class="h-1 w-full bg-slate-600"></div> -->
          <div class="p-8">
            <h2 class="pb-5 text-gray-800 font-bold uppercase">
              Reporte del 607 por tipo
              <span class="text-blue-500">B01 - B02</span>
            </h2>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4">
              <button
                class="w-full bg-green-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-green-500 hover:bg-green-600 font-semibold"
                @click="getData607B01"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
                generar reporte 607 - b01
              </button>

              <button
                class="w-full bg-blue-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-blue-500 hover:bg-blue-600 font-semibold"
                @click="getData607B02"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
                generar reporte 607 - b02
              </button>
              <button
                class="w-full bg-blue-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-blue-500 hover:bg-blue-600 font-semibold"
                @click="getData607B15"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
                generar reporte 607 - b15
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Para reporte regimen especial -->
      <div class="grid gap-6" v-show="stateSpecialRegime">
        <div class="rounded-md overflow-hidden shadow border">
          <!-- <div class="h-1 w-full bg-blue-500"></div> -->
          <div class="p-8">
            <h2 class="pb-5 text-gray-800 font-bold uppercase">
              Reporte general del comprobante
              <span class="text-blue-500">regimen especial</span>
            </h2>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4">
              <button
                class="w-full bg-blue-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-blue-500 hover:bg-blue-600 font-semibold"
                @click="getDataSpecialRegime"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
                generar reporte regimen especial
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Para reporte de coprobantes modificados-->
      <div class="grid gap-6" v-show="stateUpdateVoucher">
        <div class="rounded-md overflow-hidden shadow border">
          <div class="p-8">
            <h2 class="pb-5 text-gray-800 font-bold uppercase">
              Reporte de
              <span class="text-blue-500">comprobantes modificados</span>
            </h2>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-4">
              <button
                class="w-full bg-green-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-green-500 hover:bg-green-600 font-semibold"
                @click="getDataUpdateVouchersB04B01"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
                generar reporte b04 - b01
              </button>
              <button
                class="w-full bg-blue-500 rounded-md text-white text-sm px-3 py-3 active:scale-x-95 uppercase active:shadow-md shadow-blue-500 hover:bg-blue-600 font-semibold"
                @click="getDataUpdateVouchersB04B02"
              >
                <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
                generar reporte b04 - b02
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "@/api";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";

import { formatDateTime } from "@/publicjs/formatDate";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";

import { exportTo608b01b02 } from "./ExportData/ExcelExport608b01b02";
import { exportTo607b01b02 } from "./ExportData/ExcelExport607b01b02";
import { exportTo607b15 } from "./ExportData/exportTo607b15";
import { exportTo607b02 } from "./ExportData/ExcelExport607b02";
import { exportToUpdateb01b02 } from "./ExportData/ExcelExportUpdateVoucherb01b02";
import { exportToSpecialRegime } from "./ExportData/ExcelExportSpecialRegime";

export default {
  name: "MainReportDGII",
  setup() {
    const loading = ref(true);
    const state607 = ref(true);
    const state608 = ref(false);
    const stateSpecialRegime = ref(false);
    const stateUpdateVoucher = ref(false);

    const dataForm = ref({
      idStore: 0,
      dateStart: null,
      dateEnd: null,
      typeReport: "607",
    });

    const getData607B01B02 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-607b01b02/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportTo607b01b02(
        data,
        "REPORTE DGII 607 (B01-B02)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };
    const getData607B15 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-607b15/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        return
      }
      await exportTo607b15(
        data,
        "REPORTE DGII 607 (B15)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      
      loading.value = true;
    };



    const getData607B01 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-607b01/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportTo607b01b02(
        data,
        "REPORTE DGII 607 (B01)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };
    const getData607B02 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-607b02/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportTo607b02(
        data,
        "REPORTE DGII 607 (B02)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };

    const getDataSpecialRegime = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-regimen-especial/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportToSpecialRegime(
        data,
        "REPORTE DGII 607 (B14)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };

    const getData608B01B02 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-608b01b02/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportTo608b01b02(
        data,
        "REPORTE DGII 608 (B01-B02)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };
    const getData608B01 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-608b01/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportTo608b01b02(
        data,
        "REPORTE DGII 608 (B01)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };
    const getData608B02 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-608b02/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportTo608b01b02(
        data,
        "REPORTE DGII 608 (B02)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };

    const getDataUpdateVouchersB04B02 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-update-voucher-b02/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportToUpdateb01b02(
        data,
        "REPORTE DGII 607 (B04 - B02)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };
    const getDataUpdateVouchersB04B01 = async () => {
      loading.value = false;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = true;
        return;
      }
      const response = await axios
        .get(
          `reporte-dgii-update-voucher-b01/${dataForm.value.idStore}/?date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;

      if (data.length <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      await exportToUpdateb01b02(
        data,
        "REPORTE DGII 607 (B04 - B01)",
        dataForm.value.dateStart,
        dataForm.value.dateEnd
      );
      loading.value = true;
    };

    //Pasar el día actual y el ultimo día de la fecha
    const changeCurrentDate = async () => {
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = dates.firstDay;
      dataForm.value.dateEnd = dates.lastDay;
    };
    const showButtons = async () => {
      if (dataForm.value.typeReport === "607") {
        state607.value = true;
        state608.value = false;
        stateSpecialRegime.value = false;
        stateUpdateVoucher.value = false;
      } else if (dataForm.value.typeReport === "608") {
        state607.value = false;
        state608.value = true;
        stateSpecialRegime.value = false;
        stateUpdateVoucher.value = false;
      } else if (dataForm.value.typeReport === "update") {
        state607.value = false;
        state608.value = false;
        stateSpecialRegime.value = false;
        stateUpdateVoucher.value = true;
      } else if (dataForm.value.typeReport === "special") {
        state607.value = false;
        state608.value = false;
        stateSpecialRegime.value = true;
        stateUpdateVoucher.value = false;
      } else if (dataForm.value.typeReport === "606") {
        state607.value = false;
        state608.value = false;
        stateSpecialRegime.value = false;
        stateUpdateVoucher.value = false;
      }
    };

    onMounted(async () => {
      observeElement("#contentData");
      dataForm.value.idStore = await idLocalStore();
      await changeCurrentDate();
    });

    return {
      loading,
      getData607B01B02,
      getData607B01,
      getData607B02,
      getDataSpecialRegime,
      getData608B01B02,
      getData608B01,
      getData608B02,
      getDataUpdateVouchersB04B02,
      getDataUpdateVouchersB04B01,
      money,
      format,
      dataForm,
      formatDateTime,
      showButtons,
      state608,
      state607,
      stateSpecialRegime,
      stateUpdateVoucher,
      getData607B15
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
./Reports/ExcelExportAllSales./Reports/PDFExportSales
