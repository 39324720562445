<template>
  <header class="grid grid-cols-12 py-2 h-[3.5rem] pl-4 pr-6 bg-blue-600">
    <div class="col-span-4 flex items-center">
      <button
        class="bg-transparent text-white active:scale-75 hover:scale-100 relative"
        @click="smallMenu"
      >
        <svg class="w-5 h-5 mr-5">
          <use href="@/assets/svg/icon.svg#menu" />
        </svg>
      </button>
      <input
        type="search"
        class="py-2 px-2 rounded-lg hidden md:block outline-none bg-blue-500 text-gray-50 text-sm cursor-pointer placeholder:text-blue-50 w-full"
        placeholder="Buscar"
        @click="activeModalModules"
        readonly
      />
    </div>
    <div class="col-span-8 h-[100%] flex items-center justify-end">
      <button
        class="bg-transparent text-white active:scale-75 hover:scale-110 relative mt-[5px]"
      >
        <svg class="w-4 h-4">
          <use href="@/assets/svg/icon.svg#bell" />
        </svg>
        <span
          class="w-[5px] h-[5px] bg-white absolute top-[-10px] right-[-3px] rounded-full animate-ping"
        ></span>
      </button>
      <div
        class="w-8 h-8 bg-blue-800 ml-5 rounded-full animate-pulse"
        v-if="loading"
      ></div>
      <div class="relative group" v-else>
        <div class="rounded-full w-8 h-8 overflow-hidden ml-5 cursor-pointer">
          <img
            src="@/assets/images/defaults/avatar-default.png"
            alt="Foto de perfil"
            v-if="dataUser.avatar === null"
          />
          <img
            :src="URL_RESOURCE + dataUser.avatar"
            alt="Foto de perfil"
            v-else
          />
        </div>

        <ul
          class="absolute top-full right-0 w-[200px] bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-30"
        >
          <li
            class="block px-4 py-2 text-blue-700 hover:bg-gray-100 cursor-pointer capitalize"
          >
            {{ dataUser.fullName }}
            <span class="text-[12px] mt-0 block text-gray-500">
              {{ dataUser.email }}
            </span>
          </li>
          <router-link
            to="/perfil"
            class="block px-4 py-2 text-gray-600 hover:bg-gray-100 cursor-pointer"
          >
            Mi cuenta
          </router-link>
          <li
            class="block px-4 py-2 text-red-500 hover:bg-gray-100 cursor-pointer"
            @click="logout"
          >
            Salir
          </li>
        </ul>
      </div>
    </div>
    <SearchMenus :hidden="activeModalModules" v-if="stateModalSearchModules" />
  </header>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import Swal from "sweetalert2";
import SearchMenus from "@/components/Nav/SearchMenus.vue";
export default {
  name: "HeaderHome",
  components: {
    SearchMenus,
  },
  setup(props, { emit }) {
    const active = ref(false);
    const activeParent = ref(false);
    const stateModalSearchModules = ref(false);

    watchEffect(() => {
      activeParent.value = active.value;
      emit("actualizar-propiedad", activeParent.value);
    });

    //estilo para hacer un scroll en x de la tabla
    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const handleResize = () => {
      const bodyTable = document.querySelector(".resize-generate"); //contenedor de la tabla
      const smallMenu = document.querySelector(".grid-cols-2-small"); // cuando el nav esta en estado de 70px de ancho
      const smallMenuActive = document.querySelector(
        ".grid-cols-2-small.grid-cols-2-menu"
      ); // cuando el nav esta en estado de 200px superior
      if (bodyTable) {
        if (window.matchMedia("(max-width: 768px)").matches) {
          if (smallMenuActive) {
            bodyTable.style.width = `calc(100vw - ${70 + 42}px)`;
          } else if (smallMenu) {
            bodyTable.style.width = `calc(100vw - ${200 + 42}px)`;
          }
        } else if (window.matchMedia("(min-width: 768px)").matches) {
          if (smallMenuActive) {
            bodyTable.style.width = `calc(100vw - ${255 + 42}px)`;
          } else if (smallMenu) {
            bodyTable.style.width = `calc(100vw - ${70 + 42}px)`;
          }
        }
      }
    };

    const smallMenu = () => {
      handleResize();
      return (active.value = !active.value);
    };

    //dominio de api para traer los recursos de la empresa
    const URL_RESOURCE = process.env.VUE_APP_API_URL_RESOURCE;

    //! datos del usuario
    const loading = ref(true);
    const dataUser = ref({
      fullName: "",
      avatar: "",
      email: "",
    });

    onMounted(() => {
      loading.value = true;
      if (localStorage.getItem("access")) {
        const data = JSON.parse(localStorage.getItem("access"));
        dataUser.value.fullName = data.user.name;
        dataUser.value.avatar = data.user.avatar;
        dataUser.value.email = data.user.email;
      }

      loading.value = false;
    });

    const logout = async () => {
      if (localStorage.getItem("access")) {
        await Swal.fire({
          title: "¿Estas seguro que deseas salir del sistema?",
          text: `Si tiene trabajos pendientes y por terminar te sugerimos que termines de hacerlos...`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#315df5",
          cancelButtonColor: "#dc2626",
          confirmButtonText: "Si, ¡Salir ahora!",
          cancelButtonText: "Cancelar",
          width: "300px",
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem("access");
            localStorage.removeItem("modules");
            localStorage.removeItem("boxState");
            window.location.href = "/login";
          }
        });
      }
    };

    const activeModalModules = () => {
      stateModalSearchModules.value = !stateModalSearchModules.value;
    };

    return {
      smallMenu,
      active,
      activeParent,
      dataUser,
      loading,
      URL_RESOURCE,
      logout,
      activeModalModules,
      stateModalSearchModules,
    };
  },
};
</script>

<style scoped>
.group:hover .absolute {
  opacity: 100;
  visibility: visible;
}
.group:hover .hidden {
  visibility: visible;
}
.group:hover .invisible {
  visibility: visible;
}
</style>
