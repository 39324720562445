import { errorActions } from "@/alerts";
import axios from "../../../api";
import Swal from "sweetalert2";
import { observeElement } from "@/observer";
/**
 *
 * @param {*} id | ID de la orden
 * @param {*} msg | Mensaje para la eliminarcion
 */
const deleteData = async (id, msg) => {
  const response = await axios
    .delete("venta-temporal-cotizacion-pedido/" + id, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      errorActions(error);
    });
  const data = response.data;
  if (data.msg === "Request failed with status code 500") {
    errorActions(data.msg);
  } else if (data.msg === false) {
    errorActions(
      `Lo sentimos no pudimos eliminar la orden <strong>${msg}</strong>`
    );
  }
};
/**
 *
 * @param {*} id | ID del registro al cual queremos eliminar o anular
 * @param {*} msg | Descripcion del registro que queremos eliminar puede ser el nombre, etc.
 * @param {*} action | Funcion para volver a cargar los datos, pero esto funciona siempre en cuando n tenga parametros
 */
export const deleteOrder = async (id, msg, action) => {
  Swal.fire({
    title: "¿Estas seguro que deseas eliminar la orden?",
    html: `Si eliminas la orden <strong>${msg}</strong>, no podras recuperarlo más adelante!`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#315df5",
    cancelButtonColor: "#dc2626",
    confirmButtonText: "Si, Eliminar!",
    cancelButtonText: "Cancelar",
    width: "300px",
  }).then(async (result) => {
    observeElement(".swal2-container");
    if (result.isConfirmed) {
      await deleteData(id, msg);
      Swal.fire({
        icon: "success",
        title: "orden Eliminada",
        html: `La orden <strong>${msg}</strong>, a sido eliminado de la base de datos`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
      });
      observeElement(".swal2-container");
      await action();
    }
  });
};
