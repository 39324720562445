import { errorActions } from "@/alerts";
import axios from "../api";

/**
 *
 * @returns Retorna los datos del compronnte orden de compra
 */
export const voucherPurchaseOrder = async () => {
  try {
    const response = await axios.get("comprobantes/?page=1&per_page=20");
    const vouchers = await response.data.data;
    //* filtramos el comprobante orden de compra
    const purchaseOrderData = vouchers.filter(
      (voucher) =>
        voucher.descripcion.toUpperCase() === "ORDEN DE COMPRA" ||
        voucher.descripcion.toUpperCase() === "ORDEN DE COMPRAS" ||
        voucher.descripcion.toUpperCase() === "ORDENES DE COMPRA"
    );
    if (purchaseOrderData.length <= 0) {
      errorActions(
        `No hemos podido entrontrar un tipo de comprobante para realizar la emision de <strong>La orden de compra</strong>`
      );
      return;
    }

    //sacamos los datos necesario del comprobante
    let numberCurrent = purchaseOrderData[0].numero_actual;
    const description = purchaseOrderData[0].descripcion;
    const numberEnd = purchaseOrderData[0].numero_final;
    const serie = purchaseOrderData[0].serie;
    const id = purchaseOrderData[0].id;

    //incrementamos el numero del comprobante
    numberCurrent = (parseInt(numberCurrent) + 1)
      .toString()
      .padStart(numberCurrent.length, "0");

    const printFormat = await getPrintFormatToPaper(purchaseOrderData[0].id); //? Formato de impresion del documento

    return {
      description,
      numberCurrent,
      numberEnd,
      serie,
      id,
      printFormat,
    };
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar un tipo de comprobante para realizar la orden de compra <strong>${error}</strong>`
    );
  }
};

/**
 *
 * @param {*} idVoucher | Es el id del comprobante que queremos buscar y obtener sus registros
 * @returns || Los datos del comprobante como su [numberCurrent,description,numberEnd,serie,id]
 */
export const voucherSales = async (idVoucher) => {
  try {
    const response = await axios.get("comprobantes/?page=1&per_page=20");
    const vouchers = await response.data.data;
    //* filtramos el comprobante orden de compra
    const filterVouchers = vouchers.filter(
      (voucher) => voucher.id === idVoucher
    );

    //sacamos los datos necesario del comprobante
    let numberCurrent = filterVouchers[0].numero_actual;
    const description = filterVouchers[0].descripcion;
    const numberEnd = filterVouchers[0].numero_final;
    const serie = filterVouchers[0].serie;
    const id = filterVouchers[0].id;

    //incrementamos el numero del comprobante
    numberCurrent = (parseInt(numberCurrent) + 1)
      .toString()
      .padStart(numberCurrent.length, "0");

    const printFormat = await getPrintFormatToPaper(filterVouchers[0].id); //? Formato de impresion del documento

    return {
      description,
      numberCurrent,
      numberEnd,
      serie,
      id,
      printFormat,
    };
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar un tipo de comprobante para realizar la orden de compra <strong>${error}</strong>`
    );
  }
};

//? Actualizar el numero del comprobante a la base de datos
/**
 *
 * @param {*} id | Id del Comprobante a la cual le actualizaremos el numero del comprobante
 * @param {*} numberCurrent | Número del comprante actual por la cual sera actualizado el numero anterior del comprobante
 */
export const updateNumberVoucher = async (id, numberCurrent) => {
  try {
    await axios.put(
      "actualizar-numero-de-comprobante/" + id,
      {
        numberCurrent,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    errorActions(
      `No hemos podido actualizar el numero del comprobante${error}`
    );
  }
};

/********************************************************************************************************
 * ? Obtener al tipo de impresion del documento para el comprobante [A4, 8 1/2 X 11, MEDIA CARTA, TICKET]
 ********************************************************************************************************/
/**
 *
 * @param {*} idVoucher | ID del comprobante del cual queremos saber su formato de papel para la impresion
 * @returns | El formato en la cual sera impreso el documento
 */
export const getPrintFormatToPaper = async (idVoucher) => {
  try {
    const response = await axios.get(
      "configuracion-de-impresion/?page=1&per_page=20"
    );
    const vouchers = await response.data.data;

    //* filtramos el comprobante
    const voucher = vouchers.filter(
      (item) => parseInt(item.idcomprobante) === parseInt(idVoucher)
    );

    if (voucher.length <= 0) {
      errorActions(
        `No hemos podido entrontrar la configuracion para este tipo de <strong>comprobante</strong>`
      );
    }

    //? variable donde se almacena el valor o el formato de impresion del documento
    let printFormat = null;
    // Iterar sobre cada objeto en el array
    for (const objeto of voucher) {
      // Iterar sobre las propiedades del objeto
      for (const propiedad in objeto) {
        // Verificar si la propiedad comienza con "formato_" y tiene un valor de 1
        if (propiedad.startsWith("formato_") && objeto[propiedad] === 1) {
          printFormat = propiedad;
        }
      }
    }
    if (printFormat === null) {
      errorActions(
        `No hemos podido entrontrar la configuracion de impresion para este tipo de comprobante`
      );
      return null;
    }

    return printFormat; //? El nombre del formato se encuentra en la primera posición del array
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar la configuracion para este tipo de comprobante <strong>${error}</strong>`
    );
    throw error;
  }
};

/**
 *
 * @returns Retorna los datos del comprobante nota de credito
 */
export const voucherCreditNote = async () => {
  try {
    const response = await axios.get("comprobantes/?page=1&per_page=20");
    const vouchers = await response.data.data;
    //* filtramos el comprobante orden de compra
    const creditNote = vouchers.filter(
      (voucher) =>
        voucher.descripcion.toUpperCase() === "NOTA DE CRÉDITO" ||
        voucher.descripcion.toUpperCase() === "NOTAS DE CRÉDITO" ||
        voucher.descripcion.toUpperCase() === "NOTAS DE CREDITO" ||
        voucher.descripcion.toUpperCase() === "NOTA DE CREDITO"
    );
    if (creditNote.length <= 0) {
      errorActions(
        `No hemos podido entrontrar un tipo de comprobante para realizar la emision de <strong>nota de credito</strong>`
      );
      return;
    }

    //sacamos los datos necesario del comprobante
    let numberCurrent = creditNote[0].numero_actual;
    const description = creditNote[0].descripcion;
    const numberEnd = creditNote[0].numero_final;
    const serie = creditNote[0].serie;
    const id = creditNote[0].id;

    //incrementamos el numero del comprobante
    numberCurrent = (parseInt(numberCurrent) + 1)
      .toString()
      .padStart(numberCurrent.length, "0");

    const printFormat = await getPrintFormatToPaper(creditNote[0].id); //? Formato de impresion del documento

    return {
      description,
      numberCurrent,
      numberEnd,
      serie,
      id,
      printFormat,
    };
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar un tipo de comprobante para realizar la emision de la nota de credito <strong>${error}</strong>`
    );
  }
};

/**
 * @returns || El numero de referencia  y el ID del numero de referencia
 */
export const referenceNumber = async () => {
  try {
    const response = await axios.get("numero-de-referencia", {
      method: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;

    //sacamos los datos necesario del numero de referencia
    let referenceNumberCurrent = data.numero_de_referencia;
    const idReferenceNumber = data.id;

    //incrementamos el numero de referencia
    referenceNumberCurrent = (parseInt(referenceNumberCurrent) + 1)
      .toString()
      .padStart(referenceNumberCurrent.length, "0");

    return {
      idReferenceNumber,
      referenceNumberCurrent,
    };
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar un numero de refencia para la venta que deseas realizar <strong>${error}</strong>`
    );
  }
};
/**
 * @param id Recibe el id del numero de referencia que sera actualizado
 * @param referenceNumberCurrent Recibe el numero de referencia altual
 */
export const updateReferenceNumber = async (id, referenceNumberCurrent) => {
  try {
    const response = await axios.put(
      "numero-de-referencia/"+id,
      {
        referenceNumber:referenceNumberCurrent,
      },
      {
        method: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    if (data.msg === false) {
      errorActions(
        `No hemos podido actualizar el numero de referencia del comprobante`
      );
    }
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar un numero de refencia para la venta que deseas realizar <strong>${error}</strong>`
    );
  }
};


/**
 *
 * @returns Retorna los datos del comprobante nota de credito
 */
export const voucherCreditNoteInternal = async () => {
  try {
    const response = await axios.get("comprobantes/?page=1&per_page=20");
    const vouchers = await response.data.data;
    //* filtramos el comprobante orden de compra
    const creditNote = vouchers.filter(
      (voucher) =>
        voucher.descripcion.toUpperCase() === "NOTA DE CRÉDITO INTERNO" ||
        voucher.descripcion.toUpperCase() === "NOTAS DE CRÉDITO INTERNO" ||
        voucher.descripcion.toUpperCase() === "NOTAS DE CREDITO INTERNO" ||
        voucher.descripcion.toUpperCase() === "NOTA DE CREDITO INTERNO"
    );
    if (creditNote.length <= 0) {
      errorActions(
        `No hemos podido entrontrar un tipo de comprobante para realizar la emision de <strong>nota de credito</strong>`
      );
      return;
    }

    //sacamos los datos necesario del comprobante
    let numberCurrent = creditNote[0].numero_actual;
    const description = creditNote[0].descripcion;
    const numberEnd = creditNote[0].numero_final;
    const serie = creditNote[0].serie;
    const id = creditNote[0].id;

    //incrementamos el numero del comprobante
    numberCurrent = (parseInt(numberCurrent) + 1)
      .toString()
      .padStart(numberCurrent.length, "0");

    const printFormat = await getPrintFormatToPaper(creditNote[0].id); //? Formato de impresion del documento

    return {
      description,
      numberCurrent,
      numberEnd,
      serie,
      id,
      printFormat,
    };
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar un tipo de comprobante para realizar la emision de la nota de credito <strong>${error}</strong>`
    );
  }
};




/**
 *
 * @returns Retorna los datos del comprobante guia de remision remitente
 */
export const voucherReferralGuide = async () => {
  try {
    const response = await axios.get("comprobantes/?page=1&per_page=20");
    const vouchers = await response.data.data;
    //* filtramos el comprobante orden de compra
    const creditNote = vouchers.filter(
      (voucher) =>
        voucher.descripcion.toUpperCase() === "GUÍA DE REMISIÓN" ||
        voucher.descripcion.toUpperCase() === "GUIA DE REMISION" ||
        voucher.descripcion.toUpperCase() === "GUÍA DE REMISIÓN REMITENTE" ||
        voucher.descripcion.toUpperCase() === "GUIA DE REMISION REMITENTE"
    );
    if (creditNote.length <= 0) {
      errorActions(
        `No hemos podido entrontrar un tipo de comprobante para realizar la emision de de <strong>guía de remisión</strong>`
      );
      return;
    }

    //sacamos los datos necesario del comprobante
    let numberCurrent = creditNote[0].numero_actual;
    const description = creditNote[0].descripcion;
    const numberEnd = creditNote[0].numero_final;
    const serie = creditNote[0].serie;
    const id = creditNote[0].id;

    //incrementamos el numero del comprobante
    numberCurrent = (parseInt(numberCurrent) + 1)
      .toString()
      .padStart(numberCurrent.length, "0");

    const printFormat = await getPrintFormatToPaper(creditNote[0].id); //? Formato de impresion del documento

    return {
      description,
      numberCurrent,
      numberEnd,
      serie,
      id,
      printFormat,
    };
  } catch (error) {
    errorActions(
      `No hemos podido entrontrar un tipo de comprobante para realizar la emision de la guia de remision <strong>${error}</strong>`
    );
  }
};