<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="mainForm">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[80vw] lg:min-w-[900px] xl:min-w-[1024px]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de servicios
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-4 gap-4">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Clientes
              </span>
              <SearchCustomer @send-data="getCustomer" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Serivicios
              </span>
              <SearchServicesDGII @send-data="getServices" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° comprobante <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.numberVuocher"
                placeholder="N° comprobante..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° comprobante modificado
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.moidifiedReceiptNumber"
                placeholder="N° comprobante modificado..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en servicios <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.amountInServices"
                placeholder="Monto en servicios..."
                @keydown.enter.prevent
                min="0"
                @input="calculateTotal"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en bienes <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.amountInGoods"
                placeholder="Monto en bienes..."
                @keydown.enter.prevent
                min="0"
                @input="calculateTotal"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto total facturado <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.totalAmountBilled"
                placeholder="Monto total facturado..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} facturado <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvInvoiced"
                placeholder="facturado..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} retenido <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvWithheld"
                placeholder="retenido..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} sujeto a la proporcionalidad
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvSubjectToProportionality"
                placeholder="sujeto a la proporcionalidad..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} llevado a costo
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvCarriedAtCost"
                placeholder="llevado a costo..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} por adelantar
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvForAdvance"
                placeholder="por adelantar..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} percivido en compras
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvPerceivedInPurchase"
                placeholder="percivido en compras..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de retencion ISR <span class="text-red-300">*</span>
              </span>
              <select
                class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none focus:border-blue-500 w-full"
                v-model="dataForm.withholdingTypeInISR"
              >
                <option value="Alquileres">Alquileres</option>
                <option value="Honorarios por servicios">
                  Honorarios por servicios
                </option>
                <option value="Otras rentas">Otras rentas</option>
                <option value="Otras rentas (rentas presuntas)">
                  Otras rentas (rentas presuntas)
                </option>
                <option
                  value="Intereses pagados a personas jurídicas residentes"
                >
                  Intereses pagados a personas jurídicas residentes
                </option>
                <option value="Intereses pagados a personas físicas residentes">
                  Intereses pagados a personas físicas residentes
                </option>
                <option value="Retención por proveedores del Estado">
                  Retención por proveedores del Estado
                </option>
                <option value="Juegos telefónicos">Juegos telefónicos</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto de retencion renta <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.rentWithholdingAmOunt"
                placeholder="Monto de retencion renta..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ISR percivido en compras <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.ISRPerceivedInPurChases"
                placeholder="ISR percivido en compras..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Impuesto selectivo al consumo
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.exciseTax"
                placeholder="Impuesto selectivo al consumo..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Otros impuestos tasas <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.otherTaxesFees"
                placeholder="Otros impuestos tasas..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto propina legal <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.legalTipAmount"
                placeholder="Monto propina legal..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Forma de pago <span class="text-red-300">*</span>
              </span>
              <select
                class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none focus:border-blue-500 w-full"
                v-model="dataForm.waToPay"
              >
                <option value="01-Efectivo">01-Efectivo</option>
                <option value="02-Cheques/Transferencias/Depósitos">
                  02-Cheques/Transferencias/Depósitos
                </option>
                <option value="03-Tarjeta crédito/débito">
                  03-Tarjeta crédito/débito
                </option>
                <option value="04-Compra a crédito">04-Compra a crédito</option>
                <option value="05-Permuta">05-Permuta</option>
                <option value="06-Notas de crédito">06-Notas de crédito</option>
                <option value="07-Mixto">067-Mixto</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fecha de pago <span class="text-red-300">*</span>
              </span>
              <input
                type="date"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.paymentDate"
                placeholder="Fecha de pago..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fecha de comprobante <span class="text-red-300">*</span>
              </span>
              <input
                type="date"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.voucherDate"
                placeholder="Fecha de comprobante..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
          </div>
          <button
            class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
            @click="insert"
            :disabled="spinner === true"
          >
            <div class="flex items-center" v-if="spinner">
              <svg class="w-4 h-4 mr-3 animate-spin">
                <use href="@/assets/svg/icon.svg#spinnerForBlue" />
              </svg>
              Registrando Información
            </div>
            <span v-else>Registrar</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import axios from "@/api";
import { observeElement } from "@/observer";
import SearchCustomer from "@/components/ServicesDGII/SearchCustomer.vue";
import SearchServicesDGII from "@/components/ServicesDGII/SearchServicesDGII.vue";
import config from "/public/apis/config.json";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
} from "@/publicjs/localStorage";

export default {
  name: "RegisterPaymentServicesDGII",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getData: {
      type: Function,
      required: true,
    },
  },
  components: {
    SearchServicesDGII,
    SearchCustomer,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      idTurn: 0,
      idService: 0,
      idCustomer: 0,
      numberVuocher: "",
      moidifiedReceiptNumber: "",
      amountInServices: 0,
      amountInGoods: 0,
      totalAmountBilled: 0,
      igvInvoiced: 0,
      igvWithheld: 0,
      igvSubjectToProportionality: 0,
      igvCarriedAtCost: 0,
      igvForAdvance: 0,
      igvPerceivedInPurchase: 0,
      withholdingTypeInISR: "Alquileres",
      rentWithholdingAmOunt: 0,
      ISRPerceivedInPurChases: 0,
      exciseTax: 0,
      otherTaxesFees: 0,
      legalTipAmount: 0,
      waToPay: "01-Efectivo",
      paymentDate: null,
      voucherDate: null,
      state: "ACTIVO",
      descriptionService: "",
    });
    const getCustomer = (data) => {
      dataForm.value.idCustomer = data.idCustomer;
    };
    const getServices = (data) => {
      dataForm.value.idService = data.id;
      dataForm.value.descriptionService = data.description;
    };

    const insert = async () => {
      if (dataForm.value.idCustomer <= 0) {
        errorActions("Selecciona un   <strong>cliente</strong>");
        return;
      }
      if (dataForm.value.idService <= 0) {
        errorActions("Selecciona un   <strong>servicio</strong>");
        return;
      }
      if (
        dataForm.value.paymentDate === null ||
        dataForm.value.paymentDate === ""
      ) {
        errorActions(
          "Selecciona una   <strong>fecha de pago del servicio</strong>"
        );
        return;
      }
      if (
        dataForm.value.voucherDate === null ||
        dataForm.value.voucherDate === ""
      ) {
        errorActions("Selecciona una   <strong>fecha del comprobante</strong>");
        return;
      }
      if (dataForm.value.totalAmountBilled <= 0) {
        errorActions(
          "El monto total facturado debe de ser   <strong>mayor a 0</strong>"
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "pago-de-servicios",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idTurn: dataForm.value.idTurn,
            idService: dataForm.value.idService,
            idCustomer: dataForm.value.idCustomer,
            numberVuocher: dataForm.value.numberVuocher,
            moidifiedReceiptNumber: dataForm.value.moidifiedReceiptNumber,
            amountInServices: dataForm.value.amountInServices,
            amountInGoods: dataForm.value.amountInGoods,
            totalAmountBilled: dataForm.value.totalAmountBilled,
            igvInvoiced: dataForm.value.igvInvoiced,
            igvWithheld: dataForm.value.igvWithheld,
            igvSubjectToProportionality:
              dataForm.value.igvSubjectToProportionality,
            igvCarriedAtCost: dataForm.value.igvCarriedAtCost,
            igvForAdvance: dataForm.value.igvForAdvance,
            igvPerceivedInPurchase: dataForm.value.igvPerceivedInPurchase,
            withholdingTypeInISR: dataForm.value.withholdingTypeInISR,
            rentWithholdingAmOunt: dataForm.value.rentWithholdingAmOunt,
            ISRPerceivedInPurChases: dataForm.value.ISRPerceivedInPurChases,
            exciseTax: dataForm.value.exciseTax,
            otherTaxesFees: dataForm.value.otherTaxesFees,
            legalTipAmount: dataForm.value.legalTipAmount,
            waToPay: dataForm.value.waToPay,
            paymentDate: dataForm.value.paymentDate,
            voucherDate: dataForm.value.voucherDate,
            state: dataForm.value.state,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El pago del servicio  <strong>${dataForm.value.descriptionService}</strong>`
        );
        await props.getData();
        isActive();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el pago del  servicio <strong>${dataForm.value.descriptionService}</strong>`
        );
      }

      spinner.value = false;
    };
    onMounted(async () => {
      observeElement("#mainForm");
      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idTurn = await idLocalTurn();
      dataForm.value.idUser = await idLocalUser();
    });

    const calculateTotal = () => {
      dataForm.value.totalAmountBilled =
        dataForm.value.amountInGoods + dataForm.value.amountInServices;
    };

    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getCustomer,
      getServices,
      config,
      calculateTotal,
    };
  },
};
</script>
