<template>
    <div>
      <h2 class="text-1xl font-bold text-gray-700">
        Configuración de turnos
      </h2>
      <MainTurns />
    </div>
  </template>
  
  <script>
  import MainTurns from "@/components/Users/Turns/MainTurns.vue";
import { useRoute } from 'vue-router';
import { onBeforeMount, onMounted } from 'vue';
import { findSubmoduleIdByDescription } from '@/hisAcces';
  export default {
    name: "MainTurn",
    components: {
        MainTurns,
    },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
  };
  </script>