<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    id="contenRegisterReferalGuide"
  >
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] md:max-w-[80vw] lg:max-w-[80vw]"
        id="contentFormReferralGuide"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Guia de remisión ventas
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div class="mb-3">
          <SearchSales @send-data="getSale" />
        </div>
        <div class="w-full">
          <div v-show="showTable">
            <h2 class="text-gray-700 font-bold text-sm">
              Detalles de la venta
            </h2>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <LoadingTables v-if="loading" :columns="5" />
                  <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                    <tr
                      v-for="(sale, i) in arrayDataOrder"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.nombre_producto }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.cantidad }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.precio_de_venta) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-center"
                      >
                        <button
                          class="bg-blue-50 text-blue-500 text-sm font-bold px-2 py-1 rounded-md"
                          @click="
                            addProducts({
                              idProduct: parseInt(sale.idproducto),
                              quantity: parseFloat(sale.cantidad),
                              productName: sale.nombre_producto,
                              weight: 0,
                            })
                          "
                        >
                          <font-awesome-icon icon="fa-solid fa-plus" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mt-10 border-t-2 border-blue-300 pt-5">
            <h2 class="text-blue-500 font-bold text-md mb-3" v-show="showTable">
              Detalles de la guía
            </h2>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
              v-show="showTable"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="headerReferralGuide" />
                  <LoadingTables v-if="loading" :columns="4" />
                  <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                    <tr
                      v-for="(shopping, i) in ReferralGuideDetail"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ shopping.productName }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="shopping.quantity"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="handleQuantityChange(i, $event)"
                          @keyup.enter="handleQuantityChange(i, $event)"
                        />
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="shopping.weight"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="handleWeightChange(i, $event)"
                          @keyup.enter="handleWeightChange(i, $event)"
                        />
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-center"
                      >
                        <button
                          class="bg-red-50 text-red-500 text-sm font-bold px-2 py-1 rounded-md"
                          @click="removeProduct(i)"
                        >
                          <font-awesome-icon icon="fa-solid fa-cancel" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="mt-3 bg-blue-50 px-3 py-3 rounded-md border-blue-50 border-2"
            >
              <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                <label class="block w-full md:w-auto md:col-span-2">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Nombre del transportista
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.carrier"
                    placeholder="Nombre del transportista..."
                    @keydown.enter.prevent
                    maxlength="98"
                  />
                </label>
                <label class="block w-full md:w-auto">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    {{ config.titleTypeDocument }} del transportista
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.rucCarrier"
                    placeholder="N° documento del transportista..."
                    @keydown.enter.prevent
                    maxlength="15"
                  />
                </label>
                <label class="block w-full md:w-auto">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Celular transportista
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.carrierPhone"
                    placeholder="Celular transportista..."
                    @keydown.enter.prevent
                    maxlength="30"
                  />
                </label>
                <label class="block w-full md:w-auto">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Licencia de conducir
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.driverLicense"
                    placeholder="Licencia de conducir..."
                    @keydown.enter.prevent
                    maxlength="20"
                  />
                </label>
                <label class="block w-full md:w-auto">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Placa del vehículo
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.mobilityPlate"
                    placeholder="Placa del vehículo..."
                    @keydown.enter.prevent
                    maxlength="15"
                  />
                </label>
                <label class="block w-full md:w-auto">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Peso total del transporte
                  </span>
                  <input
                    type="number"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.totalWeightOfTransportation"
                    placeholder="Peso total del transporte..."
                    @keydown.enter.prevent
                    min="0"
                  />
                </label>
                <label class="block w-full md:w-auto">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Fecha de traslado
                  </span>
                  <input
                    type="date"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.transferDate"
                    @keydown.enter.prevent
                  />
                </label>
                <label class="block w-full md:w-auto">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Hora de traslado
                  </span>
                  <input
                    type="time"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.transferTime"
                    @keydown.enter.prevent
                  />
                </label>
                <label
                  class="block w-full md:w-auto md:col-span-2 lg:col-span-3"
                >
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Punto de partida
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.point"
                    placeholder="Punto de partida..."
                    @keydown.enter.prevent
                    maxlength="98"
                  />
                </label>
                <label
                  class="block w-full md:w-auto md:col-span-2 lg:col-span-3"
                >
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Punto de llegada
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.arrivalPoint"
                    placeholder="Punto de llegada..."
                    @keydown.enter.prevent
                    maxlength="98"
                  />
                </label>
                <label
                  class="block w-full md:w-auto md:col-span-2 lg:col-span-3"
                >
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Motivo
                  </span>
                  <input
                    type="text"
                    class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                    v-model="dataForm.motive"
                    placeholder="Motivo..."
                    @keydown.enter.prevent
                    maxlength="98"
                  />
                </label>
              </div>
              <button
                class="bg-blue-500 mt-6 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full md:w-auto justify-center"
                @click="insert"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="@/assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Registrando Información
                </div>
                <span v-else>Registrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import axios from "@/api";
import { format, money } from "@/publicjs/money";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";

import SearchSales from "@/components/ReferralGuide/SearchSales.vue";

import config from "/public/apis/config.json";

import { updateNumberVoucher, voucherReferralGuide } from "@/publicjs/vouchers";

//print voucher
import { printVoucherOnA4 } from './PrintVoucher/PrintOnA4'
import { printVoucherOnLetter } from './PrintVoucher/PrintOnLetter'
import { printVoucherOnMediaLetter } from './PrintVoucher/PrintOnMediaLetter'
import { printVoucherTicket } from './PrintVoucher/PrintOnTicket'

export default {
  name: "RegisterReferralGuide",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    LoadingTables,
    TableHead,
    SearchSales,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);
    const loading = ref(true);

    const showTable = ref(false);

    const dataForm = ref({
      idSale: 0,
      idUser: 0,
      idStore: 0,
      destinationBranchId: 0,
      idVoucher: 0,
      numberVoucher: "",
      serieVoucher: "",
      transferDate: "",
      transferTime: "",
      motive: "",
      arrivalPoint: "",
      point: "",
      carrier: "",
      carrierPhone: "",
      rucCarrier: "",
      driverLicense: "",
      mobilityPlate: "",
      totalWeightOfTransportation: 0,
    });
    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "Importe",
        },
        {
          title: "Total",
        },
        {
          title: "Acciones",
        },
      ],
    ];
    const headerReferralGuide = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "Peso",
        },
        {
          title: "acciones",
        },
      ],
    ];

    //funcion para traer los detalles de la venta
    const arrayDataOrder = ref([]);
    const ReferralGuideDetail = ref([]);
    const getSaleDetails = async (id) => {
      const response = await axios
        .get(`detalle-de-ventas/${id}`)
        .catch((error) => errorActions(error));
      const data = response.data;
      if (data.length <= 0) {
        errorActions("No hemos podido encontrar los detalles de la compra");
      } else {
        arrayDataOrder.value = data;
        loading.value = false;
      }
    };
    const getSale = async (data) => {
      dataForm.value.idSale = data.id;
      await getSaleDetails(data.id);
      showTable.value = true;
    };

    /*****************************************************************************
     * Funcion para validar si ya se emitio una nota de credito del producto
     * @param {*} idProduct ID del producto a verificar
     * @param {*} idSale ID de la venta
     *****************************************************************************/
    const verifyProduct = async (idSale, idProduct) => {
      const response = await axios
        .get(`guia-de-remision-ventas/verificar/${idSale}/${idProduct}`)
        .catch((error) => error(error));
      return await response.data;
    };

    /*****************************************************************************
     * Funcion para añadir producto a lista de productos a devolver
     * @param {*} detail detalle de la venta
     *****************************************************************************/
    const addProducts = async (detail) => {
      let { idProduct, quantity, productName, weight } = detail;

      const isVerify = await verifyProduct(dataForm.value.idSale, idProduct);

      if (isVerify.state === true) {
        if (parseFloat(isVerify.cantidad) > parseFloat(quantity)) {
          errorActions(
            "Ya se emitio una guia de remision por la totalidad de esde producto"
          );
          return;
        } else {
          quantity -= parseFloat(isVerify.cantidad);
        }
      }
      // Verificar si el producto ya ha sido agregado
      const isProductAdded = ReferralGuideDetail.value.some(
        (product) =>
          product.idProduct === idProduct && product.productName === productName
      );

      if (isProductAdded) {
        errorActions(
          "El producto que esta intentando añadir ya se añadió con anterioridad a la lista de productos de la guía"
        );
        return;
      }

      ReferralGuideDetail.value.push({
        idProduct,
        quantity,
        productName,
        weight,
      });
      calculateTheTotal(ReferralGuideDetail.value);
    };

    /*****************************************************************************
     * Funcion para quitar productos de la lista de devolucion
     * @param {*} index Indice del registro que sea elminado
     *****************************************************************************/
    const removeProduct = (index) => {
      // Verificar si el índice está dentro de los límites del array
      if (index >= 0 && index < ReferralGuideDetail.value.length) {
        ReferralGuideDetail.value.splice(index, 1);
        calculateTheTotal(ReferralGuideDetail.value);
      } else {
        errorActions("Índice inválido. No se pudo remover el producto.");
      }
    };

    /*****************************************************************************
     * Funcion para cambiar la cantidad de la orden
     * @param {*} index Indice del registro
     * @param {*} event El evento en este caso el valor que vendra en ese evento
     *****************************************************************************/
    const handleQuantityChange = (index, event) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;

      if (newQuantity > ReferralGuideDetail.value[index].quantity) {
        errorActions(
          "Lo sentimos, Usted no puede emitir una guia de remisión de un producto mayor a lo especificado"
        );
        event.target.value = ReferralGuideDetail.value[index].quantity;
        return;
      }
      if (newQuantity <= 0) {
        errorActions(
          "Lo sentimos, Usted no puede realizar una guia de remision de un producto menor a 0"
        );
        event.target.value = ReferralGuideDetail.value[index].quantity;
        return;
      }

      ReferralGuideDetail.value[index].quantity = newQuantity;
      calculateTheTotal(ReferralGuideDetail.value);
    };

    /*****************************************************************************
     * Funcion para el peso total del producto
     * @param {*} index Indice del registro
     * @param {*} event El evento en este caso el valor que vendra en ese evento
     *****************************************************************************/
    const handleWeightChange = (index, event) => {
      const newWeight =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;

      ReferralGuideDetail.value[index].weight = newWeight;
      calculateTheTotal(ReferralGuideDetail.value);
    };

    /*****************************************************************************
     * Funcion para calcular el total de la orden, incluyendo el iva, subtotal
     * @param {*} order el array de objetos de productos de la tabla
     *****************************************************************************/
    const calculateTheTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      dataForm.value.totalWeightOfTransportation = 0;
      //recorremos todo el detalle de la orden
      order.forEach((element) => {
        dataForm.value.totalWeightOfTransportation += parseFloat(
          element.weight
        );
      });
    };

    onMounted(async () => {
      observeElement("#contenRegisterReferalGuide");
      observeElement("#contentFormReferralGuide");

      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
    });

    const insert = async () => {
      spinner.value = true;

      if (ReferralGuideDetail.value.length <= 0) {
        errorActions(
          "No podemos emitir una  <strong>GUIA DE REMISION</strong> debido a que no a agregado ningun producto a  la lista"
        );
        spinner.value = false;
        return;
      }
      if (
        dataForm.value.transferDate == null ||
        dataForm.value.transferDate == ""
      ) {
        errorActions(
          "Selecciona la fecha de partida de la  <strong>GUIA DE REMISION</strong>"
        );
        spinner.value = false;
        return;
      }
      if (
        dataForm.value.transferTime == null ||
        dataForm.value.transferTime == ""
      ) {
        errorActions(
          "Selecciona la hora de partida de la  <strong>GUIA DE REMISION</strong>"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.carrier.trim() <= 3) {
        errorActions(
          "Ingresa el nombre del transportista para la  <strong>GUIA DE REMISION</strong>"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.motive.trim() <= 2) {
        errorActions(
          "Ingresa el motivo de envio para la  <strong>GUIA DE REMISION</strong>"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.point.trim() <= 3) {
        errorActions(
          "Ingresa el punto de partida para  <strong>GUIA DE REMISION</strong>"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.arrivalPoint <= 4) {
        errorActions(
          "Ingresa el punto de llegada o destino para  <strong>GUIA DE REMISION</strong>"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.rucCarrier <= 6) {
        errorActions(
          "Ingresa el numero de documento del transportista para la <strong>GUIA DE REMISION</strong>"
        );
        spinner.value = false;
        return;
      }
      const { id, numberCurrent, serie, printFormat } = await voucherReferralGuide();
      dataForm.value.idVoucher = id;
      dataForm.value.numberVoucher = numberCurrent;
      dataForm.value.serieVoucher = serie;

      const response = await axios
        .post(
          "guia-de-remision-ventas",
          {
            idUser: dataForm.value.idUser,
            idSale: dataForm.value.idSale,
            idStore: dataForm.value.idStore,
            destinationBranchId: dataForm.value.idStore,
            idVoucher: dataForm.value.idVoucher,
            numberVoucher: dataForm.value.numberVoucher,
            serie: dataForm.value.serieVoucher,
            transferDate: dataForm.value.transferDate,
            transferTime: dataForm.value.transferTime,
            motive: dataForm.value.motive,
            arrivalPoint: dataForm.value.arrivalPoint,
            point: dataForm.value.point,
            carrier: dataForm.value.carrier,
            carrierPhone: dataForm.value.carrierPhone,
            rucCarrier: dataForm.value.rucCarrier,
            driverLicense: dataForm.value.driverLicense,
            mobilityPlate: dataForm.value.mobilityPlate,
            totalWeightOfTransportation:
              dataForm.value.totalWeightOfTransportation,
            arrayDetail: ReferralGuideDetail.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(`La  <strong>GUÍA DE REMISIÓN</strong> `);

        await updateNumberVoucher(
          dataForm.value.idVoucher,
          dataForm.value.numberVoucher
        );

        if (printFormat === "formato_a4") {
          await printVoucherOnA4(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (printFormat === "formato_8x11") {
          await printVoucherOnLetter(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (printFormat === "formato_media_carta") {
          await printVoucherOnMediaLetter(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (printFormat === "formato_ticket") {
          await printVoucherTicket(data.id); // Como parametro recive el ID  de la ultima orden
        }
        props.getdata();
        isActive();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar la <strong>GUÍA DE REMISIÓN</strong>`
        );
      }

      spinner.value = false;
    };

    return {
      isActive,
      dataForm,
      insert,
      spinner,
      header,
      loading,
      arrayDataOrder,
      format,
      money,
      ReferralGuideDetail,
      addProducts,
      removeProduct,
      handleQuantityChange,
      handleWeightChange,
      getSale,
      headerReferralGuide,
      config,
      showTable,
    };
  },
};
</script>
