import { saveAs } from "file-saver";
import { getCurrentDate } from "@/publicjs/currentDate";
import ExcelJS from "exceljs";
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { idLocalStore } from "@/publicjs/localStorage"; //? Recuperar el id de la sucursal
import { closeAlert, errorActions, uploadData } from "@/alerts";
import stylesExcel from "@/publicjs/stylesExcel";
export const exportToExcel = async (data) => {
  try {
    await uploadData(
      "Generando reporte".toUpperCase(),
      "Espera un momento mientras preparamos tu reporte, <strong>solo tomará unos segundos....</strong>"
    );

    //* Traer los datos de la sucursal
    const dataCompany = await getCompany(await idLocalStore());

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Lotes".toUpperCase());

    const styles = {
      title: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.xl,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      subtitle: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      header: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "left", vertical: "middle" },
      },
      total: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.green },
        },
        alignment: { horizontal: "right", vertical: "middle" },
      },
    };

    // Agregar título
    let rowNumber = 1; // Número de fila que deseas ajustar la altura
    let row = worksheet.getRow(rowNumber);

    worksheet.mergeCells("A1:G1");
    const titleReport = worksheet.getCell("A1");
    titleReport.value = "Reporte de lotes".toLowerCase();
    titleReport.style = styles.title;
    row.height = 35;

    rowNumber = 2;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A2:G2");
    const store = worksheet.getCell("A2");
    store.value = "SUCURSAL : " + dataCompany.storeName.toUpperCase();
    store.style = styles.subtitle;
    row.height = 30;

    rowNumber = 3;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A3:G3");
    const dateReport = worksheet.getCell("A3");
    dateReport.value = "FEC:" + getCurrentDate();
    dateReport.style = styles.subtitle;
    row.height = 30;

    // Agregar encabezados
    const headers = [
      "LOTE",
      "PRODUCTOS",
      "VENCIMIENTO",
      "CANTIDAD",
      "P. DE COMPRA	",
      "P. DE VENTA	",
      "ESTADO",
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = styles.header;
    });

    rowNumber = 4; // Número de fila que deseas ajustar la altura
    row = worksheet.getRow(rowNumber);
    row.height = 35;

    // Agregar datos
    data.forEach((rowData) => {
      const rows = worksheet.addRow([
        rowData.descripcion,
        rowData.nombre_producto,
        rowData.fecha_vencimiento,
        Number(rowData.cantidad), //? Convertir el valor a número
        Number(rowData.precio_compra), //? Convertir el valor a número
        Number(rowData.precio_venta), //? Convertir el valor a número
        rowData.estado,
      ]);
      //Estilos para las columnas
      rows.getCell(7).style.font = {
        bold: true,
        color: { argb: stylesExcel.color.warning },
      };
      (rows.getCell(7).style.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: stylesExcel.color.warningTransparent },
      }),
        (rows.getCell(7).alignment = {
          horizontal: "left",
          vertical: "middle",
        }),
        (rows.getCell(6).style.font = {
          bold: true,
          size: stylesExcel.fontSize.md,
        });
    });

    //* Ajustar ancho de columnas
    worksheet.columns.forEach((column, index) => {
      //Ancho para la columna [0]
      if (index === 0) {
        column.width = 22;
      } else if (index === 1) {
        //Ancho para la columna [5]
        column.width = 40;
      } else if (
        index === 2 ||
        index === 3 ||
        index === 4 ||
        index === 5 ||
        index === 6
      ) {
        //Ancho para la columna [5]
        column.width = 25;
      } else {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      }
    });

    //! Agregar una nueva fila debajo de los totales
    const newTotalRow = worksheet.addRow([]);

    /************************************************************************************************ */
    const totalRow = data.length + 4; //total de filas

    const columnPosition = 6; //Posicion de la columna para sumar datos
    /************************************************************************************************ */

    //? Calcular la fórmula de suma como un string
    const sumFormula = `SUM(F5:F${totalRow})`;
    newTotalRow.getCell(columnPosition).value = {
      formula: sumFormula,
      result: 0,
    };

    //* Dar formato a la moneda
    newTotalRow.getCell(columnPosition).style = styles.total;
    newTotalRow.getCell(columnPosition).numFmt = "#,##0.00";

    //* Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), getCurrentDate() + ".xlsx");
    await closeAlert(); //Cerramos el modal de aviso
  } catch (error) {
    errorActions(
      "Lo sentimos no pudimos generar el reporte, intentalo mas tarde"
    );
  }
};
