/**
 * Retorna la fecha y hora actual en formato "DD-MM-YYYY HH:mm:ss"
 * @returns {string} La fecha y hora actual en el formato especificado
 */
export const getCurrentDateTime = () => {
  const currentDate = new Date();

  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentYear = currentDate.getFullYear();

  const currentHour = String(currentDate.getHours()).padStart(2, "0");
  const currentMinutes = String(currentDate.getMinutes()).padStart(2, "0");
  const currentSeconds = String(currentDate.getSeconds()).padStart(2, "0");

  return `${currentYear}-${currentMonth}-${currentDay} ${currentHour}:${currentMinutes}:${currentSeconds}`;
};
