const styles = {
  colors: {
    colorTitle: "#152E3B",
    colorDescription: "#374151",
    colorWarning: "#EE7700",
    colorSuccess: "#1D9B4F",
    bgSuccessTransparent: "#D6FFE6",
    bgWarningTransparent: "#FEE2BE",
    bgBlueTransparent: "#EFFBFF",
    white: "#FFFFFF",
    borderColor: "#AEBCC3",
    borderColorDark: "#1A3847",
    colorBlue: "#3b82f6",
    black: "#000000",
  },
  fontSize: {
    pdf: {
      xl: 16,
      lg: 13,
      md: 11,
      sm: 10,
      xsm: 9,
    },
    ticket: {
      xl: 10,
      lg: 9,
      md: 8,
      sm: 7,
    },
    pdfml: {
      xl: 10,
      lg: 8,
      md: 7,
      sm: 6,
    },
    table: {
      xl: 14,
      lg: 11,
      md: 9,
      sm: 8,
      xsm: 7,
    },
  },
};

export default styles;
