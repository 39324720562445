<template>
  <div>
    <div
      class="py-2 px-3 text-sm text-gray-600 border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-100 animate-pulse outline-none uppercase"
      v-show="loading"
    >
      Cargando comprobantes
    </div>
    <select
      class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none cursor-pointer"
      v-model="infoVoucher.id"
      @change="sendDataSelect"
      v-show="!loading"
      id="voucherOfSale"
    >
      <option
        :value="register.id"
        v-for="(register, index) in registers"
        :key="index"
        :selected="register.fijado === 1"
      >
        {{ register.descripcion }}
      </option>
    </select>
  </div>
</template>

<script>
import { nextTick, onMounted, ref } from "vue";
import axios from "../../api";
import { errorActions } from "@/alerts";

export default {
  name: "GetVouchersOfSale",
  emits: ["send-data"], //Si da problemas eliminarlo
  setup(_, { emit }) {
    const registers = ref([]);
    const loading = ref(true);
    const infoVoucher = ref({
      id: 0,
      name: "",
    });

    const sendDataSelect = async () => {
      await getNameVoucher();
      emit("send-data", infoVoucher.value);
    };

    /*********************************************************************************
     * Funcion para traer el inner text del option de lista de comprobantes
     *********************************************************************************/
    const getNameVoucher = async () => {
      const selectElement = document.getElementById("voucherOfSale");
      infoVoucher.value.name =
        selectElement.options[selectElement.selectedIndex].text;
    };

    onMounted(async () => {
      try {
        const response = await axios
          .get(`comprobantes/?page=1&per_page=30`)
          .catch((error) => errorActions(error));
        const vouchers = await response.data.data;

        // FILTRAMOS LOS COMPROBANTES QUE SON DE USO REAL PARA VENTAS Y COMPRAS
        const voucherOfSales = vouchers.filter(
          (voucher) =>
            voucher.descripcion.toUpperCase() !== "ORDENES DE COMPRA" &&
            voucher.descripcion.toUpperCase() !== "ORDEN DE COMPRAS" &&
            voucher.descripcion.toUpperCase() !== "ORDEN DE COMPRA" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE DEBITO" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE DÉBITO" &&
            voucher.descripcion.toUpperCase() !== "GUÍA DE REMISIÓN" &&
            voucher.descripcion.toUpperCase() !==
              "GUÍA DE REMISION REMITENTE" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CRÉDITO INTERNO" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CREDITO INTERNO" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CRÉDITO" &&
            voucher.descripcion.toUpperCase() !== "FACTURA PROFORMA" &&
            voucher.descripcion.toUpperCase() !== "PROFORMA" &&
            voucher.descripcion.toUpperCase() !== "COTIZACION" &&
            voucher.descripcion.toUpperCase() !== "COTIZACIÓN" &&
            voucher.descripcion.toUpperCase() !== "COTIZACIONES" &&
            voucher.descripcion.toUpperCase() !== "PEDIDOS" &&
            voucher.descripcion.toUpperCase() !== "FACTUAL HOLD" &&
            voucher.descripcion.toUpperCase() !== "PEDIDO CLIENTE" &&
            voucher.descripcion.toUpperCase() !== "FACTURAL OLD" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CREDITO"
        );

        // FILTRAMOS SOLO LOS COMPROBANTES QUE SE ESTÁN TRABAJANDO
        registers.value = voucherOfSales.filter(
          (voucher) => voucher.trabajando === 1
        );

        if (registers.value.length === 0) {
          return errorActions(
            "Lo sentimos, no hemos encontrado comprobantes en nuestra base de datos"
          );
        }

        // Actualizamos el valor de selectedOption solo si register.fijado es igual a 1
        const selectedRegister = registers.value.find(
          (register) => register.fijado === 1
        );
        if (selectedRegister) {
          infoVoucher.value.id = selectedRegister.id;
          infoVoucher.value.name = selectedRegister.descripcion;
        }
        nextTick(async () => {
          await getNameVoucher();
        });

        emit("send-data", infoVoucher.value);
        loading.value = false;
      } catch (error) {
        errorActions(error);
      }
    });

    return {
      registers,
      loading,
      sendDataSelect,
      infoVoucher,
    };
  },
};
</script>
