/**
 * Retorna el primer y el ultimo dìa segun el mes y año seleccionado en el formato YYYY-MM-DD
 * @param {*} month Número del mes
 * @param {*} anio | Año actual o año anterior
 */
export const getFirstAndLastDayOfMount = async (month, anio) => {
  // Obtener el primer día del mes
  const firstDay = new Date(anio, month - 1, 1);

  // Obtener el último día del mes
  const lastDay = new Date(anio, month, 0);

  // Formatear las fechas en el formato deseado (dd-mm-yyyy)
  const firstDayFormatted = firstDay
    .toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .reverse()
    .join("-");
  const lastDayFormatted = lastDay
    .toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  // Retornar las fechas formateadas
  return {
    firstDayFormatted,
    lastDayFormatted,
  };
};

/**
 * @returns | Retorna el primer dia  y el ultimo día del mes actual
 */
export const firstDaytAndLastDay = async () => {
  // Obtener la fecha actual
  const currentDate = new Date();

  // Obtener el primer día del mes actual
  const primerDiaMes = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const firstDay = primerDiaMes
    .toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .reverse()
    .join("-");

  // Obtener el último día del mes actual
  const ultimoDiaMes = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const lastDay = ultimoDiaMes
    .toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .reverse()
    .join("-");

  return {
    firstDay,
    lastDay,
  };
};
