<template>
  <div>
      <h2 class="text-1xl font-bold text-gray-700 mb-3">
        Listado de pedidos o facturas hold
      </h2>
    <MainCustomerOrder />
  </div>
</template>

<script>
import MainCustomerOrder from "@/components/CustomerOrder/MainCustomerOrder.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "CustomerOrder",
  components: {
    MainCustomerOrder,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
