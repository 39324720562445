<template>
    <div class="border border-blue-100 mt-3 bg-blue-50 px-3 py-3 rounded-md">
      <div class="flex items-center justify-end text-blue-500 font-semibold mb-2" v-if="totalCreditNote > 0">
        <div class="mr-3">NOTA DE CREDITO</div>
        <div class="text-blue-500 font-bold">{{ money() }}{{ format(totalCreditNote) }}</div>
      </div>
      <div class="flex items-center justify-end text-orange-500 font-semibold mb-2" v-if="totalCreditNote > 0">
        <div class="mr-3">DIF. A PAGAR</div>
        <div class="text-orange-500 font-bold">{{ money() }}{{ format(totalCreditNoteDiference) }}</div>
      </div>
      <div class="flex items-center justify-end text-blue-600 font-semibold mb-2">
        <div class="mr-3">{{ config.iva }}</div>
        <div class="text-blue-500 font-bold">{{ money() }}{{ format(iva) }}</div>
      </div>
      <div class="flex items-center justify-end text-blue-600 font-semibold mb-2">
        <div class="mr-3">SUBTOTAL</div>
        <div class="text-blue-500 font-bold">{{ money() }}{{ format(subTotal) }}</div>
      </div>
      <div class="flex items-center justify-end text-blue-600 font-semibold">
        <div class="mr-3">TOTAL</div>
        <div class="text-blue-500 font-bold">{{ money() }}{{ format(total) }}</div>
      </div>
    </div>
  </template>
  
  <script>
  import { money, format } from "@/publicjs/money";
  import config from "/public/apis/config.json";
  export default {
    name: "ShoppingTotalDetails",
    props: {
      iva: {
        type: Number,
        required: true,
      },
      subTotal: {
        type: Number,
        required: true,
      },
      total: {
        type: Number,
        required: true,
      },
      totalCreditNote: {
        type: Number,
        required: true,
      },
      totalCreditNoteDiference: {
        type: Number,
        required: true,
      },
    },
    setup() {
      return {
        money,
        format,
        config,
      };
    },
  };
  </script>
  