import { errorActions } from "@/alerts";
import axios from "../../api";
import Swal from "sweetalert2";
import { observeElement } from "@/observer";
//Acciones para eliminar
const update = async (id, msg) => {
  const response = await axios
    .put("lotes/" + id,{
        state: 'VENDIDO'
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      errorActions(error);
    });
  const data = response.data;
  if (data.msg === "Request failed with status code 500") {
    errorActions(data.msg);
  } else if (data.msg === false) {
    errorActions(
      `Lo sentimos no pudimos marcar el lote <strong>${msg}</strong> como vendido`
    );
  }
};
/**
 *
 * @param {*} id | ID del registro al cual queremos eliminar o anular
 * @param {*} msg | Descripcion del registro que queremos eliminar puede ser el nombre, etc.
 * @param {*} action | Funcion para volver a cargar los datos, pero esto funciona siempre en cuando n tenga parametros
 */
export const markAsSoldInLot = async (id, msg, action) => {
  Swal.fire({
    title: "¿Estas seguro que deseas marcar el lote como vendido?",
    html: `Si marcas el lote <strong>${msg}</strong> como vendido, no podras recuperarlo más adelante!`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#315df5",
    cancelButtonColor: "#dc2626",
    confirmButtonText: "Si, Marcar!",
    cancelButtonText: "Cancelar",
    width: "300px",
  }).then(async (result) => {
    observeElement(".swal2-container");
    if (result.isConfirmed) {
      await update(id, msg);
      Swal.fire({
        icon: "success",
        title: "Lote vendido",
        html: `El lote  <strong>${msg}</strong>, a sido marcado como vendido`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
      });

      observeElement(".swal2-container");

      await action();
    }
  });
};
