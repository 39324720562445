<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700">
      Configuración de unidad de medidas
    </h2>
    <MainUnitMeasures />
  </div>
</template>

<script>
import MainUnitMeasures from "@/components/Store/UnitMeasures/MainUnitMeasures.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "UnitMeasures",
  components: {
    MainUnitMeasures,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
