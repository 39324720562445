<template>
    <div>
      <MainShopping />
    </div>
  </template>
  
  <script>
  import MainShopping from "@/components/Shopping/MainShopping.vue";
import { useRoute } from 'vue-router';
import { onBeforeMount, onMounted } from 'vue';
import { findSubmoduleIdByDescription } from '@/hisAcces';
  export default {
    name: "ShoppingS",
    components: {
        MainShopping,
    },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
  };
  </script>
  