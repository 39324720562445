import { saveAs } from "file-saver";
import { getCurrentDate } from "@/publicjs/currentDate";
import ExcelJS from "exceljs";
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { idLocalStore } from "@/publicjs/localStorage"; //? Recuperar el id de la sucursal
import { closeAlert, errorActions, uploadData } from "@/alerts";
import stylesExcel from "@/publicjs/stylesExcel";
/**
 *
 * @param {*} data Array de datos para poblar toda la informacion
 */
export const exportToExcel = async (data) => {
  try {
    await uploadData(
      "Generando reporte".toUpperCase(),
      "Espera un momento mientras preparamos tu reporte,<br> <strong>Esto puede tardar unos segundos...</strong>"
    );

    //* Traer los datos de la sucursal
    const dataCompany = await getCompany(await idLocalStore());

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Inventario físico".toUpperCase());

    const styles = {
      title: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.xl,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      subtitle: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      header: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.sm,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "left", vertical: "middle" },
      },
      total: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.green },
        },
        alignment: { horizontal: "right", vertical: "middle" },
      },
    };

    // Agregar título
    let rowNumber = 1; // Número de fila que deseas ajustar la altura
    let row = worksheet.getRow(rowNumber);

    worksheet.mergeCells("A1:F1");
    const titleReport = worksheet.getCell("A1");
    titleReport.value = "Reporte de inventario físico".toUpperCase();
    titleReport.style = styles.title;
    row.height = 35;

    rowNumber = 2;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A2:F2");
    const store = worksheet.getCell("A2");
    store.value = "SUCURSAL : " + dataCompany.storeName.toUpperCase();
    store.style = styles.subtitle;
    row.height = 30;

    rowNumber = 3;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A3:F3");
    const dateReport = worksheet.getCell("A3");
    dateReport.value = `FECHA DE EMISIÓN ${getCurrentDate()}`;
    dateReport.style = styles.subtitle;
    row.height = 30;

    // Agregar encabezados
    const headers = [
      "COD. BARRA",
      "PRODUCTO",
      "UBICACIÓN",
      "STOCK",
      "P. COMPRA",
      "TOTAL EN COMPRAS",
      "P. VENTA",
      "TOTAL EN VENTAS",
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = styles.header;
    });

    rowNumber = 4; // Número de fila que deseas ajustar la altura
    row = worksheet.getRow(rowNumber);
    row.height = 35;

    // Agregar datos
    data.forEach((rowData) => {
      worksheet.addRow([
        rowData.codigo_de_barra,
        rowData.nombre_producto,
        rowData.ubicacion,
        Number(rowData.stock),
        Number(rowData.precio_de_compra),
        Number(rowData.total_shopping),
        Number(rowData.precio_de_venta),
        Number(rowData.total_sale),
      ]);
    });

    //* Ajustar ancho de columnas
    worksheet.columns.forEach((column, index) => {
      //Ancho para la columnas
      if (index === 0 || index === 3 || index === 4 || index === 5) {
        column.width = 20;
      } else if (index === 1 || index === 2) {
        column.width = 50;
      } else {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      }
    });

    //* Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      "Reporte de inventario físico" + getCurrentDate() + ".xlsx"
    );
    await closeAlert();
  } catch (error) {
    errorActions(
      "Lo sentimos no pudimos generar el reporte, intentalo mas tarde"
    );
  }
};
