<template>
  <div
    class="py-3 bg-blue-50 px-3 rounded-md border border-blue-100 shadow-sm shadow-blue-100"
    id="contentSearchInsurerData"
  >
    <div>
      <div class="w-full md:w-auto mt-3 md:mt-0">
        <span class="text-sm font-semibold text-blue-500 pb-2 block">
          Empresas aseguradoras
        </span>
        <div class="relative md:min-w-[260px] lg:min-w-[320px]">
          <div class="flex items-center relative text-gray-600">
            <div class="absolute top-0 left-3 w-6 h-full flex items-center">
              <font-awesome-icon icon="fa-solid fa-search" class="w-4 h-4" />
            </div>
            <input
              type="search"
              v-model="dataForm.searchInput"
              class="border-2 pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50 focus:border-blue-500"
              placeholder="Buscar por razon social o N° de documento"
              @keydown.enter="getData"
              @keydown.enter.prevent
              autocomplete="off"
              name="search"
            />
          </div>
          <div
            v-if="showInsurers"
            class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
          >
            <div v-if="loadingSearch">
              <div class="px-3">
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="!dataLength"
                class="px-3 py-1 bg-gray-50 text-gray-400"
                @click="showInsurers = false"
              >
                <strong>{{ dataForm.searchInput }}</strong> no existe en nuestra
                base de datos
              </div>
              <div
                v-else
                v-for="(result, i) in dataResult"
                :key="i"
                class="odd:bg-white even:bg-slate-50"
                @blur="showInsurers = false"
              >
                <div
                  class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                  @click="
                    selectInsurer({
                      idInsurer: result.id,
                      bussinesName: result.razon_social,
                    })
                  "
                >
                  {{ result.razon_social }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorActions } from "@/alerts";
import axios from "../../../api";
import { onMounted, onUnmounted, ref } from "vue";
import { observeElement } from '@/observer';
export default {
  name: "SearchInsurer",
  emits: ["send-data"],
  setup(_, { emit }) {
    const dataForm = ref({
      idInsurer: 0,
      bussinesName: null,
      searchInput: "",
    });

    //Funcion para cargar ventas temporales
    const showInsurers = ref(false);
    const loadingSearch = ref(true);
    const dataResult = ref([]);
    const dataLength = ref(false);
    const getData = async () => {
      if (dataForm.value.searchInput.length <= 0) {
        errorActions(
          "Por favor ingresa el<strong> la razon social o numero de documento de la empresa aseguradora</strong>"
        );
        return;
      }

      try {
        loadingSearch.value = true;
        showInsurers.value = true;
        dataLength.value = false;
        const response = await axios
          .get(
            `aseguradoras/?page=1&per_page=10&search=${dataForm.value.searchInput}`
          )
          .catch((error) => errorActions(error));

        const allData = await response.data.data;
        const activeData = allData.filter((item) => item.estado === 1);

        dataResult.value = activeData;

        if (dataResult.value.length > 0) dataLength.value = true;

        loadingSearch.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar la empresa aseguradora debido a que hemos tenido unos inconvenientes"
        );
      }
    };
    const selectInsurer = async (data) => {
      dataForm.value.idInsurer = data.idInsurer;
      dataForm.value.bussinesName = data.bussinesName;
      dataForm.value.searchInput = data.bussinesName;
      showInsurers.value = false;
      emit("send-data", dataForm.value);
    };
    onMounted(() => {
      observeElement('#contentSearchInsurerData')
      window.addEventListener("keydown", handleKeyDown);
    })
    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });
    /*******************************************************************************************
     * Funcion para cerrar resultado de busqueda de la cotizacion
     *******************************************************************************************/
    const handleKeyDown = async (event) => {
      if (event.keyCode === 27) {
        showInsurers.value = false;
      }
    };
    return {
      showInsurers,
      loadingSearch,
      dataResult,
      dataLength,
      getData,
      dataForm,
      selectInsurer,
    };
  },
};
</script>
