<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div class="bg-white rounded-lg overflow-hidden shadow-xl z-50">
        <h2 class="text-gray-700 font-bold text-md px-5 py-4 mb-0 mt-0">
          Actualización de datos del Sucursal / Almacen
        </h2>
        <form class="w-full px-5 pb-5" @submit.prevent>
          <label class="block mb-4">
            <span class="text-sm text-gray-600 mb-1 block"> Empresas </span>
            <SelectOptionsCompany
              v-if="isLoading"
              @send-data="getSelectOptions"
              :selectOption="dataForm.idCompany"
            />
          </label>
          <label class="block mb-4">
            <span class="text-sm text-gray-600 mb-1 block"> Razón social </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              v-model="dataForm.nameStore"
              placeholder="Razon social..."
            />
          </label>
          <label class="mb-3 block">
            <span class="text-sm text-gray-600 mb-1 block"> Dirección </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              v-model="dataForm.adderss"
              placeholder="Dirección..."
            />
          </label>
          <label class="mb-3 block">
            <span class="text-sm text-gray-600 mb-1 block">
              Celular / Teléfono
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              v-model="dataForm.cellPhone"
              placeholder="Celular..."
            />
          </label>

          <div class="flex">
            <button
              class="bg-red-500 text-white px-2 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-red-600 active:scale-95 w-full mt-3"
              @click="isActive"
              type="button"
            >
              Cancelar
            </button>
            <button
              class="bg-blue-500 text-white px-2 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 w-full mt-3 ml-3"
              @click="update"
            >
              Modificar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SelectOptionsCompany from "@/components/Company/SelectOptionsCompany.vue";
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import axios from "../../../api";
export default {
  name: "RegisterCompany",
  components: {
    SelectOptionsCompany,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const registers = ref([]);

    const dataForm = ref({
      nameStore: "",
      adderss: "",
      cellPhone: "",
      idCompany: 1,
    });

    //recepcion de datos desde el select hijo
    const getSelectOptions = (data) => {
      dataForm.value.idCompany = data;
    };

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("sucursales/" + props.id)
        .catch((error) => errorActions(error));
      registers.value = await response.data;
      dataForm.value.idCompany = registers.value.idempresa;
      dataForm.value.nameStore = registers.value.razon_social;
      dataForm.value.adderss = registers.value.direccion;
      dataForm.value.cellPhone = registers.value.celular;

      isLoading.value = true;
    });

    const update = async () => {
      if (dataForm.value.nameStore.trim().length < 4) {
        errorActions(
          "Lo sentimos, no podemos actualizar el sucursal, ingresa una razon social válida"
        );
        return;
      }
      const response = await axios
        .put(
          "sucursales/" + props.id,
          {
            idempresa: dataForm.value.idCompany,
            razon_social: dataForm.value.nameStore,
            direccion: dataForm.value.adderss,
            celular: dataForm.value.cellPhone,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(dataForm.value.nameStore);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          "Lo sentimos no pudimos actualizar los datos del sucursal"
        );
      }

      await props.activeModal(false);
    };
    return {
      isActive,
      dataForm,
      update,
      getSelectOptions,
      isLoading,
    };
  },
};
</script>
