<template>
  <RegisterSystemModules :getdata="getData" />
  <div class="mt-6">
    <div class="rounded-lg overflow-hidden border">
      <div class="py-4 px-3 flex items-center justify-between">
        <!-- <h2 class="text-lg font-semibold">Listado de modulos del sistema</h2> -->
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar..."
            />
          </label>
        </div>
      </div>
      <table class="border-collapse w-full border-t-[1px]">
        <TableHead :headers="header" />
        <LoadingTables v-if="loading" :columns="3" />
        <tbody class="text-gray-600 text-center border-t-[1px]" v-else>
          <tr
            v-for="(register, i) in registers"
            :key="i"
            class="hover:bg-blue-50 hover:text-gray-700 font-semibold odd:bg-white even:bg-slate-50"
          >
            <td class="border-slate-200 text-sm px-2 py-2">
              <font-awesome-icon
                :icon="register.icono_del_menu"
                class="active-rotate text-gray-400 small-hidden"
              />
            </td>
            <td class="border-slate-200 text-sm px-2 py-2">
              {{ register.descripcion }}
            </td>
            <td
              class="border-slate-200 text-sm px-2 py-2 text-right"
            >
              <button
                class="bg-blue-600 text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-blue-700"
                @click="
                  openModalUpdate(
                    register.id,
                    register.descripcion,
                    register.icono_del_menu
                  )
                "
              >
                <font-awesome-icon
                  icon="fa-solid fa-pen"
                  class="text-white w-3 h-3"
                />
              </button>
              <button
                class="bg-red-500 ml-3 text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-red-600"
                @click="isDelete(register.descripcion, register.id)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-trash"
                  class="text-white w-3 h-3"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="flex justify-end items-center">
        <h3 class="text-sm text-gray-500">Página 1 de 1</h3>
        <div class="flex justify-end py-4 px-6">
          <button
            class="bg-white rounded-lg px-2 py-1 text-gray-600 font-semibold text-sm active:scale-95 hover:bg-blue-500 flex items-center mr-3 hover:text-white"
          >
            <svg class="w-6 h-6">
              <use href="@/assets/svg/icon.svg#prev" />
            </svg>
          </button>
          <div
            class="bg-sky-100 rounded-lg px-3 flex items-center py-1 text-gray-600 font-semibold text-sm"
          >
            1
          </div>
          <button
            class="bg-white rounded-lg px-2 py-1 text-gray-600 font-semibold text-sm active:scale-95 hover:bg-blue-500 ml-3 flex items-center hover:text-white"
          >
            <svg class="w-6 h-6">
              <use href="@/assets/svg/icon.svg#next" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <UpdateModules
    :activeModalUpdate="activeModalUpdate"
    :id="id"
    :descriptions="descriptions"
    v-if="isActiveModalUpdate"
    :getdata="getData"
    :iconMenu="iconMenu"
  />
</template>
<script>
import { onMounted, ref } from "vue";
import axios from "../../api";

import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import UpdateModules from "@/components/SystemModules/UpdateModules.vue";
import RegisterSystemModules from "@/components/SystemModules/Register.vue";

import { errorActions } from "@/alerts";
import Swal from "sweetalert2";

export default {
  name: "ListModules",
  components: {
    LoadingTables,
    TableHead,
    UpdateModules,
    RegisterSystemModules,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Icono",
        },
        {
          title: "Módulos del sistema",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //Funcion para abrir el modal y pasar las Props al otro componente
    const id = ref(null);
    const descriptions = ref(null);
    const iconMenu = ref(null);
    const openModalUpdate = (idData, description, iconMenus) => {
      descriptions.value = description;
      isActiveModalUpdate.value = !isActiveModalUpdate.value;
      id.value = idData;
      iconMenu.value = iconMenus;
    };

    // funcion para activar el modal
    const isActiveModalUpdate = ref(false);
    const activeModalUpdate = () => {
      isActiveModalUpdate.value = !isActiveModalUpdate.value;
    };

    //Listado de datos
    const getData = async () => {
      loading.value = true;
      const response = await axios.get("modulos_del_sistema"
      ).catch(error => {
        errorActions(error)
      });
      registers.value = response.data;
      loading.value = false;
    };

    onMounted(async () => {
      await getData();
    });

    //Acciones para eliminar
    const deleteData = async (register, id) => {
      const response = await axios
        .delete("modulos_del_sistema/" + id, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = response.data;
      if (data.msg === "Request failed with status code 500") {
        errorActions(data.msg);
      } else if (data.msg === false) {
        errorActions(
          "Lo sentimos no pudimos eliminar el registro del módulo del sistema"
        );
      }
    };

    //SweetAlert para confirmas la eliminacion del registro
    const isDelete = (register, id) => {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar el registro?",
        text: `Si eliminas a (${register}), ya no podrás recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡eliminar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteData(register, id);
          Swal.fire({
            icon: "success",
            title: "Eliminado",
            text: `${register}, a sido eliminado de la base de datos`,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });

          await getData();
        }
      });
    };

    return {
      registers,
      paginate,
      loading,
      header,
      isDelete,
      UpdateModules,
      openModalUpdate,
      activeModalUpdate,
      id,
      descriptions,
      iconMenu,
      isActiveModalUpdate,
      getData,
      RegisterSystemModules,
    };
  },
};
</script>
