<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar contraseña del usuario
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingFormsLarge v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="px-3 py-4 rounded-lg bg-gray-50 text-blue-500 uppercase">
            <span
              class="block text-gray-700 text-sm font-semibold mb-2 capitalize"
            >
              Usuario
            </span>
            {{ dataForm.fullName }}
          </div>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Contraseña nueva
            </span>
            <input
              type="password"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.newPassword"
              placeholder="Ingrese una nueva contraseña..."
            />
          </label>

          <div class="flex mt-6">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando contraseña del usuario
              </div>
              <span v-else>Modificar contraseña</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";

//componente de loading
import LoadingFormsLarge from "@/components/Loadings/LoadingFormsLarge.vue";
export default {
  name: "UpdateUsersPassword",
  components: {
    LoadingFormsLarge,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const registers = ref([]);
    const spinner = ref(false);

    const dataForm = ref({
      fullName: "",
      newPassword: "",
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("usuarios/" + props.id)
        .catch((error) => {
          errorActions(error);
        });
      registers.value = await response.data;

      dataForm.value.fullName = registers.value.nombres;

      isLoading.value = true;
    });

    //actualizacion de password del usuario
    const update = async () => {
      if (!validatePassword(dataForm.value.newPassword)) {
        errorActions(
          "Por favor ingresa una contraseña más segura, Tu contraseña minimo debe de tener los siguiente: [ Mayúsculas, Minúsculas y Números ]"
        );
        return;
      }
      spinner.value = true;

      const response = await axios
        .put(
          "usuarios/updatepassword/" + props.id,
          {
            newPassword: dataForm.value.newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate("La contraseña de " + dataForm.value.fullName);
        setTimeout(async () => {
          //codigo para evitar el error de [resize observer]
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          await props.activeModal(false);
          await props.getdata();
        }, 200);
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El usuario ${dataForm.value.fullName}  no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          "Lo sentimos no pudimos actualizar la contraseña del usuario " +
            dataForm.value.fullName
        );
      }
    };

    //funcion para validar una contraseña (Mayusculas, Minisculas y Números )
    const validatePassword = (password) => {
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;

      const hasUppercase = uppercaseRegex.test(password);
      const hasLowercase = lowercaseRegex.test(password);
      const hasNumber = numberRegex.test(password);

      return hasUppercase && hasLowercase && hasNumber;
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
    };
  },
};
</script>
