<template>
  <div
    class="fixed top-0 left-0 right-0 z-50 w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen max-h-full flex justify-center bg-gray-950 bg-opacity-70"
    id="modalModulesSearch"
  >
    <div class="relative w-full max-w-md max-h-full pt-4">
      <!-- Modal content -->
      <div class="relative bg-slate-900 rounded-lg shadow">
        <!-- Modal header -->
        <div class="px-6 py-4 border-b border-gray-700 rounded-t">
          <div class="relative w-full">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <font-awesome-icon
                icon="fa-solid fa-search"
                class="w-5 h-5 text-gray-500"
              />
            </div>
            <input
              type="text"
              class="bg-slate-800 border-2 border-slate-900 text-white text-sm rounded-lg focus:border-blue-500 block w-full pl-10 p-2.5 outline-none"
              placeholder="Buscar..."
              autocomplete="off"
              v-model="searchTerm"
              @input="handleSearch"
              ref="searchInput"
            />
          </div>
        </div>
        <!-- Modal body -->
        <div class="p-6">
          <div>
            <router-link
              v-for="(data, i) in filteredResults"
              :to="data.url"
              class="py-2 mb-3 bg-slate-800 rounded-md px-3 text-white hover:bg-blue-500 flex items-center justify-between hover:text-white"
              :key="i"
              @click="handleKeyDownClick"
            >
              {{ data.descripcion }}
              <svg
                class="w-2 h-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </router-link>
          </div>
          <p
            v-if="filteredResults.length === 0"
            class="text-sm font-normal text-orange-500 dark:text-gray-400"
          >
            No se encontraron resultados.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { observeElement } from "@/observer";
export default {
  name: "SearchMenus",
  emits: ["send-data"],
  props: {
    hidden: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const modules = ref([]);
    const filteredResults = ref([]);
    const searchTerm = ref('');
    const searchInput = ref(null);

    // Función para manejar el evento de búsqueda
    const handleSearch = () => {
      filteredResults.value = [];

      modules.value.forEach((modulo) => {
        const matchingSubmodulos = modulo.submodulos.filter((submodulo) => {
          return submodulo.descripcion
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase());
        });

        filteredResults.value.push(...matchingSubmodulos);
      });
    };

    /*******************************************************************************************
     * Funcion para cambiar de estado de tipo de busqueda con combinacion de teclas ALT+N
     *******************************************************************************************/
    const handleKeyDown = async (event) => {
      if (event.keyCode === 27) {
        props.hidden();
      }
    };
    const handleKeyDownClick = async () => {
      props.hidden();
    };

    onMounted(() => {
      modules.value = JSON.parse(localStorage.getItem("modules"));
      window.addEventListener("keydown", handleKeyDown);
      observeElement("#modalModulesSearch");
      searchInput.value.focus();
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });

    return {
      handleSearch,
      filteredResults,
      searchTerm,
      handleKeyDownClick,
      searchInput
    };
  },
};
</script>
