<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <button
        class="bg-blue-500 text-white py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
        @click="activeModalRegister"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
        Nuevo registro
      </button>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre o numero de documento..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="4" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 flex items-center whitespace-nowrap uppercase"
                >
                  <div class="w-8 h-8 mr-3">
                    <div class="w-8 h-8 rounded-full overflow-hidden">
                      <img
                        src="@/assets/images/defaults/avatar-default.png"
                        :alt="register.nombres"
                        v-if="register.avatar === null"
                      />
                      <img
                        :src="APIURL + register.avatar"
                        :alt="register.nombres"
                        v-else
                      />
                    </div>
                  </div>
                  {{
                    register.nombres.length >= 10
                      ? register.nombres.slice(0, 10) + "***"
                      : register.nombres
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span class="bg-blue-50 text-blue-500 rounded-md px-3 py-1">{{ register.areas }}</span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span class="rounded-md px-3 py-1" :class="{'bg-green-50 text-green-500':parseFloat(register.limite_de_descuento)>0}">
                    {{ register.limite_de_descuento }}%
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <button
                    class="bg-red-100 text-red-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-red-700"
                    @click="deleteAccess(register.id,register.areas)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash"
                      class="w-3 h-3"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterSystemEspecificAcess
    :activeModal="activeModalRegister"
    :getdata="getData"
    v-if="stateRegister"
  />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterSystemEspecificAcess from "@/components/SystemModules/SystemEspecificAcess/RegisterSystemEspecificAcess.vue";
import { errorActions, warningActions } from "@/alerts";
import { observer } from "@/observer";

import { money, format } from "@/publicjs/money";
import { deleteSpecificAccess } from "@/repository/SystemSpecificAccess/SystemSpecificAccess";

export default {
  name: "MainSystemEspecificAcess",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterSystemEspecificAcess,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Usuarios",
        },
        {
          title: "areas específicas",
        },
        {
          title: "desc. asignado",
        },
        {
          title: "acciones",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `accesos-especificos/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateRegister = ref(false);
    const activeModalRegister = () => {
      stateRegister.value = !stateRegister.value;
    };

    //URL PARA LOS RECURSO DE LA API COMO IMAGNES, ARCHIVOS, ETC
    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;


    const deleteAccess = async (id,msg) => {
      await deleteSpecificAccess(id,msg,resetValuesAndGetData)
    }

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      widthNav,
      handleResize,
      money,
      format,
      APIURL,
      deleteAccess
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
