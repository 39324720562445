<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <label class="block mt-3 md:mt-0 w-full md:w-auto">
        <span class="block text-gray-600 font-semibold mb-1"
          >Filtrar por estados</span
        >
        <select
          v-model="boxState"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
          @change="resetValuesAndGetData"
        >
          <option value="1">CAJAS CERRADAS</option>
          <option value="0">CAJAS ABIERTAS</option>
        </select>
      </label>
      <div class="relative group">
        <button
          class="bg-blue-100 flex items-center justify-center w-28 h-9 rounded-md text-blue-600"
        >
          Opciones
          <font-awesome-icon
            icon="fa-solid fa-ellipsis"
            class="rotate-90 ml-2"
          />
        </button>
        <ul
          class="absolute top-full right-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100"
        >
          <button
            class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
            @click="exportPDF(registers, idstore)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a PDF
          </button>
          <button
            class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
            @click="exportToExcel(registers)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a excel
          </button>
        </ul>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre o numero de documento..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="7" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 flex items-center whitespace-nowrap"
                >
                  <div class="w-8 h-8 mr-3">
                    <div class="w-8 h-8 rounded-full overflow-hidden">
                      <img
                        src="@/assets/images/defaults/avatar-default.png"
                        :alt="register.nombres"
                        v-if="register.avatar === null"
                      />
                      <img
                        :src="APIURL + register.avatar"
                        :alt="register.nombres"
                        v-else
                      />
                    </div>
                  </div>
                  {{ register.nombres }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ formatDateTime(register.fecha_apertura) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.monto_inicial) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.monto_de_cierre_total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 text-sm rounded-lg"
                    :class="{
                      'bg-orange-50 text-orange-600': register.estado === 0,
                      'bg-green-50 text-green-600': register.estado === 1,
                    }"
                  >
                    {{ register.estado === 0 ? "ABIERTO" : "CERRADO" }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <div class="relative group">
                    <button
                      class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                    >
                      <font-awesome-icon icon="fa-solid fa-list-ul" />
                    </button>
                    <ul
                      class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                    >
                      <button
                        class="px-6 py-2 text-red-500 flex items-center hover:bg-red-50 whitespace-nowrap w-full"
                        @click="activeModalRegister(register.id)"
                        v-if="register.estado === 0"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Cerrar caja
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printTicket(register.id)"
                        v-if="register.estado === 1"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 80MM
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printOnA4(register.id)"
                        v-if="register.estado === 1"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en A4
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printOnLetter(register.id)"
                        v-if="register.estado === 1"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 8 1/1 x 11
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printOnMediaLetter(register.id)"
                        v-if="register.estado === 1"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en M. Carta
                      </button>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <ShowBoxesToClose
    :activeModal="activeModalRegister"
    :getdata="getData"
    :id="id"
    v-if="stateRegister"
  />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import ShowBoxesToClose from "@/components/Boxes/ShowBoxesToClose.vue";
import { errorActions, warningActions } from "@/alerts";
import { observer } from "@/observer";

import { money, format } from "@/publicjs/money";
import {
  idLocalStore,
  idLocalUser,
  localTypeUser,
} from "@/publicjs/localStorage";
import { formatDateTime } from "@/publicjs/formatDate";

import { exportPDF } from "./Reports/PDFExportAllBoxes";
import { exportToExcel } from "./Reports/ExcelExportAllBoxes";


import { printTicket } from "./PrintVoucher/PrintOnTicket";
import { printOnA4 } from "./PrintVoucher/PrintOnA4";
import { printOnLetter } from "./PrintVoucher/PrintOnLetter";
import { printOnMediaLetter } from "./PrintVoucher/PrintOnMediaLetter";

export default {
  name: "MainBox",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    ShowBoxesToClose,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "usuarios",
        },
        {
          title: "turnos",
        },
        {
          title: "fec. de apertura",
        },
        {
          title: "monto inicial",
        },
        {
          title: "monto de cierre",
        },
        {
          title: "estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const boxState = ref(1);
    const idStore = ref(0);
    const idUser = ref(0);
    const getData = async () => {
      const typeUser = await localTypeUser();
      if (
        typeUser === "ADMINISTRADOR" ||
        typeUser === "SUPER ADMIN" ||
        typeUser === "ADMINROOT"
      ) {
        idUser.value = 0;
      } else {
        idUser.value = await idLocalUser();
      }
      loading.value = true;
      const response = await axios
        .get(
          `caja/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idStore.value}&state=${boxState.value}&iduser=${idUser.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      idStore.value = await idLocalStore();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateRegister = ref(false);
    const id = ref(0);
    const activeModalRegister = (data) => {
      id.value = data;
      stateRegister.value = !stateRegister.value;
    };
    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      widthNav,
      handleResize,
      money,
      format,
      boxState,
      APIURL,
      formatDateTime,
      exportPDF,
      exportToExcel,
      id,
      printTicket,
      printOnA4,
      printOnLetter,
      printOnMediaLetter
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
