import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { money, format } from "@/publicjs/money"; //? Formateo y obtencion de moneda configurada de la empresa
import { errorActions } from "@/alerts"; //? Alertas de error, etc
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { imageInBase64 } from "@/publicjs/logoCompany"; //? Imagen de base64 de la empresa
import { formatDate, formatDateTime } from "@/publicjs/formatDate"; //? Retorna la fecha y hora actual
import titlesConfig from "/public/apis/config.json"; //? Títulos de configuracion segun pais
import { convertCurrencyToLetters } from "@/publicjs/convertCurrencyToLetters"; //? Convierte moneda a letras
import { paperDimension } from "@/publicjs/paperDimension"; //? Convierte moneda a letras
import axios from "@/api";
import styles from "@/publicjs/styles";
import createElementPDF from "@/publicjs/createElementPDF";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//* Función para imprimir el documento
/**
 * @param {*} idOrder | ID de la ultima orden realizada
 */
export const printVoucherTicket = async (idOrder) => {
  //* Poblado de datos de la empresa
  const dataStore = JSON.parse(localStorage.getItem("access"));
  const company = await getCompany(dataStore.user.store);

  //* Cargar la imagen o el logo de la empresa
  let base64Image = await imageInBase64(company.companyLogo);

  //? Obtencion de datos de la orden
  const registers = await order(idOrder);
  if (registers.length <= 0) {
    errorActions(
      "Lo sentimos hemos tenido unos incoveniente al generar este comprobante"
    );
  }

  let totalData = [];

  if (parseFloat(registers.orden.total_nota_de_credito) > 0) {
    totalData.push([
      {
        text: "Divisa:",
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text:
          registers.orden.descripcion_moneda.length > 10
            ? registers.orden.descripcion_moneda.slice(0, 10) + "***"
            : registers.orden.descripcion_moneda,
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Descuentos",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: registers.orden.descuento + " %",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Otros descuentos",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.otros_descuentos),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Nota de Crédito",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.total_nota_de_credito),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Subtotal",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.subtotal),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: titlesConfig.iva + " " + registers.orden.igv_aplicado + "%",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.igv),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Total",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.total),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "El cliente paga".toUpperCase(),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text:
          money() +
          " " +
          format(
            parseFloat(registers.orden.pago_en_efectivo) +
              parseFloat(registers.orden.pago_en_tarjeta) +
              parseFloat(registers.orden.pago_en_transferencia) +
              parseFloat(registers.orden.pago_en_cheque)
          ),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    if( registers.tipo_de_venta.descripcion ==='CREDITO') {

      totalData.push([
        {
          text: "Abono inicial (aplica en venta a credito)".toUpperCase(),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
        },
        {
          text: money() + " " + format(registers.abono),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
        },
      ]);
    }
    if(parseFloat(registers.abono) > 0) {

      totalData.push([
        {
          text: "Saldo pendiente".toUpperCase(),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
        },
        {
          text: money() + " " + format(parseFloat(registers.orden.total) - parseFloat(registers.abono)),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
        },
      ]);
    }
    totalData.push([
      {
        text: convertCurrencyToLetters(
          parseFloat(registers.orden.total)
        ).toUpperCase(),
        border: [false, false, false, true],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.sm,
        color: styles.colors.black,
        bold: true,
        colSpan: 2,
      },
      "",
    ]);
  } else if (parseFloat(registers.orden.monto_a_pagar_empresa_por_seguro) > 0) {
    totalData.push([
      {
        text: "Divisa:",
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text:
          registers.orden.descripcion_moneda.length > 10
            ? registers.orden.descripcion_moneda.slice(0, 10) + "***"
            : registers.orden.descripcion_moneda,
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Descuentos",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: registers.orden.descuento + " %",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Otros descuentos",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.otros_descuentos),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Subtotal",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.subtotal),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: titlesConfig.iva + " " + registers.orden.igv_aplicado + "%",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.igv),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Total",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.total),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Aseguradora",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text:
          money() +
          " " +
          format(registers.orden.monto_a_pagar_empresa_por_seguro),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "El cliente paga".toUpperCase(),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text:
          money() +
          " " +
          format(
            parseFloat(registers.orden.pago_en_efectivo) +
              parseFloat(registers.orden.pago_en_tarjeta) +
              parseFloat(registers.orden.pago_en_transferencia) +
              parseFloat(registers.orden.pago_en_cheque)
          ),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    
    if( registers.tipo_de_venta.descripcion ==='CREDITO') {

      totalData.push([
        {
          text: "Abono inicial (aplica en venta a credito)".toUpperCase(),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
        },
        {
          text: money() + " " + format(registers.abono),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
        },
      ]);
    }
    
    if(parseFloat(registers.abono) > 0) {

      totalData.push([
        {
          text: "Saldo pendiente".toUpperCase(),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
        },
        {
          text: money() + " " + format(parseFloat(registers.orden.total) - parseFloat(registers.abono)),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
        },
      ]);
    }
    totalData.push([
      {
        text: convertCurrencyToLetters(
          parseFloat(registers.orden.total)
        ).toUpperCase(),
        border: [false, false, false, true],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.sm,
        color: styles.colors.black,
        bold: true,
        colSpan: 2,
      },
      "",
    ]);
  } else {
    totalData.push([
      {
        text: "Divisa:",
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text:
          registers.orden.descripcion_moneda.length > 10
            ? registers.orden.descripcion_moneda.slice(0, 10) + "***"
            : registers.orden.descripcion_moneda,
        border: [false, true, false, false],
        margin: [0, 5, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Descuentos",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: registers.orden.descuento + " %",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Otros descuentos",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.otros_descuentos),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Subtotal",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.subtotal),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: titlesConfig.iva + " " + registers.orden.igv_aplicado + "%",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.igv),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "Total",
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text: money() + " " + format(registers.orden.total),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    totalData.push([
      {
        text: "El cliente paga".toUpperCase(),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
        bold: true,
      },
      {
        text:
          money() +
          " " +
          format(
            parseFloat(registers.orden.pago_en_efectivo) +
              parseFloat(registers.orden.pago_en_tarjeta) +
              parseFloat(registers.orden.pago_en_transferencia) +
              parseFloat(registers.orden.pago_en_cheque)
          ),
        border: [false, false, false, false],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.lg,
        color: styles.colors.black,
      },
    ]);
    if( registers.tipo_de_venta.descripcion ==='CREDITO') {

      totalData.push([
        {
          text: "Abono inicial (aplica en venta a credito)".toUpperCase(),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
        },
        {
          text: money() + " " + format(registers.abono),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
        },
      ]);
    }
    
    if(parseFloat(registers.abono) > 0) {

      totalData.push([
        {
          text: "Saldo pendiente".toUpperCase(),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
        },
        {
          text: money() + " " + format(parseFloat(registers.orden.total) - parseFloat(registers.abono)),
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          valign: "middle",
          alignment: "right",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
        },
      ]);
    }
    totalData.push([
      {
        text: convertCurrencyToLetters(
          parseFloat(registers.orden.total)
        ).toUpperCase(),
        border: [false, false, false, true],
        margin: [0, 0, 0, 0],
        valign: "middle",
        alignment: "right",
        fontSize: styles.fontSize.ticket.sm,
        color: styles.colors.black,
        bold: true,
        colSpan: 2,
      },
      "",
    ]);
  }

  try {
    //Desestructurando el ancho y al alto del papel
    const regenerateHeight = parseFloat(600 + registers.detalle.length * 12);
    const { paperWidth, paperHeight } = paperDimension(78, regenerateHeight);

    let docDefinition = null;
    docDefinition = {
      // a string or { width: number, height: number }
      pageSize: { width: paperWidth, height: paperHeight },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "portrait", //cambia a [landscape] para imprimir de forma horizontal

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 240, 10, 10],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            width: "*",
            stack: [
              { image: base64Image, fit: [40, 40] }, // logo
            ],
            alignment: "center",
            margin: [10, 10, 0, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: company.companyName,
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
              },
              {
                text: "Dirección: " + company.companyAddress,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "Tel: " + company.companycellphone,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: company.companySlogan,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text:
                  "Sucursal: ".toUpperCase() +
                  registers.sucursal.razon_social.toUpperCase(),
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
                margin: [0, 10, 0, 0],
              },
              {
                text: "DIR: " + registers.sucursal.direccion.toUpperCase(),
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "TEL: " + registers.sucursal.celular,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
            ],
            alignment: "center",
            margin: [10, 10, 10, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: titlesConfig.titleTypeDocument + " " + company.companyRUC,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: registers.tipo_comprobante.descripcion,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: `NCF: ${registers.orden.serie_comprobante}${registers.orden.numero_comprobante}`,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: `N° REF - ${registers.orden.numero_referencia_comprobante}`,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text:
                  registers.orden.vencimiento_secuencia !== null
                    ? `V. SECUENCIA: ${formatDate(
                        registers.orden.vencimiento_secuencia
                      )}`
                    : "",
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
            ],
            alignment: "center",
            margin: [10, 0, 10, 0],
          },
          {
            canvas: [
              { type: "rect", x: 10, y: 90, w: pageSize.width - 48, h: 2 },
            ],
            margin: [10, 10], // Margen entre el rectángulo y el contenido de la página
          },
        ];
      },

      content: [
        //? Encabezado del comprobante con el nombre del cliente
        {
          table: {
            headerRows: 0,
            widths: [66, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Cliente:",
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text:
                    registers.orden.nombre_cliente_pedido.length >= 29
                      ? registers.orden.nombre_cliente_pedido
                          .slice(0, 29)
                          .toUpperCase() + "***"
                      : registers.orden.nombre_cliente_pedido.toUpperCase(),
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [-40, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Dirección:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.cliente.direccion,
                  border: [false, false, false, false],
                  margin: [-30, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "N° Documento:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.cliente.numero_documento,
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Fec. de emision:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: formatDateTime(registers.orden.fecha_de_emision),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Tipo de pago:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.tipo_de_pago.descripcion,
                  border: [false, false, false, false],
                  margin: [-17, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Tipo de venta:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.tipo_de_venta.descripcion ==='CREDITO'?registers.tipo_de_venta.descripcion +' - válido por '+ registers.cliente.paydays+' días':registers.tipo_de_venta.descripcion,
                  border: [false, false, false, false],
                  margin: [-6, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Nota Adicional:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.orden.comentarios,
                  border: [false, false, false, false],
                  margin: [-6, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
            ],
          },
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: [22, 20, 36, 36, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Cant.",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: "U.M",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: "SubTotal",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: titlesConfig.iva,
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: "Total",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                  alignment: "right",
                },
              ],
              ...registers.detalle.flatMap((register) => {
                return [
                  [
                    {
                      text: register.cantidad,
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text:
                        register.prefijo !== null
                          ? register.prefijo
                          : register.unidad_de_medida,
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: money() + format(register.subtotal),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: money() + format(register.igv),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: money() + format(register.total),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      bold: true,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                      alignment: "right",
                    },
                  ],
                  [
                    {
                      text:
                        register.nombre_producto.toUpperCase() +
                        " | P.U " +
                        (parseFloat(register.cantidad) < 1
                          ? money() +
                            parseFloat(register.cantidad) *
                              parseFloat(register.precio_de_venta).toFixed(2)
                          : money() + register.precio_de_venta) +
                        `\n ${
                          register.product_serie !== null
                            ? "S=" + register.product_serie
                            : ""
                        }\n${
                          register.vencimiento !== null
                            ? "V= " + register.vencimiento
                            : ""
                        }`,
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      colSpan: 5,
                      margin: [0, 0, 0, 3],
                      valign: "middle",
                    },
                  ],
                ];
              }), // Datos dinámicos
            ],
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 10, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.black
                : styles.colors.black;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.black
                : styles.colors.black;
            },
          },
        },
        //? Total del comprobante
        {
          table: {
            headerRows: 1,
            widths: ["*", 70],
            body: totalData,
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.black
                : styles.colors.black;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.black
                : styles.colors.black;
            },
          },

          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 0, 0, 0],
        },
        //? QR del comprobante
        {
          qr:
            registers.orden.serie_comprobante +
            "-" +
            registers.orden.numero_comprobante,
          fit: 65,
          rowSpan: 4,
          margin: [0, 10, 0, 0],
          border: [true, true, false, true],
          foreground: styles.colors.black,
          background: styles.colors.white,
          alignment: "center",
        },

        //?Informacion del vendor
        {
          text:
            "Vendedor: ".toUpperCase() +
            registers.orden.nombre_vendedor.slice(0, 8).toUpperCase() +
            "***" +
            "    |    Cajer@: ".toUpperCase() +
            registers.usuario.nombres.slice(0, 8).toUpperCase() +
            "***",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 0],
          alignment: "center",
        },
        {
          text:
            "Importe: ".toUpperCase() +
            money() +
            format(registers.orden.importe_de_pago) +
            "    |    Devuelta: ".toUpperCase() +
            money() +
            format(registers.orden.vuelto_de_importe_de_pago),
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 10],
          alignment: "center",
        },
        {
          text:
            registers.orden.name_charge !== null
              ? "Mensajero: " + registers.orden.name_charge
              : "",
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 10],
          alignment: "center",
        },

        //Firma del cliente
        {
          text: "-------------------------------              -------------------------------",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 0],
          alignment: "center",
        },
        {
          text: "EMISOR                                      RECEPTOR ",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [-10, 0, 0, 0],
          alignment: "center",
        },        
        {
          text: 'Mensaje de agradecimiento:',
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 0],
          alignment: "center",
        },
        {
          text: registers.tipo_comprobante.mensaje_de_agradecimiento,
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
          alignment: "center",
        },
        {
          text: 'Información:',
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
          alignment: "center",
        },
        {
          text: registers.tipo_comprobante.mensaje_de_informacion,
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
          alignment: "center",
        },
        {
          text: registers.detalle.some(
            (producto) => producto.vencimiento !== null
          )
            ? "Términos de garantía:"
            : "",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
          alignment: "center",
        },
        {
          text: registers.detalle.some(
            (producto) => producto.vencimiento !== null
          )
            ? registers.tipo_comprobante.terminos
            : "",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
          alignment: "left",
        },
      ],
    };
    // pdfMake.createPdf(docDefinition).print();

    //? nuevo codigo para imprimir en la misma página
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    await createElementPDF(pdfDocGenerator);
  } catch (error) {
    errorActions("No se pudo generar el comprobante");
  }
};

const order = async (idOrder) => {
  try {
    const response = await axios.get(`ventas/print/${idOrder}`);

    if (response.msg === false) {
      return [];
    }
    return await response.data;
  } catch (error) {
    errorActions(error);
  }
};
