const dayjs = require("dayjs");
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

// Configura la zona horaria que necesitas, por ejemplo 'America/Mexico_City'
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);

// Configura la zona horaria que deseas utilizar
dayjs.tz.setDefault(process.env.VUE_APP_CURRENT_TIME_ZONE); // Reemplaza 'America/Mexico_City' con tu zona horaria


//Retorna la hora en (00:00:00 AM-PM)
export const formatHours = (time) => dayjs(time, "HH:mm:ss").format("hh:mm:ss A");

//Retorna la fecha en (dia/mes/año)
export const formatDate = (date) => dayjs(date, "YYYY-MM-DD").format("DD-MM-YYYY");


//Retorna la fecha y hora en formato (dia/mes/año hora:minuto:segundo am-pm)
// export const formatDateTime = (dateTime) => dayjs(dateTime, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY hh:mm A");
export const formatDateTime = (dateTime) => dayjs(dateTime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY hh:mm A");