<template>
  <div class="mt-4" id="mainPhysicalInventory">
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center gap-4 justify-between flex-wrap">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg  outline-none min-w-[140px] font-semibold w-full md:w-auto"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1,000 Registros</option>
          <option value="5000">5,000 Registros</option>
          <option value="10000">1,0000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre o numero de documento..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
        <button
          class="px-3 py-2 ml-3 text-green-500 flex bg-green-50 items-center hover:bg-green-100 rounded-md whitespace-nowrap"
          @click="exportToExcel(registers)"
        >
          <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
          Exportar a excel
        </button>
      </div>
      <div
        class="grid md:grid md:grid-cols-5 gap-6 p-3 border-t border-b mb-3"
        v-show="!loading"
      >
        <div class="border border-blue-50 shadow rounded-md p-3">
          <div
            class="text-xs text-gray-500 font-semibold border-b border-blue-50 pb-3 uppercase"
          >
            Utilidad general
          </div>
          <div class="text-lg font-bold text-green-500 pt-3">
            {{ money() }} {{ format(totalUtility) }}
          </div>
        </div>
        <div class="border border-blue-50 shadow rounded-md p-3">
          <div
            class="text-xs text-gray-500 font-semibold border-b border-blue-50 pb-3 uppercase"
          >
            Total en compras
          </div>
          <div class="text-lg font-bold text-gray-500 pt-3">
            {{ money() }} {{ format(totalInShopping) }}
          </div>
        </div>
        <div class="border border-blue-50 shadow rounded-md p-3">
          <div
            class="text-xs text-gray-500 font-semibold border-b border-blue-50 pb-3 uppercase"
          >
            {{ config.iva }} en compras
          </div>
          <div class="text-lg font-bold text-gray-500 pt-3">
            {{ money() }} {{ format(totalInShoppingIvg) }}
          </div>
        </div>
        <div class="border border-blue-50 shadow rounded-md p-3">
          <div
            class="text-xs text-gray-500 font-semibold border-b border-blue-50 pb-3 uppercase"
          >
            Total en ventas
          </div>
          <div class="text-lg font-bold text-gray-500 pt-3">
            {{ money() }} {{ format(totalInSales) }}
          </div>
        </div>
        <div class="border border-blue-50 shadow rounded-md p-3">
          <div
            class="text-xs text-gray-500 font-semibold border-b border-blue-50 pb-3 uppercase"
          >
            {{ config.iva }} en ventas
          </div>
          <div class="text-lg font-bold text-gray-500 pt-3">
            {{ money() }} {{ format(totalInSalesIvg) }}
          </div>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full" id="tablePhysicalInventory">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="9" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.codigo_de_barra }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.nombre_producto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.ubicacion === null ? "---" : register.ubicacion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.stock) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.precio_de_compra) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap bg-blue-50 text-blue-500"
                >
                  {{ money() }} {{ format(register.total_shopping) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap "
                >
                  {{ money() }} {{ format(register.precio_de_venta) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right bg-green-50 text-green-600"
                >
                  {{ money() }} {{ format(register.total_sale) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                >
                  {{ money() }} {{ format(register.utility) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";
import { exportToExcel } from "./Reports/ExportPhysicalInventory";

import config from "/public/apis/config.json";

export default {
  name: "MainPhysicalInventory",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const registersToUtility = ref({});
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Cod. barra",
        },
        {
          title: "descripción",
        },
        {
          title: "Ubicación",
        },
        {
          title: "stock",
        },
        {
          title: "p. de compra",
        },
        {
          title: "total en compras",
        },
        {
          title: "p. de venta",
        },
        {
          title: "total en ventas",
        },
        {
          title: "Utilidad",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const idStore = ref(0);

    const totalInShoppingIvg = ref(0)
    const totalInShopping = ref(0)
    const totalInSalesIvg = ref(0)
    const totalInSales = ref(0)
    const totalUtility = ref(0)
    const getData = async () => {
      totalInShoppingIvg.value = 0
      totalInShopping.value = 0
      totalInSalesIvg.value = 0
      totalInSales.value = 0
      totalUtility.value = 0
      loading.value = true;
      const response = await axios
        .get(
          `inventario-fisico/${idStore.value}/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}`
        )
        .catch((error) => errorActions(error));

      const data = await response.data.data;

      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = data.productos.map((element) => {
        const totalShopping =
          parseFloat(element.stock) * parseFloat(element.precio_de_compra);
        const totalSale =
          parseFloat(element.stock) * parseFloat(element.precio_de_venta);
        element.total_shopping = totalShopping;
        element.total_sale = totalSale;
        element.utility = totalSale - totalShopping;
        totalInSales.value += totalSale
        totalInShopping.value += totalShopping

        totalUtility.value += totalSale - totalShopping

        const igv = parseFloat(element.igv)/100

        totalInShoppingIvg.value += parseFloat((igv*totalShopping).toFixed(2))
        totalInSalesIvg.value += parseFloat((igv*totalSale).toFixed(2))


        return {
          ...element,
          total_shopping: totalShopping,
          total_sale: totalSale,
        };
      });


      registersToUtility.value = data.utilidades[0];
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(() => {
        if (widthNav.value) {
          widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
        }
      });
    };

    onMounted(async () => {
      handleResize();
      observeElement("#tablePhysicalInventory");
      observeElement("#mainPhysicalInventory");
      window.addEventListener("resize", handleResize);
      idStore.value = await idLocalStore();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      exportToExcel,
      registersToUtility,
      config,
      totalInShoppingIvg,
      totalInShopping,
      totalInSalesIvg,
      totalInSales,
      totalUtility
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
