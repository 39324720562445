<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="modalContainer">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 max-w-[90vw] min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] md:max-w-[80vw] lg:max-w-[80vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar datos del producto
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Código de barra <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.barCode"
                placeholder="Descripción..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Nombre del producto <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.productName"
                placeholder="Nombre del producto..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Descripción
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.description"
                placeholder="Descripción..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Stock <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.stock"
                placeholder="Stock..."
                @keydown.enter.prevent
                step="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Stock mínimo <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.minimumStock"
                placeholder="Stock mínimo..."
                @keydown.enter.prevent
                step="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Descuento (%) <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.discount"
                placeholder="Descuento..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio de compra <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none border-orange-300 rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.purchasePrice"
                placeholder="Precio de compra..."
                @keydown.enter.prevent
                step="0.01"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Utilidad (%) <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg border-sky-400 text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.utility"
                placeholder="Utilidad..."
                @keydown.enter.prevent
                @input="calcUtility"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio de venta <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 text-green-600 border-green-500 outline-none rounded-lg w-full border-2 focus:border-blue-500 font-bold"
                v-model="dataForm.salePrice"
                placeholder="Precio de venta..."
                @keydown.enter.prevent
                @input="calcUtilityFromSalePrice"
                step="0.01"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Ubicación
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.location"
                placeholder="Ubicación..."
                @keydown.enter.prevent
                maxlength="40"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Libre de impuesto?
              </span>
              <select
                class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none cursor-pointer"
                v-model="dataForm.taxFree"
                @change="changeTaxtFree"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Facturado?
              </span>
              <select
                class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none cursor-pointer"
                v-model="dataForm.invoiced"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fecha de vencimiento
              </span>
              <input
                type="date"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.expiration"
                placeholder="Fecha de vencimiento..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de productos
              </span>
              <TypeOfProducts
                @send-data="getSelectTypeOfProducts"
                :selectOption="dataForm.idTypeOfProduct"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio de oferta
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.precio_descuento"
                placeholder="Precio de oferta"
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Vencimiento de oferta
              </span>
              <input
                type="datetime-local"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.vencimiento_descuento"
                @keydown.enter.prevent
              />
            </label>

            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Vencimiento de garantía
              </span>
              <div class="flex items-center">
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-tl-md rounded-bl-md rounded- text-gray-600 w-full border-l-2 border-y-2 focus:border-blue-500"
                  v-model="dataForm.vencimiento_garantia"
                  @keydown.enter.prevent
                  placeholder="Tiempo de garantía"
                />
                <select
                  v-model="dataForm.tipo_vencimiento"
                  name="tipo_vencimiento"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-br-md rounded-tr-md text-gray-600 w-full border-y-2 border-r-2 focus:border-blue-500"
                >
                  <option value="year">Años</option>
                  <option value="month">Meses</option>
                  <option value="days">Días</option>
                </select>
              </div>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} (%) <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.igv"
                :placeholder="config.iva + '...'"
                @keydown.enter.prevent
              />
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Venta con stock?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.saleWithStock"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.saleWithStock === true ? "SI" : "NO" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado actual
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.state"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Especificar tipo
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.type"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.type === true ? "Producto" : "Servicio" }}
                </span>
              </div>
            </label>
            <label
              class="flex flex-col h-6 mr-5 cursor-pointer mb-[3.5rem!important]"
            >
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Está publicado en marketplace?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.published"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.published === true ? "SI" : "NO" }}
                </span>
              </div>
            </label>
            <div class="w-full" v-if="stateInputsActive">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Poster
              </span>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar imagen</span
                    >
                    PNG o JPG
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  accept=".png,.jpg"
                  ref="poster"
                  @change="selectFilePoster($event)"
                />
              </label>
            </div>
            <div class="w-full" v-if="stateInputsActive">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Ficha técnica
              </span>
              <label
                for="datSheet"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar archivo</span
                    >
                    .PDF
                  </p>
                </div>
                <input
                  id="datSheet"
                  type="file"
                  class="hidden"
                  accept=".pdf"
                  ref="datSheet"
                  @change="selectFileDatSheet($event)"
                />
              </label>
            </div>
            <div class="w-full" v-if="stateInputsActive">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Registro sanitario
              </span>
              <label
                for="healthRegister"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar archivo</span
                    >
                    .PDF
                  </p>
                </div>
                <input
                  id="healthRegister"
                  type="file"
                  class="hidden"
                  accept=".pdf"
                  ref="healthRegister"
                  @change="selectFileHealthRegisters($event)"
                />
              </label>
            </div>
          </div>

          <div class="flex justify-between mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>

            <button
              class="bg-gray-100 text-blue-500 text-sm px-3 py-2 rounded-lg hover:bg-blue-100"
              @click="inputsActive()"
            >
              Mostrar más opciones
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../../api";

//componente de loading
import LoadingForms from "@/components/Loadings/LoadingFormsLarge.vue";

import TypeOfProducts from "@/components/PublicComponents/TypeOfProducts.vue";

//convertirdor de porcentajes
import { percentage } from "@/publicjs/convertToPercentage";
import config from "/public/apis/config.json";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";
export default {
  name: "UpdateProducts",
  components: {
    LoadingForms,
    TypeOfProducts,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);
    const stateInputsActive = ref(false);

    //variables para guardar los archivos
    const datSheet = ref(null);
    const healthRegister = ref(null);
    const poster = ref(null);

    const dataForm = ref({
      idTypeOfProduct: null,
      barCode: "",
      productName: "",
      description: "",
      discount: 0,
      taxFree: "NO",
      invoiced: "SI",
      location: "",
      utility: 0,
      expiration: null,
      igv: 0,
      state: true,
      idProduct: 0,
      stock: 0,
      minimumStock: 5,
      purchasePrice: 0,
      salePrice: 0,
      saleWithStock: true,
      type: true,
      published: true,
      idProductInStock: 0,
      precio_descuento: 0,
      vencimiento_descuento: null,
      vencimiento_garantia: 0,
      tipo_vencimiento: "year",
    });

    //Vriable para enviar datos para el movimiento del producto
    const dataMovementProduct = ref({
      beforeQuantity: 0,
      afterQuantity: 0,
    });
    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("productos/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.idTypeOfProduct = data.idtipo_producto;
      dataForm.value.barCode = data.codigo_de_barra;
      dataForm.value.productName = data.nombre_producto;
      dataForm.value.description = data.descripcion;
      dataForm.value.discount = data.descuento;
      dataForm.value.taxFree = data.libre_de_impuesto;
      dataForm.value.invoiced = data.facturado;
      dataForm.value.location = data.ubicacion;
      dataForm.value.utility = data.utilidad;
      dataForm.value.expiration = data.vencimiento;
      dataForm.value.igv = data.igv;
      dataForm.value.state = data.estado === 1 ? true : false;
      dataForm.value.published = data.publicado === 1 ? true : false;
      dataForm.value.type = data.tipo === 1 ? true : false;

      const productsInStock = await axios
        .get("productos-en-almacen/" + props.id)
        .catch((error) => errorActions(error)); //CORREGIR EN EL BACK PARA TRAER SOLO LO QUE PERTENECE AL PRODUCTO
      const dataInStock = await productsInStock.data;
      dataForm.value.idProductInStock = dataInStock.id;
      dataForm.value.idProduct = dataInStock.idproducto;
      dataForm.value.stock = parseFloat(dataInStock.stock);
      dataForm.value.minimumStock = parseFloat(dataInStock.stock_minimo);
      dataForm.value.purchasePrice = dataInStock.precio_de_compra;
      dataForm.value.salePrice = dataInStock.precio_de_venta;
      dataForm.value.precio_descuento = dataInStock.precio_descuento;
      dataForm.value.vencimiento_descuento = dataInStock.vencimiento_descuento;
      dataForm.value.vencimiento_garantia = dataInStock.vencimiento_garantia;
      dataForm.value.tipo_vencimiento = dataInStock.tipo_vencimiento;
      dataForm.value.saleWithStock =
        dataInStock.venta_con_stock === 1 ? true : false;

      dataMovementProduct.value.beforeQuantity = parseFloat(dataInStock.stock);

      isLoading.value = true;

      observer.observe(
        document.getElementById("modalContainer"),
        { box: "content-box" },
        10
      );
    });

    //recepcion de datos desde el componente publico, todas los tipo de productos
    const getSelectTypeOfProducts = (data) => {
      dataForm.value.idTypeOfProduct = data;
    };
    //funciona para activar input opcionales
    const inputsActive = () => {
      stateInputsActive.value = !stateInputsActive.value;
    };

    // Selecciona el foto de prefil
    const selectFilePoster = async (e) => {
      poster.value = await e.target.files[0];
    };
    // Selecciona registro sanitario
    const selectFileHealthRegisters = async (e) => {
      healthRegister.value = await e.target.files[0];
    };
    // Selecciona ficha tenica
    const selectFileDatSheet = async (e) => {
      datSheet.value = await e.target.files[0];
    };

    //funcion para calcular la utilidad de l producto
    const calcUtility = () => {
      if (dataForm.value.purchasePrice <= 0) {
        errorActions(
          "Ingresa el precio de compra del <strong>producto</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      let salePrice = 0;
      const purchasePrice = parseFloat(dataForm.value.purchasePrice);
      const totalSales = purchasePrice * percentage(dataForm.value.utility);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10;
      dataForm.value.salePrice = salePrice.toFixed(2);
      dataForm.value.price = salePrice.toFixed(2);
    };

    const calcUtilityFromSalePrice = async () => {
      // Precio de compra y venta
      let shoppinPrice = dataForm.value.purchasePrice;
      let salePrice = dataForm.value.salePrice;
      let porcentajeGanancia = 0;
      // Calcular el porcentaje de ganancia
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;

      // Redondear el porcentaje a un número entero
      dataForm.value.utility = Math.round(porcentajeGanancia);
    };

    const update = async () => {
      if (dataForm.value.productName.trim().length <= 3) {
        errorActions("Ingresa el nombre del <strong>producto</strong>");
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.barCode.trim().length <= 3) {
        errorActions(
          `Ingresa un codigo de barra del producto <strong>${dataForm.value.productName}</strong>`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (isNaN(dataForm.value.stock) || dataForm.value.stock.length <=0) {
        errorActions(`El STOCK del producto debe de ser mayor o igual a (0)`);
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (
        isNaN(dataForm.value.minimumStock) ||
        dataForm.value.minimumStock <= 0
      ) {
        errorActions(
          `Ingresa un valor en el stock minimo del producto <strong>${dataForm.value.productName}</strong>, este te servira para que el sistema te alerte cuando el stock del producto se este agotando`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.purchasePrice <= 0) {
        errorActions(
          `Ingresa el precio de compra del producto <strong>${dataForm.value.productName}</strong>, este te servira parasacar tu margen de utilidad`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.salePrice <= 0) {
        errorActions(
          `El precio de venta del producto <strong>${dataForm.value.productName}</strong>, no debe de ir con el valor de 0, ingresa el precio de compra, luego ingresa la utilidad y finalmente preciona enter en al campo utilidad`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.utility <= dataForm.value.discount) {
        errorActions(
          `La utilidad del producto <strong>${dataForm.value.productName}</strong>, no debe de ser menor al descuento del producto`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.idProduct === null) {
        errorActions(
          `Selecciona una sucursal para el producto <strong>${dataForm.value.productName}</strong>.`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.idTypeOfProduct === null) {
        errorActions(
          `Selecciona un tipo de producto para el producto <strong>${dataForm.value.productName}</strong>.`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.igv <= 0 && dataForm.value.taxFree === "NO") {
        errorActions(
          `El iva del producto <strong>${dataForm.value.productName}</strong>, no debe de ir con el valor de 0, ingresa el impuesto agregado del producto`
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      const formValue = new FormData();
      formValue.append("idTypeOfProduct", dataForm.value.idTypeOfProduct);
      formValue.append("barCode", dataForm.value.barCode);
      formValue.append("productName", dataForm.value.productName);
      formValue.append("description", dataForm.value.description);
      formValue.append("discount", dataForm.value.discount);
      formValue.append("taxFree", dataForm.value.taxFree);
      formValue.append("invoiced", dataForm.value.invoiced);
      formValue.append("location", dataForm.value.location);
      formValue.append("utility", dataForm.value.utility);
      formValue.append("expiration", dataForm.value.expiration);
      formValue.append("igv", dataForm.value.igv);
      formValue.append("healthRegister", healthRegister.value);
      formValue.append("datSheet", datSheet.value);
      formValue.append("poster", poster.value);
      formValue.append("state", dataForm.value.state ? 1 : 0);
      formValue.append("type", dataForm.value.type ? 1 : 0);
      formValue.append("published", dataForm.value.published ? 1 : 0);

      dataMovementProduct.value.afterQuantity = dataForm.value.stock;

      formValue.append(
        "beforeQuantity",
        dataMovementProduct.value.beforeQuantity
      );
      formValue.append(
        "afterQuantity",
        dataMovementProduct.value.afterQuantity
      );
      formValue.append("idUser", await idLocalUser());
      formValue.append("idStore", await idLocalStore());

      spinner.value = true;
      const response = await axios
        .post("productos/" + props.id, formValue, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function (error) {
          errorActions(error);
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          spinner.value = false;
        });
      const data = await response.data;
      if (data.msg === true) {
        await updateInStock(props.id); //ACTUALIZA LOS PRODUCTOS EN ALMACEN

        confirmationOfUpdate(dataForm.value.productName);

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El Producto <trong>${dataForm.value.productName} </strong> que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos del producto <trong>${dataForm.value.productName} </strong>`
        );
      }

      spinner.value = false;
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };
    /*************************************************
     * FUNCIÓN PARA GUARDAR EL PRODUCTO AL ALMACEN
     *************************************************/

    const updateInStock = async (idProduct) => {
      spinner.value = true;
      const response = await axios
        .put(
          "productos-en-almacen/" + dataForm.value.idProductInStock,
          {
            idProduct,
            stock: dataForm.value.stock,
            minimumStock: dataForm.value.minimumStock,
            purchasePrice: dataForm.value.purchasePrice,
            salePrice: dataForm.value.salePrice,
            precio_descuento: dataForm.value.precio_descuento,
            vencimiento_descuento: dataForm.value.vencimiento_descuento,
            vencimiento_garantia: dataForm.value.vencimiento_garantia,
            tipo_vencimiento: dataForm.value.tipo_vencimiento,
            saleWithStock: dataForm.value.saleWithStock ? 1 : 0,
            utility: dataForm.value.utility,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
        });
      const data = await response.data;
      if (data.msg === true) return true;
    };

    const changeTaxtFree = () => {
      if (dataForm.value.taxFree === "NO") {
        dataForm.value.igv = 18;
      } else {
        dataForm.value.igv = 0;
      }
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
      getSelectTypeOfProducts,
      inputsActive,
      stateInputsActive,
      selectFilePoster,
      selectFileHealthRegisters,
      selectFileDatSheet,
      calcUtility,
      config,
      calcUtilityFromSalePrice,
      changeTaxtFree,
    };
  },
};
</script>
