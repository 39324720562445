import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/Home.vue";
import SystemModules from "./views/SystemModules/SystemModules.vue";
import SystemSubModules from "./views/SystemModules/SystemSubModules.vue";
import CompanyConfigurations from "./views/Company/Company.vue";
import Stores from "./views/Company/Stores/Stores.vue";
import TypeDocuments from "./views/ConfigurationModules/TypeDocuments/TypeDocuments.vue";
import TypeOfUsers from "./views/Users/TypeOfUsers/TypeOfUsers.vue";
import MainUsers from "./views/Users/Main/MainUsers.vue";
import ProfileUser from "./views/Users/Main/ProfileUser.vue";
import MainTurn from "./views/Users/Turns/Turns.vue";
import WorkSchedules from "./views/Users/WorkSchedules/WorkSchedules.vue";
import Access from "./views/Users/Access/Access.vue";
import Login from "./views/Login.vue";
import ConfigureUsersPayment from "./views/Users/Payments/UserHiring.vue";
import TypeOfVouchers from "./views/ConfigurationModules/TypeOfVouchers/TypeOfVouchers.vue";
import DeliveryApp from "./views/ConfigurationModules/DeliveryApp/DeliveryApp.vue";
import Vouchers from "./views/ConfigurationModules/Vouchers/Vouchers.vue";
import Iva from "./views/ConfigurationModules/Iva/Iva.vue";
import MainDiscountGlobal from "./views/ConfigurationModules/DiscountGlobal/DiscountGlobal.vue";
import Currencys from "./views/ConfigurationModules/Currencys/Currencys.vue";
import ConfigureVouchersPrint from "./views/ConfigurationModules/ConfigureVouchersPrint/ConfigureVouchersPrint.vue";
import Emails from "./views/ConfigurationModules/Emails/Emails.vue";
import Commissions from "./views/ConfigurationModules/Commissions/Commissions.vue";
import Customers from "./views/Customers/Customers.vue";
import InsuranceCompanies from "./views/InsuranceCompanies/InsuranceCompanies.vue";
import Suppliers from "./views/Suppliers/Suppliers.vue";
import TypeOfProducts from "./views/Store/TypeOfProducts/TypeOfProducts.vue";
import UnitMeasures from "./views/Store/UnitMeasures/UnitMeasures.vue";
import Products from "./views/Store/Products/Main/Products.vue";
import PurchaseOrder from "./views/PurchaseOrder/PurchaseOrder.vue";
import ListPurchaseOrder from "./views/PurchaseOrder/ListPurchaseOrder.vue";
import Shopping from "./views/Shopping/Shopping.vue";
import HelpMe from "./views/HelpMe/HelpMe.vue";
import ListShopping from "./views/Shopping/ListShopping.vue";
import Kardex from "./views/Kardex/Kardex.vue";
import Lots from "./views/Store/BatchesOfProducts/Lots.vue";
import CreditNoteShopping from "./views/Shopping/CreditNote/CreditNoteShopping.vue";
import AccountPayable from "./views/Shopping/AccountsPayable/AccountsPayable.vue";
import ListOfAccountsPayablePayments from "./views/Shopping/AccountsPayable/ListOfAccountsPayablePayments.vue";
import Disbursements from "./views/Disbursements/Disbursements.vue";
import SystemEspecificAccess from "./views/SystemModules/SystemEspecificAccess.vue";
import BoxesData from "./views/Boxes/Boxes.vue";
import ControlOfBoxes from "./views/Boxes/ControlOfBoxes.vue";
import DGII from "./views/DGII/DGII.vue";
import Sales from "./views/Sales/Sales.vue";
import SalesReport from "./views/Reports/Sales/General/SalesReport.vue";
import SalesList from "./views/Sales/SalesList.vue";
import Quotes from "./views/Quotes/Quotes.vue";
import TemporarySales from "./views/TemporarySales/TemporarySales.vue";
import CustomerOrder from "./views/CustomerOrder/CustomerOrder.vue";
import AccountsReceivable from "./views/Sales/AccountsReceivable/AccountsReceivable.vue";
import ListOfAccountsReceivablePayments from "./views/Sales/AccountsReceivable/ListOfAccountsReceivablePayments.vue";
import InsuranceAccountsReceivable from "./views/Sales/InsuranceAccountsReceivable/InsuranceAccountsReceivable.vue";
import ListOfInsuranceAccountsReceivable from "./views/Sales/InsuranceAccountsReceivable/ListOfInsuranceAccountsReceivable.vue";
import CreditNote from "./views/Sales/CreditNote/CreditNote.vue";
import ReportProfits from "./views/Reports/Sales/ReportProfits/ReportProfits.vue";
import ReportProducts from "./views/Reports/Products/ReportProducts.vue";
import ReportDGII from "./views/Reports/Sales/DGII/ReportDGII/ReportDGII.vue";
import BestSellingAndLessSold from "./views/Reports/Products/BestSellingAndLessSold.vue";
import ProductsAccordingToModuleEntered from "./views/Reports/Products/ProductsAccordingToModuleEntered.vue";
import ProductMovement from "./views/Reports/Products/ProductMovement.vue";
import SellerOfTheMonth from "./views/Reports/SellerOfTheMonth/SellerOfTheMonth.vue";
import ComparePriceBetweenProviders from "./views/Reports/ComparePriceBetweenProviders/ComparePriceBetweenProviders.vue";
import ProductsBySupplier from "./views/Reports/ProductsBySupplier/ProductsBySupplier.vue";
import ProductsWithMinimumStock from "./views/Reports/ProductsWithMinimumStock/ProductsWithMinimumStock.vue";
import ProductTraking from "./views/Reports/ProductTraking/ProductTraking.vue";
import StaffLoans from "./views/Users/StaffLoans/StaffLoans.vue";
import UserAssists from "./views/Users/UserAssists/UserAssists.vue";
import ListUserAssists from "./views/Users/UserAssists/ListUserAssists.vue";
import PersonalPayment from "./views/Users/PersonalPayment/PersonalPayment.vue";
import ServicesDGII from "./views/ServicesDGII/ServicesDGII.vue";
import ConsolidatedSales from "./views/Reports/ConsolidatedSales/ConsolidatedSales.vue";
import SalesReferralGuide from "./views/ReferralGuide/SalesReferralGuide.vue";
import NotFound from "./views/NotFound.vue";
import PhysicalInventory from "./views/Reports/Products/PhysicalInventory.vue";
import MainDeliveryMan from "./views/DeliveryMan/MainDeliveryMan.vue";
import MainDailyReport from "./views/Reports/Sales/DailyReport/MainDailyReport.vue";
import ListInternalTransferOfProductsView from "./views/InternalTransferOfProducts/ListInternalTransferOfProductsView.vue";
import InternalTransferOfProductsView from "./views/InternalTransferOfProducts/InternalTransferOfProductsView.vue";
import CreditLotesLogView from "./views/logs/CreditLotesLogView.vue";
import ProductPriceListView from "./views/Reports/Products/ProductPriceListView.vue";

const routes = [
  { 
    path: '/:pathMatch(.*)', 
    component: NotFound 
  },
  {
    path: "/",
    name: "HomePage",
    component: Home,
    meta: {
      title: "Home ",
      requiresAuth: true,
    },
  },
  {
    path: "/modulos-del-sistema",
    name: "SystemModules",
    component: SystemModules,
    meta: {
      title: "Módulos del sistema ",
      requiresAuth: true,
    },
  },
  {
    path: "/sub-modulos",
    name: "SystemSubModules",
    component: SystemSubModules,
    meta: {
      title: "Submódulos del Sistema ",
      requiresAuth: true,
    },
  },
  {
    path: "/empresa",
    name: "CompanyConfigurations",
    component: CompanyConfigurations,
    meta: {
      title: "Empresa ",
      requiresAuth: true,
    },
  },
  {
    path: "/sucursal",
    name: "StoresConfigurations",
    component: Stores,
    meta: {
      title: "Sucursal ",
      requiresAuth: true,
    },
  },
  {
    path: "/tipo-de-documentos",
    name: "TypeDocuments",
    component: TypeDocuments,
    meta: {
      title: "Tipo de documentos ",
      requiresAuth: true,
    },
  },
  {
    path: "/tipo-de-usuarios",
    name: "TypeOfUsers",
    component: TypeOfUsers,
    meta: {
      title: "Tipo de Usuarios ",
      requiresAuth: true,
    },
  },
  {
    path: "/usuarios",
    name: "MainUser",
    component: MainUsers,
    meta: {
      title: "Usuarios ",
      requiresAuth: true,
    },
  },
  {
    path: "/turnos",
    name: "MainTurn",
    component: MainTurn,
    meta: {
      title: "Turnos ",
      requiresAuth: true,
    },
  },
  {
    path: "/horarios",
    name: "WorkSchedules",
    component: WorkSchedules,
    meta: {
      title: "Horarios ",
      requiresAuth: true,
    },
  },
  {
    path: "/accesos",
    name: "AccessMain",
    component: Access,
    meta: {
      title: "Acessos De Módulo Del Sistema ",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Inicio de sesión ",
    },
  },
  {
    path: "/perfil",
    name: "profile",
    component: ProfileUser,
    meta: {
      title: "Mi perfil ",
      requiresAuth: true,
    },
  },
  {
    path: "/contrataciones",
    name: "ConfigureUsersPayment",
    component: ConfigureUsersPayment,
    meta: {
      title: "Configurar pagos del usuario ",
      requiresAuth: true,
    },
  },
  {
    path: "/tipo-de-comprobantes",
    name: "TypeOfVouchers",
    component: TypeOfVouchers,
    meta: {
      title: "Tipo de comprobantes ",
      requiresAuth: true,
    },
  },
  {
    path: "/delivery-app",
    name: "DeliveryApp",
    component: DeliveryApp,
    meta: {
      title: "App de delivery ",
      requiresAuth: true,
    },
  },
  {
    path: "/comprobantes",
    name: "Vouchers",
    component: Vouchers,
    meta: {
      title: "Comprobantes ",
      requiresAuth: true,
    },
  },
  {
    path: "/iva",
    name: "iva",
    component: Iva,
    meta: {
      title: "Iva ",
      requiresAuth: true,
    },
  },
  {
    path: "/descuento-global",
    name: "DiscountGlobal",
    component: MainDiscountGlobal,
    meta: {
      title: "Descuento global ",
      requiresAuth: true,
    },
  },
  {
    path: "/divisas",
    name: "Currencys",
    component: Currencys,
    meta: {
      title: "Divisas ",
      requiresAuth: true,
    },
  },
  {
    path: "/configuracion-de-impresion",
    name: "ConfigureVouchersPrint",
    component: ConfigureVouchersPrint,
    meta: {
      title: "Configuracion de impresion de comprobantes ",
      requiresAuth: true,
    },
  },
  {
    path: "/correos",
    name: "Emails",
    component: Emails,
    meta: {
      title: "Configuracion correos ",
      requiresAuth: true,
    },
  },
  {
    path: "/comisiones",
    name: "ConfigCommissions",
    component: Commissions,
    meta: {
      title: "Configuracion comisiones ",
      requiresAuth: true,
    },
  },
  {
    path: "/clientes",
    name: "SettingCustomers",
    component: Customers,
    meta: {
      title: "Clientes ",
      requiresAuth: true,
    },
  },
  {
    path: "/aseguradoras",
    name: "InsuranceCompanies",
    component: InsuranceCompanies,
    meta: {
      title: "Aseguradoras ",
      requiresAuth: true,
    },
  },
  {
    path: "/proveedores",
    name: "Suppliers",
    component: Suppliers,
    meta: {
      title: "Proveedores ",
      requiresAuth: true,
    },
  },
  {
    path: "/tipo-de-productos",
    name: "TypeOfProducts",
    component: TypeOfProducts,
    meta: {
      title: "Tipo de productos ",
      requiresAuth: true,
    },
  },
  {
    path: "/unidad-de-medidas",
    name: "UnitMeasures",
    component: UnitMeasures,
    meta: {
      title: "Unidad de medidas ",
      requiresAuth: true,
    },
  },
  {
    path: "/productos",
    name: "Products",
    component: Products,
    meta: {
      title: "Productos ",
      requiresAuth: true,
    },
  },
  {
    path: "/orden-de-compras",
    name: "PurchaseOrder",
    component: PurchaseOrder,
    meta: {
      title: "Orden de compras ",
      requiresAuth: true,
    },
  },
  {
    path: "/listado-de-orden-de-compras",
    name: "ListPurchaseOrder",
    component: ListPurchaseOrder,
    meta: {
      title: "Listado de orden de compras ",
      requiresAuth: true,
    },
  },
  {
    path: "/compras",
    name: "Shopping",
    component: Shopping,
    meta: {
      title: "Compras",
      requiresAuth: true,
    },
  },
  {
    path: "/ayuda",
    name: "HelpMe",
    component: HelpMe,
    meta: {
      title: "Ayuda sobre el sistema",
      requiresAuth: true,
    },
  },
  {
    path: "/listado-de-compras",
    name: "ListShopping",
    component: ListShopping,
    meta: {
      title: "Listado de compras",
      requiresAuth: true,
    },
  },
  {
    path: "/kardex",
    name: "KardexData",
    component: Kardex,
    meta: {
      title: "Kardex ",
      requiresAuth: true,
    },
  },
  {
    path: "/lotes",
    name: "LotsData",
    component: Lots,
    meta: {
      title: "Lotes ",
      requiresAuth: true,
    },
  },
  {
    path: "/nota-de-credito-compras",
    name: "CreditNoteShopping",
    component: CreditNoteShopping,
    meta: {
      title: "Nota de crédito de compras ",
      requiresAuth: true,
    },
  },
  {
    path: "/cuentas-por-pagar",
    name: "AccountPayable",
    component: AccountPayable,
    meta: {
      title: "Cuentas por pagar ",
      requiresAuth: true,
    },
  },
  {
    path: "/historial-de-pagos-cuentas-por-pagar",
    name: "ListOfAccountsPayablePayments",
    component: ListOfAccountsPayablePayments,
    meta: {
      title: "Historial de pagos C.P. ",
      requiresAuth: true,
    },
  },
  {
    path: "/desembolsos",
    name: "DisbursementsData",
    component: Disbursements,
    meta: {
      title: "Desembolsos o egresos ",
      requiresAuth: true,
    },
  },
  {
    path: "/accesos-especificos",
    name: "SystemEspecificAccess",
    component: SystemEspecificAccess,
    meta: {
      title: "Accesos específicos del sistema ",
      requiresAuth: true,
    },
  },
  {
    path: "/cajas",
    name: "BoxesData",
    component: BoxesData,
    meta: {
      title: "Listado de cajas ",
      requiresAuth: true,
    },
  },
  {
    path: "/apertura-y-cierre-de-caja",
    name: "ControlOfBoxes",
    component: ControlOfBoxes,
    meta: {
      title: "Apertura y cierre de caja ",
      requiresAuth: true,
    },
  },
  {
    path: "/dgii",
    name: "DGII",
    component: DGII,
    meta: {
      title: "Actualizacion de datos DGII ",
      requiresAuth: true,
    },
  },
  {
    path: "/ventas",
    name: "SalesRoot",
    component: Sales,
    meta: {
      title: "Ventas ",
      requiresAuth: true,
    },
  },
  {
    path: "/listado-de-ventas",
    name: "SalesList",
    component: SalesList,
    meta: {
      title: "Listado de ventas ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-de-ventas",
    name: "SalesReport",
    component: SalesReport,
    meta: {
      title: "Reporte de ventas ",
      requiresAuth: true,
    },
  },
  {
    path: "/cotizaciones",
    name: "Quotes",
    component: Quotes,
    meta: {
      title: "Cotizaciones ",
      requiresAuth: true,
    },
  },
  {
    path: "/ventas-temporales",
    name: "TemporarySales",
    component: TemporarySales,
    meta: {
      title: "Ventas temporales ",
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos",
    name: "CustomerOrder",
    component: CustomerOrder,
    meta: {
      title: "Pedidos - Factura HOLD ",
      requiresAuth: true,
    },
  },
  {
    path: "/cuentas-por-cobrar",
    name: "AccountsReceivable",
    component: AccountsReceivable,
    meta: {
      title: "Cuentas por cobrar ",
      requiresAuth: true,
    },
  },
  {
    path: "/historial-de-pagos-cuentas-por-crobrar",
    name: "ListOfAccountsReceivablePayments",
    component: ListOfAccountsReceivablePayments,
    meta: {
      title: "Historial de cuentas por cobrar ",
      requiresAuth: true,
    },
  },
  {
    path: "/cuentas-por-crobrar-aseguradoras",
    name: "InsuranceAccountsReceivable",
    component: InsuranceAccountsReceivable,
    meta: {
      title: "Cuentas por cobrar aseguradoras ",
      requiresAuth: true,
    },
  },
  {
    path: "/historial-de-pagos-cuentas-por-crobrar-aseguradoras",
    name: "ListOfInsuranceAccountsReceivable",
    component: ListOfInsuranceAccountsReceivable,
    meta: {
      title: "Historial de cuentas por cobrar aseguradoras ",
      requiresAuth: true,
    },
  },
  {
    path: "/notas-de-credito-ventas",
    name: "CreditNote",
    component: CreditNote,
    meta: {
      title: "Notas de crédito ventas ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-de-ganancias-y-utilidades-de-ventas",
    name: "ReportProfits",
    component: ReportProfits,
    meta: {
      title: "Reporte de ventas, Ganancias y venta total ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-de-productos",
    name: "ReportProducts",
    component: ReportProducts,
    meta: {
      title: "Reporte de productos ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-para-dgii",
    name: "ReportDGII",
    component: ReportDGII,
    meta: {
      title: "Declaración de impuestos ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-de-productos-mas-y-menos-vendidos",
    name: "BestSellingAndLessSold",
    component: BestSellingAndLessSold,
    meta: {
      title: "Productos con mas y menos movimiento ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-de-movimiento-de-productos-por-modulo",
    name: "ProductsAccordingToModuleEntered",
    component: ProductsAccordingToModuleEntered,
    meta: {
      title: "Movimiento de productos por módulo ",
      requiresAuth: true,
    },
  },
  {
    path: "/movimiento-de-productos",
    name: "ProductMovement",
    component: ProductMovement,
    meta: {
      title: "Movimiento de productos ",
      requiresAuth: true,
    },
  },
  {
    path: "/vendedor-del-mes",
    name: "SellerOfTheMonth",
    component: SellerOfTheMonth,
    meta: {
      title: "Vendedores del mes ",
      requiresAuth: true,
    },
  },
  {
    path: "/comparar-precio-entre-proveedores",
    name: "ComparePriceBetweenProviders",
    component: ComparePriceBetweenProviders,
    meta: {
      title: "Comparar precio entre proveedores ",
      requiresAuth: true,
    },
  },
  {
    path: "/modulo-de-verificacion-de-productos-por-proveedor",
    name: "ProductsBySupplier",
    component: ProductsBySupplier,
    meta: {
      title: "Seguimiento de productos segun compra y proveedor",
      requiresAuth: true,
    },
  },
  {
    path: "/productos-con-stock-minimo",
    name: "ProductsWithMinimumStock",
    component: ProductsWithMinimumStock,
    meta: {
      title: "Listado de productos segun stock minimo ",
      requiresAuth: true,
    },
  },
  {
    path: "/seguimiento-de-productos",
    name: "ProductTraking",
    component: ProductTraking,
    meta: {
      title: "Seguimiento de productos segun venta ",
      requiresAuth: true,
    },
  },
  {
    path: "/prestamos-del-personal",
    name: "StaffLoans",
    component: StaffLoans,
    meta: {
      title: "Prestamos del personal ",
      requiresAuth: true,
    },
  },
  {
    path: "/marcar-asistencias",
    name: "UserAssists",
    component: UserAssists,
    meta: {
      title: "Marcar asistencias ",
      requiresAuth: true,
    },
  },
  {
    path: "/listado-de-asistencias",
    name: "ListUserAssists",
    component: ListUserAssists,
    meta: {
      title: "Listado de asistencias ",
      requiresAuth: true,
    },
  },
  {
    path: "/pagos-del-personal",
    name: "PersonalPayment",
    component: PersonalPayment,
    meta: {
      title: "Pagos del personal ",
      requiresAuth: true,
    },
  },
  {
    path: "/pagos-de-servicio",
    name: "ServicesDGII",
    component: ServicesDGII,
    meta: {
      title: "Pago de servicio ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-consolidado",
    name: "ConsolidatedSales",
    component: ConsolidatedSales,
    meta: {
      title: "Consolidado de reportes ",
      requiresAuth: true,
    },
  },
  {
    path: "/guia-de-remision-ventas",
    name: "SalesReferralGuide",
    component: SalesReferralGuide,
    meta: {
      title: "Guia de remision ventas ",
      requiresAuth: true,
    },
  },
  {
    path: "/inventario-fisico",
    name: "PhysicalInventory",
    component: PhysicalInventory,
    meta: {
      title: "Inventario físico ",
      requiresAuth: true,
    },
  },
  {
    path: "/repartidor",
    name: "MainDeliveryMan",
    component: MainDeliveryMan,
    meta: {
      title: "Repartidor ",
      requiresAuth: true,
    },
  },
  {
    path: "/reporte-diario-de-ventas-detallado",
    name: "MainDailyReport",
    component: MainDailyReport,
    meta: {
      title: "Reporte detallado de ventas ",
      requiresAuth: true,
    },
  },
  {
    path: "/traslado-interno",
    name: "InternalTransferOfProductsView",
    component: InternalTransferOfProductsView,
    meta: {
      title: "Traslado interno de productos ",
      requiresAuth: true,
    },
  },
  {
    path: "/listado-traslado-interno",
    name: "ListInternalTransferOfProductsView",
    component: ListInternalTransferOfProductsView,
    meta: {
      title: "Listado de traslado interno de productos ",
      requiresAuth: true,
    },
  },
  {
    path: "/log-notas-de-credito",
    name: "CreditLotesLogView",
    component: CreditLotesLogView,
    meta: {
      title: "Log Notas de crédito ",
      requiresAuth: true,
    },
  },
  {
    path: "/lista-de-precio-productos",
    name: "ProductPriceListView",
    component: ProductPriceListView,
    meta: {
      title: "Lista de precios",
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
