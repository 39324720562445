<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700">
      Contrataciones / Configuración pagos
    </h2>
    <ConfigureUsersPayment />
  </div>
</template>

<script>
import ConfigureUsersPayment from "@/components/Users/Payments/Hirings/MainHirings.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "UserHiring",
  components: {
    ConfigureUsersPayment,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
