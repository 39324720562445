<template>
  <select
    class="py-2 px-3 text-sm text-gray-600 border block w-full rounded-lg mb-2 bg-gray-100 animate-pulse outline-none"
    v-if="loading"
  ></select>
  <select
    class="py-2 px-3 text-sm text-gray-600 border block w-full rounded-lg mb-2 bg-white outline-none"
    v-model="vModelSelectOptions"
    @change="sendDataSelect"
    v-else
  >
    <option :value="resgiter.id" v-for="(resgiter, i) in registers" :key="i">
      {{ resgiter.descripcion }}
    </option>
  </select>
</template>
<script>
import { onMounted, ref } from "vue";
import axios from "../../api";
export default {
  props: {
    selectOption: {
      type: Number,
      required: false,
    },
  },
  setup(props, { emit }) {
    const registers = ref([]);
    const loading = ref(true);
    const vModelSelectOptions = ref(1);

    //funcion para enviar datos desde este componente hacia el otro componente
    const sendDataSelect = () => {
      emit("send-data", vModelSelectOptions.value);
    };
    onMounted(async () => {
      try {
        const response = await axios.get("modulos_del_sistema"
        );
        registers.value = await response.data;
        loading.value = false;
        if (props.selectOption != "" && props.selectOption !== undefined) {
          vModelSelectOptions.value = props.selectOption || 1;
        } else {
          vModelSelectOptions.value = 1;
        }
      } catch (error) {
        console.log(error);
      }
    });
    return {
      registers,
      loading,
      vModelSelectOptions,
      sendDataSelect,
    };
  },
};
</script>
