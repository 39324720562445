<template>
  <div class="w-full md:w-auto mt-3 md:mt-0" id="containerSearchSales">
    <span class="text-sm font-semibold text-blue-500 pb-2 block">
      Ventas
    </span>
    <div class="relative md:min-w-[260px] lg:min-w-[320px]">
      <div class="flex items-center relative text-gray-600">
        <div class="absolute top-0 left-3 w-6 h-full flex items-center">
          <font-awesome-icon icon="fa-solid fa-search" class="w-4 h-4" />
        </div>
        <input
          type="search"
          v-model="dataForm.searchInput"
          class="border-2 pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50 focus:border-blue-500"
          placeholder="Buscar por nombre de cliente o n° de documento"
          @keydown.enter="getData"
          @input="getData"
          @keydown.enter.prevent
          autocomplete="off"
        />
      </div>
      <div
        v-if="showSales"
        class="absolute top-[2.4rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-72 overflow-y-auto"
      >
        <div v-if="loadingSearch">
          <div class="px-3">
            <div
              class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
            ></div>
            <div
              class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
            ></div>
          </div>
        </div>
        <div v-else>
          <div
            v-if="!dataLength"
            class="px-3 py-1 bg-gray-50 text-gray-400"
            @click="showSales = false"
          >
            <strong>{{ dataForm.searchInput }}</strong> no existe en nuestra
            base de datos
          </div>
          <div
            v-else
            v-for="(result, i) in dataResult"
            :key="i"
            class="odd:bg-white even:bg-slate-50"
            @blur="showSales = false"
          >
            <div
              class="py-2 px-3 pb-1 text-gray-600 cursor-pointer text-sm hover:bg-gray-100 hover:text-blue-500 uppercase"
              @click="
                addOrder(
                  {
                    id: result.id,
                    nameCustomer: result.nombre_cliente_pedido,
                    details: result.detalle,
                  }
                )
              "
            >
              {{ result.nombre_cliente_pedido }}
              <div>
                <span
                  class="px-3 py-1 rounded-md text-green-500 bg-green-50 text-xs font-semibold mr-2"
                >
                 {{ result.serie_comprobante }} {{ result.numero_comprobante }}
                </span>
                <span
                  class="px-3 py-1 rounded-md text-orange-500 bg-orange-50 text-xs font-semibold"
                >
                  {{ money() }} {{ format(result.total) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorActions } from "@/alerts";
import axios from "@/api";
import { onMounted, onUnmounted, ref } from "vue";
import { observeElement } from "@/observer";
import { idLocalStore } from "@/publicjs/localStorage";
import { money, format } from "@/publicjs/money";
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
export default {
  name: "SearchSales",
  emits: ["send-data"],
  setup(_, { emit }) {
    const dataForm = ref({
      idStore: 0,
      searchInput: "",
    });

    const infoOrder = ref({
      id: 0,
      idSales: 0,
      nameCustomer: "",
      details: [],
    });

    //Funcion para cargar las cotizaciones
    const showSales = ref(false);
    const loadingSearch = ref(true);
    const dataResult = ref([]);
    const dataLength = ref(false);
    const getData = async () => {
      try {
        loadingSearch.value = true;
        showSales.value = true;
        dataLength.value = false;
        const response = await axios
          .get(
            `ventas/?page=1&per_page=10&search=${dataForm.value.searchInput}&idstore=${dataForm.value.idStore}&state=EFECTUADA&type_voucher=false`
          )
          .catch((error) => errorActions(error));
        dataResult.value = await response.data.data;
        if (dataResult.value.length > 0) {
          dataLength.value = true;
        }
        loadingSearch.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar la cotización debido a que hemos tenido unos inconvenientes"
        );
      }
    };

    onMounted(async () => {
      observeElement("#containerSearchSales");
      window.addEventListener("keydown", handleKeyDown);
      dataForm.value.idStore = await idLocalStore();
      await getData();
    });
    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });
    /*******************************************************************************************
     * Funcion para cerrar resultado de busqueda de la cotizacion
     *******************************************************************************************/
    const handleKeyDown = async (event) => {
      if (event.keyCode === 27) {
        showSales.value = false;
      }
    };

    /*******************************************************************************************
     * Funcion para agregar la orden a la venta
     *******************************************************************************************/
    const addOrder = async (data) => {

      const { id, idSales, nameCustomer, details } = data;

      infoOrder.value.id = id;
      infoOrder.value.idSales = idSales;
      infoOrder.value.nameCustomer = nameCustomer;
      infoOrder.value.details = details;

      emit("send-data", infoOrder.value);

      showSales.value = false;
    };

    return {
      showSales,
      loadingSearch,
      dataResult,
      dataLength,
      getData,
      dataForm,
      money,
      format,
      addOrder,
      calculateExpirationDate,
    };
  },
};
</script>
