<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="mainContainerForm">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Estado de caja
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <!--Aqui va el codigo-->
        <div>
          <div class="mt-1" id="contentFormBox">
            <div v-show="loading">
              <div class="bg-gray-50 px-3 py-3 animate-pulse mb-3 rounded-md">
                <p class="text-gray-500 animate-pulse">
                  Espera un momento mientras preparamos la caja para el turno
                  actual
                </p>
              </div>
            </div>
            <div v-show="!loading">
              <div
                class="px-3 py-3 rounded-md flex justify-between flex-wrap"
                v-if="stateBeforeOpenBox"
                :class="{
                  'bg-red-50 mb-6 text-red-500': stateInputsHiddenForCloseBox,
                  'bg-green-50 mb-6 text-green-500 ':
                    !stateInputsHiddenForCloseBox,
                }"
              >
                <strong
                  class="font-semibold uppercase text-sm whitespace-nowrap"
                  v-if="!stateInputsHiddenForCloseBox"
                >
                  Caja abierta
                </strong>
                <strong
                  class="font-semibold uppercase text-sm whitespace-nowrap"
                  v-else
                >
                  Caja cerrada
                </strong>
                <span class="font-semibold uppercase text-sm whitespace-nowrap">
                  FEC: {{ formatDateTime(dataOfOpenBox.openDate) }}
                </span>
              </div>
              <div
                class="px-3 py-3 rounded-md bg-red-50 mb-6 text-red-500"
                v-else
              >
                <strong
                  class="font-semibold uppercase text-sm whitespace-nowrap block border-b border-red-100 pb-2"
                >
                  en este momento la caja se encuentra cerrada
                </strong>
                <span class="text-sm text-red-700 pt-3 block">
                  Por favor apertura tu caja para poder hacer ventas,
                  desembolsos, etc.
                </span>
              </div>
              <!-- Seccion para mostrar el monto de la venta total -->
              <div
                class="grid md:grid-cols-2 lg:grid-cols-5 gap-6 mb-6"
                v-if="stateBeforeOpenBox"
              >
                <div
                  class="px-3 py-3 bg-gray-50 text-gray-500 uppercase rounded-md shadow-md shadow-gray-100 border border-gray-100"
                >
                  <div
                    class="border-b-2 pb-2 border-gray-100 text-xs font-semibold"
                  >
                    Monto en cheque
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.chequeAmount) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-gray-50 text-gray-500 uppercase rounded-md shadow-md shadow-gray-100 border border-gray-100"
                >
                  <div
                    class="border-b-2 pb-2 border-gray-100 text-xs font-semibold"
                  >
                    Monto en tarjeta
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.targetAmount) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-gray-50 text-gray-500 uppercase rounded-md shadow-md shadow-gray-100 border border-gray-100"
                >
                  <div
                    class="border-b-2 pb-2 border-gray-100 text-xs font-semibold"
                  >
                    Monto en efectivo
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.cashAmount) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-gray-50 text-gray-500 uppercase rounded-md shadow-md shadow-gray-100 border border-gray-100"
                >
                  <div
                    class="border-b-2 pb-2 border-gray-100 text-xs font-semibold"
                  >
                    Monto en transferencia
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.transferAmount) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-red-50 text-red-500 uppercase rounded-md shadow-md shadow-red-100 border border-red-200"
                >
                  <div
                    class="border-b-2 pb-2 border-red-100 text-xs font-semibold"
                  >
                    Monto en desembolso
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.expenceAmount) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-orange-50 text-orange-500 uppercase rounded-md shadow-md shadow-orange-100 border border-orange-200"
                >
                  <div
                    class="border-b-2 pb-2 border-orange-100 text-xs font-semibold"
                  >
                    Monto de cierre total
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.totalClosingAmount) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-green-50 text-green-500 uppercase rounded-md shadow-md shadow-green-100 border border-green-200"
                >
                  <div
                    class="border-b-2 pb-2 border-green-100 text-xs font-semibold"
                  >
                    Monto efectivo en caja
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.amountInTheBox) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-green-500 text-white uppercase rounded-md shadow-md shadow-green-100 border border-green-200"
                >
                  <div
                    class="border-b-2 pb-2 border-green-100 text-xs font-semibold"
                  >
                    hiciste un descuento por
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.discounts) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-red-500 text-white uppercase rounded-md shadow-md shadow-green-100 border border-green-200"
                >
                  <div
                    class="border-b-2 pb-2 border-green-100 text-xs font-semibold"
                  >
                    pagos de mensajeros hoy
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }} {{ format(dataForm.totalPagosMensajero) }}
                  </div>
                </div>
                <div
                  class="px-3 py-3 bg-orange-500 text-white uppercase rounded-md shadow-md shadow-orange-100 border border-orange-200"
                >
                  <div
                    class="border-b-2 pb-2 border-green-100 text-xs font-semibold"
                  >
                    pagos cuentas por cobrar
                  </div>
                  <div class="font-bold text-lg mt-3">
                    {{ money() }}
                    {{ format(dataForm.totalPagosCuentasPorCobrar) }}
                  </div>
                </div>
              </div>
              <form class="w-full space-y-6" @submit.prevent>
                <div v-if="!stateBeforeOpenBox">
                  <label class="block">
                    <span
                      class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                    >
                      Monto inicial de apertura de caja
                      <span class="text-red-300">*</span>
                    </span>
                    <input
                      type="number"
                      class="py-2 px-2 text-sm bg-green-50 outline-none rounded-lg text-green-600 w-full border-2 border-green-500 focus:border-green-500 font-bold"
                      v-model="dataForm.startingAmount"
                      placeholder="Monto inicial"
                      @keydown.enter.prevent
                      min="0"
                    />
                  </label>
                </div>
                <div v-else>
                  <div class="space-y-6" v-if="!stateInputsHiddenForCloseBox">
                    <h2
                      class="text-blue-500 font-bold text-md"
                      v-if="stateBeforeOpenBox"
                    >
                      Ingresa la cantidad de billetes para cuadrar tu caja
                    </h2>
                    <div
                      class="grid md:grid-cols-2 lg:grid-cols-6 gap-6"
                      v-if="stateBeforeOpenBox"
                    >
                      <label class="block" v-show="hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 10 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill10"
                          placeholder="Billetes de 10"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block" v-show="hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 20 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill20"
                          placeholder="Billetes de 20"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 50 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill50"
                          placeholder="Billetes de 50"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 100 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill100"
                          placeholder="Billetes de 100"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 200 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill200"
                          placeholder="Billetes de 200"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block" v-show="!hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 500 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill500"
                          placeholder="Billetes de 500"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block" v-show="!hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 1000 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill1000"
                          placeholder="Billetes de 1000"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block" v-show="!hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Billetes de 2000 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.bill2000"
                          placeholder="Billetes de 2000"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                    </div>
                    <h2
                      class="text-blue-500 font-bold text-md"
                      v-if="stateBeforeOpenBox"
                    >
                      Ingresa la cantidad de monedas para cuadrar tu caja
                    </h2>
                    <div
                      class="grid md:grid-cols-2 lg:grid-cols-6 gap-6"
                      v-if="stateBeforeOpenBox"
                    >
                      <label class="block" v-show="hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Monedas de 50 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.coin50"
                          placeholder="Monedas de 50"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Monedas de 1 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.coin1"
                          placeholder="Monedas de 1"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block" v-show="hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Monedas de 2 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.coin2"
                          placeholder="Monedas de 2"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Monedas de 5 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.coin5"
                          placeholder="Monedas de 5"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Monedas de 10 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.coin10"
                          placeholder="Monedas de 10"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block" v-show="hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Monedas de 20 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.coin20"
                          placeholder="Monedas de 20"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                      <label class="block" v-show="!hiddenInputsByContry">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Monedas de 25 <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                          v-model="dataForm.coin25"
                          placeholder="Monedas de 25"
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateBox"
                        />
                      </label>
                    </div>
                    <!-- Aqui debe de ir las demas configuraciones -->
                    <h2
                      class="text-blue-500 font-bold text-md"
                      v-if="stateBeforeOpenBox"
                    >
                      Más configuraciones
                    </h2>
                    <div
                      class="grid md:grid-cols-2 lg:grid-cols-6 gap-6"
                      v-if="stateBeforeOpenBox"
                    >
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Dinero en tránsito efectivo
                          <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-orange-600 font-semibold w-full border-2 border-orange-300 focus:border-blue-500"
                          v-model="dataForm.moneyInTransit"
                          placeholder="Dinero en tránsito efectivo"
                          @keydown.enter.prevent
                          min="0"
                          @input="transitAmount"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-xs font-medium text-gray-800 dark:text-white"
                        >
                          Dinero en tránsito otros tipos de pago
                          <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-orange-600 font-semibold w-full border-2 border-orange-300 focus:border-blue-500"
                          v-model="dataForm.moneyInTransitOthersPayments"
                          placeholder="Dinero en tránsito otros"
                          @keydown.enter.prevent
                          disabled
                          min="0"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Otros ingreso <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-red-600 font-semibold w-full border-2 border-red-300 focus:border-blue-500"
                          v-model="dataForm.creditAmount"
                          placeholder="Otros ingreso "
                          @keydown.enter.prevent
                          min="0"
                          @input="calculateOthersInputs"
                        />
                      </label>
                      <label class="block lg:col-span-3">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Comentario adicional
                          <span class="text-red-300">*</span>
                        </span>
                        <textarea
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
                          v-model="dataForm.comentary"
                          placeholder="Comentario adicional"
                          cols="30"
                          rows="1"
                          maxlength="100"
                        ></textarea>
                      </label>
                    </div>
                    <!-- Aqui debe de ir cuanto dinero se depositara a la empresa y cuanto dinero se enviara a la empresa -->
                    <h2
                      class="text-blue-500 font-bold text-md"
                      v-if="stateBeforeOpenBox"
                    >
                      Especifica cuando dinero se quedará en la caja y cuanto
                      dinero sera entregado o transferido a la empresa.
                    </h2>
                    <div
                      class="grid md:grid-cols-2 lg:grid-cols-6 gap-6"
                      v-if="stateBeforeOpenBox"
                    >
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Dinero que se queda
                          <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-orange-600 font-semibold w-full border-2 border-orange-300 focus:border-blue-500"
                          v-model="dataForm.moneyThatStays"
                          placeholder="Dinero que se queda"
                          @keydown.enter.prevent
                          min="0"
                        />
                      </label>
                      <label class="block">
                        <span
                          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                        >
                          Dinero que se deposita
                          <span class="text-red-300">*</span>
                        </span>
                        <input
                          type="number"
                          class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-red-600 font-semibold w-full border-2 border-red-300 focus:border-blue-500"
                          v-model="dataForm.moneyThatIsDeposited"
                          placeholder="Dinero que se deposita "
                          @keydown.enter.prevent
                          min="0"
                        />
                      </label>
                    </div>
                    <div
                      class="grid md:grid-cols-2 lg:grid-cols-3 gap-6"
                      v-if="stateBeforeOpenBox"
                    >
                      <div
                        class="px-3 py-3 bg-red-50 text-red-500 uppercase rounded-md shadow-md shadow-red-100 border border-red-100"
                      >
                        <div
                          class="border-b-2 pb-2 border-red-100 text-xs font-semibold"
                        >
                          Monto faltante
                        </div>
                        <div class="font-bold text-lg mt-3">
                          {{ money() }} {{ format(dataForm.remainingAmount) }}
                        </div>
                      </div>
                      <div
                        class="px-3 py-3 bg-orange-50 text-orange-500 uppercase rounded-md shadow-md shadow-orange-100 border border-orange-100"
                        v-show="canYouLeftoverInCard"
                      >
                        <div
                          class="border-b-2 pb-2 border-orange-100 text-xs font-semibold"
                        >
                          Monto sobrante
                        </div>
                        <div class="font-bold text-lg mt-3">
                          {{ money() }} {{ format(dataForm.excessAmount) }}
                        </div>
                      </div>
                      <div
                        class="px-3 py-3 bg-green-50 text-green-500 uppercase rounded-md shadow-md shadow-green-100 border border-green-200"
                        v-show="canYouTotalInCard"
                      >
                        <div
                          class="border-b-2 pb-2 border-green-200 text-xs font-semibold"
                        >
                          Tus monedas y billetes contabilizadas suman:
                        </div>
                        <div class="font-bold text-lg mt-3">
                          {{ money() }} {{ format(postedAmount) }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex mt-[3.5rem!important] lg:mt-[2rem!important]"
                    >
                      <button
                        class="bg-blue-500 text-white px-4 py-3 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                        @click="closingBox"
                        :disabled="spinner === true"
                      >
                        <div class="flex items-center" v-if="spinner">
                          <svg class="w-4 h-4 mr-3 animate-spin">
                            <use
                              href="../../assets/svg/icon.svg#spinnerForBlue"
                            />
                          </svg>
                          Cerrando caja
                        </div>
                        <span class="flex items-center" v-else>
                          <font-awesome-icon
                            icon="fa-solid fa-cancel"
                            class="w-4 h-4 mr-3"
                          />
                          Cerrar caja
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
                  <button
                    class="bg-blue-500 text-white px-4 py-3 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                    @click="openBox"
                    :disabled="spinner === true"
                    v-if="!stateBeforeOpenBox"
                  >
                    <div class="flex items-center" v-if="spinner">
                      <svg class="w-4 h-4 mr-3 animate-spin">
                        <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                      </svg>
                      Abriendo caja, espere un momento...
                    </div>
                    <span class="flex items-center" v-else> Abrir caja </span>
                  </button>
                </div>
              </form>

              <TypeOfPrinting
                @send-data="handleFormatPrinting"
                v-if="stateBeforeOpenBox && !stateInputsHiddenForCloseBox"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import axios from "@/api";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import boxConfigurationAccordingToCountry from "/public/apis/boxConfigurationAccordingToCountry.json";
import { confirmationOfRecord, errorActions, uploadData } from "@/alerts";
import { formatDateTime } from "@/publicjs/formatDate";
import { getCurrentDateTime } from "@/publicjs/currentDateTime";
import TypeOfPrinting from "@/components/PublicComponents/TypeOfPrinting.vue";

import { printTicket } from "./PrintVoucher/PrintOnTicket";
import { printOnA4 } from "./PrintVoucher/PrintOnA4";
import { printOnLetter } from "./PrintVoucher/PrintOnLetter";
import { printOnMediaLetter } from "./PrintVoucher/PrintOnMediaLetter";
import { validateAccessSpecific } from "@/repository/Sales/ValidateAccessEspecific/ValidateAccess";

import Swal from "sweetalert2";
import { idLocalUser } from "@/publicjs/localStorage";
export default {
  name: "MainBox",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    TypeOfPrinting,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }
    const spinner = ref(false);
    const loading = ref(true);

    const hiddenInputsByContry = ref(false); //Estado para activar inputs segun pais
    const formatPrint = ref("");

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      idTurn: 0,
      openingDate: 0,
      startingAmount: 0,
      creditAmount: 0,
      chequeAmount: 0,
      targetAmount: 0,
      cashAmount: 0,
      cashAmountCurrent: 0,
      transferAmount: 0,
      expenceAmount: 0,
      totalClosingAmount: 0,
      totalClosingAmountCurrent: 0,
      amountInTheBox: 0,
      bill10: 0,
      bill20: 0,
      bill50: 0,
      bill100: 0,
      bill200: 0,
      bill500: 0,
      bill1000: 0,
      bill2000: 0,
      coin50: 0,
      coin1: 0,
      coin2: 0,
      coin5: 0,
      coin10: 0,
      coin20: 0,
      coin25: 0,
      comentary: "",
      remainingAmount: 0,
      excessAmount: 0,
      discounts: 0,
      moneyInTransit: 0,
      deadline: 0,
      state: 0,
      idBox: 0,
      moneyThatStays: 0,
      moneyThatIsDeposited: 0,
      pago_mensajero: 0,
      totalPagosMensajero: 0,
      totalPagosCuentasPorCobrar: 0,
      moneyInTransitOthersPayments: 0,
    });

    const canYouLeftoverInCard = ref(false);
    const canYouTotalInCard = ref(false);

    //Valor de la monedas
    const valueOfCoinsAndBills = ref({
      bill10: 10,
      bill20: 20,
      bill50: 50,
      bill100: 100,
      bill200: 200,
      bill500: 500,
      bill1000: 1000,
      bill2000: 2000,
      coin1: 1,
      coin2: 2,
      coin5: 5,
      coin10: 10,
      coin20: 20,
      coin25: 25,
      coin50: 50,
    });

    const stateBeforeOpenBox = ref(true); //Estado para saber si la caja se encuentra abierta

    const deudasRepartidor = ref([]);
    //Funcion para calcular el monto contabilizado sun monedas introducidas
    const postedAmount = ref(0);

    //Funcion para sumar los billetes y las monedas
    const calculateBox = async () => {
      postedAmount.value = 0;
      const bill10 = dataForm.value.bill10 * valueOfCoinsAndBills.value.bill10;
      const bill20 = dataForm.value.bill20 * valueOfCoinsAndBills.value.bill20;
      const bill50 = dataForm.value.bill50 * valueOfCoinsAndBills.value.bill50;
      const bill100 =
        dataForm.value.bill100 === null
          ? 0
          : dataForm.value.bill100 * valueOfCoinsAndBills.value.bill100;
      const bill200 =
        dataForm.value.bill200 === null
          ? 0
          : dataForm.value.bill200 * valueOfCoinsAndBills.value.bill200;
      const bill500 =
        dataForm.value.bill500 === null
          ? 0
          : dataForm.value.bill500 * valueOfCoinsAndBills.value.bill500;
      const bill1000 =
        dataForm.value.bill1000 === null
          ? 0
          : dataForm.value.bill1000 * valueOfCoinsAndBills.value.bill1000;
      const bill2000 =
        dataForm.value.bill2000 === null
          ? 0
          : dataForm.value.bill2000 * valueOfCoinsAndBills.value.bill2000;
      const coin1 =
        dataForm.value.coin1 === null
          ? 0
          : dataForm.value.coin1 * valueOfCoinsAndBills.value.coin1;
      const coin2 =
        dataForm.value.coin2 === null
          ? 0
          : dataForm.value.coin2 * valueOfCoinsAndBills.value.coin2;
      const coin5 =
        dataForm.value.coin5 === null
          ? 0
          : dataForm.value.coin5 * valueOfCoinsAndBills.value.coin5;
      const coin10 =
        dataForm.value.coin10 === null
          ? 0
          : dataForm.value.coin10 * valueOfCoinsAndBills.value.coin10;
      const coin20 =
        dataForm.value.coin20 === null
          ? 0
          : dataForm.value.coin20 * valueOfCoinsAndBills.value.coin20;
      const coin25 =
        dataForm.value.coin25 === null
          ? 0
          : dataForm.value.coin25 * valueOfCoinsAndBills.value.coin25;
      const coin50 =
        dataForm.value.coin50 === null
          ? 0
          : dataForm.value.coin50 * valueOfCoinsAndBills.value.coin50;

      const amountTotal =
        bill10 +
        bill20 +
        bill50 +
        bill100 +
        bill200 +
        bill500 +
        bill1000 +
        bill2000 +
        coin1 +
        coin2 +
        coin5 +
        coin10 +
        coin20 +
        coin25 +
        coin50;
      postedAmount.value = amountTotal;
      await calculateRemainingAmountAndAxcessAmount(amountTotal); //Calcular el monto faltanta y sobrante
    };

    //Funcion para calcular el dinero en transito menos el dinero en efectivo
    const transitAmount = async () => {
      const efectivoPayment = deudasRepartidor.value.find(
        (item) => item.payment_method === "EFECTIVO"
      );
      let newPrice = 0;
      if (
        parseFloat(efectivoPayment.total) > 0 &&
        parseFloat(dataForm.value.moneyInTransit) <= 0
      ) {
        newPrice = parseFloat(efectivoPayment.total);
      } else {
        newPrice = parseFloat(dataForm.value.moneyInTransit);
      }
      dataForm.value.amountInTheBox =
        (dataForm.value.cashAmountCurrent + dataForm.value.creditAmount) - newPrice;
    };

    //Funcion para calcular el monto sobrante o faltante
    // const calculateRemainingAmountAndAxcessAmount = async (countMoney) => {
    //   let closeAmount = 0;
    //   let amountInTheBox = parseFloat(dataForm.value.amountInTheBox);
    //   if (parseFloat(dataForm.value.expenceAmount) <= 0) {
    //     closeAmount =
    //       amountInTheBox +
    //       parseFloat(dataOfOpenBox.value.amountOpen) -
    //       parseFloat(dataForm.value.expenceAmount);
    //     amountInTheBox += parseFloat(dataOfOpenBox.value.amountOpen);
    //   } else {
    //     closeAmount = amountInTheBox;
    //   }

    //   if (countMoney < amountInTheBox) {
    //     if (parseFloat(dataForm.value.expenceAmount) <= 0) {
    //       if (countMoney > (amountInTheBox - parseFloat(dataOfOpenBox.value.amountOpen))) {
    //         dataForm.value.excessAmount =   closeAmount - countMoney;
    //         dataForm.value.remainingAmount = 0;
    //       } else {
    //         dataForm.value.remainingAmount =
    //           closeAmount -
    //           countMoney -
    //           parseFloat(dataOfOpenBox.value.amountOpen);
    //         dataForm.value.excessAmount = 0;
    //       }
    //     } else {
    //       dataForm.value.remainingAmount = closeAmount - countMoney;
    //       dataForm.value.excessAmount = 0;
    //     }
    //   } else if (amountInTheBox < countMoney) {
    //     if (countMoney > amountInTheBox) {
    //         dataForm.value.excessAmount =  countMoney - closeAmount;
    //         dataForm.value.remainingAmount = 0;
    //       } else {
    //         dataForm.value.remainingAmount =
    //           closeAmount -
    //           countMoney -
    //           parseFloat(dataOfOpenBox.value.amountOpen);
    //         dataForm.value.excessAmount = 0;
    //       }
    //   } else {
    //     dataForm.value.remainingAmount = 0;
    //     dataForm.value.excessAmount = 0;
    //   }
    // };
    const calculateRemainingAmountAndAxcessAmount = async (countMoney) => {
      let closeAmount = parseFloat(dataForm.value.amountInTheBox);
      if (countMoney < closeAmount) {
        dataForm.value.remainingAmount = closeAmount - countMoney;
        dataForm.value.excessAmount = 0;
      } else if (countMoney > closeAmount) {
        dataForm.value.excessAmount = countMoney - closeAmount;
        dataForm.value.remainingAmount = 0;
      } else {
        dataForm.value.remainingAmount = 0;
        dataForm.value.excessAmount = 0;
      }
    };
    //Funcion para calcular otros ingreso, como pago de credito, cobros , etc
    const calculateOthersInputs = async () => {
      dataForm.value.amountInTheBox =
        parseFloat(dataForm.value.cashAmountCurrent) + parseFloat(dataForm.value.creditAmount);
      dataForm.value.totalClosingAmount =
        parseFloat(dataForm.value.totalClosingAmountCurrent) + parseFloat(dataForm.value.creditAmount);
    };

    //Funcion para validar si la caja se encuentra abierta
    const dataOfOpenBox = ref({
      openDate: null,
      amountOpen: 0,
    });
    const stateInputsHiddenForCloseBox = ref(false);
    const validateOpenBox = async () => {
      const date = dataForm.value.openingDate.slice(0, 10);
      const response = await axios
        .get(
          `caja/check-status/show-box-to-close/?idstore=${dataForm.value.idStore}&iduser=${dataForm.value.idUser}&idturn=${dataForm.value.idTurn}&date=${date}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === "caja por por abrir") {
        stateBeforeOpenBox.value = false;
      } else if (data.msg === "caja abierta") {
        dataOfOpenBox.value.openDate = data.data[0].fecha_apertura;
        dataOfOpenBox.value.amountOpen = parseFloat(data.data[0].monto_inicial);
        stateInputsHiddenForCloseBox.value = false;
        dataForm.value.idBox = data.data[0].id;
        await squareBox();

        dataForm.value.totalClosingAmountCurrent += parseFloat(
          data.data[0].monto_inicial
        );
        dataForm.value.totalClosingAmount += parseFloat(
          data.data[0].monto_inicial
        );

        dataForm.value.amountInTheBox = parseFloat(
          dataForm.value.amountInTheBox
        );
      } else if (data.msg === "caja cerrada") {
        dataForm.value.chequeAmount = parseFloat(data.data[0].monto_en_cheque);
        dataForm.value.targetAmount = parseFloat(data.data[0].monto_en_tarjeta);
        dataForm.value.cashAmount = parseFloat(data.data[0].monto_en_efectivo);
        dataForm.value.transferAmount = parseFloat(
          data.data[0].monto_en_transferencia
        );
        dataForm.value.expenceAmount = parseFloat(data.data[0].monto_de_egreso);
        dataForm.value.totalClosingAmount = parseFloat(
          data.data[0].monto_de_cierre_total
        );
        dataForm.value.amountInTheBox = parseFloat(data.data[0].monto_en_caja);
        dataForm.value.discounts = parseFloat(data.data[0].descuentos);
        dataForm.value.totalPagosMensajero = parseFloat(
          data.data[0].pago_mensajero
        );
        dataForm.value.totalPagosCuentasPorCobrar = parseFloat(
          data.data[0].pagos_cuentas_por_cobrar
        );
        dataOfOpenBox.value.openDate = data.data[0].fecha_de_cierre;

        dataForm.value.cashAmountCurrent =
          parseFloat(data.data[0].monto_en_efectivo) +
          parseFloat(data.data[0].pago_mensajero);
        stateInputsHiddenForCloseBox.value = true;
      } else {
        errorActions(
          "Lo sentimos hemos tenido unos inconvenientes al momento de cofigurar la caja, intentalo mas tarde"
        );
      }
    };

    //Funcion para validar si la caja se encuentra abierta
    const openBox = async () => {
      await uploadData(
        "ABRIENDO CAJA",
        "Espera un momento mientras aperturamos la caja"
      );
      if (
        dataForm.value.startingAmount === null ||
        dataForm.value.startingAmount === undefined ||
        dataForm.value.startingAmount < 0
      ) {
        errorActions(
          "Ingresa un monto de apertura válido, <strong>por lo menos debe de tener un valor de 0</strong>"
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          `caja`,
          {
            idUser: dataForm.value.idUser,
            idStore: dataForm.value.idStore,
            idTurn: dataForm.value.idTurn,
            startingAmount: dataForm.value.startingAmount,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === true) {
        stateBeforeOpenBox.value = true;
        await validateOpenBox();

        confirmationOfRecord(
          "La caja a sido aperturada con éxito , la apertura de la caja "
        );

        const storage = localStorage;
        storage.setItem("boxState", true);
        storage.setItem("boxState", true);
      } else if (data.msg === "exist") {
        errorActions(
          "Lo sentimos no pudimos abrir caja en el turno actual debido a que <strong>El usuario ya tiene su caja abierta en este momento</strong>"
        );
      } else {
        errorActions("Lo sentimos no pudimos abrir caja en el turno actual");
      }
      loading.value = false;
      spinner.value = false;
    };
    //Funcion para validar si la caja se encuentra cerrada
    const closeBox = async () => {
      await uploadData(
        "CERRANDO CAJA",
        "Espera un momento mientras cerramos la caja"
      );
      spinner.value = true;
      const response = await axios
        .put(
          `caja/` + dataForm.value.idBox,
          {
            creditAmount: dataForm.value.creditAmount,
            chequeAmount: dataForm.value.chequeAmount,
            targetAmount: dataForm.value.targetAmount,
            cashAmount: dataForm.value.cashAmount,
            transferAmount: dataForm.value.transferAmount,
            expenceAmount: dataForm.value.expenceAmount,
            totalClosingAmount: dataForm.value.totalClosingAmount,
            amountInTheBox: dataForm.value.amountInTheBox,
            bill10: dataForm.value.bill10,
            bill20: dataForm.value.bill20,
            bill50: dataForm.value.bill50,
            bill100: dataForm.value.bill100,
            bill200: dataForm.value.bill200,
            bill500: dataForm.value.bill500,
            bill1000: dataForm.value.bill1000,
            bill2000: dataForm.value.bill2000,
            coin50: dataForm.value.coin50,
            coin1: dataForm.value.coin1,
            coin2: dataForm.value.coin2,
            coin5: dataForm.value.coin5,
            coin10: dataForm.value.coin10,
            coin20: dataForm.value.coin20,
            coin25: dataForm.value.coin25,
            comentary: dataForm.value.comentary,
            remainingAmount: dataForm.value.remainingAmount,
            excessAmount: dataForm.value.excessAmount,
            discounts: dataForm.value.discounts,
            moneyInTransit: dataForm.value.moneyInTransit,
            deadline: getCurrentDateTime(),
            moneyThatStays: dataForm.value.moneyThatStays,
            moneyThatIsDeposited: dataForm.value.moneyThatIsDeposited,
            pago_mensajero: dataForm.value.pago_mensajero,
            pagos_cuentas_por_cobrar: dataForm.value.totalPagosCuentasPorCobrar,
            dinero_en_transito_otros:
              dataForm.value.moneyInTransitOthersPayments,
            state: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === true) {
        stateBeforeOpenBox.value = true;
        await validateOpenBox();
        if (formatPrint.value === "format_ticket") {
          printTicket(dataForm.value.idBox);
        } else if (formatPrint.value === "format_a4") {
          printOnA4(dataForm.value.idBox);
        } else if (formatPrint.value === "format_m_carta") {
          printOnMediaLetter(dataForm.value.idBox);
        } else if (formatPrint.value === "format_8x11") {
          printOnLetter(dataForm.value.idBox);
        }

        localStorage.setItem("boxState", false);
        localStorage.setItem("boxState", false);

        //enviamos el envio de correo con el cuadre de caja
      } else if (data.msg === "exist") {
        errorActions(
          "Lo sentimos no pudimos cerrar la caja en el turno actual debido a que <strong>El usuario ya tiene su caja cerrada en este momento</strong>"
        );
      } else {
        errorActions("Lo sentimos no pudimos cerrar caja en el turno actual");
      }
      loading.value = false;
      spinner.value = false;
    };

    const closingBox = async () => {
      await calculateBox();
      Swal.fire({
        title: `¿Estas seguro que deseas cerrar la caja?`,
        html: `Si cierras la caja en este momento <strong>ya no podras hacer mas ventas</strong>,
        <div class="pb-3"><strong>_________________________</strong></div>
        <div><strong>SOBRANTE: ${money()}${format(
          dataForm.value.excessAmount
        )}</strong></div>
        <div class="text-red-500"><strong>FALTANTE: ${money()}${format(
          dataForm.value.remainingAmount
        )}</strong></div>
        <div><strong>_________________________</strong></div>
        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, Cerrar caja!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        observeElement(".swal2-container");
        if (result.isConfirmed) {
          uploadData(
            "Cerrando caja",
            "Espera un momento mientras cerramos la caja."
          );
          await closeBox();
          await Swal.fire({
            icon: "success",
            title: "Caja cerrada",
            html: `Tu caja fue cerrada con un monto de  <strong>${dataForm.value.totalClosingAmount}</strong>`,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });

          observeElement(".swal2-container");
        }
      });
    };

    //Funcion para consultar montos para el cuadre de caja
    const squareBox = async () => {
      const date = dataForm.value.openingDate.slice(0, 10);
      const response = await axios
        .get(
          `caja/square-box/current/?idstore=${dataForm.value.idStore}&iduser=${dataForm.value.idUser}&idturn=${dataForm.value.idTurn}&date=${date}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.length <= 0) {
        errorActions("Error al consultar datos para el cuadre de caja");
      } else {
        //Segun tipos de venta
        dataForm.value.cashAmount = parseFloat(data.ventas.total_efectivo);
        dataForm.value.cashAmountCurrent =
          parseFloat(data.desembolsos.total_desembolso) > 0
            ? parseFloat(data.ventas.total_efectivo) +
              parseFloat(dataOfOpenBox.value.amountOpen) -
              parseFloat(data.desembolsos.total_desembolso)
            : parseFloat(data.ventas.total_efectivo) -
              parseFloat(data.desembolsos.total_desembolso);

        dataForm.value.chequeAmount = parseFloat(data.ventas.total_cheque);
        dataForm.value.targetAmount = parseFloat(data.ventas.total_tarjeta);
        dataForm.value.transferAmount = parseFloat(
          data.ventas.total_transferencia
        );

        dataForm.value.totalClosingAmount =
          parseFloat(data.ventas.total_de_ventas) -
          parseFloat(data.desembolsos.total_desembolso);

        dataForm.value.totalClosingAmountCurrent =
          parseFloat(data.ventas.total_de_ventas) -
          parseFloat(data.desembolsos.total_desembolso);

        //Montos por notas de credito
        dataForm.value.cashAmount += parseFloat(data.monto_nc.efectivo);
        dataForm.value.cashAmountCurrent += parseFloat(data.monto_nc.efectivo);
        //Sumamos el monto de notas de credito
        dataForm.value.totalClosingAmount +=
          parseFloat(data.monto_nc.efectivo) +
          parseFloat(data.monto_nc.tarjeta) +
          parseFloat(data.monto_nc.cheque) +
          parseFloat(data.monto_nc.transferencia);
        dataForm.value.totalClosingAmountCurrent +=
          parseFloat(data.monto_nc.efectivo) +
          parseFloat(data.monto_nc.tarjeta) +
          parseFloat(data.monto_nc.cheque) +
          parseFloat(data.monto_nc.transferencia);

        //Monto por tipo de pago
        dataForm.value.transferAmount += parseFloat(data.monto_nc.transferencia);
        dataForm.value.chequeAmount += parseFloat(data.monto_nc.cheque);
        dataForm.value.targetAmount += parseFloat(data.monto_nc.tarjeta);

        //Desembolsos
        dataForm.value.expenceAmount = parseFloat(data.desembolsos.total_desembolso);
        //Monto efectivo en caja
        dataForm.value.amountInTheBox =
          parseFloat(data.ventas.total_efectivo) -
          parseFloat(data.desembolsos.total_desembolso);
        //Mis descuentos
        dataForm.value.discounts = parseFloat(data.mis_descuentos.total);

        const courier = parseFloat(data.pagos_mensajero.otros_efectivo);
        const tarjeta = parseFloat(data.pagos_mensajero.otros_tarjeta);
        const transferencia = parseFloat(
          data.pagos_mensajero.otros_transferencia
        );
        const cheque = parseFloat(data.pagos_mensajero.otros_cheque);
        dataForm.value.pago_mensajero = courier;
        dataForm.value.amountInTheBox += courier;
        dataForm.value.cashAmountCurrent += courier;
        // dataForm.value.totalClosingAmount += courier
        // dataForm.value.totalClosingAmountCurrent += courier
        dataForm.value.transferAmount += transferencia;
        dataForm.value.chequeAmount += cheque;
        dataForm.value.targetAmount += tarjeta;
        dataForm.value.totalClosingAmount +=
          courier + tarjeta + transferencia + cheque;
        dataForm.value.totalClosingAmountCurrent +=
          courier + tarjeta + transferencia + cheque;
        dataForm.value.totalPagosMensajero =
          courier + tarjeta + transferencia + cheque;

        //CUENTAS POR COBRAR
        const CCash = parseFloat(data.corte.efectivo);
        const CTarjeta = parseFloat(data.corte.tarjeta);
        const CTransferencia = parseFloat(data.corte.transferencia);
        const CCheque = parseFloat(data.corte.cheque);

        dataForm.value.totalPagosCuentasPorCobrar =
          CCash + CTarjeta + CTransferencia + CCheque;

        dataForm.value.amountInTheBox += CCash;
        dataForm.value.cashAmountCurrent += CCash;
        dataForm.value.transferAmount += CTransferencia;
        dataForm.value.chequeAmount += CCheque;
        dataForm.value.targetAmount += CTarjeta;
        dataForm.value.totalClosingAmount +=
          CCash + CTarjeta + CTransferencia + CCheque;
        dataForm.value.totalClosingAmountCurrent +=
          CCash + CTarjeta + CTransferencia + CCheque;
      }
    };
    //funciona para obtener el formato de impresion
    const handleFormatPrinting = (data) => {
      formatPrint.value = data;
    };

    //Funcion para consultar montos para el cuadre de caja
    const getMoneyDeliveryMan = async () => {
      const date = dataForm.value.openingDate.slice(0, 10);
      const response = await axios
        .get(
          `repartidor/${dataForm.value.idUser}/?store=${dataForm.value.idStore}&turn=${dataForm.value.idTurn}&date=${date}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data) {
        // dinero en transito pagos diferentes a efectivo
        const amountFilterPaymentsOther = data.filter(
          (item) => item.payment_method !== "EFECTIVO"
        );

        const totalSumPaymentsOther = amountFilterPaymentsOther.reduce(
          (acc, item) => acc + parseFloat(item.total),
          0
        );
        dataForm.value.moneyInTransitOthersPayments = parseFloat(
          totalSumPaymentsOther
        );

        const totalSum = data.reduce(
          (acc, item) => acc + parseFloat(item.total),
          0
        );

        const amountFilter = data.filter(
          (item) => item.payment_method === "EFECTIVO"
        );

        if (amountFilter.length > 0) {
          dataForm.value.moneyInTransit = parseFloat(data[0].total);
        } else {
          dataForm.value.moneyInTransit = parseFloat(totalSum);
        }

        deudasRepartidor.value = data;
      } else {
        deudasRepartidor.value = [];
      }
    };

    //Funcion para traer datos segun id enviado
    const getBox = async () => {
      const response = await axios
        .get(`caja/${props.id}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.idStore = data.idsucursal;
      dataForm.value.idUser = data.idusuario;
      dataForm.value.idTurn = data.idturno;
      dataForm.value.openingDate = data.fecha_apertura;
    };
    onBeforeMount(async () => {
      if (boxConfigurationAccordingToCountry.country === "PERU") {
        hiddenInputsByContry.value = true;
      } else {
        hiddenInputsByContry.value = false;
      }

      await getBox();
      await getMoneyDeliveryMan();
      await validateOpenBox();
      await transitAmount();

      const ACCESS = [
        "MOSTRAR SOBRANTE EN CAJA",
        "MOSTRAR TOTAL CONTABILIZADO EN CAJA",
      ];

      const validate = await validateAccessSpecific(
        ACCESS,
        await idLocalUser()
      );

      if (validate.includes("MOSTRAR SOBRANTE EN CAJA")) {
        canYouLeftoverInCard.value = true;
      }
      if (validate.includes("MOSTRAR TOTAL CONTABILIZADO EN CAJA")) {
        canYouTotalInCard.value = true;
      }
      loading.value = false;
    });
    onMounted(async () => {
      observeElement("#contentFormBox");

      //Configurar el valor de monedas y billetes segun pais
      valueOfCoinsAndBills.value.coin10 = !hiddenInputsByContry.value
        ? 10
        : 0.1;
      valueOfCoinsAndBills.value.coin20 = !hiddenInputsByContry.value
        ? 20
        : 0.2;
      valueOfCoinsAndBills.value.coin25 = !hiddenInputsByContry.value
        ? 25
        : 0.25;
      valueOfCoinsAndBills.value.coin50 = !hiddenInputsByContry.value
        ? 50
        : 0.5;
    });
    return {
      money,
      format,
      dataForm,
      spinner,
      stateBeforeOpenBox,
      hiddenInputsByContry,
      calculateBox,
      postedAmount,
      loading,
      openBox,
      dataOfOpenBox,
      formatDateTime,
      closingBox,
      stateInputsHiddenForCloseBox,
      calculateOthersInputs,
      transitAmount,
      isActive,
      handleFormatPrinting,
      canYouTotalInCard,
      canYouLeftoverInCard,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
