<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar configuración de divisa
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Descripción de moneda
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.description"
              placeholder="Descripción de moneda..."
              @keydown.enter.prevent
              maxlength="30"
            />
          </label>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Prefijo de moneda
            </span>
            <input
              type="text"
              maxlength="6"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.prefix"
              placeholder="Prefijo de moneda..."
              @keydown.enter.prevent
            />
          </label>

          <label
            class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
          >
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Estado actual
            </span>
            <div
              class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
            >
              <input
                type="checkbox"
                class="sr-only peer"
                v-model="dataForm.state"
              />
              <div
                class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
              ></div>
              <span
                class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                {{ dataForm.state === true ? "En uso" : "Inactivo" }}
              </span>
            </div>
          </label>

          <div class="flex mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";

//componente de loading
import LoadingForms from "@/components/Loadings/LoadingFormsOneColumn.vue";
export default {
  name: "UpdateCurrencys",
  components: {
    LoadingForms,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idStore: 0,
      description: "",
      prefix: "",
      state: false,
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("divisas/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.description = data.descripcion;
      dataForm.value.prefix = data.prefijo;
      dataForm.value.state = data.estado===1?true:false;
      dataForm.value.idStore = data.idsucursal;

      isLoading.value = true;
    });

    const update = async () => {
      if (dataForm.value.description <= 2) {
        errorActions(
          "Ingresa una descripción de la  <strong>moneda (divisa)</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.prefix <= 1) {
        errorActions(
          "Ingresa un prefijo de la  <strong>moneda (divisa)</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      spinner.value = true;

      const response = await axios
        .put(
          "divisas/" + props.id,
          {
            idStore: dataForm.value.idStore,
            description: dataForm.value.description,
            prefix: dataForm.value.prefix,
            state: dataForm.value.state?1:0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(dataForm.value.description);
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500"); 
        spinner.value = false;
      } else if (data.status === 404) {
        errorActions(
          `Los datos de la divisa <trong>${dataForm.value.description} </strong> que estas intentando actualizar no existe en nuestra base de datos`
        );
        spinner.value = false;
      } else if (data.msg === 'exist') {
        errorActions(
          `No pedemos actualizar la divisa debido a que existe una  <strong>divisa activa en estos momentos</strong>, primero inactiva la divisa activa`
        );
        spinner.value = false;
      }  else {
        errorActions(
          `Lo sentimos no pudimos actualizar la divisa <trong>${dataForm.value.description} </strong>`
        );
        spinner.value = false;
      }

      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
    };
  },
};
</script>
