<template>
  <div class="px-3 py-3 bg-blue-50 rounded-md border border-blue-100">
    <h3 class="font-semibold text-sm text-blue-500 mb-3">
      Elige tu formato de impresion del comprobante
    </h3>
    <div class="flex gap-4 flex-wrap justify-between" ref="buttonContainer">
      <button
        v-for="(button, index) in buttons"
        :key="index"
        :class="{
          'bg-blue-500 text-white': button.active,
          'bg-blue-100 text-blue-500': !button.active,
        }"
        class="text-xs rounded-md px-3 py-2 uppercase"
        @click="setActiveButton(index)"
      >
        {{ button.label }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "TypeOfPrinting",
  emits: ["send-data"],
  setup(_, { emit }) {
    const buttons = ref([
      { label: "Formato Ticket", active: true },
      { label: "Formato A4", active: false },
      { label: "formato M. carta", active: false },
      { label: "Formato 8 1/2 x 11", active: false },
    ]);
    //Variable para los formatos de impresion
    const formatOptions = [
      "format_ticket",
      "format_a4",
      "format_m_carta",
      "format_8x11",
    ];

    const formatPrinting = ref("format_ticket");

    const setActiveButton = (index) => {
      buttons.value.forEach((button, i) => {
        button.active = i === index;
      });
      //Seleccionamos el formato de impresion
      formatPrinting.value = formatOptions[index];
      emit("send-data", formatPrinting.value);
    };
    emit("send-data", formatPrinting.value);

    return {
      buttons,
      setActiveButton,
    };
  },
};
</script>
