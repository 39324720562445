<template>
    <div
      class="py-2 px-3 text-sm text-gray-600 border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-100 animate-pulse outline-none uppercase"
      v-show="loading"
    >Cargando comprobantes</div>
    <select
      class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none"
      v-model="vModelSelectOptions"
      @change="sendDataSelect"
      v-show="!loading"
    >
      <option value="null" disabled>Seleccionar comprobante</option>
      <option :value="resgiter.id" v-for="(resgiter, i) in registers" :key="i">
        {{ resgiter.descripcion }}
      </option>
    </select>
  </template>
  <script>
  import { onMounted, ref } from "vue";
  import axios from "@/api";
  import { errorActions } from "@/alerts";
  export default {
    name: "SelectVouchers",
    props: {
      selectOption: {
        type: Number,
        required: false,
      },
    },
  emits: ['send-data'],//Si da problemas eliminarlo
    setup(props, { emit }) {
      const registers = ref([]);
      const loading = ref(true);
      const vModelSelectOptions = ref(1);
  
      //funcion para enviar datos desde este componente hacia el otro componente
      const sendDataSelect = () => {
        emit("send-data", vModelSelectOptions.value);
      };
      onMounted(async () => {
        try {
          const response = await axios
            .get("comprobantes/?page=1&per_page=30")
            .catch((error) => errorActions(error));
          const data = await response.data.data;
          // FILTRAMOS LOS COMPROBANTES QUE SON DE USO REAL PARA VENTAS Y COMPRAS
          registers.value  = data.filter(
          (voucher) =>
            voucher.descripcion.toUpperCase() !== "ORDENES DE COMPRA" &&
            voucher.descripcion.toUpperCase() !== "ORDEN DE COMPRAS" &&
            voucher.descripcion.toUpperCase() !== "ORDEN DE COMPRA" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE DEBITO" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE DÉBITO" &&
            voucher.descripcion.toUpperCase() !== "GUÍA DE REMISIÓN" &&
            voucher.descripcion.toUpperCase() !== "GUIA DE REMISION" &&
            voucher.descripcion.toUpperCase() !==
              "GUÍA DE REMISION REMITENTE" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CRÉDITO INTERNO" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CREDITO INTERNO" &&
            voucher.descripcion.toUpperCase() !== "NOTAS DE CREDITO" &&
            voucher.descripcion.toUpperCase() !== "NOTAS DE CRÉDITO INTERNO" &&
            voucher.descripcion.toUpperCase() !== "NOTAS DE CREDITO INTERNO" &&
            voucher.descripcion.toUpperCase() !== "NOTAS DE CRÉDITO" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CRÉDITO" &&
            voucher.descripcion.toUpperCase() !== "NOTA DE CREDITO"
        );

          if (registers.value.length === 0) {
            return errorActions(
              "Lo sentimos no hemos encontrado tipo de comprobantes"
            );
          }
          if (props.selectOption != "" && props.selectOption !== undefined && props.selectOption !==0) {
            vModelSelectOptions.value = props.selectOption || 1;
          } else {
            vModelSelectOptions.value = null;
          }
          loading.value = false;
        } catch (error) {
          errorActions(error);
        }
      });
      return {
        registers,
        loading,
        vModelSelectOptions,
        sendDataSelect,
      };
    },
  };
  </script>
  