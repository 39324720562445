<template>
  <div>
    <h2 class="font-bold text-gray-700">Listado de cuentas por pagar</h2>
    <MainAccountsPayable />
  </div>
</template>

<script>
import MainAccountsPayable from "@/components/Shopping/AccountsPayable/MainAccountsPayable.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "@/hisAcces";
export default {
  name: "AccountsPayable",
  components: {
    MainAccountsPayable,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
