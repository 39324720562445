<template>
  <div class="flex justify-end items-center">
    <h3 class="text-sm text-gray-500">Página {{ currentPage }} de {{ total }}</h3>
    <div class="flex justify-end py-4 px-6">
      <button
        class="bg-white rounded-lg px-2 py-1 text-gray-600 font-semibold text-sm active:scale-95 hover:bg-blue-500 flex items-center mr-3 hover:text-white"
        @click="prev"
        
        :disabled="currentPage===1"
      >
        <svg class="w-6 h-6">
          <use href="@/assets/svg/icon.svg#prev" />
        </svg>
      </button>
      <div
        class="bg-sky-100 rounded-lg px-3 flex items-center py-1 text-gray-600 font-semibold text-sm"
      >
        {{ currentPage }}
      </div>
      <button
        class="bg-white rounded-lg px-2 py-1 text-gray-600 font-semibold text-sm active:scale-95 hover:bg-blue-500 ml-3 flex items-center hover:text-white disabled"
        @click="next"
        :disabled="currentPage===total"
      >
        <svg class="w-6 h-6">
          <use href="@/assets/svg/icon.svg#next" />
        </svg>
      </button>
    </div>
  </div>
</template>


<script>
export default {
  /** Importante: todos estos valores se pasan por la props
   * currentPage | es la página actual 
   * total | es el total de paginas que existe para navegar
   * next | es la funcion para pasar a la siguiente página
   * prev | esta la funcion para retroceder
   */
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        next: {
            type: Function,
            required: true
        },
        prev: {
            type: Function,
            required: true
        },
    }
}
</script>