<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar datos del cliente
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Nombres <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.names"
                placeholder="Nombres..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de documentos
              </span>
              <TypeOfDocuments
                v-if="isLoading"
                @send-data="getSelectTypeOfDocuments"
                :selectOption="dataForm.idTypeOfDocument"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de documentos <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberDocument"
                placeholder="N° de documentos..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Dirección
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.address"
                placeholder="Dirección..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Teléfono / Celular
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.phone"
                placeholder="Teléfono / Celular..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Descuento límite asignado
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.discountLimit"
                placeholder="Descuento límite asignado..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Límite de crédito
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.creditLimit"
                placeholder="Límite de crédito..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Días de pago
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600  w-full border-2 focus:border-blue-600"
                v-model="dataForm.paydays"
                placeholder="Días de pago..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de cliente
              </span>
              <select
                class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
                v-model="dataForm.customerType"
              >
                <option value="RECURRENTE">RECURRENTE</option>
                <option value="CASUAL">CASUAL</option>
                <option value="GENERICO">GENERICO</option>
                <option value="PARTICULAR">PARTICULAR</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Vender con:
              </span>
              <select
                class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
                v-model="dataForm.sell_with"
              >
                <option value="1">PRECIO PRINCIPAL</option>
                <option value="2">PRECIO 2</option>
                <option value="3">PRECIO 3</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fijar comprobante
              </span>
              <SelectVouchers @send-data="getSelectStore" :selectOption="dataForm.fixed_voucher" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Correo electrónico
              </span>
              <input
                type="email"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.email"
                placeholder="Correo electrónico..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
          </div>

          <div class="flex mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../api";

//componente de loading
import TypeOfDocuments from "@/components/PublicComponents/TypeOfDocuments.vue";
import LoadingForms from "@/components/Loadings/LoadingFormsLarge.vue";
import SelectVouchers from "@/components/PublicComponents/SelectVouchers.vue";
export default {
  name: "UpdateCustomers",
  components: {
    LoadingForms,
    TypeOfDocuments,
    SelectVouchers
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idTypeOfDocument: 0,
      names: "",
      address: "",
      numberDocument: "",
      phone: "",
      discountLimit: 0,
      creditLimit: 0,
      sell_with: 1,
      fixed_voucher: 0,
      paydays: 0,
      customerType: "CASUAL",
      email: "",
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("clientes/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.idTypeOfDocument = data.idtipo_documento;
      dataForm.value.names = data.nombres;
      dataForm.value.address = data.direccion;
      dataForm.value.numberDocument = data.numero_documento;
      dataForm.value.phone = data.telefono;
      dataForm.value.discountLimit = data.descuento_limite_asignado;
      dataForm.value.creditLimit = data.limite_de_credito;
      dataForm.value.customerType = data.tipo_de_cliente;
      dataForm.value.email = data.correo;
      dataForm.value.sell_with = data.sell_with;
      dataForm.value.fixed_voucher = data.fixed_voucher;
      dataForm.value.paydays = data.paydays;

      isLoading.value = true;
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectTypeOfDocuments = (data) => {
      dataForm.value.idTypeOfDocument = data;
    };
    const getSelectStore = (data) => dataForm.value.fixed_voucher = data;

    const update = async () => {
      if (dataForm.value.names.trim().length <= 2) {
        errorActions("Ingresa el nombre de un <strong>cliente</strong>");
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.numberDocument <= 100000) {
        errorActions(
          "Ingresa un número de <strong>documento</strong> válido para el cliente"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      spinner.value = true;

      const response = await axios
        .put(
          "clientes/" + props.id,
          {
            idTypeOfDocument: dataForm.value.idTypeOfDocument,
            names: dataForm.value.names,
            address: dataForm.value.address,
            numberDocument: dataForm.value.numberDocument,
            phone: dataForm.value.phone,
            discountLimit: dataForm.value.discountLimit,
            creditLimit: dataForm.value.creditLimit,
            customerType: dataForm.value.customerType,
            email: dataForm.value.email,
            sell_with: dataForm.value.sell_with,
            fixed_voucher: dataForm.value.fixed_voucher,
            paydays: dataForm.value.paydays,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(dataForm.value.names);
        observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
        await props.activeModal(false);
        await props.getdata();
        
      
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El cliente <trong>${dataForm.value.names} </strong> que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos del cliente <trong>${dataForm.value.names} </strong>`
        );
      }

      spinner.value = false;
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
      getSelectTypeOfDocuments,
      getSelectStore
    };
  },
};
</script>
