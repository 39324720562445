<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="mainForm">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Registro de servicios
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <label class="block">
            <span
              class="block text-sm font-medium text-gray-800 dark:text-white"
            >
              Descripción del servicio <span class="text-red-300">*</span>
            </span>
            <div class="flex items-center">
              <select
                class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none rounded-tr-none rounded-br-none border-r-0"
                v-model="dataForm.number"
              >
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
              </select>
              <input
                type="text"
                class="py-2 px-3 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 rounded-tl-none rounded-bl-none border-l-0 focus:border-blue-500"
                v-model="dataForm.description"
                placeholder="Descripción del servicio..."
                @keydown.enter.prevent
                maxlength="160"
              />
            </div>
          </label>
          <button
            class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
            @click="insert"
            :disabled="spinner === true"
          >
            <div class="flex items-center" v-if="spinner">
              <svg class="w-4 h-4 mr-3 animate-spin">
                <use href="@/assets/svg/icon.svg#spinnerForBlue" />
              </svg>
              Registrando Información
            </div>
            <span v-else>Registrar</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import axios from "@/api";
import { observeElement } from "@/observer";

export default {
  name: "RegisterNewServicesDGII",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    const dataForm = ref({
      description: "",
      number: "01",
    });

    const insert = async () => {
      if (dataForm.value.description.length <= 0) {
        errorActions("Ingresa una descripción del  <strong>servicio</strong>");
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "servicios",
          {
            description:
              dataForm.value.number + "-" + dataForm.value.description,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El servicio  <strong>${dataForm.value.description}</strong>`
        );
        dataForm.value.number = "01";
        dataForm.value.description = "";
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el servicio <strong>${dataForm.value.description}</strong>`
        );
      }

      spinner.value = false;
    };
    onMounted(() => {
      observeElement("#mainForm");
    });

    return {
      isActive,
      dataForm,
      insert,
      spinner,
    };
  },
};
</script>
