import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { format } from "@/publicjs/money"; //? Formateo y obtencion de moneda configurada de la empresa
import { closeAlert, errorActions, uploadData } from "@/alerts"; //? Alertas de error, etc
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { imageInBase64 } from "@/publicjs/logoCompany"; //? Imagen de base64 de la empresa
import { formatDateTime } from "@/publicjs/formatDate"; //? Retorna la fecha y hora actual
import titlesConfig from "/public/apis/config.json"; //? Títulos de configuracion segun pais
import { paperDimension } from "@/publicjs/paperDimension"; //? Convierte moneda a letras
import axios from "@/api";
import styles from "@/publicjs/styles";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//* Función para imprimir el documento
/**
 * @param {*} idOrder | ID de la ultima orden realizada
 */
export const printVoucherTicket = async (idOrder) => {
  await uploadData(
    "GENERANDO COMPROBANTE",
    "Espera un momento mientras generamos el comprobantes.<br><strong>Esto tomará solo unos segundos</strong>"
  );
  //* Poblado de datos de la empresa
  const dataStore = JSON.parse(localStorage.getItem("access"));
  const company = await getCompany(dataStore.user.store);

  //* Cargar la imagen o el logo de la empresa
  let base64Image = await imageInBase64(company.companyLogo);

  //? Obtencion de datos de la orden
  const registers = await order(idOrder);
  if (registers.length <= 0) {
    errorActions(
      "Lo sentimos hemos tenido unos incoveniente al generar este comprobante"
    );
  }

  try {
    //Desestructurando el ancho y al alto del papel
    const regenerateHeight = parseFloat(300 + registers.detalle.length * 12);
    const { paperWidth, paperHeight } = paperDimension(78, regenerateHeight);

    let docDefinition = null;
    docDefinition = {
      // a string or { width: number, height: number }
      pageSize: { width: paperWidth, height: paperHeight },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "portrait", //cambia a [landscape] para imprimir de forma horizontal

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 200, 10, 10],
      //!encabezado del documento con datos de la empresa, sucursal y datos del comprobante
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            width: "*",
            stack: [
              { image: base64Image, fit: [40, 40] }, // logo
            ],
            alignment: "center",
            margin: [10, 10, 0, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: company.companyName,
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
              },
              {
                text: "Dirección: " + company.companyAddress,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "Tel: " + company.companycellphone,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: company.companySlogan,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text:
                  "Sucursal: ".toUpperCase() +
                  registers.sucursal.razon_social.toUpperCase(),
                fontSize: styles.fontSize.ticket.xl,
                alignment: "center",
                bold: true,
                color: styles.colors.black,
                margin: [0, 10, 0, 0],
              },
              {
                text: "DIR: " + registers.sucursal.direccion.toUpperCase(),
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
              {
                text: "TEL: " + registers.sucursal.celular,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
              },
            ],
            alignment: "center",
            margin: [10, 10, 10, 0],
          },
          {
            width: "*",
            stack: [
              {
                text: titlesConfig.titleTypeDocument + " " + company.companyRUC,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: registers.tipo_comprobante.descripcion,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
              {
                text: `${registers.orden.serie} - ${registers.orden.numero_comprobante}`,
                fontSize: styles.fontSize.ticket.lg,
                alignment: "center",
                color: styles.colors.black,
                bold: true,
                margin: [0, 5, 0, 0],
              },
            ],
            alignment: "center",
            margin: [10, 0, 10, 0],
          },
          {
            canvas: [
              { type: "rect", x: 10, y: 90, w: pageSize.width - 48, h: 2 },
            ],
            margin: [10, 10], // Margen entre el rectángulo y el contenido de la página
          },
        ];
      },

      content: [
        //? Encabezado del comprobante con el nombre del proveedor
        {
          table: {
            headerRows: 0,
            widths: [66, "*"], //ancho de la columnas
            body: [
              [
                {
                  text: "Destinatario:",
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [0, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text:
                    registers.cliente.nombres.length >= 24
                      ? registers.cliente.nombres.slice(0, 24).toUpperCase() +
                        "***"
                      : registers.cliente.nombres.toUpperCase(),
                  border: [false, false, false, false],
                  fontSize: styles.fontSize.ticket.lg,
                  margin: [-24, 0, 0, 0],
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Dirección:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.cliente.direccion,
                  border: [false, false, false, false],
                  margin: [-30, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "N° Documento:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.cliente.numero_documento,
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Fec. de emision:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: formatDateTime(registers.orden.fecha_emision),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "P. partida:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.orden.punto_de_partida,
                  border: [false, false, false, false],
                  margin: [-25, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "P. llegada:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.orden.punto_de_llegada,
                  border: [false, false, false, false],
                  margin: [-25, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "Fec. traslado:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.orden.fecha_de_traslado,
                  border: [false, false, false, false],
                  margin: [-20, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
              [
                {
                  text: "H. traslado:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: registers.orden.hora_de_traslado,
                  border: [false, false, false, false],
                  margin: [-20, 0, 0, 0],
                  fontSize: styles.fontSize.ticket.lg,
                  valign: "middle",
                  color: styles.colors.black,
                },
              ],
            ],
          },
        },
        //? Detalles del comprobante
        {
          table: {
            headerRows: 1,
            widths: ["*", 60], //ancho de la columnas
            body: [
              [
                {
                  text: "Cantidad",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                },
                {
                  text: "Peso total",
                  border: [false, true, false, true],
                  margin: [0, 2, 0, 2],
                  valign: "middle",
                  fontSize: styles.fontSize.ticket.lg,
                  color: styles.colors.black,
                  bold: true,
                  alignment: "right",
                },
              ],
              ...registers.detalle.flatMap((register) => {
                return [
                  [
                    {
                      text: register.cantidad,
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                    },
                    {
                      text: format(register.peso),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      bold: true,
                      border: [false, false, false, false],
                      valign: "middle",
                      margin: [0, 0],
                      alignment: "right",
                    },
                  ],
                  [
                    {
                      text:
                        register.nombre_producto.length >= 30
                          ? register.nombre_producto.slice(0, 30).toUpperCase()
                          : register.nombre_producto.toUpperCase(),
                      fontSize: styles.fontSize.ticket.md,
                      color: styles.colors.black,
                      border: [false, false, false, false],
                      colSpan: 2,
                      margin: [0, 0, 0, 3],
                      valign: "middle",
                    },
                  ],
                ];
              }), // Datos dinámicos
            ],
          },
          //! Margen para separar el encabezado con el detalle del comprobante
          margin: [0, 10, 0, 0],

          //* Color de los bordes de la celda
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.black
                : styles.colors.black;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.black
                : styles.colors.black;
            },
          },
        },
        {
          text: "**************************************************",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        {
          text: "Datos del transportista",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        {
          text: "Denominación, Apellidos y nombres",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
        },
        {
          text: registers.orden.transportista,
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
        },
        {
          text: "N° documento",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
        },
        {
          text: registers.orden.ruc_transportista,
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
        },
        {
          text: "Celular / Telefonos",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
        },
        {
          text: registers.orden.telefono_transportista,
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
        },
        {
          text: "Datos de la unidad de transporte y conductor",
          fontSize: styles.fontSize.ticket.lg,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 10],
        },
        {
          text: "Marca / placa",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 0, 0, 0],
        },
        {
          text: registers.orden.placa_de_la_movilidad,
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
        },
        {
          text: "Licencia de conducir",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
        },
        {
          text: registers.orden.licencia_de_conducir,
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
        },
        {
          text: "Motivo",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 5, 0, 0],
        },
        {
          text: registers.orden.motivo,
          fontSize: styles.fontSize.ticket.sm,
          color: styles.colors.black,
          margin: [0, 0, 0, 0],
        },

        //?Informacion del emisor
        {
          text:
            "Emitido por: ".toUpperCase() +
            registers.usuario.nombres.slice(0, 5).toUpperCase() +
            "***",
          fontSize: styles.fontSize.ticket.md,
          color: styles.colors.black,
          bold: true,
          margin: [0, 10, 0, 5],
          alignment: "center",
        },
      ],
    };
    pdfMake.createPdf(docDefinition).print();
    await closeAlert();
  } catch (error) {
    errorActions("No se pudo generar el comprobante");
  }
};

const order = async (idOrder) => {
  try {
    const response = await axios.get(
      `guia-de-remision-ventas/${idOrder}`
    );

    if (response.msg === false) {
      return [];
    }
    return await response.data;
  } catch (error) {
    errorActions(error);
  }
};
