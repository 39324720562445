<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="containerCombiendPayment">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw] md:max-w-[60vw] max-w-[90vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Pagos combinado
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en efectivo <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.cashAmount"
                placeholder="Monto en efectivo..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en Tarjeta <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.targetAmount"
                placeholder="Monto en tarjeta..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en transferencia <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.transferAmount"
                placeholder="Monto en transferencia..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en cheque <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.chequeAmount"
                placeholder="Monto en cheque..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
          </div>

          <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="apply"
            >
              Aplicar pago
            </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import { money, format } from "@/publicjs/money";
export default {
  name: "CombinedPayments",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },
  emits: ["send-data"],
  setup(props, { emit }) {
    function isActive() {
      props.activeModal(true);
    }

    const dataForm = ref({
      cashAmount: 0,
      targetAmount: 0,
      transferAmount: 0,
      chequeAmount: 0,
    });

    /**********************************************************************************************************
     * Funcion para aplicar los multiples formas de pago
     **********************************************************************************************************/
    const apply = () => {
      const total =
        parseFloat(dataForm.value.cashAmount) +
        parseFloat(dataForm.value.targetAmount) +
        parseFloat(dataForm.value.transferAmount) +
        parseFloat(dataForm.value.chequeAmount);
      if (total < props.amount || total  > props.amount) {
        errorActions(
          "Lo sentimos no podemos aplicar el pago combinado <strong>debido a que el monto que estas ingresando es menor al monto de compra del cliente.</strong>"
        );
        return;
      }
      emit("send-data", dataForm.value);
      isActive();
    };

    onMounted(async () => {
      observeElement("#containerCombiendPayment");
    });

    return {
      isActive,
      dataForm,
      money,
      format,
      apply,
    };
  },
};
</script>
