<template>
<div
      class="py-2 px-3 text-sm text-gray-600 border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-100 animate-pulse outline-none uppercase"
      v-show="loading"
    >Cargando tipo de usuarios</div>
  <select
    class="py-2 px-3 text-sm text-gray-600 uppercase border-2 focus:border-blue-500 block w-full rounded-lg mb-2 bg-gray-50 outline-none"
    v-model="vModelSelectOptions"
    @change="sendDataSelect"
    v-show="!loading"
  >
    <option value="null" disabled>Selecciona un tipo de usuario</option>
    <option :value="resgiter.id" v-for="(resgiter, i) in registers" :key="i">
      {{ resgiter.descripcion }}
    </option>
  </select>
</template>
<script>
import { onMounted, ref } from "vue";
import axios from "../../api";
import { errorActions } from "@/alerts";
export default {
  name: "GetTypeOfUsers",
  props: {
    selectOption: {
      type: Number,
      required: false,
    },
  },
  emits: ['send-data'],//Si da problemas eliminarlo
  setup(props, { emit }) {
    const registers = ref([]);
    const loading = ref(true);
    const vModelSelectOptions = ref(1);

    //funcion para enviar datos desde este componente hacia el otro componente
    const sendDataSelect = () => {
      emit("send-data", vModelSelectOptions.value);
    };
    onMounted(async () => {
      try {
        const response = await axios
          .get("tipo_de_usuarios/?page=1&per_page=30")
          .catch((error) => errorActions(error));
        registers.value = await response.data.data;
        if (registers.value.length === 0) {
          return errorActions(
            "Lo sentimos no hemos encontrado los tipo de usuarios"
          );
        }
        if (props.selectOption != "" && props.selectOption !== undefined) {
          vModelSelectOptions.value = props.selectOption || 1;
        } else {
          vModelSelectOptions.value = null;
        }
        loading.value = false;
      } catch (error) {
        errorActions(error);
      }
    });
    return {
      registers,
      loading,
      vModelSelectOptions,
      sendDataSelect,
    };
  },
};
</script>
