const stylesExcel = {
  color: {
    dark: "374151", // "#374151"
    darkAlt: "152E3B", // "#152E3B"
    blue: "3b82f6", // "#3b82f6"
    warning: "EE7700", // "#EE7700"
    green: "1D9B4F", // "#1D9B4F"
    greenTransparent: "D6FFE6", // "#D6FFE6"
    warningTransparent: "FFF9F4", // "#FFF9F4"
    blueTransparent: "EFFBFF", // "#EFFBFF"
    white: "FFFFFF", // "#FFFFFF"
    borderColor: "AEBCC3", // "#AEBCC3"
    borderColorDark: "1A3847", // "#1A3847"
  },
  fontSize: {
    xl: 24,
    lg: 16,
    md: 14,
    sm: 12,
    xsm: 10,
  },
};

export default stylesExcel;
