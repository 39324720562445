<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar configuración de impresión
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid md:grid-cols-2 gap-4">
            <label
            class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
          >
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Formato A4
            </span>
            <div
              class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
            >
              <input
                type="checkbox"
                class="sr-only peer"
                v-model="dataForm.a4Format"
              />
              <div
                class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
              ></div>
              <span
                class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                {{ dataForm.a4Format === true ? "En uso" : "Inactivo" }}
              </span>
            </div>
          </label>
          <label
            class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
          >
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Formato 8 1/2 x 11
            </span>
            <div
              class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
            >
              <input
                type="checkbox"
                class="sr-only peer"
                v-model="dataForm.format8x11"
              />
              <div
                class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
              ></div>
              <span
                class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                {{ dataForm.format8x11 === true ? "En uso" : "Inactivo" }}
              </span>
            </div>
          </label>
          <label
            class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
          >
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Formato media carta
            </span>
            <div
              class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
            >
              <input
                type="checkbox"
                class="sr-only peer"
                v-model="dataForm.halfLetterFormat"
              />
              <div
                class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
              ></div>
              <span
                class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                {{ dataForm.halfLetterFormat === true ? "En uso" : "Inactivo" }}
              </span>
            </div>
          </label>
          <label
            class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
          >
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Formato ticket
            </span>
            <div
              class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
            >
              <input
                type="checkbox"
                class="sr-only peer"
                v-model="dataForm.ticketFormat"
              />
              <div
                class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
              ></div>
              <span
                class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                {{ dataForm.ticketFormat === true ? "En uso" : "Inactivo" }}
              </span>
            </div>
          </label>
          </div>

          <div class="flex mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";

//componente de loading
import LoadingForms from "@/components/Loadings/LoadingFormsTwoColumn.vue";
export default {
  name: "UpdateConfigureVouchersPrint",
  components: {
    LoadingForms,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      a4Format: false,
      format8x11: false,
      halfLetterFormat: false,
      ticketFormat: false,
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("configuracion-de-impresion/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;

      dataForm.value.a4Format = data.formato_a4 === 1 ? true : false;
      dataForm.value.format8x11 = data.formato_8x11 === 1 ? true : false;
      dataForm.value.halfLetterFormat =
        data.formato_media_carta === 1 ? true : false;
      dataForm.value.ticketFormat = data.formato_ticket === 1 ? true : false;

      isLoading.value = true;
    });

    const update = async () => {
      spinner.value = true;

      const response = await axios
        .put(
          "configuracion-de-impresion/" + props.id,
          {
            a4Format: dataForm.value.a4Format ? 1 : 0,
            format8x11: dataForm.value.format8x11 ? 1 : 0,
            halfLetterFormat: dataForm.value.halfLetterFormat ? 1 : 0,
            ticketFormat: dataForm.value.ticketFormat ? 1 : 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate("Datos del comprobante");
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
        spinner.value = false;
      } else if (data.status === 404) {
        errorActions(
          `Los datos de la <trong>configuración del comprobante</strong> que estas intentando actualizar no existe en nuestra base de datos`
        );
        spinner.value = false;
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar <trong>la configuración del comprobante</strong>`
        );
        spinner.value = false;
      }

      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
    };
  },
};
</script>
