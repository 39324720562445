<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <label class="block">
        <span
          class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
        >
          PERIODO
        </span>
        <div class="flex items-center">
          <select
            class="bg-white py-2 border-l-2 border-b-2 border-t-2 text-sm px-3 text-gray-600 cursor-pointer rounded-tl-lg rounded-bl-lg outline-none font-semibold"
            v-model="currentMonth"
            @change="changeCurrentDate"
          >
            <option value="1">ENERO</option>
            <option value="2">FEBRERO</option>
            <option value="3">MARZO</option>
            <option value="4">ABRIL</option>
            <option value="5">MAYO</option>
            <option value="6">JUNIO</option>
            <option value="7">JULIO</option>
            <option value="8">AGOSTO</option>
            <option value="9">SETIEMBRE</option>
            <option value="10">OCTUBRE</option>
            <option value="11">NOVIEMBRE</option>
            <option value="12">DICIEMBRE</option>
          </select>
          <select
            class="bg-white py-2 border-r-2 border-b-2 border-t-2 text-sm px-3 text-gray-600 cursor-pointer rounded-tr-lg rounded-br-lg outline-none font-semibold"
            v-model="currentYear"
            @change="changeCurrentDate"
          >
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
            <option value="2031">2031</option>
            <option value="2032">2032</option>
            <option value="2033">2033</option>
            <option value="2034">2034</option>
            <option value="2035">2035</option>
            <option value="2036">2036</option>
            <option value="2037">2037</option>
            <option value="2038">2038</option>
            <option value="2039">2039</option>
            <option value="2040">2040</option>
            <option value="2041">2041</option>
            <option value="2042">2042</option>
            <option value="2043">2043</option>
          </select>
        </div>
      </label>
      <!-- acciones de impresion -->
      <button
        class="px-3 py-2 text-green-500 flex bg-green-50 items-center hover:bg-green-100 rounded-md whitespace-nowrap"
        @click="exportToExcel(registers, currentDate.dateStart,currentDate.dateEnd,currentYear )"
      >
        <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
        Exportar a excel
      </button>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <div class="w-full">
              <div class="relative">
                <div class="flex items-center relative text-gray-600">
                  <div
                    class="absolute top-0 left-3 w-6 h-full flex items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-barcode"
                      class="w-4 h-4"
                    />
                  </div>
                  <input
                    type="search"
                    v-model="searchProduct"
                    class="border pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50"
                    placeholder="Buscar producto por nombre o codigo de barra"
                    @keyup="searchProducts"
                    @keydown.enter.prevent
                    id="searchInputProduct"
                  />
                </div>
                <div
                  v-if="showResultsProducts"
                  class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
                >
                  <div v-if="loadingSearchProducts">
                    <div class="px-3">
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="!resultLengthProducts"
                      class="px-3 py-1 bg-gray-50 text-gray-400"
                      @click="showResultsProducts = false"
                    >
                      <strong>{{ searchProduct }}</strong> no existe
                    </div>
                    <div
                      v-else
                      v-for="(result, i) in resultSearchProducts"
                      :key="i"
                      class="odd:bg-white even:bg-slate-50"
                    >
                      <div
                        class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-500 uppercase"
                        @click="
                          changeIDproducto(result.id, result.nombre_producto)
                        "
                        @blur="showResultsProducts = false"
                      >
                        {{ result.nombre_producto }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
        id="contenDataTable"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="14" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap bg-blue-50 text-blue-500"
                >
                  {{ formatDateTime(register.fecha_ingreso) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{ register.nombre_producto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{ register.concepto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  <span
                    class="rounded-md px-3 py-1"
                    :class="{
                      'bg-green-50 text-green-600':
                        register.tipo_de_operacion === 1,
                      'bg-red-50 text-red-600':
                        register.tipo_de_operacion === 2,
                      'bg-orange-50 text-orange-600':
                        register.tipo_de_operacion === 3,
                      'bg-red-500 text-white':
                        register.tipo_de_operacion === 4,
                    }"
                  >
                    {{
                      register.tipo_de_operacion === 1
                        ? "INGRESO"
                        : register.tipo_de_operacion === 2
                        ? "SALIDA"
                        : register.tipo_de_operacion === 3?"DEVOLUCION":"ANULACION"
                    }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap uppercase"
                >
                  {{ register.cantidad_de_entrada }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.valor_unitario_entrada) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.valor_total_entrada) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-md"
                    :class="{
                      'bg-red-50 text-red-500':
                        parseFloat(register.cantidad_salida) > 0,
                    }"
                  >
                    {{ register.cantidad_salida }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.valor_unitario_salida) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.valor_total_salida) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  {{ register.cantidad_de_saldo }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  {{ money() }}{{ format(register.valor_unitario_saldo) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.valor_total_saldo) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-3 whitespace-nowrap"
                >
                  <span
                    class="rounded-md px-3 py-1"
                    :class="{
                      'bg-green-50 text-green-600': register.estado === 1,
                      'bg-red-50 text-red-600': register.estado === 0,
                    }"
                  >
                    {{ register.estado === 1 ? "VIGENTE" : register.estado === 0 ?"VENDIDO":'' }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement, observer } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";
import { getFirstAndLastDayOfMount } from "@/publicjs/getFirstAndLastDayOfMount";
import { formatDateTime } from "@/publicjs/formatDate";

import { exportToExcel } from "./Reports/PDFExportAllKardex";

export default {
  name: "MainCustomers",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Fec. Movimiento",
        },
        {
          title: "Producto",
        },
        {
          title: "Concepto",
        },
        {
          title: "tipo operación",
        },
        {
          title: "Cant. entrada",
        },
        {
          title: "valor u. entrada",
        },
        {
          title: "valor total  entrada",
        },
        {
          title: "cant. salida",
        },
        {
          title: "valor u. salida",
        },
        {
          title: "valor total salida",
        },
        {
          title: "cant. saldo",
        },
        {
          title: "valor u. saldo",
        },
        {
          title: "valor total saldo",
        },
        {
          title: "Estado",
        },
      ],
    ];
    const currentMonth = ref(1);
    const currentYear = ref(2023);
    const idStore = ref(0);
    const idProduct = ref(0);
    //Variable donde se guarda el primer y el ultimo dia del mes para las consultas de kardex
    const currentDate = ref({
      dateStart: null,
      dateEnd: null,
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `kardex/?page=${currentPage.value}&per_page=${limitRegister.value}&date_start=${currentDate.value.dateStart}&date_end=${currentDate.value.dateEnd}&idstore=${idStore.value}&idproduct=${idProduct.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    const changeIDproducto = async (id, productName) => {
      idProduct.value = id;
      showResultsProducts.value = false;
      searchProduct.value = productName;
      currentPage.value = 1;
      await getData();
    };

    //Variables para la busqueda de producto
    const showResultsProducts = ref(false);
    const loadingSearchProducts = ref(true);
    const resultSearchProducts = ref([]);
    const resultLengthProducts = ref(false);
    const searchProduct = ref("");
    // Filtro de productos por estado igual a 1
    const filteredRegisters = ref([]);
    // esta funcion sirve para buscar usuario por nombre
    const searchProducts = async () => {
      if (searchProduct.value.length <= 0) {
        errorActions(
          "Por favor ingresa <strong>el nombre de producto o su codigo de barra</strong>"
        );
        return;
      }
      try {
        loadingSearchProducts.value = true;
        showResultsProducts.value = true;
        resultLengthProducts.value = false;
        const response = await axios
          .get(
            `productos/?page=1&per_page=50&search=${searchProduct.value}&idstore=${idStore.value}`
          )
          .catch((error) => errorActions(error));
        filteredRegisters.value = await response.data.data;
        resultSearchProducts.value = filteredRegisters.value.filter(
          (register) => register.estado === 1
        );
        if (resultSearchProducts.value.length > 0) {
          resultLengthProducts.value = true;
        }
        loadingSearchProducts.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar el producto debido a que hemos tenido unos inconvenientes"
        );

        searchProduct.value = "";
      }
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      const yearAnMonth = await new Date();
      currentMonth.value = yearAnMonth.getMonth() + 1;
      currentYear.value = yearAnMonth.getFullYear();
      idStore.value = await idLocalStore();

      await changeCurrentDate();

      observeElement("#contenDataTable");
    });

    //funcion para cambiar las fecha para las consultas
    const changeCurrentDate = async () => {
      //Poblar las varaibles de fecha de inicio y fecha de termino para las consultas
      const dateStartAndDateEnd = await getFirstAndLastDayOfMount(
        currentMonth.value,
        currentYear.value
      );
      currentDate.value.dateStart = dateStartAndDateEnd.firstDayFormatted;
      currentDate.value.dateEnd = dateStartAndDateEnd.lastDayFormatted;
      await getData();
    };

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      currentMonth,
      currentYear,
      showResultsProducts,
      loadingSearchProducts,
      resultSearchProducts,
      resultLengthProducts,
      searchProduct,
      searchProducts,
      changeIDproducto,
      formatDateTime,
      changeCurrentDate,
      exportToExcel,
      currentDate
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
