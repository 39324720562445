<template>
  <div class="bg-blue-50 pt-3 flex flex-wrap gap-4" id="containerInfoCustomer">
    <div
      class="bg-blue-100 px-1 py-1 rounded-md border border-blue-200 text-xs text-gray-600"
    >
      <strong>Desc. máximo: </strong> {{ maximumDiscount }} %
    </div>
    <div
      class="bg-blue-100 px-1 py-1 rounded-md border border-blue-200 text-xs text-gray-600"
    >
      <strong>Limite crediticio: </strong> {{ money() }} {{ format(creditLimit) }}
    </div>
    <div
      class="bg-blue-100 px-1 py-1 rounded-md border border-blue-200 text-xs text-gray-600"
    >
      <strong>Tipo: </strong> {{ typeCustomer }}
    </div>
    <div
      class="bg-blue-100 px-1 py-1 rounded-md border border-blue-200 text-xs text-gray-600"
    >
      <strong>N° documento: </strong> {{ numberDocument }}
    </div>
  </div>
</template>

<script>
import { format, money } from '@/publicjs/money';
import { onMounted } from 'vue';
import { observeElement } from '@/observer';
export default {
  name: "InfoCustomer",
  props: {
    maximumDiscount: {
      type: Number,
      required: true,
      default: 0
    },
    creditLimit: {
      type: Number,
      required: true,
      default: 0
    },
    typeCustomer: {
      type: String,
      required: true,
      default: 'CASUAL'
    },
    numberDocument: {
      type: String,
      required: true,
      default: '00000000'
    },
  },
  setup() {
    onMounted(() => {
      observeElement("#containerInfoCustomer");
    });
    return {
        format,
        money
    }
  }
};
</script>
