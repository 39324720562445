<template>
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center py-8 justify-center min-h-screen">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div
          class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
        >
          <div class="flex justify-between items-center mb-6">
            <h2 class="text-xl font-medium text-gray-800 dark:text-white">
              Actualizar datos de la comisión
            </h2>
            <button
              @click="isActive"
              class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
            >
              <svg class="w-5 h-5">
                <use href="../../../assets/svg/icon.svg#cancel" />
              </svg>
            </button>
          </div>
          <LoadingForms v-if="!isLoading" />
          <form class="w-full space-y-6" @submit.prevent v-else>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto a superar 
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                min="1"
                v-model="dataForm.amount"
                placeholder="Monto a superar ..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
              Monto de comisión (%)
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                min="1"
                v-model="dataForm.commission"
                placeholder="Monto de comisión (%)..."
                @keydown.enter.prevent
              />
            </label>
  
            <div class="flex mt-[3.5rem!important]">
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                @click="update"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Modificando Información
                </div>
                <span v-else>Modificar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
  import { confirmationOfUpdate, errorActions } from "@/alerts";
  import { observer } from "@/observer";
  import axios from "../../../api";
  
  //componente de loading
  import LoadingForms from "@/components/Loadings/LoadingFormsLarge.vue";
  export default {
    name: "UpdateCommissions",
    components: {
      LoadingForms,
    },
    props: {
      activeModal: {
        type: Function,
        required: true,
      },
      getdata: {
        type: Function,
        required: true,
      },
      id: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      async function isActive() {
        await props.activeModal(true);
      }
  
      const spinner = ref(false);
  
      const dataForm = ref({
        amount: 0,
        commission: 1,
      });
  
      // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
      const isLoading = ref(false);
      onMounted(async () => {
        const response = await axios
          .get("comisiones/" + props.id)
          .catch((error) => errorActions(error));
        const data = await response.data;
        dataForm.value.amount = data.monto_a_superar;
        dataForm.value.commission = data.monto_de_comision;
  
        isLoading.value = true;
      });
  
      const update = async () => {
        if (dataForm.value.amount <= 0) {
          errorActions("Ingresa el monto del <strong>a superar en las ventas</strong>");
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          return;
        }
        if (dataForm.value.commission <= 0) {
          errorActions("Ingresa el monto del <strong>comisión</strong>");
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          return;
        }
  
        spinner.value = true;
  
        const response = await axios
          .put(
            "comisiones/" + props.id,
            {
              amount: dataForm.value.amount,
              commission: dataForm.value.commission,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch(function (error) {
            errorActions(error);
          });
        const data = await response.data;
        if (data.msg === true) {
          confirmationOfUpdate(`La comisión por el monto de  ${dataForm.value.amount}`);
          await props.activeModal(false);
          await props.getdata();
        } else if (data.msg === "Request failed with status code 500") {
          errorActions("Request failed with status code 500");
        } else if (data.status === 404) {
          errorActions(
            `Los <trong>comisión</strong> que estas intentando actualizar no existe en nuestra base de datos`
          );
        } else {
          errorActions(
            `Lo sentimos no pudimos actualizar los datos de la <trong>comisión </strong>`
          );
        }
  
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      };
  
      return {
        isActive,
        dataForm,
        update,
        isLoading,
        spinner,
      };
    },
  };
  </script>
  