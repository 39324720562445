<template>
    <div>
      <h2 class="text-1xl font-bold text-gray-700">
        Configuración de {{ Iva.iva }}
      </h2>
      <MainIva />
    </div>
  </template>
  
  <script>
  import MainIva from "@/components/ConfigurationModules/Iva/MainIva.vue";
import { useRoute } from 'vue-router';
import { onBeforeMount, onMounted } from 'vue';
import { findSubmoduleIdByDescription } from '@/hisAcces';
import Iva from "/public/apis/config.json"
  export default {
    name: "IVA",
    components: {
        MainIva,
    },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
    return {
        Iva
    }
  },
  };
  </script>
  