<template>
    <div>
      <h2 class="text-1xl font-bold text-gray-700">
        Movimiento de productos (Salidas, Ajustes y Devoluciones)
      </h2>
      <MainProductMovement />
    </div>
  </template>
  
  <script>
  import MainProductMovement from "@/components/Reports/Products/ProductMovement/MainProductMovement.vue";
  import { useRoute } from "vue-router";
  import { onBeforeMount, onMounted } from "vue";
  import { findSubmoduleIdByDescription } from "@/hisAcces";
  export default {
    name: "ProductMovement",
    components: {
        MainProductMovement,
    },
    setup() {
      const route = useRoute();
      onBeforeMount(() => {
        if (!findSubmoduleIdByDescription(route.path)) {
          window.location.href = "/login";
        }
      });
  
      onMounted(() => {
        document.title =
          route.meta.title + process.env.VUE_APP_TITLE ||
          process.env.VUE_APP_TITLE;
      });
    },
  };
  </script>
  