import { errorActions } from "@/alerts";
import axios from "@/api";

//* Devuelve los datos de la empresa y la sucursal
export const getCompany = async (idStore) => {
  //! PETICION PARA OBTENER LOS DATOS DE LA EMPRESA
  const response = await axios
    .get("empresa", {
      method: {
        "Content-Type": "application/json",
      },
    })
    .catch((error) => errorActions(error));
  const data = await response.data[0];
  const companyName = data.nombre_comercial;
  const companyAddress = data.direccion;
  const companycellphone = data.telefonos;
  const companySlogan = data.slogan;
  const companyRUC = data.ruc;
  const companyLogo = data.logo;

  //? PETICION PARA OBTENER LOS DATOS DE LA SUCURSAL
  const responseStore = await axios
    .get("sucursales/" + idStore, {
      method: {
        "Content-Type": "application/json",
      },
    })
    .catch((error) => errorActions(error));
  const dataStore = await responseStore.data;
  const storeName = dataStore.razon_social;
  const storeAddress = dataStore.direccion;
  const storeCellphone = dataStore.celular;

  return {
    companyName,
    companyAddress,
    companycellphone,
    companySlogan,
    companyRUC,
    companyLogo,
    storeName,
    storeAddress,
    storeCellphone,
  };
};
