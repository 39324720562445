import { errorActions } from "@/alerts";
import axios from "../../api";
import Swal from "sweetalert2";
import { observeElement } from "@/observer";
/**
 *
 * @param {*} id | ID del acceso
 * @param {*} msg | Mensaje para la eliminarcion
 */
const deleteData = async (id, msg) => {
  const response = await axios
    .delete("accesos-especificos/" + id, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      errorActions(error);
    });
  const data = response.data;
  if (data.msg === "Request failed with status code 500") {
    errorActions(data.msg);
  } else if (data.msg === false) {
    errorActions(`Lo sentimos no pudimos el acceso <strong>${msg}</strong>`);
  }
};
/**
 *
 * @param {*} id | ID del registro al cual queremos eliminar o anular
 * @param {*} msg | Descripcion del registro que queremos eliminar puede ser el nombre, etc.
 * @param {*} action | Funcion para volver a cargar los datos, pero esto funciona siempre en cuando n tenga parametros
 */
export const deleteSpecificAccess = async (id, msg, action) => {
  Swal.fire({
    title: "¿Estas seguro que deseas eliminar el acceso?",
    html: `Si eliminas el acceso del usuario <strong>no podras recuperarlo más adelante.</strong>`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#315df5",
    cancelButtonColor: "#dc2626",
    confirmButtonText: "Si, Eliminar!",
    cancelButtonText: "Cancelar",
    width: "300px",
  }).then(async (result) => {
    observeElement(".swal2-container");
    if (result.isConfirmed) {
      await deleteData(id, msg);
      Swal.fire({
        icon: "success",
        title: "Acceso eliminado",
        html: `El acceso <strong>${msg}</strong>, del usuario a sido eliminado de la base de datos`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
      });

      observeElement(".swal2-container");

      await action();
    }
  });
};
