<template>
  <div
    role="status"
    class="max-w-md p-4 space-y-4 rounded animate-pulse md:p-6"
    v-if="loadingMenus"
  >
    <div class="flex items-center justify-between pt-4">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <div class="flex items-center justify-between pt-4">
      <div>
        <div
          class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"
        ></div>
        <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
    <span class="sr-only">Loading...</span>
  </div>

  <div v-else>
    <li v-for="(module, index) in data.value" :key="index">
      <span
        class="bg-transparent flex items-center justify-between hover:bg-sky-900 hover:rounded-tr-xl hover:rounded-br-xl active:rounded-br-none toggle transition-colors py-3 px-4 cursor-pointer small-center"
      >
        <span class="flex items-center">
          <font-awesome-icon
            :icon="module.icon"
            class="text-blue-100 w-5 h-5"
          />
          <span class="ml-2 small-hidden"> {{ module.descripcion }} </span>
        </span>
        <font-awesome-icon
          icon="fa-solid fa-angle-up"
          class="active-rotate text-gray-400 small-hidden"
        />
      </span>
      <ul class="bg-sky-900 h-0 overflow-hidden sub-menu rounded-br-lg">
        <router-link
          :to="submodule.url"
          class="bg-transparent hover:bg-sky-900 hover:text-white hover:rounded-tr-xl hover:rounded-br-xl hover:font-semibold transition-colors block py-3 px-4"
          v-for="(submodule, indexsubmodule) in module.submodulos"
          :key="indexsubmodule"
        >
          <font-awesome-icon
            icon="fa-solid fa-circle-notch"
            class="small-hidden w-4 h-4"
          />
          <span class="ml-2 small-hidden">
            {{ submodule.descripcion }}
          </span>
        </router-link>
      </ul>
    </li>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import axios from "../../api";
import { errorActions } from "@/alerts";
export default {
  name: "GetSystemModules",
  setup() {
    const data = reactive({});
    const loadingMenus = ref(true);
    //! esta es la ruta anterior (modulos_del_sistema_all)
    async function getModules() {
      if (localStorage.getItem("access")) {
        const dataUser = JSON.parse(localStorage.getItem('access'))
        const res = await axios
          .get("mis_modulos/"+dataUser.user.key)
          .catch((error) => {
            errorActions(error);
          });
        const modules = await res.data;
        if (
          modules.status === "Invalid token" ||
          modules.status === "Expired token" ||
          modules.status === "Token not found"
        ) {
          errorActions(res.data.status);
          return;
        }
        data.value = modules;
        localStorage.setItem('modules', JSON.stringify(modules))
        loadingMenus.value = false;
      }
    }
    getModules();

    return {
      data,
      loadingMenus,
    };
  },
};
</script>

<style scoped>
.active {
  height: 100%;
}
.active-rotate {
  rotate: 90deg;
  transition: rotate 0.15s;
}
.sub-menu.active {
  box-shadow: 0 -2px 0 rgba(63, 146, 255, 0.288) inset;
}
.toggle.active .active-rotate {
  rotate: 180deg;
  color: #ffffff;
}
.toggle.active {
  font-weight: 700;
  background: rgb(63, 130, 255);
  border-radius: 0 0.75rem 0 0;
  color: #ffffff;
}
.toggle.active svg {
  color: #ffffff;
}
.scroll-nav::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}
.scroll-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 6px var(--purple); */
  background-color: rgba(0, 0, 0, 0.068);
}
.scroll-nav::-webkit-scrollbar-track {
  background-color: transparent;
}
.scroll-nav::-webkit-scrollbar-thumb:hover,
.scroll-nav::-webkit-scrollbar-thumb:active {
  background: #93c5fd;
}
</style>
