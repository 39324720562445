<template>
  <div
    class="bg-white w-full md:w-auto text-gray-500 shadow-md shadow-gray-100 rounded-md px-4 py-4 border border-gray-100 flex justify-between"
  >
    <div>
      <span class="text-sm block mb-3 uppercase">Total de clientes</span>
      <span class="text-xl block font-extrabold text-gray-600">{{
        total
      }}</span>
      <span class="text-sm block mt-3 text-gray-400"
        >Activos desde los inicios</span
      >
    </div>
    <div class="w-10 text-gray-500 flex justify-end">
      <div
        class="w-9 h-9 flex items-center justify-center bg-gray-100 rounded-full"
      >
        <font-awesome-icon icon="fa-solid fa-people-group" class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalCustomer",
  props: {
    total: {
      type: String,
      required: true,
    },
  },
};
</script>
