<template>
    <div class="flex py-2 h-[3.5rem] px-4 bg-sky-950">
        <div class="w-10 h-10 rounded-full bg-sky-800 animate-pulse"></div>
        <div
          class="col-span-11 text-white font-bold ml-2 mt-0 mb-0 small-hidden uppercase flex-wrap md:flex-nowrap"
        >
          <div class="bg-sky-800 animate-pulse px-6 py-2 rounded-lg mb-1"></div>
          <div class="bg-sky-800 animate-pulse px-6 py-2 rounded-lg"></div>
        </div>
      </div>
</template>
<script>
export default {
    name:'LoadingNavCompany',
}
</script>