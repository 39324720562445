/**
 * @param stateInsurance Columna de aseguradora
 * @param stateCheck Columna de check para delivery
 * @param statePSerie Columna de serie de productos
 * @returns Array de columnas
 */
export default (stateInsurance, stateCheck, statePSerie) => {
  if (stateInsurance === true) {
    return [
      [
        { title: "" },
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "U.M",
        },
        {
          title: "Precios",
        },
        {
          title: "P. U",
        },
        {
          title: "Importe",
        },
        // {
        //   title: "Descuento %",
        // },
        {
          title: "Desc. Seguro %",
        },
        // {
        //   title: "usar desc.",
        // },
        {
          title: "serie p.",
        },
        {
          title: "acciones",
        },
      ],
    ];
  } else {
    if (stateCheck && statePSerie) {
      return [
        [
          { title: "" },
          {
            title: "Descripción",
          },
          {
            title: "Cantidad",
          },
          {
            title: "U.M",
          },
          {
            title: "Precios",
          },
          {
            title: "P. U",
          },
          {
            title: "Importe",
          },
          // {
          //   title: "Descuento %",
          // },
          // {
          //   title: "usar desc.",
          // },
          {
            title: "serie p.",
          },
          {
            title: "acciones",
          },
        ],
      ];
    } else if (stateCheck) {
      return [
        [
          { title: "" },
          {
            title: "Descripción",
          },
          {
            title: "Cantidad",
          },
          {
            title: "U.M",
          },
          {
            title: "Precios",
          },
          {
            title: "P. U",
          },
          {
            title: "Importe",
          },
          // {
          //   title: "Descuento %",
          // },
          // {
          //   title: "usar desc.",
          // },
          // {
          //   title: "serie p.",
          // },
          {
            title: "acciones",
          },
        ],
      ];
    } else if (statePSerie) {
      return [
        [
          // { title: "" },
          {
            title: "Descripción",
          },
          {
            title: "Cantidad",
          },
          {
            title: "U.M",
          },
          {
            title: "Precios",
          },
          {
            title: "P. U",
          },
          {
            title: "Importe",
          },
          // {
          //   title: "Descuento %",
          // },
          // {
          //   title: "usar desc.",
          // },
          {
            title: "serie p.",
          },
          {
            title: "acciones",
          },
        ],
      ];
    } else {
      return [
        [
          // { title: "" },
          {
            title: "Descripción",
          },
          {
            title: "Cantidad",
          },
          {
            title: "U.M",
          },
          {
            title: "Precios",
          },
          {
            title: "P. U",
          },
          {
            title: "Importe",
          },
          // {
          //   title: "Descuento %",
          // },
          // {
          //   title: "usar desc.",
          // },
          // {
          //   title: "serie p.",
          // },
          {
            title: "acciones",
          },
        ],
      ];
    }
  }
};
