<template>
  <!-- loading-squeleton -->
  <tbody class="text-gray-600 text-center">
    <tr>
      <td class="py-3" v-for="col, i in columns" :key="i">
        <div
          class="rounded-full border-t-[1px] text-sm px-2 py-4 bg-gray-100 animate-pulse h-5 text-right"
        ></div>
      </td>
    </tr>
    <tr>
      <td class="py-3" v-for="col, i in columns" :key="i">
        <div
          class="rounded-full border-t-[1px] text-sm px-2 py-4 bg-gray-100 animate-pulse h-5 text-right"
        ></div>
      </td>
    </tr>
    <tr>
      <td class="py-3" v-for="col, i in columns" :key="i">
        <div
          class="rounded-full border-t-[1px] text-sm px-2 py-4 bg-gray-100 animate-pulse h-5 text-right"
        ></div>
      </td>
    </tr>
  </tbody>
  <!-- loading-esqueleton -->
</template>

<script>
    // import { ref } from 'vue'
export default {
  name: "LoadingTables",
  props: {
    columns: {
      type: Number,
      required: true
    }
  }
};
</script>
