import { saveAs } from "file-saver";
import { getCurrentDate } from "@/publicjs/currentDate";
import ExcelJS from "exceljs";
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { idLocalStore } from "@/publicjs/localStorage"; //? Recuperar el id de la sucursal
import { closeAlert, errorActions, uploadData } from "@/alerts";
import stylesExcel from "@/publicjs/stylesExcel";
import config from "/public/apis/config.json";
/**
 *
 * @param {*} data Array de datos para poblar toda la informacion
 * @param {*} title titulo personazalido para el reporte
 * @param {*} start fecha de inicio del reporte
 * @param {*} end fecha de termino del reporte
 */
export const exportToSpecialRegime = async (data, title, start, end) => {
  try {
    await uploadData(
      "Generando reporte".toUpperCase(),
      "Espera un momento mientras preparamos tu reporte,<br> <strong>Esto puede tardar unos segundos...</strong>"
    );

    //* Traer los datos de la sucursal
    const dataCompany = await getCompany(await idLocalStore());

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    const styles = {
      title: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.xl,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      subtitle: {
        font: {
          // bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      header: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.sm,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.green },
        },
        alignment: { horizontal: "left", vertical: "middle" },
      },
      total: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.green },
        },
        alignment: { horizontal: "right", vertical: "middle" },
      },
    };

    // Agregar título
    let rowNumber = 1; // Número de fila que deseas ajustar la altura
    let row = worksheet.getRow(rowNumber);

    worksheet.mergeCells("A1:O1");
    const titleReport = worksheet.getCell("A1");
    titleReport.value = title;
    titleReport.style = styles.title;
    row.height = 35;

    rowNumber = 2;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A2:O2");
    const store = worksheet.getCell("A2");
    store.value = "SUCURSAL: " + dataCompany.storeName.toUpperCase();
    store.style = styles.subtitle;
    row.height = 20;

    rowNumber = 3;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A3:O3");
    const dateReport = worksheet.getCell("A3");
    dateReport.value = "FECHA DE REPORTE - " + getCurrentDate();
    dateReport.style = styles.subtitle;
    row.height = 20;

    rowNumber = 4;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A4:O4");
    const rangeReport = worksheet.getCell("A4");
    rangeReport.value = "DESDE " + start + " HASTA " + end;
    rangeReport.style = styles.subtitle;
    row.height = 20;

    // Agregar encabezados
    const headers = [
      "#",
      "RNC / Cédula \n o pasaporte",
      "Tipo de \nidentificación",
      "N° de comprobante \nfiscal",
      "N° de comprobante \nfiscal modificado",
      "Fecha de \ncomprobante",
      "Monto \nfacturado",
      config.iva + "\nFacturado",
      "Subtotal\nFacturado",
      "Efectivo",
      "Cheque / \nTransferencia / \nDepósito",
      "Tarjeta / \nDébito / Crédito",
      "Venta a \ncredito",
      "N° refencia/ \nComprobante",
      "Tipo NFC",
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = styles.header;
      cell.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    rowNumber = 5; // Número de fila que deseas ajustar la altura
    row = worksheet.getRow(rowNumber);
    row.height = 40;

    // Agregar datos
    data.forEach((rowData) => {
      const rows = worksheet.addRow([
        Number(rowData.id_data),
        rowData.numero_documento,
        rowData.numero_de_envio_documento,

        rowData.estado === "MODIFICADO"
          ? rowData.serie_nota_de_credito + rowData.numero_nota_de_credito
          : rowData.serie_comprobante + rowData.numero_comprobante,

        rowData.estado !== "MODIFICADO"
          ? (rowData.serie_nota_de_credito !==null?rowData.serie_nota_de_credito+''+ rowData.numero_nota_de_credito:'')
          : rowData.numero_nota_de_credito === null
          ? null
          : rowData.serie_comprobante + rowData.numero_comprobante,

        rowData.fecha_de_emision_nc !== null
          ? rowData.fecha_de_emision_nc.slice(0, 10).replace(/-/g, "")
          : rowData.fecha_de_emision.slice(0, 10).replace(/-/g, ""),
        Number(rowData.total),
        Number(rowData.igv),
        Number(rowData.subtotal),
        Number(rowData.pago_en_efectivo),
        Number(
          parseFloat(rowData.pago_en_transferencia) +
            parseFloat(rowData.pago_en_cheque)
        ),
        Number(rowData.pago_en_tarjeta),
        Number(rowData.estado === "VENTA A CREDITO" ? rowData.total : 0),
        rowData.numero_referencia_comprobante,
        Number(rowData.numero_de_envio_sunat),
      ]);
      rows.getCell(1).style.font = {
        bold: true,
        size: stylesExcel.fontSize.sm,
      };

      for (let i = 1; i <= rows.cellCount; i++) {
        rows.getCell(i).border = {
          top: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      }
    });

    //* Ajustar ancho de columnas
    worksheet.columns.forEach((column, index) => {
      //Ancho para la columnas
      if (index === 0) {
        column.width = 7;
      } else if (
        index === 1 ||
        index === 2 ||
        index === 3 ||
        index === 4 ||
        index === 5 ||
        index === 6 ||
        index === 7 ||
        index === 8 ||
        index === 9 ||
        index === 10 ||
        index === 11 ||
        index === 12 ||
        index === 13 ||
        index === 14
      ) {
        column.width = 25;
      } else {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      }
    });

    //! Agregar una nueva fila debajo de los totales
    const newTotalRow = worksheet.addRow([]);

    /************************************************************************************************ */
    const totalRow = data.length + 5; //total de filas

    const columnPosition = 7; //Posicion de la columna para sumar datos
    const columnPositionIgv = 8; //Posicion de la columna para sumar datos
    const columnPositionSubtotal = 9; //Posicion de la columna para sumar datos
    /************************************************************************************************ */

    //? Calcular la fórmula de suma como un string para el total, subtotal y el igv
    const sumFormula = `SUM(G6:G${totalRow})`;
    newTotalRow.getCell(columnPosition).value = {
      formula: sumFormula,
      result: 0,
    };
    const sumFormulaIgv = `SUM(H6:H${totalRow})`;
    newTotalRow.getCell(columnPositionIgv).value = {
      formula: sumFormulaIgv,
      result: 0,
    };
    const sumFormulaSubTotal = `SUM(I6:I${totalRow})`;
    newTotalRow.getCell(columnPositionSubtotal).value = {
      formula: sumFormulaSubTotal,
      result: 0,
    };

    //* Dar formato a la moneda total
    newTotalRow.getCell(columnPosition).style = styles.total;
    newTotalRow.getCell(columnPosition).numFmt = "#,##0.00";
    //* Dar formato a la moneda del igv
    newTotalRow.getCell(columnPositionIgv).style = styles.total;
    newTotalRow.getCell(columnPositionIgv).numFmt = "#,##0.00";
    //* Dar formato a la moneda del subTotal
    newTotalRow.getCell(columnPositionSubtotal).style = styles.total;
    newTotalRow.getCell(columnPositionSubtotal).numFmt = "#,##0.00";

    //* Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), getCurrentDate() + ".xlsx");
    await closeAlert(); //Cerramos el modal de aviso
  } catch (error) {
    console.log(error);
    errorActions(
      "Lo sentimos no pudimos generar el reporte, intentalo mas tarde"
    );
  }
};
