<template>
  <div id="contentSearchTypeOfProduct">
    <div>
      <div class="w-full md:w-auto mt-3 md:mt-0">
        <div class="relative">
          <div class="flex items-center relative text-gray-600">
            <div class="absolute top-0 left-3 w-6 h-full flex items-center">
              <font-awesome-icon icon="fa-solid fa-search" class="w-4 h-4" />
            </div>
            <input
              type="search"
              v-model="dataForm.userName"
              class="border-2 pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50 focus:border-blue-500"
              placeholder="Buscar por nombre o numero de documento"
              @keydown.enter="getData"
              @keydown.enter.prevent
              autocomplete="off"
              id="inputCustomer"
            />
          </div>
          <div
            v-if="showUsers"
            class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
          >
            <div v-if="loadingSearch">
              <div class="px-3">
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
                <div
                  class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                ></div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="!dataLength"
                class="px-3 py-1 bg-gray-50 text-gray-400"
                @click="showUsers = false"
              >
                <strong>{{ dataForm.userName }}</strong> no existe en nuestra
                base de datos
              </div>
              <div
                v-else
                v-for="(result, i) in dataResult"
                :key="i"
                class="odd:bg-white even:bg-slate-50"
                @blur="showUsers = false"
              >
                <div
                  class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                  @click="
                    selectUser({
                      id: result.id,
                      userName: result.nombres,
                    })
                  "
                >
                  {{ result.nombres }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorActions } from "@/alerts";
import axios from "../../../api";
import { onMounted, onUnmounted, ref } from "vue";
import { observeElement } from "@/observer";
import { idLocalStore } from "@/publicjs/localStorage";
export default {
  name: "SearchUsers",
  emits: ["send-data"],
  setup(_, { emit }) {
    const dataForm = ref({
      id: 0,
      userName: "",
      idStore: 0,
    });

    //Funcion para cargar ventas temporales
    const showUsers = ref(false);
    const loadingSearch = ref(true);
    const dataResult = ref([]);
    const dataLength = ref(false);
    const getData = async () => {
      try {
        loadingSearch.value = true;
        showUsers.value = true;
        dataLength.value = false;
        const response = await axios
          .get(
            `usuarios/?page=1&per_page=30&search=${dataForm.value.userName}&idstore=${dataForm.value.idStore}`
          )
          .catch((error) => errorActions(error));
        dataResult.value = await response.data.data;
        if (dataResult.value.length > 0) {
          dataLength.value = true;
        }
        loadingSearch.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar usuarios debido a que hemos tenido unos inconvenientes"
        );
      }
    };
    const selectUser = async (data) => {
      dataForm.value.id = data.id;
      dataForm.value.userName = data.userName;
      showUsers.value = false;
      emit("send-data", dataForm.value);
    };
    /*******************************************************************************************
     * Funcion para cambiar de estado de tipo de busqueda con combinacion de teclas ALT+N
     *******************************************************************************************/
     const handleKeyDown = async (event) => {
      if (event.keyCode === 27) {
        showUsers.value = false;
      }
    };
    
    onMounted(async () => {
      window.addEventListener("keydown", handleKeyDown);
      dataForm.value.idStore = await idLocalStore();
      observeElement("#contentSearchTypeOfProduct");
    });
    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });

    return {
      showUsers,
      loadingSearch,
      dataResult,
      dataLength,
      getData,
      dataForm,
      selectUser,
    };
  },
};
</script>
