<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[80vw] lg:min-w-[900px] xl:min-w-[1024px]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar datos de usuario
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingFormsLarge v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Nombres y apellidos
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.fullName"
                placeholder="Nombres y apellidos..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Direccion
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.address"
                placeholder="Dirección..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Teléfono / Celular
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.cellPhone"
                placeholder="Teléfono / Celular..."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de documentos
              </span>
              <TypeOfDocuments
                v-if="isLoading"
                @send-data="getSelecTypeOfDocuments"
                :selectOption="dataForm.idTypeOfDocument"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de documento
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberDocument"
                placeholder="N° de documento.."
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sucursales
              </span>
              <Stores
                v-if="isLoading"
                @send-data="getSelectStore"
                :selectOption="dataForm.idStore"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de usuarios
              </span>
              <TypeOfUsers
                v-if="isLoading"
                @send-data="getSelecTypeOfUsers"
                :selectOption="dataForm.idTypeOfUser"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Correo electrónico
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.email"
                placeholder="Correo electrónico..."
              />
            </label>

            <div class="w-full">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Cambiar Foto de perfil
              </span>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex items-center justify-between w-full px-4">
                  <div class="w-4 h-4 mr-4">
                    <svg class="w-4 h-4 text-gray-400">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-[12px] text-gray-500 dark:text-gray-400">
                    <span class="font-semibold"
                      >Click para seleccionar imagen</span
                    >
                    PNG o JPG
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  accept=".png,.jpg"
                  ref="avatar"
                  @change="selectFile"
                />
              </label>
            </div>

            <label class="flex flex-col h-6 mr-5 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado del usuario
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.state"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                </span>
              </div>
            </label>
            <label class="flex flex-col h-6 mr-5 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ¿Es mensajero?
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  @change="isAMessenger = !isAMessenger"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ isAMessenger === true ? "SI" : "NO" }}
                </span>
              </div>
            </label>
          </div>

          <div class="flex mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../api";
//componentes publicos
import Stores from "@/components/PublicComponents/Stores.vue";
import TypeOfUsers from "@/components/PublicComponents/TypeOfUsers.vue";
import TypeOfDocuments from "@/components/PublicComponents/TypeOfDocuments.vue";

//componente de loading
import LoadingFormsLarge from "@/components/Loadings/LoadingFormsLarge.vue";
export default {
  name: "UpdateUsers",
  components: {
    Stores,
    TypeOfUsers,
    TypeOfDocuments,
    LoadingFormsLarge,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const registers = ref([]);
    const spinner = ref(false);

    const dataForm = ref({
      idStore: null,
      idTypeOfUser: null,
      idTypeOfDocument: null,
      fullName: "",
      address: "",
      email: "",
      numberDocument: "",
      cellPhone: "",
      state: true,
    });

    //logo del usuario
    const avatar = ref(null);
    const isAMessenger = ref(false)

    // Selecciona el archivo
    const selectFile = async () => {
      await avatar.value.files;
    };

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios.get("usuarios/" + props.id).catch(error => errorActions(error));
      registers.value = await response.data;

      dataForm.value.idStore = registers.value.idsucursal;
      dataForm.value.idTypeOfUser = registers.value.idtipo_usuario;
      dataForm.value.idTypeOfDocument = registers.value.idtipo_documento;
      dataForm.value.fullName = registers.value.nombres;
      dataForm.value.address = registers.value.direccion;
      dataForm.value.email = registers.value.correo;
      dataForm.value.numberDocument = registers.value.numero_documento;
      dataForm.value.cellPhone = registers.value.telefonos;
      dataForm.value.state = registers.value.estado === 1 ? true : false;

      isLoading.value = true;
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectStore = (data) => {
      dataForm.value.idStore = data;
    };
    //recepcion de datos desde el componente publico, todos los tipos de usuarios
    const getSelecTypeOfUsers = (data) => {
      dataForm.value.idTypeOfUser = data;
    };
    //recepcion de datos desde el componente publico, todos los tipos de documentos
    const getSelecTypeOfDocuments = (data) => {
      dataForm.value.idTypeOfDocument = data;
    };

    const update = async () => {
      if (dataForm.value.fullName.length <= 3) {
        errorActions("Ingresa un nombre de usuario válido");
        return;
      }
      if (dataForm.value.idTypeOfDocument === null) {
        console.log(dataForm.value.idTypeOfDocument);
        errorActions("Selecciona el tipo de documento del usuario");
        return;
      }
      if (
        dataForm.value.numberDocument.length <= 7 ||
        !Number(dataForm.value.numberDocument)
      ) {
        errorActions("Ingresa un numero de documento válido...");
        return;
      }

      if (dataForm.value.idStore === null) {
        errorActions(
          "Selecciona la sucursal a la cual va a pertenecer el usuario"
        );
        return;
      }
      if (dataForm.value.idTypeOfUser === null) {
        errorActions(
          "Selecciona un tipo de usuario o el cargo que tendra este nuevo usuario"
        );
        return;
      }
      
      if(!isAMessenger.value) {
        if (!validateEmail(dataForm.value.email)) {
        errorActions(
          "Ingresa un correo válido, el correo que ingresas sera con el cual podras acceder al sistema te sugerimos que sea un correo que uses a diario"
        );
        return;
      }
      }
      spinner.value = true;

      //Formdata
      const formData = new FormData();
      formData.append("idStore", dataForm.value.idStore);
      formData.append("idTypeDocument", dataForm.value.idTypeOfDocument);
      formData.append("idTypeUser", dataForm.value.idTypeOfUser);
      formData.append("fullName", dataForm.value.fullName);
      formData.append("numberDocument", dataForm.value.numberDocument);
      formData.append("address", dataForm.value.address);
      formData.append("email", dataForm.value.email);
      formData.append("cellPhone", dataForm.value.cellPhone);
      formData.append("state", dataForm.value.state ? 1 : 0);
      formData.append("avatar", avatar.value.files[0]);

      const response = await axios
        .post("usuarios/" + props.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(dataForm.value.fullName);
        setTimeout(async () => {
          //codigo para evitar el error de [resize observer]
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          await props.activeModal(false);
          await props.getdata();
        }, 200);
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          "El usuario que estas intentando actualizar no existe en nuestra base de datos"
        );
      } else {
        errorActions(
          "Lo sentimos no pudimos actualizar los datos del usuario " +
            dataForm.value.fullName
        );
      }
    };

    //funcion para validar un email ( @, . )
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
      getSelectStore,
      getSelecTypeOfUsers,
      getSelecTypeOfDocuments,
      selectFile,
      avatar,
      isAMessenger
    };
  },
};
</script>
