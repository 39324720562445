import { errorActions } from "@/alerts";
import axios from "../../api";
import Swal from "sweetalert2";
import { observeElement } from "@/observer";
/**
 *
 * @param {*} id | ID de la compra al cual se va a anular
 * @param {*} state | El estado del desembolso, puede ser [ACEPTADO, RECHAZADO]
 */
const deleteData = async (id, state) => {
  const response = await axios
    .put(
      "desembolsos/estado/" + id,
      {
        state,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .catch(function (error) {
      errorActions(error);
    });
  const data = response.data;
  if (data.msg === "Request failed with status code 500") {
    errorActions(data.msg);
  } else if (data.msg === false) {
    errorActions(
      `Lo sentimos no pudimos cambiar el estado del desembolso a <strong>${state}</strong>`
    );
  }
};
/**
 *
 * @param {*} id | ID del registro al cual queremos eliminar, anular o cambiar de estado
 * @param {*} state | Estado del desembolso
 * @param {*} action | Funcion para volver a cargar los datos, pero esto funciona siempre en cuando n tenga parametros
 */
export const changeStateDisbursement = async (id, state, action) => {
  Swal.fire({
    title: `¿Estas seguro que deseas cambiar de estado al desembolso?`,
    html: `Si cambias de estado de desembolso a <strong>${state}</strong>, no podras recuperarlo más adelante!`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#315df5",
    cancelButtonColor: "#dc2626",
    confirmButtonText: "Si, cambiar!",
    cancelButtonText: "Cancelar",
    width: "300px",
  }).then(async (result) => {
    observeElement(".swal2-container");
    if (result.isConfirmed) {
      await deleteData(id, state);
      Swal.fire({
        icon: "success",
        title: "Desembolso cambiado de estado",
        html: `El estado del desembolso a sido cambiado a <strong>${state}</strong>`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
      });

      observeElement(".swal2-container");

      await action();
    }
  });
};
