const units = [
  "",
  "uno",
  "dos",
  "tres",
  "cuatro",
  "cinco",
  "seis",
  "siete",
  "ocho",
  "nueve",
];
const specials = [
  "diez",
  "once",
  "doce",
  "trece",
  "catorce",
  "quince",
  "dieciséis",
  "diecisiete",
  "dieciocho",
  "diecinueve",
];
const tens = [
  "",
  "",
  "veinte",
  "treinta",
  "cuarenta",
  "cincuenta",
  "sesenta",
  "setenta",
  "ochenta",
  "noventa",
];
const hundreds = [
  "",
  "ciento",
  "doscientos",
  "trescientos",
  "cuatrocientos",
  "quinientos",
  "seiscientos",
  "setecientos",
  "ochocientos",
  "novecientos",
];
const thousands = [
  "",
  "mil",
  "millón",
  "mil millones",
  "billón",
  "mil billones",
  "trillón",
  "mil trillones",
  "cuatrillón",
  "mil cuatrillones",
];

const convertIntegerPart = (wholepart) => {
  if (wholepart === 0) {
    return "cero";
  }

  let groupCount = 0;
  const groups = [];

  while (wholepart > 0) {
    const group = wholepart % 1000; // Obtener el último grupo de tres dígitos
    let groupText = "";

    if (group === 1 && groupCount > 0) {
      groupText = "mil";
    } else {
      groupText = convertGroup(group);
      if (groupCount > 0) {
        if (groupText) {
          groupText += ` ${thousands[groupCount]}`;
        }
      }
    }

    if (groupText) {
      groups.unshift(groupText); // Agregar el grupo al inicio del array
    }
    wholepart = Math.floor(wholepart / 1000); // Eliminar el último grupo de tres dígitos
    groupCount++;
  }

  return groups.join(" ");
};

const convertGroup = (group) => {
  let groupText = "";

  const centena = Math.floor(group / 100) % 10;
  const decena = Math.floor(group / 10) % 10;
  const unidad = group % 10;

  if (centena === 1 && decena === 0 && unidad === 0) {
    groupText = "cien";
  } else {
    if (centena > 0) {
      groupText += hundreds[centena] + " ";
    }

    if (decena === 1) {
      groupText += specials[unidad];
    } else {
      if (decena > 1) {
        if (groupText) {
          groupText += " ";
        }
        groupText += tens[decena];
        if (unidad > 0) {
          groupText += ` y ${units[unidad]}`;
        }
      } else {
        if (unidad > 0) {
          if (groupText) {
            groupText += " ";
          }
          groupText += units[unidad];
        }
      }
    }
  }

  return groupText;
};

export const convertCurrencyToLetters = (monto) => {
  const moneyLocal = JSON.parse(localStorage.getItem("access"));

  const amountStr = monto.toFixed(2);
  const [wholepart, partDecimal] = amountStr.split(".");

  const lettersWholePart = convertIntegerPart(parseInt(wholepart, 10));
  const lettersPartDecimal =
    partDecimal === "00" ? "con 00/100" : `con ${partDecimal}/100`;

  const result = `Son: ${lettersWholePart} ${lettersPartDecimal} ${moneyLocal.currency}`;

  return result;
};
