import { saveAs } from "file-saver";
import { getCurrentDate } from "@/publicjs/currentDate";
import ExcelJS from "exceljs";
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { idLocalStore } from "@/publicjs/localStorage"; //? Recuperar el id de la sucursal
import { closeAlert, errorActions, uploadData } from "@/alerts";
import stylesExcel from "@/publicjs/stylesExcel";
/**
 *
 * @param {*} data Array de datos para poblar toda la informacion
 */
export const exportToExcel = async (data, dateStart, dateEnd, period) => {
  try {
    await uploadData(
      "Generando reporte".toUpperCase(),
      "Espera un momento mientras preparamos tu reporte,<br> <strong>Esto puede tardar unos segundos...</strong>"
    );

    //* Traer los datos de la sucursal
    const dataCompany = await getCompany(await idLocalStore());

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("kardex".toUpperCase());

    const styles = {
      title: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.xl,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      subtitle: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.darkAlt },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.white },
        },
        alignment: { horizontal: "center", vertical: "middle" },
      },
      header: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.sm,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.darkAlt },
        },
        alignment: { horizontal: "left", vertical: "middle" },
      },
      total: {
        font: {
          bold: true,
          size: stylesExcel.fontSize.md,
          color: { argb: stylesExcel.color.white },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: stylesExcel.color.green },
        },
        alignment: { horizontal: "right", vertical: "middle" },
      },
    };

    // Agregar título
    let rowNumber = 1; // Número de fila que deseas ajustar la altura
    let row = worksheet.getRow(rowNumber);

    worksheet.mergeCells("A1:N1");
    const titleReport = worksheet.getCell("A1");
    titleReport.value =
      "Reporte de kardex".toUpperCase() + " PERIODO " + period;
    titleReport.style = styles.title;
    row.height = 35;

    rowNumber = 2;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A2:N2");
    const store = worksheet.getCell("A2");
    store.value = "SUCURSAL : " + dataCompany.storeName.toUpperCase();
    store.style = styles.subtitle;
    row.height = 30;

    rowNumber = 3;
    row = worksheet.getRow(rowNumber);
    worksheet.mergeCells("A3:N3");
    const dateReport = worksheet.getCell("A3");
    dateReport.value = `DESDE ${dateStart} HASTA ${dateEnd}`;
    dateReport.style = styles.subtitle;
    row.height = 30;

    // Agregar encabezados
    const headers = [
      "FEC- MOVIMIENTO",
      "PRODUCTO",
      "CONCEPTO",
      "TIPO OPERACIÓN",
      "CANT. ENTRADA",
      "VALOR U. ENTRADA",
      "VALOR TOTAL ENTRADA",
      "CANT. SALIDA",
      "VALOR U. SALIDA",
      "VALOR TOTAL SALIDA",
      "CANT. SALDO",
      "VALOR U. SALDO",
      "VALOR TOTAL SALDO",
      "ESTADO",
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.style = styles.header;
    });

    rowNumber = 4; // Número de fila que deseas ajustar la altura
    row = worksheet.getRow(rowNumber);
    row.height = 35;

    // Agregar datos
    data.forEach((rowData) => {
      const rows = worksheet.addRow([
        rowData.fecha_ingreso,
        rowData.nombre_producto,
        rowData.concepto,
        rowData.tipo_de_operacion === 1
          ? "INGRESO"
          : rowData.tipo_de_operacion === 2
          ? "SALIDA"
          : "ANULACION",
        Number(rowData.cantidad_de_entrada),
        Number(rowData.valor_unitario_entrada),
        Number(rowData.valor_total_entrada),
        Number(rowData.cantidad_salida),
        Number(rowData.valor_unitario_salida),
        Number(rowData.valor_total_salida),
        Number(rowData.cantidad_de_saldo),
        Number(rowData.valor_unitario_saldo),
        Number(rowData.valor_total_saldo),
        rowData.estado === 1 ? "VIGENTE" : rowData.estado === 0 ?"VENDIDO":'',
      ]);
      //Estilos para las columnas
      rows.getCell(14).style.font = {
        bold: true,
        color: { argb: stylesExcel.color.warning },
      };
      (rows.getCell(14).style.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: stylesExcel.color.warningTransparent },
      }),
        (rows.getCell(14).alignment = {
          horizontal: "center",
          vertical: "middle",
        }),
        (rows.getCell(13).style.font = {
          bold: true,
          size: stylesExcel.fontSize.md,
        });
    });

    //* Ajustar ancho de columnas
    worksheet.columns.forEach((column, index) => {
      //Ancho para la columnas
      if (index === 0) {
        column.width = 20;
      } else if (
        index === 3 ||
        index === 4 ||
        index === 5 ||
        index === 6 ||
        index === 7 ||
        index === 8 ||
        index === 9 ||
        index === 10 ||
        index === 11 ||
        index === 13
      ) {
        column.width = 25;
      } else if (index === 1 || index === 2) {
        column.width = 50;
      } else {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      }
    });

    //* Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), getCurrentDate() + ".xlsx");
    await closeAlert(); //Cerramos el modal de aviso
  } catch (error) {
    errorActions(
      "Lo sentimos no pudimos generar el reporte, intentalo mas tarde"
    );
  }
};
