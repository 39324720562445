<template>
  <div class="mt-4">
    <div
      class="h-[calc(100vh-10rem)] flex items-center justify-center flex-col"
    >
      <div
        class="px-6 py-10 border shadow-md border-blue-50 shadow-blue-50 rounded-md md:min-w-[50%] md:max-w-[50%]"
      >
        <h2 class="text-1xl font-bold text-gray-700 text-center pb-8">
          Sincronización de datos de contribuyentes de la DGII
        </h2>
        <div class="w-32 h-32 mx-auto">
          <img
            src="../../assets/images/statics/sincronizar.png"
            alt="Sincronizar datos de la DGII"
            :class="{ 'animate-pulse': stateChangeFile }"
          />
        </div>
        <div class="mt-6 text-center">
          <p class="text-gray-500 text-sm mb-6">
            Sincroniza los datos de contribuyentes de una forma segura
            <br />y con información más actualizada por la DGII
          </p>
          <button
            class="px-3 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 active:scale-95"
            :class="{ 'animate-pulse  active:scale-100': stateChangeFile }"
            :disabled="stateChangeFile"
            @click="uploadData"
          >
            <span v-if="!stateChangeFile">Sincronizar datos</span>
            <span v-else>Sincronizando datos...</span>
          </button>

          <div
            class="px-3 py-3 rounded-md bg-blue-50 border border-blue-100 mt-8 text-blue-500 text-sm"
            v-show="isChange"
          >
            Felicitaciones, los datos de los contribuyentes an sido actualizado
            con éxito...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "../../api";
import { errorActions } from "@/alerts";
export default {
  name: "MainDGII",
  setup() {
    const stateChangeFile = ref(false);
    const isChange = ref(false);
    const uploadData = async () => {
      stateChangeFile.value = !stateChangeFile.value;
      const response = await axios
        .get("dgii", {
          method: {
            "Cotent-Type": "application/json",
          },
        })
        .catch((error) => errorActions(error));
      const data = response.data;
      if (data.msg === "ok") {
        isChange.value = true;
      } else if (data.msg === "error al abrir zip") {
        errorActions("error al abrir zip");
      } else if (data.msg === "error al descargar") {
        errorActions("error al descargar");
      } else if (data.msg === "tiempo de espera expirado") {
        errorActions("tiempo de espera expirado");
      }
      stateChangeFile.value = false;
    };
    return {
      stateChangeFile,
      uploadData,
      isChange
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
