<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentDataFormUpdate">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar información del desembolso
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid md:grid-cols-2 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto de desembolso <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.amount"
                placeholder="Monto de desemnolso..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de desembolso <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.disbursementNumber"
                placeholder="FC01-00021540..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Beneficiario
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.beneficiary"
                placeholder="EMPRESA DE HIDROCARBUROS PERU"
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Departamento
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.departament"
                placeholder="RR-HH"
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block md:col-span-2">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Concepto <span class="text-red-300">*</span>
              </span>
              <textarea
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 resize-none w-full border-2 focus:border-blue-500"
                v-model="dataForm.concept"
                placeholder="COMPRA DE UNA MINI COMPRENSORA"
                maxlength="150"
                cols="30"
                rows="2"
              ></textarea>
            </label>
          </div>

          <div class="flex mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../api";

import LoadingForms from "@/components/Loadings/LoadingFormsTwoColumn.vue";
export default {
  name: "UpdateDisbursements",
  components: {
    LoadingForms,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      idTurn: 0,
      amount: 0,
      disbursementNumber: "",
      beneficiary: "",
      departament: "",
      concept: "",
      attachedFile: "",
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      
      observeElement('#contentDataFormUpdate')
      const response = await axios
        .get("desembolsos/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.amount = data.monto;
      dataForm.value.disbursementNumber = data.numero_desembolso;
      dataForm.value.beneficiary = data.beneficiario;
      dataForm.value.departament = data.departamento;
      dataForm.value.concept = data.concepto;

      isLoading.value = true;
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectTypeOfDocuments = (data) => {
      dataForm.value.idTypeOfDocument = data;
    };

    const update = async () => {
      if (dataForm.value.amount <= 0) {
        errorActions("El monto de desembolso no debe de ser menor o igual a <strong>0</strong>");
        return;
      }
      if (dataForm.value.disbursementNumber.trim().length <= 2) {
        errorActions("Ingresa el número de desembolso <strong>El número es el número de comprobante u otro identificador</strong>");
        return;
      }
      if (dataForm.value.concept.trim().length <= 2) {
        errorActions("Ingresa el concepto o motivo <strong>por el cual se esta realizando el desembolso</strong>");
        return;
      }

      spinner.value = true;

      const response = await axios
        .put(
          "desembolsos/" + props.id,
          {
            amount: dataForm.value.amount,
            disbursementNumber: dataForm.value.disbursementNumber,
            beneficiary: dataForm.value.beneficiary,
            departament: dataForm.value.departament,
            concept: dataForm.value.concept,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate('El monto desembolso por '+dataForm.value.amount);
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El desembolso  por el monto de <trong>${dataForm.value.amount} </strong> que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos del desembolso por el monto de <trong>${dataForm.value.names} </strong>`
        );
      }

      spinner.value = false;
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
      getSelectTypeOfDocuments,
    };
  },
};
</script>
