import { errorActions } from "@/alerts";
import axios from "../../api";

/**
 * Esta funcion obtiene los datos de contribuyentes de manera segura y optima
 * @param {*} rnc_cedula El numero del contribuyente
 */

export const dgii = async (rnc_cedula) => {
  try {
    const response = await axios.get(`dgii/${rnc_cedula}`);
    const data = await response.data;
    const numberDocument = data.rnc;
    const bussinesName = data.razon_social;
    const tradeName = data.nombre_comercial;
    const paymentRegime = data.regimen_de_pagos;
    const state = data.estado;
    const category = data.categoria;
    return {
      numberDocument,
      bussinesName,
      tradeName,
      paymentRegime,
      state,
      category,
    };
  } catch (error) {
    if (error.response.data.msg === "no encontrado") {
      errorActions(
        `El contribuyente con el numero <strong>${rnc_cedula} </strong> no fue encontrado`
      );
      return null;
    }
  }
};
