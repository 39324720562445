<template>
  <div>
    <h2 class="text-1xl font-bold text-gray-700">
      Cofiguracion de sucursales / almacenes
    </h2>
    <MainStores />
  </div>
</template>

<script>
import MainStores from "@/components/Company/Stores/MainStores.vue";
import { useRoute } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { findSubmoduleIdByDescription } from "../../../hisAcces";

export default {
  name: "StoresConfigurations",
  components: {
    MainStores,
  },
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      if (!findSubmoduleIdByDescription(route.path)) {
        window.location.href = "/login";
      }
    });

    onMounted(() => {
      document.title =
        route.meta.title + process.env.VUE_APP_TITLE ||
        process.env.VUE_APP_TITLE;
    });
  },
};
</script>
