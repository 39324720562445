<template>
  <div>
    <Bar :data="data" :options="options" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
} from "chart.js";
import { Bar } from "vue-chartjs";
import { ref } from "vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export default {
  name: "TotalSalesForMonthReportGrapics",
  components: {
    Bar,
  },
  props: {
    datas: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    //Datos que se van a pintar
    const data = ref({
      labels: ["Cheque", "Efectivo", "Tarjeta", "Transferencia"],
      datasets: [
        {
          label: "VENTAS DEL MES",
          data: [
            props.datas.cheque,
            props.datas.efectivo,
            props.datas.tarjeta,
            props.datas.transferencia,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)", 
            "rgba(66, 216, 155, 0.6)", 
            "rgba(255, 206, 86, 0.6)", 
            "rgba(54, 162, 235, 0.6)", 
          ],
        },
      ],
    });
    //Configuracion de todas la opciones del gráfico de barra, como titulos, leyendas, etc.
    const options = ref({
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
        colors: {
          enabled: true,
        },
        title: {
          display: true,
          text: "ventas del mes".toUpperCase(),
        },
      },
    });
    return {
      data,
      options,
    };
  },
};
</script>
<style scoped>
/* canvas {
  height: 100% !important;
  width: 100% !important;
} */
.chart-container {
  width: 100%;
  height: auto;
}
</style>
