<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentDataShow">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Vista previa
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <div v-else class="space-y-4">
          <div
            class="px-3 py-2 rounded-sm"
            :class="{
              'bg-green-50 text-green-500': dataForm.state === 'ACEPTADO',
              'bg-orange-50 text-orange-500': dataForm.state === 'PENDIENTE',
              'bg-red-50 text-red-500': dataForm.state === 'RECHAZADO',
            }"
          >
            DESEMBOLSO {{ dataForm.state }}
          </div>
          <div class="bg-blue-50 text-blue-500 px-3 py-2 rounded-sm">
            <strong class="block font-semibold text-sm pb-2 text-blue-700"
              >N° de desembolso:
            </strong>
            {{ dataForm.disbursementNumber }}
          </div>
          <div class="bg-blue-50 text-blue-500 px-3 py-2 rounded-sm">
            <strong class="block font-semibold text-sm pb-2 text-blue-700"
              >Beneficiario:
            </strong>
            {{ dataForm.beneficiary === null ? "---" : dataForm.beneficiary }}
          </div>
          <div class="bg-blue-50 text-blue-500 px-3 py-2 rounded-sm">
            <strong class="block font-semibold text-sm pb-2 text-blue-700"
              >Departamento:
            </strong>
            {{ dataForm.departament === null ? "---" : dataForm.departament }}
          </div>
          <div class="bg-blue-50 text-blue-500 px-3 py-2 rounded-sm">
            <strong class="block font-semibold text-sm pb-2 text-blue-700"
              >Concepto o motivo:
            </strong>
            {{ dataForm.concept }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../api";

import LoadingForms from "@/components/Loadings/LoadingFormsOneColumn.vue";
export default {
  name: "ShowDisbursements",
  components: {
    LoadingForms,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const dataForm = ref({
      state: "",
      amount: 0,
      disbursementNumber: "",
      beneficiary: "",
      departament: "",
      concept: "",
      attachedFile: "",
    });

    const isLoading = ref(false);
    onMounted(async () => {
      observeElement("#contentDataShow");
      const response = await axios
        .get("desembolsos/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.amount = data.monto;
      dataForm.value.disbursementNumber = data.numero_desembolso;
      dataForm.value.beneficiary = data.beneficiario;
      dataForm.value.departament = data.departamento;
      dataForm.value.concept = data.concepto;
      dataForm.value.state = data.estado;
      isLoading.value = true;
    });

    return {
      isActive,
      dataForm,
      isLoading,
    };
  },
};
</script>
