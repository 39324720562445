/**
 * Es la funcion que sirve para seleccionar un fila de la tabla
 * @param event Es el evento click del DOM
 */
export const selectRow = (event) => {
  const select = document.querySelectorAll(".select");
  select.forEach((item) => item.classList.remove("select"));
  if(event.target.parentNode.tagName === 'TR') {
    event.target.parentNode.classList.add("select");
  }
};
