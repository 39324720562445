<template>
    <div>
      <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
        <div class="flex items-end gap-4 flex-wrap">
          <label class="block mt-3 md:mt-0 w-full md:w-auto">
            <span class="block text-gray-600 font-semibold mb-1 text-sm"
              >Fec. de inicio</span
            >
            <input
              type="date"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.dateStart"
              @keydown.enter.prevent
            />
          </label>
          <label class="block mt-3 md:mt-0 w-full md:w-auto">
            <span class="block text-gray-600 font-semibold mb-1 text-sm"
              >Fec. Término</span
            >
            <input
              type="date"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.dateEnd"
              @keydown.enter.prevent
            />
          </label>
        </div>
        <div>            
          <button
            class="px-3 py-2 bg-blue-500 hover:bg-blue-600 border-2 border-blue-500 rounded-md hover:border-blue-600 text-sm text-white active:scale-95"
            @click="resetValuesAndGetData"
          >
            Consultar
          </button>
        </div>
      </div>
      <div class="rounded-lg overflow-hidden border">
        <!-- secction para filtar por limite de registro -->
        <div class="py-4 px-3 flex items-center justify-between">
          <select
            v-model="limitRegister"
            class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
            @change="resetValuesAndGetData"
          >
            <option value="10">10 Registros</option>
            <option value="25">25 Registros</option>
            <option value="50">50 Registros</option>
            <option value="100">100 Registros</option>
            <option value="500">500 Registros</option>
            <option value="1000">1000 Registros</option>
            <option value="5000">5000 Registros</option>
            <option value="10000">10000 Registros</option>
          </select>
          <div class="w-full">
            <label for="modules">
              <input
                type="text"
                class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
                placeholder="Buscar por fecha o n° de traslado"
                v-model="search"
                @keydown.enter="resetValuesAndGetData"
              />
            </label>
          </div>
        </div>
  
        <!-- seccion de conteido de datos tablas
                1: Para poner una tabla responsiva añadir lo siguiente:
                1.1: class="resize-generate"
                1.2: ref="widthNav" 
                1.3: @resize="handleResize"
            -->
        <div
          class="w-full resize-x resize-generate"
          ref="widthNav"
          @resize="handleResize"
        >
          <div class="overflow-x-auto">
            <table class="table-auto w-full">
              <TableHead :headers="header" />
              <LoadingTables v-if="loading" :columns="8" />
              <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                <tr
                  v-for="(register, i) in registers"
                  :key="i"
                  class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                  @click="selectRow($event)"
                >
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    {{ register.usuario.nombres }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase bg-blue-50 text-blue-500"
                  >
                    T001-{{ register.numero_traslado }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    {{ register.sucursal.razon_social }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase "
                  >
                    {{ register.destino.razon_social }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    {{ money() }} {{ format(register.igv) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    {{ money() }} {{ format(register.subtotal) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap font-bold"
                  >
                    {{ money() }} {{ format(register.total) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap flex justify-end"
                  >
                    <div class="relative group">
                      <button
                        class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                      >
                        <font-awesome-icon icon="fa-solid fa-list-ul" />
                      </button>
                      <ul
                        class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                      >
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printVoucherTicket(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en 80MM
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printVoucherOnA4(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en A4
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printVoucherOnLetter(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en 8 1/2x11
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printVoucherOnMediaLetter(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en M. carta
                        </button>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <TablePagination
          :currentPage="currentPage"
          :total="totalPage"
          :next="next"
          :prev="prev"
        />
      </div>
    </div>
  
  </template>
  
  <script>
  import { onMounted, ref, watchEffect } from "vue";
  import axios from "@/api";
  import LoadingTables from "@/components/Loadings/LoadingTables";
  import TableHead from "@/components/TablesComponents/TableHead";
  import TablePagination from "@/components/TablesComponents/TablePagination.vue";
  import { errorActions, warningActions } from "@/alerts";
  import { observer } from "@/observer";
  import { idLocalStore} from "@/publicjs/localStorage";
  import { money, format } from "@/publicjs/money";
  import { formatDateTime } from "@/publicjs/formatDate";
  
  import config from "/public/apis/config.json";
  
  // Print voucher
  import { printVoucherOnA4 } from "./vouchers/PrintOnA4";
  import { printVoucherOnLetter } from "./vouchers/PrintOnLetter";
  import { printVoucherOnMediaLetter } from "./vouchers/PrintOnMediaLetter";
  import { printVoucherTicket } from "./vouchers/PrintOnTicket";
  
  import {selectRow} from '@/publicjs/selectRowTable'
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import { currentDay } from "@/publicjs/currentDate";
  
  export default {
    name: "ListInternalTransferOfProducts",
    components: {
      LoadingTables,
      TableHead,
      TablePagination
    },
    setup() {
      // carga de datos a la tabla
      const registers = ref([]);
      const paginate = ["registers"];
      const loading = ref(true);
      const header = [
        [
          {
            title: "Encargado",
          },
          {
            title: "N° traslado",
          },
          {
            title: "origen",
          },
          {
            title: "destino",
          },
          {
            title: config.iva,
          },
          {
            title: "subtotal",
          },
          {
            title: "total",
          },
          {
            title: "Acciones",
          },
        ],
      ];
  
      const dataForm = ref({
        dateStart: '',
        dateEnd: '',
        idStore: 0
      });
  
      //variables de paginacion
      const currentPage = ref(1);
      const limitRegister = ref(10);
      const search = ref("");
      const resgisterPaginate = ref(null);
      const totalPage = ref(0);
      const salesState = ref("EFECTUADA");
      const getData = async () => {
        loading.value = true;
        const response = await axios
          .get(
            `traslado-interno/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${dataForm.value.idStore}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
          )
          .catch((error) => errorActions(error));
        // validacion para mostrar una alerta cuando no se ha encontrado un registro
        if ((await response.data.data.length) === 0) {
          warningActions(
            "Los sentimos no pudimos encontrar lo que estabas buscando 😥."
          );
          //codigo para evitar el error de [resize observer]
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
        }
        registers.value = await response.data.data;
        resgisterPaginate.value = await response.data;
        totalPage.value = resgisterPaginate.value.last_page;
        loading.value = false;
      };
  
      //funcion para recetear valores
      const resetValuesAndGetData = async () => {
        currentPage.value = 1;
        await getData();
      };
  
      //calcular el ancho del nav y hacer el scroll en x de la tabla
      const widthNav = ref(null);
      const handleResize = () => {
        const nav = document.querySelector("nav").offsetWidth;
        watchEffect(() => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        });
      };
  
      onMounted(async () => {
        handleResize();
        window.addEventListener("resize", handleResize);
        dataForm.value.idStore = await idLocalStore();
        const dates = await firstDaytAndLastDay();
        dataForm.value.dateEnd = dates.lastDay
        dataForm.value.dateStart = currentDay()
  
        await getData();
      });
  
      //! Funciones para avanzar y retrocedeer en la paginaciones
      const next = async () => {
        totalPage.value = resgisterPaginate.value.last_page;
        if (currentPage.value <= totalPage.value - 1) currentPage.value++;
        await getData();
      };
      const prev = async () => {
        totalPage.value = resgisterPaginate.value.last_page;
        if (currentPage.value >= 2) currentPage.value--;
        await getData();
      };
  
  
      return {
        loading,
        getData,
        paginate,
        header,
        registers,
        limitRegister,
        currentPage,
        search,
        next,
        prev,
        totalPage,
        resetValuesAndGetData,
        widthNav,
        handleResize,
        money,
        format,
        formatDateTime,
        dataForm,
        salesState,
        printVoucherOnA4,
        printVoucherOnLetter,
        printVoucherOnMediaLetter,
        printVoucherTicket,
        selectRow,
      };
    },
  };
  </script>
  
  <style scoped>
  .active button svg {
    rotate: 90deg;
  }
  </style>
  