<template>
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center py-8 justify-center min-h-screen">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div
          class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[40vw]"
        >
          <div class="flex justify-between items-center mb-6">
            <h2 class="text-xl font-medium text-gray-800 dark:text-white">
              Configuración de impresión
            </h2>
            <button
              @click="isActive"
              class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
            >
              <svg class="w-5 h-5">
                <use href="../../../assets/svg/icon.svg#cancel" />
              </svg>
            </button>
          </div>
          <form class="w-full space-y-6" @submit.prevent>
            <div class="grid md:grid-cols-2 gap-4">
              <label class="md:col-span-2 ">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Comprobantes
                </span>
                <Vouchers @send-data="getSelectVouchers" />
              </label>
              <label
                class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
              >
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Formato A4
                </span>
                <div
                  class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="sr-only peer"
                    v-model="dataForm.a4Format"
                  />
                  <div
                    class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                  ></div>
                  <span
                    class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    {{ dataForm.a4Format === true ? "En uso" : "Inactivo" }}
                  </span>
                </div>
              </label>
              <label
                class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
              >
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Formato 8 1/2 x 11
                </span>
                <div
                  class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="sr-only peer"
                    v-model="dataForm.format8x11"
                  />
                  <div
                    class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                  ></div>
                  <span
                    class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    {{ dataForm.format8x11 === true ? "En uso" : "Inactivo" }}
                  </span>
                </div>
              </label>
              <label
                class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
              >
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Formato media carta
                </span>
                <div
                  class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="sr-only peer"
                    v-model="dataForm.halfLetterFormat"
                  />
                  <div
                    class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                  ></div>
                  <span
                    class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    {{ dataForm.halfLetterFormat === true ? "En uso" : "Inactivo" }}
                  </span>
                </div>
              </label>
              <label
                class="flex flex-col h-6 mr-5 cursor-pointer mb-[3rem!important]"
              >
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Formato ticket
                </span>
                <div
                  class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="sr-only peer"
                    v-model="dataForm.ticketFormat"
                  />
                  <div
                    class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                  ></div>
                  <span
                    class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    {{ dataForm.ticketFormat === true ? "En uso" : "Inactivo" }}
                  </span>
                </div>
              </label>
            </div>
            <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                @click="insert"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Registrando Información
                </div>
                <span v-else>Registrar</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import { confirmationOfRecord, errorActions } from "@/alerts";
  import { observer } from "@/observer";
  import axios from "../../../api";
  import Vouchers from "@/components/PublicComponents/Vouchers.vue";
  
  export default {
    name: "RegisterConfigureVouchersPrint",
    props: {
      activeModal: {
        type: Function,
        required: true,
      },
      getdata: {
        type: Function,
        required: true,
      },
    },
    components:{
      Vouchers
    },
    setup(props) {
      function isActive() {
        props.activeModal(true);
      }
  
      const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información
  
      const dataForm = ref({
        idVoucher: 0,
        a4Format: false,
        format8x11: false,
        halfLetterFormat: false,
        ticketFormat: false,
      });
  
      //recepcion de datos desde el componente publico | lista todos los comprobantes
      const getSelectVouchers = (data) => {
        dataForm.value.idVoucher = data;
      };
  
      const insert = async () => {
        if (dataForm.value.idVoucher <= 0) {
          errorActions("Por favor selecciona un <strong>comprobantes</strong> y vuelve a intentarlo");
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
          return;
        }
        spinner.value = true;
        const response = await axios
          .post(
            "configuracion-de-impresion",
            {
              idVoucher: dataForm.value.idVoucher,
              a4Format: dataForm.value.a4Format?1:0,
              format8x11: dataForm.value.format8x11?1:0,
              halfLetterFormat: dataForm.value.halfLetterFormat?1:0,
              ticketFormat: dataForm.value.ticketFormat?1:0,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .catch(function (error) {
            errorActions(error);
          });
        const data = await response.data;
        if (data.msg === true) {
          confirmationOfRecord(
            `La configuración del comprobante`
          );
          props.getdata();
          dataForm.value.a4Format = false;
          dataForm.value.format8x11 = false;
          dataForm.value.halfLetterFormat = false;
          dataForm.value.ticketFormat = false;
        } else if (data.msg === "Request failed with status code 500") {
          errorActions("Request failed with status code 500");
        } else if (data.msg === 'exist') {
          errorActions(
            `Lo sentimos no podemos registrar <strong>la configuración del comprobante</strong>, debido a que ya existe un comprobante con la misma configuración`
          );
        } else {
          errorActions(
            `Lo sentimos, no pudimos registrar la <strong>configuración del comprobante</strong>`
          );
        }
        
        spinner.value = false; //dejando activo al boton
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      };
      return {
        isActive,
        dataForm,
        insert,
        spinner,
        getSelectVouchers,
        Vouchers
      };
    },
  };
  </script>
  