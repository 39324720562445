<template>
  <LoadingForms v-if="loading" />
  <form @submit.prevent v-else enctype="multipart/form-data">
    <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
      <label class="block mb-4">
        <span class="text-sm text-gray-600 mb-1 block"> Razón social </span>
        <input
          type="text"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          placeholder="Razón social"
          v-model="nameCompany"
        />
      </label>
      <label class="mb-3 block">
        <span class="text-sm text-gray-600 mb-1 block"> Nombre comercial </span>
        <input
          type="text"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          placeholder="Nombre comercial"
          v-model="nameComercial"
        />
      </label>
      <label class="mb-3 block">
        <span class="text-sm text-gray-600 mb-1 block"> Slogan </span>
        <textarea
          name=""
          id=""
          cols="30"
          rows="1"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full resize-none"
          placeholder="Slogan"
          v-model="slogan"
        ></textarea>
      </label>
      <label class="mb-3 block">
        <span class="text-sm text-gray-600 mb-1 block">
          {{ titlesByCountry.titleTypeDocument }}
        </span>
        <input
          type="text"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          :placeholder="titlesByCountry.titleTypeDocument"
          v-model="ruc"
        />
      </label>
      <label class="mb-3 block">
        <span class="text-sm text-gray-600 mb-1 block"> Dirección </span>
        <input
          type="text"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          placeholder="Dirección"
          v-model="address"
        />
      </label>
      <label class="mb-3 block">
        <span class="text-sm text-gray-600 mb-1 block">
          Representante legal
        </span>
        <input
          type="text"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          placeholder="Representante legal"
          v-model="ceo"
        />
      </label>
      <label class="mb-3 block">
        <span class="text-sm text-gray-600 mb-1 block"> Teléfonos </span>
        <input
          type="text"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          placeholder="Teléfonos"
          v-model="cellPhone"
        />
      </label>
      <label class="mb-3 block">
        <span class="text-sm text-gray-600 mb-1 block"> Tipo de empresa </span>
        <input
          type="text"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          placeholder="Tipo de empresa"
          v-model="typeCompany"
        />
      </label>
      <label class="mb-3 block" v-show="stateShowInput">
        <span class="text-sm text-gray-600 mb-1 block">
          N° Sucursales permitidos
        </span>
        <input
          type="number"
          class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
          placeholder="N° Sucursales permitidos"
          v-model="numberStores"
        />
      </label>

      <div class="w-full">
        <span class="text-sm text-gray-600 mb-1 block">
          Logo de la empresa
        </span>
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-10 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div class="flex items-center justify-between w-full px-4">
            <div class="w-4 h-4 mr-4">
              <svg class="w-4 h-4 text-gray-400">
                <use href="@/assets/svg/icon.svg#upload" />
              </svg>
            </div>
            <p class="text-[12px] text-gray-500 dark:text-gray-400">
              <span class="font-semibold">Click para seleccionar imagen</span>
              PNG o JPG
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".png,.jpg"
            ref="logo"
            @change="selectFile"
          />
        </label>
      </div>
      <label class="flex flex-col h-6 mr-5 cursor-pointer">
        <span class="text-sm text-gray-600 mb-3 block"> Estado </span>
        <div class="relative inline-flex items-center h-6 mr-5 cursor-pointer">
          <input type="checkbox" class="sr-only peer" v-model="stateCompany" />
          <div
            class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
          ></div>
          <span
            class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
            >{{ stateCompany === true ? "Activo" : "Inactivo" }}</span
          >
        </div>
      </label>
      <label class="flex flex-col h-6 mr-5 cursor-pointer"  v-show="stateShowInput">
        <span class="text-sm text-gray-600 mb-3 block">
          ¿Puede enviar correos?
        </span>
        <div class="relative inline-flex items-center h-6 mr-5 cursor-pointer">
          <input type="checkbox" class="sr-only peer" v-model="senEmail" />
          <div
            class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
          ></div>
          <span
            class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
          >
            {{ senEmail === true ? "Si" : "No" }}
          </span>
        </div>
      </label>

      <div class="mt-10">
        <div class="w-20 h-20 overflow-hidden rounded-lg">
          <img :src="APIURL + '' + logoCurrent" alt="" />
        </div>
        <div class="text-gray-600 text-sm font-semibold">Logo actual</div>
      </div>
    </div>

    <div class="flex justify-start mt-6">
      <div class="flex">
        <button
          class="bg-red-500 text-white px-3 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-red-600 active:scale-95 w-full mt-3 flex items-center"
          type="button"
        >
          <font-awesome-icon
            icon="fa-solid fa-ban"
            class="small-hidden w-4 h-4 mr-2"
          />
          Cancelar
        </button>
        <button
          class="bg-blue-500 text-white min-w-[10rem] px-3 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 w-full mt-3 ml-3 flex items-center"
          @click="insert"
          v-if="id === 0"
        >
          <font-awesome-icon
            icon="fa-solid fa-check"
            class="small-hidden w-4 h-4 mr-2"
          />
          Registrar
        </button>
        <button
          class="bg-blue-500 text-white min-w-[10rem] px-3 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 w-full mt-3 ml-3 flex items-center"
          @click="update"
          v-else
        >
          <font-awesome-icon
            icon="fa-solid fa-check"
            class="small-hidden w-4 h-4 mr-2"
          />
          Guardar cambios
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { onMounted, ref } from "vue";
import titlesByCountry from "/public/apis/titles-by-country.json";
import {
  confirmationOfRecord,
  confirmationOfUpdate,
  errorActions,
} from "../../../alerts.js";
import LoadingForms from "@/components/Loadings/LoadingForms.vue";
import axios from "../../../api";
import { localTypeUser } from '@/publicjs/localStorage.js';
export default {
  name: "DataCompany",
  components: {
    LoadingForms,
  },
  setup() {
    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;
    const nameCompany = ref("");
    const nameComercial = ref("");
    const slogan = ref("");
    const ruc = ref("");
    const address = ref("");
    const ceo = ref("");
    const cellPhone = ref("");
    const typeCompany = ref("");
    const senEmail = ref(false);
    const numberStores = ref(0);
    const logo = ref("");
    const logoCurrent = ref("");
    const stateCompany = ref(false);
    const id = ref(0);
    const stateShowInput  = ref(false)

    const loading = ref(true);
    const getData = async () => {
      loading.value = true;
      try {
        const response = await axios
          .get("empresa")
          .catch((error) => errorActions(error));
        if (response.data.length !== 0) {
          const data = await response.data[0];
          nameCompany.value = data.razon_social;
          nameComercial.value = data.nombre_comercial;
          slogan.value = data.slogan;
          ruc.value = data.ruc;
          address.value = data.direccion;
          ceo.value = data.representante;
          cellPhone.value = data.telefonos;
          typeCompany.value = data.tipo_empresa;
          senEmail.value = data.envia_correos === 1 ? true : false;
          numberStores.value = data.numero_de_sucursales;
          logoCurrent.value = data.logo;
          stateCompany.value = data.estado === 1 ? true : false;
          id.value = data.id;
        }
        loading.value = false;
      } catch (error) {
        errorActions(error);
      }
    };

    // Selecciona el archivo
    const selectFile = async () => {
      await logo.value.files;
      if (id.value > 0) {
        await updateLogo();
      }
    };

    const insert = async () => {
      if (nameCompany.value.trim().length < 4) {
        errorActions(
          "Lo sentimos, no podemos registrar la empresa, escribe una longitud de caracteres mayor a 3"
        );
        return;
      }

      //recuperando la imagen
      const formData = new FormData();
      formData.append("razon_social", nameCompany.value);
      formData.append("nombre_comercial", nameComercial.value);
      formData.append("slogan", slogan.value);
      formData.append("ruc", ruc.value);
      formData.append("direccion", address.value);
      formData.append("representante", ceo.value);
      formData.append("telefonos", cellPhone.value);
      formData.append("tipo_empresa", typeCompany.value);
      formData.append("envia_correos", senEmail.value === true ? 1 : 0);
      formData.append("numero_de_sucursales", numberStores.value);
      formData.append("logo", logo.value.files[0]);
      formData.append("estado", stateCompany.value === true ? 1 : 0);

      const response = await axios
        .post("empresa", formData, {
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(nameCompany.value);
        await getData();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          "Lo sentimos no pudimos realizar el registro de la empresa"
        );
      }
    };

    const update = async () => {
      if (nameCompany.value.trim().length < 4) {
        errorActions(
          "Lo sentimos, no podemos registrar la empresa, escribe una longitud de caracteres mayor a 3"
        );
        return;
      }

      //recuperando la imagen
      const dataForm = {
        razon_social: nameCompany.value,
        nombre_comercial: nameComercial.value,
        slogan: slogan.value,
        ruc: ruc.value,
        direccion: address.value,
        representante: ceo.value,
        telefonos: cellPhone.value,
        tipo_empresa: typeCompany.value,
        envia_correos: senEmail.value === true ? 1 : 0,
        numero_de_sucursales: numberStores.value,
        estado: stateCompany.value === true ? 1 : 0,
      };

      const response = await axios
        .put(`empresa/${id.value}`, dataForm, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(nameCompany.value);
        await getData();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          "Lo sentimos no pudimos realizar el registro de la empresa"
        );
      }
    };

    const updateLogo = async () => {
      const formData = new FormData();
      formData.append("logo", logo.value.files[0]);
      // formData.append("_method", "PUT");
      const response = await axios
        .post(`empresa/updatelogo/${id.value}`, formData, {
          "Content-Type": "multipart/form-data",
        })
        .catch((error) => {
          errorActions(error);
        });
      const dataresponse = await response.data;
      if (dataresponse.msg === true) {
        confirmationOfUpdate("El logo de " + nameCompany.value);
        await getData();
      } else if (dataresponse.msg === "error") {
        errorActions("Error al guardar imagen");
      }
    };

    onMounted(async () => {
      const typeUser = await localTypeUser()
      stateShowInput.value = typeUser === 'SUPERADMIN_ROOT'?true:false
      await getData();
    });

    return {
      titlesByCountry,
      nameCompany,
      nameComercial,
      slogan,
      ruc,
      address,
      ceo,
      cellPhone,
      typeCompany,
      senEmail,
      numberStores,
      logo,
      logoCurrent,
      stateCompany,
      loading,
      selectFile,
      insert,
      APIURL,
      id,
      update,
      stateShowInput
    };
  },
};
</script>
