<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar datos de la aseguradora
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Nombres <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.names"
                placeholder="Nombres..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de documentos
              </span>
              <TypeOfDocuments
                v-if="isLoading"
                @send-data="getSelectTypeOfDocuments"
                :selectOption="dataForm.idTypeOfDocument"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° de documentos <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.numberDocument"
                placeholder="N° de documentos..."
                @keydown.enter.prevent
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Dirección
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.address"
                placeholder="Dirección..."
                @keydown.enter.prevent
                maxlength="100"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Teléfono / Celular
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.phone"
                placeholder="Teléfono / Celular..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>

            <label class="flex flex-col h-6 mr-5 cursor-pointer">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Estado de la empresa
              </span>
              <div
                class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="sr-only peer"
                  v-model="dataForm.state"
                />
                <div
                  class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                ></div>
                <span
                  class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {{ dataForm.state === true ? "Activo" : "Inactivo" }}
                </span>
              </div>
            </label>
          </div>

          <div class="flex mt-[3.5rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="update"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Modificando Información
              </div>
              <span v-else>Modificar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../api";

//componente de loading
import TypeOfDocuments from "@/components/PublicComponents/TypeOfDocuments.vue";
import LoadingForms from "@/components/Loadings/LoadingFormsLarge.vue";
export default {
  name: "UpdateInsuranceCompanies",
  components: {
    LoadingForms,
    TypeOfDocuments,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idTypeOfDocument: 0,
      names: "",
      address: "",
      numberDocument: "",
      phone: "",
      state: false,
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      const response = await axios
        .get("aseguradoras/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.idTypeOfDocument = data.idtipo_documento;
      dataForm.value.names = data.razon_social;
      dataForm.value.address = data.direccion;
      dataForm.value.numberDocument = data.numero_documento;
      dataForm.value.phone = data.telefonos;
      dataForm.value.state = data.estado === 1 ? true : false;

      isLoading.value = true;
    });

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectTypeOfDocuments = (data) => {
      dataForm.value.idTypeOfDocument = data;
    };

    const update = async () => {
      if (dataForm.value.names.trim().length <= 2) {
        errorActions(
          "Ingresa la razón social de la <strong>empresa aseguradora</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.numberDocument <= 100000) {
        errorActions(
          "Ingresa un número de <strong>documento</strong> válido para la empresa aseguradora"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      spinner.value = true;

      const response = await axios
        .put(
          "aseguradoras/" + props.id,
          {
            idTypeOfDocument: dataForm.value.idTypeOfDocument,
            names: dataForm.value.names,
            address: dataForm.value.address,
            numberDocument: dataForm.value.numberDocument,
            phone: dataForm.value.phone,
            state: dataForm.value.state ? 1 : 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate(dataForm.value.names);
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `La empresa aseguradora <trong>${dataForm.value.names} </strong> que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos de la empresa aseguradora <trong>${dataForm.value.names} </strong>`
        );
      }

      spinner.value = false;
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
      getSelectTypeOfDocuments,
    };
  },
};
</script>
