import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { money, format } from "@/publicjs/money"; //? Formateo y obtencion de moneda configurada de la empresa
import { closeAlert, errorActions, uploadData } from "@/alerts"; //? Alertas de error, etc
import { idLocalStore } from "@/publicjs/localStorage"; //? Recuperar el id de la sucursal
import { getCompany } from "@/publicjs/company"; //? datos de la empresa y la sucursal
import { imageInBase64 } from "@/publicjs/logoCompany"; //? Imagen de base64 de la empresa
import { getCurrentDate } from "@/publicjs/currentDate"; //? Retorna la fecha y hora actual
import titlesConfig from "/public/apis/config.json"; //? Títulos de configuracion segun pais
import { formatDate } from "@/publicjs/formatDate";
import styles from "@/publicjs/styles";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//* Función para imprimir el documento
/**
 *
 * @param {*} registers | un array de datos para poblar todo el contenido
 * @param {*} dataForm | Datos del formulario como fechas
 */
export const exportToPDF = async (registers, dataForm) => {
  await uploadData(
    "Generando reporte".toUpperCase(),
    "Espera un momento mientras preparamos tu reporte, <br> <strong>Esto puede tardar unos segundos...</strong>"
  );
  //* Poblado de datos de la empresa
  const company = await getCompany(await idLocalStore());

  //* Cargar la imagen o el logo de la empresa
  let base64Image = await imageInBase64(company.companyLogo);

  try {
    let docDefinition = null;
    docDefinition = {
      // a string or { width: number, height: number }
      pageSize: "A4",

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: "portrait",

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [24, 140, 24, 24],
      //!encabezado del documento
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            columns: [
              // Columna izquierda para el logotipo
              {
                width: 100,
                stack: [
                  { image: base64Image, fit: [80, 80] }, // logo
                ],
                alignment: "left",
                margin: [0, 10, 0, 0],
              },
              {
                width: 200,
                stack: [
                  {
                    text: company.companyName,
                    fontSize: styles.fontSize.pdf.xl,
                    alignment: "left",
                    bold: true,
                    color: styles.colors.colorTitle,
                  },
                  {
                    text: "Dirección: " + company.companyAddress,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: "Tel:" + company.companycellphone,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: company.companySlogan,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text:
                      titlesConfig.titleTypeDocument + " " + company.companyRUC,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "left",
                    color: styles.colors.colorDescription,
                    bold: true,
                  },
                ],
                alignment: "left",
                margin: [12, 10, 24, 0],
              },
              // Columna derecha para los datos de la empresa
              {
                width: "*",
                stack: [
                  {
                    text: company.storeName,
                    fontSize: styles.fontSize.pdf.lg,
                    alignment: "right",
                    color: styles.colors.colorTitle,
                    bold: true,
                  },
                  {
                    text: "Dirección: " + company.storeAddress,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "right",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: "Tel: " + company.storeCellphone,
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "right",
                    color: styles.colors.colorDescription,
                  },
                  {
                    text: "CONSOLIDADO",
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "right",
                    color: styles.colors.colorBlue,
                    bold: true,
                  },
                  {
                    text: getCurrentDate(),
                    fontSize: styles.fontSize.pdf.sm,
                    alignment: "right",
                    color: styles.colors.colorDescription,
                    bold: true,
                  },
                ],
                alignment: "right",
                margin: [0, 10, 24, 0],
              },
            ],
            margin: [24, 12, 0, 0], // Margen entre el encabezado y el contenido de la página
          },
          {
            canvas: [
              { type: "rect", x: 24, y: 90, w: pageSize.width - 48, h: 2 },
            ],
            margin: [0, 10], // Margen entre el rectángulo y el contenido de la página
          },
        ];
      },

      //!pie de página del documento
      footer: function (currentPage, pageCount) {
        return {
          text:
            "Estas en la página " + currentPage.toString() + " de " + pageCount,
          alignment: "right",
          fontSize: styles.fontSize.pdf.sm,
          color: styles.colors.colorDescription,
          margin: [0, 0, 24, 0],
        };
      },
      content: [
        {
          text: "Consolidado de compras, ventas, pagos,etc.".toUpperCase(),
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorBlue,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        {
          text:
            "Desde: " +
            formatDate(dataForm.dateStart) +
            " hasta " +
            formatDate(dataForm.dateEnd),
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorTitle,
          margin: [0, 0, 0, 30],
        },
        {
          text: "CONSOLIDADO TOTAL",
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorBlue,
          bold: true,
          margin: [0, 0, 0, 20],
        },
        {
          table: {
            headerRows: 1,
            widths: [45, "*"],
            body: [
              [
                {
                  text: "Ganancias:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(dataForm.totalSale),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
              [
                {
                  text: "Ventas:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(registers.ventas.total),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
              [
                {
                  text: "Gastos:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(dataForm.totalExpences),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
            ],
          },

          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },

        {
          text: "CONSILIDADO POR CATEGORÍA",
          fontSize: styles.fontSize.pdf.md,
          color: styles.colors.colorBlue,
          bold: true,
          margin: [0, 20, 0, 20],
        },

        {
          table: {
            headerRows: 1,
            widths: [100, "*"],
            body: [
              [
                {
                  text: "Ventas:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(registers.ventas.total),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
              [
                {
                  text: "Compras:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(registers.compras.total),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
              [
                {
                  text: "Desembolsos:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(registers.desembolsos.total),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
              [
                {
                  text: "Pago de servicios:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(registers.pago_de_servicios.total),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
              [
                {
                  text: "Pago de personal:",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  bold: true,
                  fontSize: styles.fontSize.table.md + 2,
                },
                {
                  text: money() + format(registers.pago_de_personal.total),
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 5],
                  valign: "middle",
                  color: styles.colors.colorTitle,
                  fontSize: styles.fontSize.table.lg + 2,
                },
              ],
            ],
          },

          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? styles.colors.borderColor
                : styles.colors.borderColor;
            },
          },
        },
      ],
    };
    pdfMake.createPdf(docDefinition).download(getCurrentDate());
    await closeAlert();
  } catch (error) {
    errorActions("No se pudo generar el reporte");
  }
};
