<template>
  <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
    <div class="bg-gray-100 rounded-lg border-gray-100 animate-pulse border">
      <div class="px-3 py-5"></div>
      <div class="bg-white py-2 flex gap-4">
        <div
          class="w-20 py-4 bg-gray-100 rounded-lg border-gray-100 animate-pulse"
        ></div>
        <div
          class="w-full py-4 bg-gray-100 rounded-lg border-gray-100 animate-pulse"
        ></div>
      </div>
    </div>
    <div class="bg-gray-100 rounded-lg border-gray-100 animate-pulse border">
      <div class="px-3 py-5"></div>
      <div class="bg-white py-2 flex gap-4">
        <div
          class="w-20 py-4 bg-gray-100 rounded-lg border-gray-100 animate-pulse"
        ></div>
        <div
          class="w-full py-4 bg-gray-100 rounded-lg border-gray-100 animate-pulse"
        ></div>
      </div>
    </div>
    <div class="bg-gray-100 rounded-lg border-gray-100 animate-pulse border">
      <div class="px-3 py-5"></div>
      <div class="bg-white py-2 flex gap-4">
        <div
          class="w-20 py-4 bg-gray-100 rounded-lg border-gray-100 animate-pulse"
        ></div>
        <div
          class="w-full py-4 bg-gray-100 rounded-lg border-gray-100 animate-pulse"
        ></div>
      </div>
    </div>
  </div>
</template>
