<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <!-- componente para mfiltrar por estado de orden de compra -->
      <label class="block mt-3 md:mt-0 w-full md:w-auto">
        <span class="block text-gray-600 font-semibold mb-1"
          >Filtrar por estados</span
        >
        <select
          v-model="shoppingState"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
          @change="resetValuesAndGetData"
        >
          <option value="VIGENTE">VIGENTE</option>
          <option value="USADO">USADO</option>
        </select>
      </label>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por fecha, nombre o n° de documento del cliente..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
              1: Para poner una tabla responsiva añadir lo siguiente:
              1.1: class="resize-generate"
              1.2: ref="widthNav" 
              1.3: @resize="handleResize"
          -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="9" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombre_cliente }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.tipo_comprobante.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase bg-blue-50 text-blue-500"
                >
                  {{ register.numero_comprobante }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ money() }} {{ format(register.igv) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.subtotal) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap font-bold"
                >
                  {{ money() }} {{ format(register.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{
                    register.comentarios === null ? "---" : register.comentarios
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg"
                    :class="{
                      'bg-green-50 text-green-600':
                        register.estado === 'VIGENTE',
                      'bg-orange-50 text-orange-600':
                        register.estado === 'USADO',
                    }"
                  >
                    {{ register.estado }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap flex justify-end"
                >
                  <div class="relative group">
                    <button
                      class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                    >
                      <font-awesome-icon icon="fa-solid fa-list-ul" />
                    </button>
                    <ul
                      class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                    >
                      <div v-if="register.estado === 'VIGENTE'">
                        <button
                          class="px-6 py-2 text-blue-500 bg-blue-50 hover:text-white flex items-center hover:bg-blue-500 whitespace-nowrap w-full gap-4"
                          @click="showModal(register.id, register.detalle)"
                        >
                          <font-awesome-icon icon="fa-solid fa-pen" />
                          Modificar pedido
                        </button>
                        <button
                          class="px-6 py-2 text-red-500 bg-red-50 hover:text-white flex items-center hover:bg-red-500 whitespace-nowrap w-full gap-4"
                          @click="
                            deleteOrder(
                              register.id,
                              register.numero_comprobante,
                              resetValuesAndGetData
                            )
                          "
                        >
                          <font-awesome-icon icon="fa-solid fa-trash" />
                          Eliminar pedido
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printOnTicket(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en 80MM
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printOnA4(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en A4
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printOnLetter(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en 8 1/2 x 11
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="printOnMediaLetter(register.id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-print"
                            class="mr-2"
                          />
                          Imprimir en M. carta
                        </button>
                      </div>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
      <UpdateOrder
        :showModal="showModal"
        v-if="isShowModal"
        :getdata="resetValuesAndGetData"
        :id="idRegister"
        :details="detailsOrder"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observer } from "@/observer";
import { idLocalStore } from "@/publicjs/localStorage";
import { money, format } from "@/publicjs/money";

import config from "/public/apis/config.json";

import { deleteOrder } from "@/repository/Sales/OrdersQuotesAndTemporarySales/Delete";
import { printOnA4 } from "@/components/CustomerOrder/PrintVoucher/PrintOnA4";
import { printOnLetter } from "@/components/CustomerOrder/PrintVoucher/PrintOnLetter";
import { printOnMediaLetter } from "@/components/CustomerOrder/PrintVoucher/PrintOnMediaLetter";
import { printOnTicket } from "@/components/CustomerOrder/PrintVoucher/PrintOnTicket";
import UpdateOrder from "@/components/CustomerOrder/UpdateOrder.vue";
export default {
  name: "MainCustomerOrder",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    UpdateOrder,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "cliente",
        },
        {
          title: "comprobante",
        },
        {
          title: "n° de comprobante",
        },
        {
          title: config.iva,
        },
        {
          title: "subtotal",
        },
        {
          title: "total",
        },
        {
          title: "Comentario",
        },
        {
          title: "Estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];
    const isShowModal = ref(false);
    const idRegister = ref(0);
    const detailsOrder = ref([]);
    const showModal = (id = 0, details = []) => {
      idRegister.value = id;
      isShowModal.value = !isShowModal.value;
      detailsOrder.value = details;
    };
    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const idStore = ref(0); //ID de la sucursal
    const shoppingState = ref("VIGENTE");
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `pedido/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idStore.value}&state=${shoppingState.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(() => {
        if (widthNav.value) {
          widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
        }
      });
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      idStore.value = await idLocalStore();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      shoppingState,
      money,
      format,
      printOnA4,
      printOnLetter,
      printOnMediaLetter,
      printOnTicket,
      deleteOrder,
      showModal,
      isShowModal,
      idRegister,
      detailsOrder,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
