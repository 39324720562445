<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="containerCancelSale">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[20vw] md:max-w-[27vw] max-w-[90vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Anular venta
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div class="p-2 bg-blue-50 rounded-md text-blue-500 mb-3 text-md font-semibold">
            VENTA: {{ numberSale }}
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white uppercase"
            >
              Tipo de anulación
            </span>
            <select
              class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
              v-model="dataForm.typeCancel"
            >
              <option value="DETERIORO DE FACTURA PRE-IMPRESA">
                DETERIORO DE FACTURA PRE-IMPRESA
              </option>
              <option value="ERRORES DE IMPRESIÓN">ERRORES DE IMPRESIÓN</option>
              <option value="DUPLICIDAD DE FACTURA">
                DUPLICIDAD DE FACTURA
              </option>
              <option value="CORRECCIÓN DE LA INFORMACIÓN">
                CORRECCIÓN DE LA INFORMACIÓN
              </option>
              <option value="CAMBIO DE PRODUCTOS">CAMBIO DE PRODUCTOS</option>
              <option value="DEVOLUCIÓN DE PRODUCTOS">
                DEVOLUCIÓN DE PRODUCTOS
              </option>
              <option value="OMISIÓN DE PRODUCTOS">OMISIÓN DE PRODUCTOS</option>
              <option value="ERRORES EN SECUENCIA DE NFC">
                ERRORES EN SECUENCIA DE NFC
              </option>
              <option value="ERRORES EN SECUENCIA DE N° DE DOCUMENTO">
                ERRORES EN SECUENCIA DE N° DE DOCUMENTO
              </option>
            </select>
          </label>

          <div class="flex lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="cancelSales"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Anulando venta
              </div>
              <span v-else>Anular venta</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "../../../api";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";
export default {
  name: "CancelSales",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    numberSale: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      typeCancel: "DETERIORO DE FACTURA PRE-IMPRESA",
    });

    /**********************************************************************************************************
     * Funcion para anular un venta
     **********************************************************************************************************/
    const cancelSales = async () => {
      spinner.value = true;
      const response = await axios
        .put(
          "ventas/cancelar/" + props.id,
          {
            idUser: dataForm.value.idUser,
            typeOfCancellation: dataForm.value.typeCancel,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(() => {
          errorActions(
            "Lo sentimos algo salio mal, <strong>no pudimos anular la venta</strong> <br> vuelve a intentarlo"
          );
          spinner.value = false;
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `La venta a sido  <strong>ANULADO</strong> con éxito`
        );
        isActive();
        props.getdata()
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos <strong>ANULAR</strong> la venta`
        );
      }

      spinner.value = false;
    };

    onMounted(async () => {
      observeElement("#containerCancelSale");
      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
    });

    return {
      isActive,
      dataForm,
      cancelSales,
      spinner,
    };
  },
};
</script>
