<template>
  <!-- <LoadingDashboardCards /> -->
  <div
    class="bg-white w-full md:w-auto text-gray-500 shadow-md shadow-gray-100 rounded-md px-4 py-4 border border-gray-100 flex justify-between"
  >
    <div>
      <span class="text-sm block mb-3 uppercase">total de Ventas</span>
      <span class="text-xl block font-extrabold text-green-500"
        >{{ money }} {{ total }}</span
      >
      <span class="text-sm block mt-3 text-gray-400">Desde el primer día</span>
    </div>
    <div class="w-10 text-green-500 flex justify-end">
      <div
        class="w-9 h-9 flex items-center justify-center bg-green-100 rounded-full"
      >
        <font-awesome-icon icon="fa-solid fa-cart-shopping" class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalSalesForTheCurrentMonth",
  props: {
    total: {
      type: String,
      required: true,
    },
    money: {
      type: String,
      required: true,
    },
  },
};
</script>
