<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="modalContainer">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[40vw] md:max-w-[50vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Importar productos desde excel
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div>
          <div class="bg-orange-50 p-3 rounded-md text-sm text-orange-600">
            Para subir los productos desde un archivo excel por favor revisa el
            formato que tiene que tener el libro para que la carga sea
            satisfactorio. <br />
            <a
              href="/files/productos.xlsx"
              download
              class="text-blue-500 block mt-1"
              >Descargar formato</a
            >
          </div>
          <div>
            <form @submit.prevent>
              <div class="grid md:grid-cols-2 gap-4">
                <label class="block">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Sucursales
                  </span>
                  <Stores @send-data="getSelectStore" />
                </label>
                <label class="block">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    Tipo de productos
                  </span>
                  <TypeOfProducts @send-data="getSelectTypeOfProducts" />
                </label>

                <label class="block w-full">
                  <span
                    class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    U.M
                  </span>
                  <UnitMeasure @send-data="getSelectUnitMeasure" />
                </label>
              </div>
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-20 border-2 border-blue-200 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-blue-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 mt-6 text-blue-500"
              >
                <div class="flex items-center justify-center w-full px-4">
                  <div class="w-6 h-6 mr-4">
                    <svg class="w-6 h-6">
                      <use href="@/assets/svg/icon.svg#upload" />
                    </svg>
                  </div>
                  <p class="text-sm dark:text-gray-400">
                    Click para seleccionar archivo
                    <span class="font-bold">XLSX</span>
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  accept=".xlsx"
                  ref="fileInput"
                  @change="handleFileChange($event)"
                />
              </label>
              <div
                class="text-blue-500 bg-blue-50 p-3 rounded-md border-2 border-blue-100 mt-6 mb-6 text-xs"
                v-show="nameFile.length > 0"
              >
                Archivo seleccionado:
                <span class="font-bold">{{ nameFile }}</span>
              </div>
              <button
                class="bg-blue-500 text-white text-sm rounded-md border-2 border-blue-500 hover:bg-blue-600 px-3 py-3 mt-3 flex items-center active:scale-95"
                @click="insert"
              >
                <svg class="w-4 h-4 mr-2">
                  <use href="@/assets/svg/icon.svg#upload" />
                </svg>
                Subir productos
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Swal from "sweetalert2";

import ExcelJS from "exceljs";
import axios from "@/api";
import { observeElement } from "@/observer";
import { errorActions, uploadData } from "@/alerts";
import Stores from "@/components/PublicComponents/Stores.vue";
import UnitMeasure from "@/components/PublicComponents/UnitMeasure.vue";
import TypeOfProducts from "@/components/PublicComponents/TypeOfProducts.vue";
import { idLocalUser } from "@/publicjs/localStorage";

export default {
  name: "ImportProducts",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getData: {
      type: Function,
      required: true,
    },
  },
  components: {
    Stores,
    UnitMeasure,
    TypeOfProducts,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }
    const spinner = ref(false);

    const dataForm = ref({
      idStore: 0,
      idUnitMeasure: 0,
      idTypeProduct: 0,
      idUser: 0,
    });

    const nameFile = ref("");
    const workbook = ref([]);
    const dataExcel = ref([]);

    const fileInput = ref(null);

    const handleFileChange = async (e) => {
      const file = await fileInput.value.files[0];
      nameFile.value = e.target.files[0].name;
      await readExcelFile(file);
    };

    const readExcelFile = async (file) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        await new Promise((resolve) => {
          fileReader.onload = resolve;
        });

        const buffer = fileReader.result;
        const newWorkbook = new ExcelJS.Workbook();
        await newWorkbook.xlsx.load(buffer);

        workbook.value = newWorkbook;
      } catch (error) {
        errorActions("Error al leer el archivo Excel");
      }
    };

    const processData = async () => {
      if (workbook.value) {
        const worksheet = workbook.value.getWorksheet(1);
        const headers = worksheet.getRow(1).values.slice(1); // Obtener los valores de la primera fila como encabezados

        // Objeto que mapea los nombres originales a los nuevos nombres
        const headerMappings = {
          codigo_de_barra: "barCode",
          nombre_de_producto: "productName",
          descripcion_de_producto: "descripction",
          descuento: "discount",
          libre_de_impuesto: "taxFree",
          utilidad: "utility",
          iva: "igv",
          stock: "stock",
          stock_minimo: "minimumStock",
          precio_de_compra: "purchasePrice",
          precio_de_venta: "salePrice",
          es_con_stock: "isItWithStock",
        };

        const data = [];

        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            const rowData = row.values.slice(1); // Omitir la primera columna si contiene índices (row.values[0])
            const rowDataObject = {};

            for (let i = 0; i < headers.length; i++) {
              // Obtener el nombre original del encabezado
              const header = headers[i];
              // Obtener el nuevo nombre del encabezado si está en el objeto de mapeo, de lo contrario, mantener el nombre original
              const newHeader = headerMappings[header] || header;
              rowDataObject[newHeader] =
                rowData[i] === undefined ? "" : rowData[i];
            }

            // Agregar los datos adicionales al objeto rowDataObject
            rowDataObject.idStore = dataForm.value.idStore;
            rowDataObject.idTypeProduct = dataForm.value.idTypeProduct;
            rowDataObject.idUnitMeasure = dataForm.value.idUnitMeasure;
            rowDataObject.idUser = dataForm.value.idUser;

            data.push(rowDataObject);
          }
        });

        dataExcel.value = data;
      }
    };

    const insert = async () => {
      if (dataForm.value.idStore <= 0) {
        errorActions(
          "Por favor, Selecciona un sucursal donde cargaras los datos"
        );
        return;
      }
      if (dataForm.value.idTypeProduct <= 0) {
        errorActions("Por favor, Selecciona un tipo de producto");
        return;
      }
      if (dataForm.value.idUnitMeasure <= 0) {
        errorActions(
          "Por favor, Selecciona una unidad de medida inicial del producto"
        );
        return;
      }
      if (!fileInput.value || fileInput.value.files.length === 0) {
        errorActions(
          "Por favor, primero selecciona un archivo donde se encuentra tus productos y vuelve a intentarlo"
        );
        return;
      }
      await processData();
      if (dataExcel.value.length <= 0) {
        errorActions(
          "Por favor, primero selecciona un archivo donde se encuentra tus productos y vuelve a intentarlo"
        );
        return;
      }
      await uploadData(
        "SUBIENDO DATOS AL SERVIDOR",
        "Espera un momento mientras subimos tus datos al servidor. <br> <strong>Esto tomara unos minutos...</strong>"
      );
      spinner.value = true;
      const response = await axios
        .post(
          "cargar-productos-desde-excel",
          {
            arrayData: dataExcel.value,
          },
          {
            method: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => error);
      //   await closeAlert();
      const data = await response.data;
      if (data.msg === true) {
        Swal.fire({
          icon: "success",
          title: "Registrado",
          html: `Hemos subido ${dataExcel.value.length} productos al servidor de manera satisfactoria.`,
          confirmButtonColor: "#315df5",
          confirmButtonText: "Aceptar!",
        });
        observeElement(".swal2-container");
        dataExcel.value = [];
        nameFile.value = "";
        fileInput.value = null;
      } else {
        errorActions(
          "Lo sentimo no pudimo subir productos al servidor intentalo de nuevo"
        );
      }

      spinner.value = true;
    };

    //recepcion de datos desde el componente publico, todas la sucursales
    const getSelectStore = (data) => {
      dataForm.value.idStore = data;
    };
    //recepcion de datos desde el componente publico, todas los tipo de productos
    const getSelectTypeOfProducts = (data) => {
      dataForm.value.idTypeProduct = data;
    };
    //recepcion de datos desde el componente publico, todas las unidades de medida
    const getSelectUnitMeasure = (data) => {
      dataForm.value.idUnitMeasure = data;
    };

    onMounted(async () => {
      dataForm.value.idUser = await idLocalUser();
    });
    return {
      isActive,
      spinner,
      handleFileChange,
      nameFile,
      insert,
      getSelectStore,
      getSelectTypeOfProducts,
      getSelectUnitMeasure,
      fileInput,
    };
  },
};
</script>
