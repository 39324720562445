<template>
  <div>
    <Bar :data="data" :options="options" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
} from "chart.js";
import { Bar } from "vue-chartjs";
import { ref } from "vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export default {
  name: "ChartComponent",
  components: {
    Bar,
  },
  props: {
    datas: {
      type: Object,
      required: true,
    },
    titleChart: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const products = props.datas.map((data) => data.producto);
    const colors = ref([]);
    const getRandomColor = () => {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return `rgb(${r}, ${g}, ${b})`;
    };
    const quantities = props.datas.map((data) => {
      colors.value.push(getRandomColor());
      return parseFloat(data.cantidad.replace(",", ""));
    });

    const datasets = [
      {
        label: "Cantidad",
        data: quantities,
        backgroundColor: colors.value,
        borderColor: colors.value,
        borderWidth: 1,
      },
    ];

    const data = ref({
      labels: products,
      datasets: datasets,
    });

    //Configuracion de todas la opciones del gráfico de barra, como titulos, leyendas, etc.
    const options = ref({
      responsive: true,

      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        colors: {
          enabled: true,
        },
        title: {
          display: true,
          text: props.titleChart,
        },
      },
    });
    return {
      data,
      options,
    };
  },
};
</script>
<style scoped>
canvas {
  height: 300px !important;
  width: 100% !important;
}
.chart-container {
  width: 100%;
  height: auto;
}
</style>
